import { prediction } from "./prediction";

class PredictionService {
  static async getPrediction(formData) {
    const env = process.env.REACT_APP_ENV;

    if (env === "local") {
      return prediction;
    }

    const basepath = process.env.REACT_APP_API_URL;
    try {
      const response = await fetch(`${basepath}/rent/predict`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error("Error al obtener la predicción");
      }
      return await response.json();
    } catch (error) {
      console.error("Error en PredictionService:", error);
      throw error;
    }
  }
}

export default PredictionService;
