// src/App.js
import React, { useState } from 'react';
import "bootstrap/dist/css/bootstrap.min.css";

import { Row, Col, Container } from 'react-bootstrap';

import { file_data } from '../config/data';
import { historic_data } from '../config/historic_data';

import HeaderComponent from '../components/statistics/HeaderComponent';
import MedianPriceByM2 from '../components/statistics/MedianPriceByM2';
import MonthlyPriceEvolution from '../components/statistics/MonthlyPriceEvolution';
import MonthlyPriceEvolutionByM2 from '../components/statistics/MonthlyPriceEvolutionByM2';
import MarketNumbers from '../components/statistics/MarketNumbers';
import RoomPriceCharts from '../components/statistics/RoomPriceChart';
import PropertyDistribution from '../components/statistics/PiePropertyDistribution';
import NeighborhoodSelector from '../components/NeighborhoodSelector';

// Extraer los barrios únicos
const getNeighborhoods = (data) => {
    const neighborhoods = new Set(data.map((item) => item.normalized_neighborhood).filter(Boolean));
    return ["Todos", ...Array.from(neighborhoods)];
  };

const StatisticsPage = () => {

    const [selectedNeighborhood, setSelectedNeighborhood] = useState("Todos");
    const neighborhoods = getNeighborhoods(file_data);

    // Filtrar datos según el barrio seleccionado
    const filteredData = selectedNeighborhood === "Todos"
        ? file_data
        : file_data.filter(item => item.normalized_neighborhood === selectedNeighborhood);

    return (
        <>
            <Container style={{ marginTop: '20px', marginBottom: '20px', backgroundColor: 'white', padding: '20px', borderRadius: '10px' }}>
                {/* Header */}
                <Row className='mb-3'>
                    <Col xs={12} className="text-center">
                        <h1 className="fw-light">Estadísticas del Mercado Inmobiliario</h1>
                        <p>Conoce el comportamiento del mercado inmobiliario en tu barrio.</p>
                    </Col>
                </Row>
                <Row className='mb-3'>
                    <HeaderComponent/>
                </Row>
                <Row className='mb-3'> 
                    <MedianPriceByM2 data={file_data} />
                </Row>
                {/* Selector de barrio */}
                <Row className="justify-content-center">
                    <Col md={6}>
                        <NeighborhoodSelector
                            neighborhoods={neighborhoods}
                            selected={selectedNeighborhood}
                            onChange={setSelectedNeighborhood}
                        />
                    </Col>
                </Row>
                {/* Gráficos */}
                <Row>
                    <MonthlyPriceEvolution data={historic_data} neighborhood={selectedNeighborhood} />
                </Row>
                <Row>
                    <MonthlyPriceEvolutionByM2 data={historic_data} neighborhood={selectedNeighborhood} />
                </Row>
                {/* TODO -> Ver como utilizar info real sin tener que hacer peticiones */}
                {/*
                <Row>
                    <MarketNumbers total_properties={file_data.length} removed_propierties="1234" new_properties="535" />
                </Row>
                */}
                <Row>
                    <RoomPriceCharts data={filteredData} neighborhood={selectedNeighborhood} />
                </Row>
                <Row>
                    <PropertyDistribution data={filteredData} neighborhood={selectedNeighborhood} />
                </Row>
            </Container>
        </>
    );
}

export default StatisticsPage;