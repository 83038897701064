import React from "react";
import { Outlet } from "react-router-dom"; // Para mostrar el contenido dinámico de cada página
import Footer from "./Footer";
import Header from "./Header";

const Layout = () => {
  return (
    <>
      <div className="body-bg">
        <Header />
        <Outlet />
        <Footer />
      </div>
    </>
  );
};

export default Layout;
