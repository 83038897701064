export const file_data = [{price: 550000.0, neighborhood: "Villa Santa Rita", total_area: 38.0, rooms: 2.0, bedrooms: 1.0,bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "San Telmo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "Belgrano", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Centro / Microcentro", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Retiro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1098000.0, neighborhood: "Puerto Madero", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 1464000.0, neighborhood: "Palermo Nuevo", total_area: 75.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1159000.0, neighborhood: "Palermo Nuevo", total_area: 84.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 590000.0, neighborhood: "Barrio Norte", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Villa Crespo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 990000.0, neighborhood: "Colegiales", total_area: 85.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Almagro", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Flores", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 976000.0, neighborhood: "Puerto Madero", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Barrio Norte", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 980000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 790000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Villa Urquiza", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 732000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 460000.0, neighborhood: "Villa Urquiza", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Palermo Chico", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Colegiales", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 680000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 395000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 330000.0, neighborhood: "Colegiales", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 420000.0, neighborhood: "Barrio Norte", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1098000.0, neighborhood: "Puerto Madero", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Colegiales", total_area: 69.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 671000.0, neighborhood: "Palermo Chico", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Colegiales", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 732000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Monserrat", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 854000.0, neighborhood: "Caballito", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 878400.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Flores", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 950000.0, neighborhood: "Palermo", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "Puerto Madero", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 1586000.0, neighborhood: "Palermo Viejo", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 69.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 750000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 780000.0, neighborhood: "Villa Urquiza", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 854000.0, neighborhood: "Puerto Madero", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 671000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 420000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 370000.0, neighborhood: "Floresta", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 732000.0, neighborhood: "Retiro", total_area: 55.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 77.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Liniers", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 680000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Recoleta", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 340000.0, neighborhood: "Caballito", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 300000.0, neighborhood: "San Nicol\u00e1s", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 420000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1403000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Villa General Mitre", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 1159000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1159000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 380000.0, neighborhood: "Balvanera", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Congreso", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 488000.0, neighborhood: "Recoleta", total_area: 14.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Congreso", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Centro / Microcentro", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 57.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 854000.0, neighborhood: "Colegiales", total_area: 82.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 671000.0, neighborhood: "Belgrano", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Villa del Parque", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 600000.0, neighborhood: "Belgrano R", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Belgrano C", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 930000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 460000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Puerto Madero", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 420000.0, neighborhood: "San Nicol\u00e1s", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1464000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 750000.0, neighborhood: "Palermo Nuevo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Villa Devoto", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 768600.0, neighborhood: "N\u00fa\u00f1ez", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 905240.0, neighborhood: "Retiro", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1342000.0, neighborhood: "Retiro", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1037000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 580000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 915000.0, neighborhood: "Retiro", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1342000.0, neighborhood: "Palermo Soho", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Belgrano Chico", total_area: 51.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Flores", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1037000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Villa Ortuzar", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 671000.0, neighborhood: "Villa Urquiza", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 793000.0, neighborhood: "Belgrano C", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 420000.0, neighborhood: "Palermo Chico", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Palermo Soho", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 330000.0, neighborhood: "Flores", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 440000.0, neighborhood: "Almagro", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 475000.0, neighborhood: "Belgrano R", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 499000.0, neighborhood: "Flores", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 470000.0, neighborhood: "Villa Devoto", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 850000.0, neighborhood: "Villa Urquiza", total_area: 84.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Barracas", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1073600.0, neighborhood: "Barrio Norte", total_area: 90.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1342000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 87.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 477000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Puerto Madero", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 793000.0, neighborhood: "Puerto Madero", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Palermo Viejo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 400000.0, neighborhood: "Belgrano C", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 475800.0, neighborhood: "Colegiales", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 430000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 732000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 585600.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Colegiales", total_area: 64.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 740000.0, neighborhood: "Palermo Chico", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Palermo Chico", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Recoleta", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 470000.0, neighborhood: "Villa Urquiza", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 620000.0, neighborhood: "Recoleta", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1342000.0, neighborhood: "Palermo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Barrio Norte", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 365000.0, neighborhood: "Recoleta", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 590000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 360000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 793000.0, neighborhood: "Villa Urquiza", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 560000.0, neighborhood: "Colegiales", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 480000.0, neighborhood: "Centro / Microcentro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 520000.0, neighborhood: "Villa Crespo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 590000.0, neighborhood: "Barrio Norte", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 580000.0, neighborhood: "Almagro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 560000.0, neighborhood: "Flores", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 490000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1464000.0, neighborhood: "Recoleta", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Retiro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 380000.0, neighborhood: "Balvanera", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1220000.0, neighborhood: "Puerto Madero", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 380000.0, neighborhood: "Centro / Microcentro", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1342000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Caballito", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1000000.0, neighborhood: "Palermo Chico", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Colegiales", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 400000.0, neighborhood: "Retiro", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 850000.0, neighborhood: "Almagro", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "San Telmo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 854000.0, neighborhood: "Palermo Soho", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Colegiales", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Palermo Chico", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 695000.0, neighborhood: "Belgrano", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 915000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 51.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1000000.0, neighborhood: "Caballito", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Palermo Chico", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Puerto Madero", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 460000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 460000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1037000.0, neighborhood: "Palermo", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Caballito Norte", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "San Nicol\u00e1s", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 290000.0, neighborhood: "Parque Patricios", total_area: 16.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 320000.0, neighborhood: "Almagro", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 549000.0, neighborhood: "Palermo Chico", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 597800.0, neighborhood: "Villa Crespo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 420000.0, neighborhood: "Retiro", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Barracas", total_area: 90.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1220000.0, neighborhood: "Caballito", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Flores", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "Villa Luro", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 488000.0, neighborhood: "San Nicol\u00e1s", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 900000.0, neighborhood: "Belgrano", total_area: 82.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 580000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Almagro Norte", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 850000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 420000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Floresta", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 610000.0, neighborhood: "Palermo Nuevo", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo Nuevo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 470000.0, neighborhood: "San Telmo", total_area: 25.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 750000.0, neighborhood: "Villa Devoto", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1098000.0, neighborhood: "Puerto Madero", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "Caballito", total_area: 85.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 620000.0, neighborhood: "Villa Crespo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 540000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Retiro", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1464000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 430000.0, neighborhood: "Parque Patricios", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 980000.0, neighborhood: "Palermo Soho", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 540000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Centro / Microcentro", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 490000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 488000.0, neighborhood: "Villa Crespo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 580000.0, neighborhood: "Belgrano", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 900000.0, neighborhood: "Palermo Chico", total_area: 82.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Colegiales", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 350000.0, neighborhood: "Colegiales", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1220000.0, neighborhood: "Puerto Madero", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 280000.0, neighborhood: "Caballito", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 549000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 460000.0, neighborhood: "Flores", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Parque Chacabuco", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 970000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "San Nicol\u00e1s", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 900000.0, neighborhood: "Recoleta", total_area: 74.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 3.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1220000.0, neighborhood: "Belgrano", total_area: 87.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 480000.0, neighborhood: "Caballito", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 579500.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1586000.0, neighborhood: "Palermo Viejo", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1037000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Colegiales", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 700000.0, neighborhood: "Centro / Microcentro", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Abasto", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 599000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 915000.0, neighborhood: "Villa Crespo", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 565000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1000000.0, neighborhood: "Recoleta", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 951600.0, neighborhood: "Villa Crespo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 780000.0, neighborhood: "Barrio Norte", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 80.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Parque Patricios", total_area: 68.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 950000.0, neighborhood: "Belgrano", total_area: 77.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 488000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Centro / Microcentro", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 700000.0, neighborhood: "Villa Urquiza", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 480000.0, neighborhood: "Almagro", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 610000.0, neighborhood: "Villa Urquiza", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 850000.0, neighborhood: "Palermo Soho", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Belgrano", total_area: 82.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Centro / Microcentro", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1647000.0, neighborhood: "Belgrano", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 850000.0, neighborhood: "Villa Urquiza", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "San Nicol\u00e1s", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 720000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Flores", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "Palermo", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Caballito", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1220000.0, neighborhood: "Barracas", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 854000.0, neighborhood: "Retiro", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 790000.0, neighborhood: "Palermo Chico", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 78.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 793000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 634400.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "San Nicol\u00e1s", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 420000.0, neighborhood: "Flores", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Centro / Microcentro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 340000.0, neighborhood: "Caballito", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 385000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Villa Ortuzar", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 707600.0, neighborhood: "San Telmo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1159000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Palermo Soho", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Villa Devoto", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Villa Crespo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Villa General Mitre", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 430000.0, neighborhood: "San Telmo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Villa Devoto", total_area: 67.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 900000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Villa Santa Rita", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1098000.0, neighborhood: "Belgrano C", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 488000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Almagro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 330000.0, neighborhood: "Parque Chacabuco", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Caballito Norte", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 570000.0, neighborhood: "Villa Devoto", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 488000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Palermo Nuevo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 650000.0, neighborhood: "Belgrano R", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Villa del Parque", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 550000.0, neighborhood: "Colegiales", total_area: 39.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1000000.0, neighborhood: "Colegiales", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 450000.0, neighborhood: "San Nicol\u00e1s", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 350000.0, neighborhood: "Belgrano C", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 620000.0, neighborhood: "Recoleta", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Villa Crespo", total_area: 67.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 730000.0, neighborhood: "Recoleta", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Almagro Norte", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 976000.0, neighborhood: "San Telmo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1220000.0, neighborhood: "Palermo Soho", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 660000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Constituci\u00f3n", total_area: 43.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1037000.0, neighborhood: "Puerto Madero", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 732000.0, neighborhood: "San Nicol\u00e1s", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Flores Norte", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 800000.0, neighborhood: "Balvanera", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Colegiales", total_area: 45.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 415000.0, neighborhood: "Monserrat", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 671000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Flores", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Villa Lugano", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 370000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 690000.0, neighborhood: "Villa Urquiza", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Almagro Norte", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 300000.0, neighborhood: "Palermo", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 650000.0, neighborhood: "Congreso", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 670000.0, neighborhood: "Barrio Norte", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Palermo Chico", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Flores", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 671000.0, neighborhood: "Monserrat", total_area: 45.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 580000.0, neighborhood: "Flores", total_area: 66.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 915000.0, neighborhood: "Palermo Viejo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 82.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Balvanera", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 350000.0, neighborhood: "Liniers", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Villa General Mitre", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 430000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Villa Urquiza", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1464000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Retiro", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 972000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 68.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1000000.0, neighborhood: "Recoleta", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Villa del Parque", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 420000.0, neighborhood: "Caballito Sur", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Barracas", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 756400.0, neighborhood: "Palermo", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Caballito Sur", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 2.0, bedrooms: 11.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Tribunales", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 610000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 430000.0, neighborhood: "Caballito", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 370000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1000000.0, neighborhood: "Barracas", total_area: 89.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 900000.0, neighborhood: "Barracas", total_area: 90.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 390000.0, neighborhood: "Monserrat", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 1220000.0, neighborhood: "Belgrano C", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 730000.0, neighborhood: "Villa Crespo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 915000.0, neighborhood: "Recoleta", total_area: 72.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1647000.0, neighborhood: "Recoleta", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Congreso", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 650000.0, neighborhood: "Floresta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Tribunales", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 420000.0, neighborhood: "Villa Crespo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Puerto Madero", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 732000.0, neighborhood: "Parque Centenario", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 700000.0, neighborhood: "Villa Ortuzar", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1586000.0, neighborhood: "Palermo Soho", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 300000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 580000.0, neighborhood: "Barrio Norte", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 650000.0, neighborhood: "Belgrano R", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 515000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 340000.0, neighborhood: "Caballito", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 610000.0, neighborhood: "San Nicol\u00e1s", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 51.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1647000.0, neighborhood: "Palermo", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Villa del Parque", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 650000.0, neighborhood: "Caballito Norte", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 420000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 488000.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Palermo Soho", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 730000.0, neighborhood: "Palermo", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 670000.0, neighborhood: "Caballito", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Villa General Mitre", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 793000.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "Retiro", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 695000.0, neighborhood: "Villa Crespo", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Belgrano C", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Retiro", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 380000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 370000.0, neighborhood: "Caballito", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Floresta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1037000.0, neighborhood: "Villa Urquiza", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 520000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1098000.0, neighborhood: "Belgrano", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1464000.0, neighborhood: "Recoleta", total_area: 80.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 670000.0, neighborhood: "Palermo Soho", total_area: 82.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 549000.0, neighborhood: "Centro / Microcentro", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 900000.0, neighborhood: "Caballito Norte", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 390000.0, neighborhood: "Floresta Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Monserrat", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Centro / Microcentro", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "San Telmo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 520000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 380000.0, neighborhood: "Centro / Microcentro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 900000.0, neighborhood: "Palermo Nuevo", total_area: 68.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Belgrano", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 680000.0, neighborhood: "Belgrano", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "San Nicol\u00e1s", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 690000.0, neighborhood: "Abasto", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 597800.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Parque Chas", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 488000.0, neighborhood: "Centro / Microcentro", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 850000.0, neighborhood: "Belgrano", total_area: 68.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 690000.0, neighborhood: "Belgrano C", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 690000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 950000.0, neighborhood: "Belgrano", total_area: 91.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 950000.0, neighborhood: "Villa Urquiza", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 520000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 900000.0, neighborhood: "Villa Devoto", total_area: 74.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Villa del Parque", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 430000.0, neighborhood: "Centro / Microcentro", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Belgrano C", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 150000.0, neighborhood: "Belgrano", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Villa Crespo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 490000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 270000.0, neighborhood: "Recoleta", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Villa Devoto", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1098000.0, neighborhood: "Caballito Norte", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 850000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Barracas", total_area: 81.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Palermo Soho", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 420000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Retiro", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 976000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 560000.0, neighborhood: "Centro / Microcentro", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Villa del Parque", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 950000.0, neighborhood: "Caballito", total_area: 86.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 370000.0, neighborhood: "Villa Urquiza", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Barrio Norte", total_area: 82.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 390000.0, neighborhood: "Caballito", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1464000.0, neighborhood: "Belgrano", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1000000.0, neighborhood: "Palermo Soho", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 680000.0, neighborhood: "Barracas", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 380000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Recoleta", total_area: 87.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Flores", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 854000.0, neighborhood: "Caballito", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Parque Chas", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Palermo Soho", total_area: 24.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Belgrano", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 490000.0, neighborhood: "Retiro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 400000.0, neighborhood: "Belgrano C", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 790000.0, neighborhood: "Congreso", total_area: 85.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 490000.0, neighborhood: "Flores", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 900000.0, neighborhood: "Almagro", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 58.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 300000.0, neighborhood: "Flores", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 420000.0, neighborhood: "Balvanera", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 950000.0, neighborhood: "Barrio Norte", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 320000.0, neighborhood: "Centro / Microcentro", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 570000.0, neighborhood: "Monserrat", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 976000.0, neighborhood: "Almagro Norte", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 699000.0, neighborhood: "Belgrano", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Monserrat", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 700000.0, neighborhood: "Barracas", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 850000.0, neighborhood: "Villa Urquiza", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 580000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 790000.0, neighborhood: "Congreso", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 390000.0, neighborhood: "Villa Santa Rita", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Villa Urquiza", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 42.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Palermo Soho", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Congreso", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 590000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Flores Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Villa Urquiza", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Colegiales", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 390000.0, neighborhood: "Recoleta", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Belgrano C", total_area: 78.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 730000.0, neighborhood: "Palermo", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Belgrano R", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Belgrano C", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 625000.0, neighborhood: "Centro / Microcentro", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 800000.0, neighborhood: "San Telmo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 350000.0, neighborhood: "Belgrano", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 375000.0, neighborhood: "Balvanera", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 420000.0, neighborhood: "Palermo Soho", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 680000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 488000.0, neighborhood: "Tribunales", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 61.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 380000.0, neighborhood: "Floresta", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Colegiales", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 951600.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Colegiales", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 732000.0, neighborhood: "Otro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RETIRO"},
    {price: 430000.0, neighborhood: "Palermo Viejo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Puerto Madero", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 719800.0, neighborhood: "N\u00fa\u00f1ez", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 300000.0, neighborhood: "Flores", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 870000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1647000.0, neighborhood: "Puerto Madero", total_area: 90.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 650000.0, neighborhood: "Caballito Sur", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1000000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 486780.0, neighborhood: "Caballito", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Villa Luro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 550000.0, neighborhood: "Balvanera", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 470000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 320000.0, neighborhood: "Caballito", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Villa Urquiza", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Villa Santa Rita", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 380000.0, neighborhood: "Retiro", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Colegiales", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 750000.0, neighborhood: "Barrio Norte", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Balvanera", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "Retiro", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 650000.0, neighborhood: "Villa Ortuzar", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Parque Patricios", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 430000.0, neighborhood: "San Nicol\u00e1s", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 900000.0, neighborhood: "Belgrano C", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Villa Devoto", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Monserrat", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 580000.0, neighborhood: "Tribunales", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 950000.0, neighborhood: "Barracas", total_area: 85.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 670000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Caballito Sur", total_area: 51.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 549000.0, neighborhood: "Caballito", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "Barrio Norte", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Belgrano R", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Congreso", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 350000.0, neighborhood: "Retiro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 580000.0, neighborhood: "Almagro", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Villa Ortuzar", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 500000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 900000.0, neighborhood: "Belgrano", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 610000.0, neighborhood: "Colegiales", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 600000.0, neighborhood: "Retiro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 600000.0, neighborhood: "Villa Santa Rita", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Palermo Soho", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Parque Chacabuco", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1000000.0, neighborhood: "Villa Ortuzar", total_area: 64.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Balvanera", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 590000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Palermo Nuevo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 524600.0, neighborhood: "Almagro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1000000.0, neighborhood: "Belgrano R", total_area: 88.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 300000.0, neighborhood: "Villa Ortuzar", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 549000.0, neighborhood: "Villa Crespo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 760000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 488000.0, neighborhood: "Colegiales", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 976000.0, neighborhood: "Colegiales", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 854000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Barrio Norte", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 420000.0, neighborhood: "Villa del Parque", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 1207800.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Retiro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 671000.0, neighborhood: "Puerto Madero", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 420000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 829600.0, neighborhood: "Barrio Norte", total_area: 75.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1586000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "San Telmo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 793000.0, neighborhood: "Palermo Viejo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 820000.0, neighborhood: "Colegiales", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1586000.0, neighborhood: "Palermo Nuevo", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 488000.0, neighborhood: "San Nicol\u00e1s", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Balvanera", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Villa del Parque", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "San Telmo", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 599000.0, neighborhood: "Villa Luro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 360000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Colegiales", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Parque Rivadavia", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 340000.0, neighborhood: "Flores", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 780000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 610000.0, neighborhood: "Barrio Norte", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Villa Urquiza", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Flores", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 520000.0, neighborhood: "Caballito Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 590000.0, neighborhood: "Villa Crespo", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1342000.0, neighborhood: "Palermo", total_area: 84.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Belgrano R", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 430000.0, neighborhood: "San Telmo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "Palermo Chico", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Monserrat", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Palermo Viejo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Palermo Chico", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 580000.0, neighborhood: "Barrio Norte", total_area: 64.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Parque Chacabuco", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 560000.0, neighborhood: "San Nicol\u00e1s", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 360000.0, neighborhood: "Palermo Chico", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Congreso", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 680000.0, neighborhood: "Caballito", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 732000.0, neighborhood: "Palermo Chico", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1037000.0, neighborhood: "Barrio Norte", total_area: 90.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Centro / Microcentro", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 480000.0, neighborhood: "Villa Urquiza", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Monserrat", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 490000.0, neighborhood: "Villa Urquiza", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1159000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1586000.0, neighborhood: "Palermo Soho", total_area: 66.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 370000.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1000000.0, neighborhood: "Belgrano R", total_area: 74.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 549000.0, neighborhood: "Monserrat", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 380000.0, neighborhood: "Villa Urquiza", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Balvanera", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 650000.0, neighborhood: "Centro / Microcentro", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 730000.0, neighborhood: "Recoleta", total_area: 79.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Caballito", total_area: 75.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 300000.0, neighborhood: "Balvanera", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "Almagro", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 671000.0, neighborhood: "San Telmo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 300000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 270000.0, neighborhood: "Palermo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Villa Devoto", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1037000.0, neighborhood: "Caballito", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 854000.0, neighborhood: "Retiro", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 650000.0, neighborhood: "Barracas", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 720000.0, neighborhood: "Villa Urquiza", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 660000.0, neighborhood: "Barrio Norte", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 451400.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 646600.0, neighborhood: "Palermo Soho", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Colegiales", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 915000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 670000.0, neighborhood: "San Telmo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 366000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Colegiales", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 460000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1085800.0, neighborhood: "Retiro", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 72.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 27.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1220000.0, neighborhood: "Belgrano R", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Retiro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1034560.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Centro / Microcentro", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 680000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1268800.0, neighborhood: "Recoleta", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Parque Patricios", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 220000.0, neighborhood: "Barrio Norte", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Floresta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 77.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1037000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Barrio Norte", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Villa del Parque", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 976000.0, neighborhood: "Palermo Soho", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 366000.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 530000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 360000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Flores", total_area: 64.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 300000.0, neighborhood: "Barrio Norte", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "San Nicol\u00e1s", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 793000.0, neighborhood: "Colegiales", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Retiro", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Recoleta", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "Palermo Soho", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Almagro", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 560000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 950000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 549000.0, neighborhood: "Centro / Microcentro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 590000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 470000.0, neighborhood: "San Nicol\u00e1s", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1037000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Abasto", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 673440.0, neighborhood: "Constituci\u00f3n", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 13.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Retiro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 320000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "San Nicol\u00e1s", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 488000.0, neighborhood: "San Telmo", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Balvanera", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 410000.0, neighborhood: "Balvanera", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 560000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 260000.0, neighborhood: "Barrio Norte", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Palermo Soho", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Barracas", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 690000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 549000.0, neighborhood: "Centro / Microcentro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 340000.0, neighborhood: "Barrio Norte", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Villa Devoto", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 390000.0, neighborhood: "Barrio Norte", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 485000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1403000.0, neighborhood: "Palermo", total_area: 83.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "San Nicol\u00e1s", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Barrio Norte", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Palermo", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 465000.0, neighborhood: "Villa Urquiza", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1586000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 580000.0, neighborhood: "Belgrano C", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 440000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 72.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Palermo Soho", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 390000.0, neighborhood: "Villa Santa Rita", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 530000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Villa Crespo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Retiro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "Villa Crespo", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 540000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Colegiales", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1647000.0, neighborhood: "Colegiales", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 590000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Monserrat", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "San Telmo", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1281000.0, neighborhood: "Palermo Viejo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 48.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1037000.0, neighborhood: "Belgrano C", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 18.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Villa Devoto", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 854000.0, neighborhood: "Centro / Microcentro", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 490000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Villa Urquiza", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 890000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Villa Urquiza", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Centro / Microcentro", total_area: 67.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Belgrano R", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1037000.0, neighborhood: "Retiro", total_area: 69.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1464000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 90.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 250000.0, neighborhood: "Centro / Microcentro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 530000.0, neighborhood: "Belgrano", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 817400.0, neighborhood: "Recoleta", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Balvanera", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1342000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 950000.0, neighborhood: "Retiro", total_area: 88.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 460000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 590000.0, neighborhood: "Almagro", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 480000.0, neighborhood: "Belgrano", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 793000.0, neighborhood: "Retiro", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 350000.0, neighborhood: "Tribunales", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 732000.0, neighborhood: "Palermo Soho", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Parque Chacabuco", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 650000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 854000.0, neighborhood: "Caballito", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 363000.0, neighborhood: "Recoleta", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Retiro", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 950000.0, neighborhood: "Recoleta", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 440000.0, neighborhood: "Monserrat", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 671000.0, neighborhood: "Balvanera", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 695400.0, neighborhood: "Palermo", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Barrio Norte", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1586000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 80.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 980000.0, neighborhood: "Belgrano", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Parque Centenario", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 671000.0, neighborhood: "Belgrano Chico", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 520000.0, neighborhood: "Almagro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Parque Chacabuco", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 51.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 420000.0, neighborhood: "Villa Crespo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 800000.0, neighborhood: "Villa Ortuzar", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Balvanera", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1159000.0, neighborhood: "Palermo", total_area: 83.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Parque Chacabuco", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Flores Sur", total_area: 81.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 520000.0, neighborhood: "Villa Urquiza", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 51.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Colegiales", total_area: 62.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1000000.0, neighborhood: "Recoleta", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Monserrat", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 1000000.0, neighborhood: "Villa Urquiza", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 330000.0, neighborhood: "Caballito", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 500000.0, neighborhood: "Centro / Microcentro", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 683200.0, neighborhood: "N\u00fa\u00f1ez", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 560000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Flores", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 671000.0, neighborhood: "Villa Devoto", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 390000.0, neighborhood: "Floresta Sur", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 380000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 300000.0, neighborhood: "San Telmo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 366000.0, neighborhood: "Barrio Norte", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 671000.0, neighborhood: "Retiro", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 875000.0, neighborhood: "Villa Luro", total_area: 71.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 460000.0, neighborhood: "Caballito", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Caballito", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 330000.0, neighborhood: "San Nicol\u00e1s", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 670000.0, neighborhood: "Balvanera", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 51.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "San Telmo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1342000.0, neighborhood: "Palermo Chico", total_area: 53.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Centro / Microcentro", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 790000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1220000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Almagro", total_area: 65.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 700000.0, neighborhood: "Palermo Soho", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Palermo Soho", total_area: 73.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 780000.0, neighborhood: "Almagro", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 976000.0, neighborhood: "Retiro", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 841800.0, neighborhood: "Belgrano R", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 420000.0, neighborhood: "Centro / Microcentro", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 490000.0, neighborhood: "Villa Crespo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Belgrano C", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 730000.0, neighborhood: "Palermo", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Villa Crespo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 390000.0, neighborhood: "Centro / Microcentro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 700000.0, neighborhood: "Villa Urquiza", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 980000.0, neighborhood: "Recoleta", total_area: 77.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Belgrano", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1220000.0, neighborhood: "Puerto Madero", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 500000.0, neighborhood: "San Telmo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "Almagro Norte", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 683200.0, neighborhood: "N\u00fa\u00f1ez", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 854000.0, neighborhood: "Villa Urquiza", total_area: 64.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 549000.0, neighborhood: "Barracas", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Flores", total_area: 85.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Villa Devoto", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 620000.0, neighborhood: "Centro / Microcentro", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 680000.0, neighborhood: "Parque Patricios", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 390000.0, neighborhood: "Balvanera", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "Villa Crespo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Almagro Sur", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 360000.0, neighborhood: "Almagro Sur", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 74.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 490000.0, neighborhood: "Barrio Norte", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "Belgrano C", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Barrio Norte", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 340000.0, neighborhood: "Caballito Norte", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 300000.0, neighborhood: "Balvanera", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 800000.0, neighborhood: "Palermo Nuevo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Palermo Nuevo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 457500.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Colegiales", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Parque Chacabuco", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1464000.0, neighborhood: "Recoleta", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 280000.0, neighborhood: "Palermo", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1000000.0, neighborhood: "Palermo Nuevo", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Villa Crespo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Belgrano R", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 680000.0, neighborhood: "Flores", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 350000.0, neighborhood: "Monserrat", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 699000.0, neighborhood: "Barracas", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "Balvanera", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Belgrano C", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 820000.0, neighborhood: "Villa Urquiza", total_area: 74.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 800000.0, neighborhood: "Caballito", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Villa Urquiza", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Belgrano Chico", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 610000.0, neighborhood: "Palermo Chico", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 53.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1159000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Almagro", total_area: 72.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1000000.0, neighborhood: "Barrio Norte", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Parque Centenario", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 280000.0, neighborhood: "Barrio Norte", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 430000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Flores", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Villa Devoto", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 990000.0, neighborhood: "Belgrano Chico", total_area: 53.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Palermo Soho", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "Colegiales", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 510000.0, neighborhood: "Belgrano C", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 395000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Villa del Parque", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 850000.0, neighborhood: "Belgrano Chico", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 488000.0, neighborhood: "Villa Crespo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 305000.0, neighborhood: "Retiro", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Barrio Norte", total_area: 84.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Monserrat", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 300000.0, neighborhood: "San Nicol\u00e1s", total_area: 28.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 280000.0, neighborhood: "Barrio Norte", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 549000.0, neighborhood: "San Nicol\u00e1s", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 850000.0, neighborhood: "Colegiales", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 671000.0, neighborhood: "Parque Patricios", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 380000.0, neighborhood: "Retiro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 690000.0, neighborhood: "Villa Devoto", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 435000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 675000.0, neighborhood: "Caballito", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 300000.0, neighborhood: "Caballito", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 475800.0, neighborhood: "Congreso", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 520000.0, neighborhood: "Mataderos", total_area: 64.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Villa Devoto", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 63.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 630000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 950000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 270000.0, neighborhood: "Barrio Norte", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "San Telmo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Caballito Norte", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "Villa del Parque", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Retiro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 450000.0, neighborhood: "Tribunales", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 420000.0, neighborhood: "San Telmo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 488000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 950000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 670000.0, neighborhood: "Caballito Norte", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 671000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 340000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "San Telmo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 550000.0, neighborhood: "Belgrano C", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 399000.0, neighborhood: "Centro / Microcentro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 300000.0, neighborhood: "Palermo", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 790000.0, neighborhood: "Belgrano", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 470000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 671000.0, neighborhood: "Retiro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 280000.0, neighborhood: "Constituci\u00f3n", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 920000.0, neighborhood: "Villa Urquiza", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1464000.0, neighborhood: "Palermo Chico", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Almagro Norte", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 700000.0, neighborhood: "San Telmo", total_area: 88.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 854000.0, neighborhood: "Caballito", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Flores Norte", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 490000.0, neighborhood: "Balvanera", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Villa Santa Rita", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "San Nicol\u00e1s", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Tribunales", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "San Telmo", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 743000.0, neighborhood: "Belgrano", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Parque Chacabuco", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Villa Santa Rita", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Colegiales", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 650000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Villa Santa Rita", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 430000.0, neighborhood: "Balvanera", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Colegiales", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 520000.0, neighborhood: "Palermo", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Colegiales", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 500000.0, neighborhood: "Flores", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 475000.0, neighborhood: "Parque Chacabuco", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Caballito Sur", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "Almagro Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 850000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 60.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 619000.0, neighborhood: "Belgrano C", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 580000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Balvanera", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "San Telmo", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 880000.0, neighborhood: "Caballito", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 510000.0, neighborhood: "Belgrano R", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 402600.0, neighborhood: "Balvanera", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 597800.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Parque Rivadavia", total_area: 83.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 390000.0, neighborhood: "Villa Crespo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 800000.0, neighborhood: "Abasto", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Villa Ortuzar", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 540000.0, neighborhood: "Parque Chacabuco", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 732000.0, neighborhood: "San Telmo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 570000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 250000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 540000.0, neighborhood: "Villa del Parque", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 650000.0, neighborhood: "Liniers", total_area: 66.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Balvanera", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1647000.0, neighborhood: "Belgrano", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 430000.0, neighborhood: "San Nicol\u00e1s", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1098000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Monserrat", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Recoleta", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Flores", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 620000.0, neighborhood: "Palermo", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "Villa Crespo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 330000.0, neighborhood: "Abasto", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 370000.0, neighborhood: "Monserrat", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 488000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Tribunales", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Flores", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 470000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 320000.0, neighborhood: "Belgrano", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 660000.0, neighborhood: "Caballito Sur", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Belgrano", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 690000.0, neighborhood: "Villa Crespo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 720000.0, neighborhood: "Villa del Parque", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 850000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 900000.0, neighborhood: "Recoleta", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1000000.0, neighborhood: "Barrio Norte", total_area: 64.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 475000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Villa Devoto", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1000000.0, neighborhood: "Recoleta", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 515000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Centro / Microcentro", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 430000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Colegiales", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 395000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Villa Urquiza", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Retiro", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 400000.0, neighborhood: "Villa del Parque", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Belgrano C", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "San Telmo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 450000.0, neighborhood: "Flores", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1037000.0, neighborhood: "Villa Devoto", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 460000.0, neighborhood: "Parque Patricios", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 700000.0, neighborhood: "Parque Chacabuco", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 380000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Barracas", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 878400.0, neighborhood: "Belgrano", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 360000.0, neighborhood: "Villa Urquiza", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Retiro", total_area: 79.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 380000.0, neighborhood: "Villa Crespo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Caballito", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Flores", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 64.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 850000.0, neighborhood: "San Telmo", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 580000.0, neighborhood: "Belgrano", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 490000.0, neighborhood: "Caballito Norte", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Constituci\u00f3n", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 345000.0, neighborhood: "Flores Norte", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Belgrano", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 520000.0, neighborhood: "Barrio Norte", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 310000.0, neighborhood: "San Nicol\u00e1s", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 800000.0, neighborhood: "Barrio Norte", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 670000.0, neighborhood: "Colegiales", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1000000.0, neighborhood: "Flores", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1098000.0, neighborhood: "San Telmo", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 300000.0, neighborhood: "Centro / Microcentro", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Villa Urquiza", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "Caballito", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 280000.0, neighborhood: "Congreso", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Floresta Sur", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 480000.0, neighborhood: "Recoleta", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 460000.0, neighborhood: "Caballito", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 366000.0, neighborhood: "San Telmo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 330000.0, neighborhood: "Floresta", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 980000.0, neighborhood: "Belgrano C", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1098000.0, neighborhood: "Palermo Chico", total_area: 84.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Balvanera", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Almagro", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Balvanera", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 430000.0, neighborhood: "Barrio Norte", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 320000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 590000.0, neighborhood: "Palermo", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 540000.0, neighborhood: "Villa del Parque", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 366000.0, neighborhood: "Balvanera", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 650000.0, neighborhood: "Belgrano R", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Retiro", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 56.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 420000.0, neighborhood: "San Telmo", total_area: 37.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 450000.0, neighborhood: "Congreso", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 480000.0, neighborhood: "Retiro", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 770000.0, neighborhood: "San Telmo", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "Centro / Microcentro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Abasto", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 976000.0, neighborhood: "Palermo Soho", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 427000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Centro / Microcentro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 570000.0, neighborhood: "Almagro Norte", total_area: 41.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Parque Centenario", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 430000.0, neighborhood: "Colegiales", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Retiro", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 780000.0, neighborhood: "Belgrano C", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 420000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "Palermo Chico", total_area: 80.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Flores", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 650000.0, neighborhood: "Parque Chacabuco", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 370000.0, neighborhood: "Villa Crespo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Barracas", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 470000.0, neighborhood: "Caballito", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 630000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Abasto", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Monserrat", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 780000.0, neighborhood: "Villa Urquiza", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 395000.0, neighborhood: "Congreso", total_area: 79.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 913780.0, neighborhood: "Colegiales", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 380000.0, neighborhood: "Belgrano", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 610000.0, neighborhood: "Monserrat", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 550000.0, neighborhood: "Villa Crespo", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 620000.0, neighborhood: "Congreso", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Villa General Mitre", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 350000.0, neighborhood: "Monserrat", total_area: 25.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 530000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "Barrio Norte", total_area: 62.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Balvanera", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Monserrat", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 649000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa Devoto", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 630000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Palermo Viejo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Caballito", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Villa Devoto", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 320000.0, neighborhood: "Centro / Microcentro", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Belgrano R", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Retiro", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 420000.0, neighborhood: "Belgrano", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "Palermo Nuevo", total_area: 50.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 320000.0, neighborhood: "Palermo", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1525000.0, neighborhood: "Puerto Madero", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 580000.0, neighborhood: "Villa Urquiza", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 850000.0, neighborhood: "Villa Santa Rita", total_area: 79.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 480000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 610000.0, neighborhood: "Villa Urquiza", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 732000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 549000.0, neighborhood: "San Telmo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 680000.0, neighborhood: "Barrio Norte", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Abasto", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Palermo Soho", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 80.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Caballito", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1586000.0, neighborhood: "San Telmo", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 854000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1220000.0, neighborhood: "Villa Devoto", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Caballito Norte", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 360000.0, neighborhood: "Barrio Norte", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Caballito Sur", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "San Nicol\u00e1s", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Almagro Norte", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 530000.0, neighborhood: "Colegiales", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 520000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Flores", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1000000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1220000.0, neighborhood: "Puerto Madero", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 300000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 402600.0, neighborhood: "Balvanera", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 610000.0, neighborhood: "Palermo Viejo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 580000.0, neighborhood: "Villa Crespo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 439000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 620000.0, neighborhood: "Villa Urquiza", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 320000.0, neighborhood: "Villa Urquiza", total_area: 27.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 79.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Belgrano C", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 26.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo Viejo", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 430000.0, neighborhood: "Balvanera", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 390000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1000000.0, neighborhood: "Villa Urquiza", total_area: 83.0, rooms: 2.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Palermo Viejo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 990000.0, neighborhood: "Balvanera", total_area: 90.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 890000.0, neighborhood: "Caballito", total_area: 86.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 680000.0, neighborhood: "Recoleta", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Villa del Parque", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 395000.0, neighborhood: "Palermo Viejo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Tribunales", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Centro / Microcentro", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 250000.0, neighborhood: "Belgrano R", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 370000.0, neighborhood: "Barrio Norte", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Colegiales", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Centro / Microcentro", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 320000.0, neighborhood: "Monserrat", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 650000.0, neighborhood: "Colegiales", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 750000.0, neighborhood: "Caballito Sur", total_area: 89.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 320000.0, neighborhood: "Recoleta", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 680000.0, neighborhood: "Villa Crespo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Retiro", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 730000.0, neighborhood: "Flores", total_area: 78.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 370000.0, neighborhood: "Barrio Norte", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1403000.0, neighborhood: "Palermo Viejo", total_area: 88.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Belgrano", total_area: 54.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 460000.0, neighborhood: "Balvanera", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Parque Patricios", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 480000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 560000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 850000.0, neighborhood: "Palermo Soho", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 690000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 793000.0, neighborhood: "Centro / Microcentro", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 430000.0, neighborhood: "Almagro", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 480000.0, neighborhood: "Barrio Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Caballito Sur", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 300000.0, neighborhood: "Palermo", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Caballito", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 440000.0, neighborhood: "Palermo Nuevo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Balvanera", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Belgrano C", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Puerto Madero", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 470000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Palermo Chico", total_area: 44.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Palermo Viejo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 580000.0, neighborhood: "Barrio Norte", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Palermo Chico", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 15.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 200000.0, neighborhood: "Barrio Norte", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Parque Patricios", total_area: 28.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "Floresta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 360000.0, neighborhood: "Villa Santa Rita", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Palermo Soho", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 585600.0, neighborhood: "Balvanera", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 950000.0, neighborhood: "Belgrano", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 690000.0, neighborhood: "Barracas", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 790000.0, neighborhood: "Palermo", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 976000.0, neighborhood: "Balvanera", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 470000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 854000.0, neighborhood: "Flores", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1464000.0, neighborhood: "Barrio Norte", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Belgrano", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 750000.0, neighborhood: "Palermo Nuevo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Caballito", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 499000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 550000.0, neighborhood: "Villa Devoto", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "San Nicol\u00e1s", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 700000.0, neighborhood: "Barracas", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Palermo Soho", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 470000.0, neighborhood: "Belgrano R", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 420000.0, neighborhood: "Floresta Sur", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 850000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 350000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 490000.0, neighborhood: "Retiro", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1098000.0, neighborhood: "Palermo Soho", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Balvanera", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 430000.0, neighborhood: "Belgrano", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 530000.0, neighborhood: "Abasto", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 390000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Palermo Chico", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 360000.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 854000.0, neighborhood: "Palermo Soho", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 730000.0, neighborhood: "Villa Urquiza", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 549000.0, neighborhood: "San Nicol\u00e1s", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Belgrano C", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 470000.0, neighborhood: "Caballito", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 850000.0, neighborhood: "Belgrano", total_area: 72.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 900000.0, neighborhood: "Belgrano R", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "Almagro", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "Villa Luro", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Palermo Nuevo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Almagro Norte", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Retiro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 650000.0, neighborhood: "Parque Chas", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "San Telmo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 549000.0, neighborhood: "Congreso", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 420000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Villa Urquiza", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 720000.0, neighborhood: "Barrio Norte", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 950000.0, neighborhood: "Almagro", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 671000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 950000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 390000.0, neighborhood: "Flores", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1220000.0, neighborhood: "Caballito", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 81.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Monserrat", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 420000.0, neighborhood: "Colegiales", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "Villa Crespo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Centro / Microcentro", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 530000.0, neighborhood: "Almagro Norte", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Colegiales", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 340000.0, neighborhood: "Villa Lugano", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 915000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Barrio Norte", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Parque Chacabuco", total_area: 88.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Villa Crespo", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 610000.0, neighborhood: "Centro / Microcentro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1464000.0, neighborhood: "Palermo Soho", total_area: 88.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Palermo Soho", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 390400.0, neighborhood: "Retiro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 470000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Villa Luro", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 430000.0, neighborhood: "Caballito Sur", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 67.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Belgrano C", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Monserrat", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 585600.0, neighborhood: "Palermo Nuevo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 915000.0, neighborhood: "Puerto Madero", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 990000.0, neighborhood: "Recoleta", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Almagro", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 690000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 300000.0, neighborhood: "Caballito", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 390000.0, neighborhood: "Abasto", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1464000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Floresta", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "San Telmo", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Belgrano C", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 440000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 380000.0, neighborhood: "Villa Crespo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Monserrat", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Monserrat", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 900000.0, neighborhood: "Belgrano", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 915000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Colegiales", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 451400.0, neighborhood: "Recoleta", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1586000.0, neighborhood: "Belgrano", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Villa del Parque", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 500000.0, neighborhood: "Parque Rivadavia", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 360000.0, neighborhood: "Flores", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 488000.0, neighborhood: "San Nicol\u00e1s", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 440000.0, neighborhood: "Caballito Sur", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 690000.0, neighborhood: "Barrio Norte", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Almagro", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Almagro", total_area: 77.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Palermo Viejo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Villa Crespo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 580000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 90.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 430000.0, neighborhood: "Villa Urquiza", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Palermo Soho", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Villa Ortuzar", total_area: 43.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 420000.0, neighborhood: "Parque Chas", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 430000.0, neighborhood: "Belgrano C", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 488000.0, neighborhood: "Belgrano", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Almagro", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Monserrat", total_area: 60.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 577000.0, neighborhood: "Congreso", total_area: 23.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 290000.0, neighborhood: "Balvanera", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 597800.0, neighborhood: "Belgrano R", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 915000.0, neighborhood: "Retiro", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 390000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 580000.0, neighborhood: "Colegiales", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 610000.0, neighborhood: "Retiro", total_area: 53.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 600000.0, neighborhood: "Versalles", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 671000.0, neighborhood: "Retiro", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 690000.0, neighborhood: "Palermo", total_area: 89.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Balvanera", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 463600.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 79.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Centro / Microcentro", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 915000.0, neighborhood: "Flores", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 732000.0, neighborhood: "San Telmo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 950000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 719800.0, neighborhood: "Belgrano", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 18.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 540000.0, neighborhood: "Villa Crespo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Palermo Soho", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 670000.0, neighborhood: "Villa Urquiza", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Monserrat", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 976000.0, neighborhood: "Caballito Norte", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 430000.0, neighborhood: "Barrio Norte", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1403000.0, neighborhood: "Puerto Madero", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 550000.0, neighborhood: "Villa Ortuzar", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 390400.0, neighborhood: "San Nicol\u00e1s", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 620000.0, neighborhood: "Colegiales", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1586000.0, neighborhood: "Colegiales", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 69.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 607000.0, neighborhood: "Belgrano", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 480000.0, neighborhood: "Caballito", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 732000.0, neighborhood: "Balvanera", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 300000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 470000.0, neighborhood: "Balvanera", total_area: 46.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 732000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Monserrat", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 600000.0, neighborhood: "Villa del Parque", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 820000.0, neighborhood: "Belgrano", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 620000.0, neighborhood: "Villa Devoto", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 310000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 695400.0, neighborhood: "Centro / Microcentro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 976000.0, neighborhood: "San Telmo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 450000.0, neighborhood: "Flores", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Belgrano", total_area: 81.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 520000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Barrio Norte", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "San Nicol\u00e1s", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Colegiales", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 740000.0, neighborhood: "Balvanera", total_area: 66.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 650000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1586000.0, neighborhood: "Palermo", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 410000.0, neighborhood: "Barrio Norte", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Flores", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1000000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Villa Crespo", total_area: 88.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Villa del Parque", total_area: 47.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 854000.0, neighborhood: "Palermo Nuevo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Almagro", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 86.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 549000.0, neighborhood: "Palermo Soho", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 298000.0, neighborhood: "Congreso", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 470000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 750000.0, neighborhood: "Almagro", total_area: 83.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Almagro", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Villa Devoto", total_area: 15.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1098000.0, neighborhood: "Villa Urquiza", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 732000.0, neighborhood: "Retiro", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 600000.0, neighborhood: "Villa Santa Rita", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Retiro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 470000.0, neighborhood: "Balvanera", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 360000.0, neighborhood: "Villa Crespo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 370000.0, neighborhood: "San Telmo", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "Palermo Chico", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 854000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Caballito Sur", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Belgrano C", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 18.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "Villa Urquiza", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 700000.0, neighborhood: "Centro / Microcentro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 410000.0, neighborhood: "Palermo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 488000.0, neighborhood: "Retiro", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Pompeya", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 854000.0, neighborhood: "Belgrano", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo Soho", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Almagro", total_area: 72.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 793000.0, neighborhood: "Belgrano", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 575000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 370000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 800000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Barrio Norte", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Floresta", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 485000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Retiro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 690000.0, neighborhood: "Villa Urquiza", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 620000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Centro / Microcentro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 610000.0, neighborhood: "Villa Crespo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1220000.0, neighborhood: "San Nicol\u00e1s", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Retiro", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 590000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 600000.0, neighborhood: "Belgrano R", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Monserrat", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 366000.0, neighborhood: "Recoleta", total_area: 19.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Palermo Soho", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 549000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Villa Crespo", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Centro / Microcentro", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 650000.0, neighborhood: "Parque Chacabuco", total_area: 79.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 650000.0, neighborhood: "Almagro", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Floresta", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 690000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 817400.0, neighborhood: "Belgrano C", total_area: 54.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 530000.0, neighborhood: "Almagro", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 671000.0, neighborhood: "Retiro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1098000.0, neighborhood: "Palermo Soho", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 410000.0, neighborhood: "Belgrano", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 850000.0, neighborhood: "Centro / Microcentro", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 850000.0, neighborhood: "Villa del Parque", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 427000.0, neighborhood: "Recoleta", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Colegiales", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 47.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 671000.0, neighborhood: "Retiro", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 793000.0, neighborhood: "San Telmo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Tribunales", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1464000.0, neighborhood: "Belgrano", total_area: 86.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1525000.0, neighborhood: "Puerto Madero", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Villa Crespo", total_area: 83.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 770000.0, neighborhood: "Palermo Soho", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 950000.0, neighborhood: "Caballito", total_area: 73.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 250000.0, neighborhood: "Recoleta", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 460000.0, neighborhood: "Balvanera", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 980000.0, neighborhood: "Belgrano", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "Caballito", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 590000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 488000.0, neighborhood: "Barrio Norte", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 630000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Villa Devoto", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 671000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Monserrat", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Flores", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 320000.0, neighborhood: "Belgrano", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 43.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 707600.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 74.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 732000.0, neighborhood: "Barrio Norte", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Colegiales", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Abasto", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Congreso", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 549000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 420000.0, neighborhood: "Recoleta", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 549000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1586000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Parque Chacabuco", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 427000.0, neighborhood: "Tribunales", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 480000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 902800.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 370000.0, neighborhood: "Barrio Norte", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1098000.0, neighborhood: "Retiro", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 800000.0, neighborhood: "Retiro", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 480000.0, neighborhood: "Centro / Microcentro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Barrio Norte", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Monserrat", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 750000.0, neighborhood: "Caballito Sur", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 671000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 793000.0, neighborhood: "San Nicol\u00e1s", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 850000.0, neighborhood: "Villa Devoto", total_area: 68.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Congreso", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1342000.0, neighborhood: "Belgrano", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Caballito Sur", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 976000.0, neighborhood: "Belgrano C", total_area: 71.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 530000.0, neighborhood: "Colegiales", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1220000.0, neighborhood: "Barrio Norte", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 770000.0, neighborhood: "Belgrano", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 850000.0, neighborhood: "Retiro", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 540000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 690000.0, neighborhood: "Belgrano", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1220000.0, neighborhood: "Puerto Madero", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 450000.0, neighborhood: "Flores", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 365000.0, neighborhood: "Belgrano", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1220000.0, neighborhood: "Barrio Norte", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 760000.0, neighborhood: "Caballito", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "Monserrat", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Palermo", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Belgrano C", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 680000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 488000.0, neighborhood: "Colegiales", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 490000.0, neighborhood: "Villa Ortuzar", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 671000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa Devoto", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 700000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 66.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 800000.0, neighborhood: "Caballito", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "Retiro", total_area: 77.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "San Nicol\u00e1s", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 430000.0, neighborhood: "Belgrano", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "Belgrano C", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 580000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 750000.0, neighborhood: "Parque Chacabuco", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 370000.0, neighborhood: "Monserrat", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 750000.0, neighborhood: "Villa Crespo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Retiro", total_area: 63.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 700000.0, neighborhood: "Barrio Norte", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Villa Crespo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Abasto", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 150000.0, neighborhood: "Villa Ortuzar", total_area: 78.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 380000.0, neighborhood: "Almagro Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 800000.0, neighborhood: "Villa Devoto", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Belgrano", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 329000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 390000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 800000.0, neighborhood: "Caballito", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 690000.0, neighborhood: "Recoleta", total_area: 78.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Centro / Microcentro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 360000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Villa del Parque", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 690000.0, neighborhood: "Belgrano", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Retiro", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 900000.0, neighborhood: "Almagro", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 360000.0, neighborhood: "Villa General Mitre", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 1098000.0, neighborhood: "Belgrano C", total_area: 82.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1464000.0, neighborhood: "Caballito", total_area: 77.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Palermo Viejo", total_area: 79.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "Caballito", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 370000.0, neighborhood: "San Telmo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 405000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 570000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 380000.0, neighborhood: "Belgrano", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 850000.0, neighborhood: "Balvanera", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 366000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Villa Crespo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "San Telmo", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 66.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 420000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "Retiro", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1342000.0, neighborhood: "Palermo Soho", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Villa Devoto", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 530000.0, neighborhood: "Belgrano R", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 520000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 550000.0, neighborhood: "Barrio Norte", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Flores", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 793000.0, neighborhood: "Belgrano R", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Palermo Nuevo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Balvanera", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Villa Devoto", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 976000.0, neighborhood: "Barrio Norte", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 490000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 72.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 690000.0, neighborhood: "Parque Patricios", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 380000.0, neighborhood: "Barrio Norte", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1342000.0, neighborhood: "Belgrano", total_area: 85.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 660000.0, neighborhood: "Belgrano", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 620000.0, neighborhood: "Palermo", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 512400.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Almagro", total_area: 80.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 90.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Retiro", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 854000.0, neighborhood: "Congreso", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1342000.0, neighborhood: "Colegiales", total_area: 53.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 854000.0, neighborhood: "San Telmo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Tribunales", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 430000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1159000.0, neighborhood: "Belgrano C", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 720000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 460000.0, neighborhood: "Centro / Microcentro", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "Flores", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1159000.0, neighborhood: "Caballito Sur", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1000000.0, neighborhood: "Caballito", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 414800.0, neighborhood: "Palermo Soho", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Monserrat", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 550000.0, neighborhood: "Flores", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Palermo Soho", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Palermo", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 524600.0, neighborhood: "San Telmo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 402600.0, neighborhood: "Centro / Microcentro", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1464000.0, neighborhood: "Palermo", total_area: 73.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 488000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Villa Ortuzar", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 800000.0, neighborhood: "Villa Urquiza", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 725000.0, neighborhood: "Belgrano", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 580000.0, neighborhood: "Villa Urquiza", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 399000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 549000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "Puerto Madero", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 1464000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 490000.0, neighborhood: "Villa Santa Rita", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Colegiales", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 610000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 510000.0, neighborhood: "Villa del Parque", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "San Nicol\u00e1s", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Villa Devoto", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 410000.0, neighborhood: "Caballito", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Flores", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 732000.0, neighborhood: "Villa Urquiza", total_area: 57.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Palermo Viejo", total_area: 51.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Belgrano Chico", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 51.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 87.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Palermo Soho", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 460000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 680000.0, neighborhood: "Colegiales", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 680000.0, neighborhood: "Belgrano", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 290000.0, neighborhood: "Caballito Sur", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 490000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 475000.0, neighborhood: "Caballito", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 370000.0, neighborhood: "Constituci\u00f3n", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Villa Urquiza", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 795000.0, neighborhood: "Belgrano", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 840000.0, neighborhood: "Villa Crespo", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1037000.0, neighborhood: "Palermo Chico", total_area: 60.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 370000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 320000.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Balvanera", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 580000.0, neighborhood: "Villa Devoto", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 800000.0, neighborhood: "Villa Devoto", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 260000.0, neighborhood: "Balvanera", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "Balvanera", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 854000.0, neighborhood: "Palermo Chico", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "Belgrano", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 530000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 18.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 549000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 330000.0, neighborhood: "Belgrano", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 799000.0, neighborhood: "Belgrano", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 480000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Belgrano C", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 463600.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1098000.0, neighborhood: "Puerto Madero", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 1037000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 950000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1000000.0, neighborhood: "Barrio Norte", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "Belgrano C", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Balvanera", total_area: 48.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1464000.0, neighborhood: "Palermo Soho", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 530000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Villa Devoto", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 550000.0, neighborhood: "Villa del Parque", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1220000.0, neighborhood: "Puerto Madero", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 549000.0, neighborhood: "Palermo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Villa Ortuzar", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1159000.0, neighborhood: "Villa Crespo", total_area: 84.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 430000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 854000.0, neighborhood: "Palermo Soho", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 995000.0, neighborhood: "Caballito", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 420000.0, neighborhood: "Monserrat", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 450000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 630000.0, neighborhood: "Almagro", total_area: 51.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 330000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 330000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Villa Crespo", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1098000.0, neighborhood: "Puerto Madero", total_area: 51.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 800000.0, neighborhood: "Villa Urquiza", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 360000.0, neighborhood: "Flores", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 900000.0, neighborhood: "Palermo Chico", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Colegiales", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 440000.0, neighborhood: "San Nicol\u00e1s", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 630000.0, neighborhood: "Caballito", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Belgrano Chico", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 80.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1098000.0, neighborhood: "Puerto Madero", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 450000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 634400.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Villa Ortuzar", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 320000.0, neighborhood: "Balvanera", total_area: 19.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Villa Santa Rita", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Balvanera", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 690000.0, neighborhood: "Belgrano C", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1586000.0, neighborhood: "Belgrano", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "Retiro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 817400.0, neighborhood: "Belgrano", total_area: 54.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Villa del Parque", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 290000.0, neighborhood: "San Nicol\u00e1s", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 700000.0, neighborhood: "Villa Crespo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 440000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 850000.0, neighborhood: "Almagro", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Villa del Parque", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 580000.0, neighborhood: "Villa Urquiza", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 915000.0, neighborhood: "Retiro", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 580000.0, neighborhood: "Caballito", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Flores", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 900000.0, neighborhood: "Belgrano", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1464000.0, neighborhood: "Belgrano", total_area: 72.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 950000.0, neighborhood: "Recoleta", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 615000.0, neighborhood: "San Telmo", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 300000.0, neighborhood: "San Telmo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 350000.0, neighborhood: "Flores", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 750000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1403000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 963800.0, neighborhood: "Recoleta", total_area: 91.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 85.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 460000.0, neighborhood: "Colegiales", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Caballito", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Palermo", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 620000.0, neighborhood: "Colegiales", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 488000.0, neighborhood: "Barrio Norte", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1220000.0, neighborhood: "Palermo Chico", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 410000.0, neighborhood: "Balvanera", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 395000.0, neighborhood: "Centro / Microcentro", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 370000.0, neighborhood: "Balvanera", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 59.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 390000.0, neighborhood: "Palermo Chico", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1000000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Belgrano", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "Caballito", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 590000.0, neighborhood: "Villa Ortuzar", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 488000.0, neighborhood: "Recoleta", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 620000.0, neighborhood: "Almagro", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Flores", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 732000.0, neighborhood: "Villa Crespo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 507520.0, neighborhood: "Flores", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa Urquiza", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "San Telmo", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 350000.0, neighborhood: "Villa Crespo", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 350000.0, neighborhood: "San Telmo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 670000.0, neighborhood: "Villa Urquiza", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 420000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 380000.0, neighborhood: "Balvanera", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "Retiro", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1037000.0, neighborhood: "Retiro", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 560000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 671000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 570000.0, neighborhood: "Villa Ortuzar", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "Belgrano", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 390000.0, neighborhood: "Recoleta", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa Urquiza", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 850000.0, neighborhood: "Flores", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Constituci\u00f3n", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 700000.0, neighborhood: "Villa del Parque", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 84.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Villa Devoto", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 732000.0, neighborhood: "Colegiales", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 27.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Palermo Viejo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "Villa del Parque", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 950000.0, neighborhood: "Recoleta", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 870000.0, neighborhood: "Balvanera", total_area: 91.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 800000.0, neighborhood: "Barrio Norte", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 660000.0, neighborhood: "Palermo", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 530000.0, neighborhood: "Almagro", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 440000.0, neighborhood: "Belgrano", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 420000.0, neighborhood: "Recoleta", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Caballito Sur", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 530000.0, neighborhood: "Villa Urquiza", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Villa Urquiza", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Villa del Parque", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 701500.0, neighborhood: "San Telmo", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 900000.0, neighborhood: "Almagro", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1586000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "Villa Lugano", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 549000.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 350000.0, neighborhood: "Belgrano", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 430000.0, neighborhood: "Belgrano", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "Colegiales", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 680000.0, neighborhood: "Caballito Norte", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 430000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo Soho", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1195600.0, neighborhood: "Centro / Microcentro", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 480000.0, neighborhood: "Centro / Microcentro", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 488000.0, neighborhood: "Caballito", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 430000.0, neighborhood: "Tribunales", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 309000.0, neighborhood: "Almagro Norte", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 490000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Villa del Parque", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "San Telmo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 430000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 420000.0, neighborhood: "Colegiales", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 480000.0, neighborhood: "Belgrano", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "Parque Centenario", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 390000.0, neighborhood: "Belgrano R", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 79.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Belgrano C", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 430000.0, neighborhood: "Parque Centenario", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Monserrat", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 793000.0, neighborhood: "Retiro", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 68.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 320000.0, neighborhood: "Balvanera", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 380000.0, neighborhood: "Villa Santa Rita", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Villa Devoto", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Flores Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 400000.0, neighborhood: "Villa Devoto", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 580000.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1098000.0, neighborhood: "Palermo Chico", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Villa Devoto", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Abasto", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 278000.0, neighborhood: "Recoleta", total_area: 18.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 420000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 520000.0, neighborhood: "Colegiales", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 900000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 330000.0, neighborhood: "Floresta Norte", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Monserrat", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Palermo Soho", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 440000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 549000.0, neighborhood: "Villa Crespo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "San Telmo", total_area: 87.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 597800.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa Luro", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 750000.0, neighborhood: "Colegiales", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 640000.0, neighborhood: "Belgrano C", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Retiro", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 370000.0, neighborhood: "Villa Luro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1220000.0, neighborhood: "Retiro", total_area: 60.0, rooms: 60.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1098000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 671000.0, neighborhood: "Belgrano R", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1586000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 500000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 732000.0, neighborhood: "San Telmo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Palermo Soho", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Palermo Chico", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 320000.0, neighborhood: "Constituci\u00f3n", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 549000.0, neighborhood: "Villa Urquiza", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Centro / Microcentro", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1464000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Centro / Microcentro", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1220000.0, neighborhood: "Puerto Madero", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 732000.0, neighborhood: "Flores Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 1098000.0, neighborhood: "Puerto Madero", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 400000.0, neighborhood: "Palermo Viejo", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 670000.0, neighborhood: "Almagro", total_area: 79.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1218780.0, neighborhood: "N\u00fa\u00f1ez", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 488000.0, neighborhood: "Monserrat", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 700000.0, neighborhood: "Almagro", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 854000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 340000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Villa Devoto", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 540000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 290000.0, neighborhood: "Centro / Microcentro", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 520000.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 430000.0, neighborhood: "Villa Crespo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 390000.0, neighborhood: "Barracas", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 700000.0, neighborhood: "Colegiales", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 420000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "San Telmo", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 800000.0, neighborhood: "Parque Rivadavia", total_area: 72.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 520000.0, neighborhood: "Parque Chas", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1220000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 420000.0, neighborhood: "Almagro", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 310000.0, neighborhood: "Villa Urquiza", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Caballito Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 250000.0, neighborhood: "Congreso", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 700000.0, neighborhood: "Balvanera", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Villa del Parque", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 570000.0, neighborhood: "Barrio Norte", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 280000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Villa Urquiza", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 349000.0, neighborhood: "Villa Crespo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 720000.0, neighborhood: "Barracas", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "Colegiales", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 550000.0, neighborhood: "Belgrano C", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Flores", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 750000.0, neighborhood: "Palermo Soho", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Retiro", total_area: 79.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 319000.0, neighborhood: "Caballito", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "Balvanera", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 390000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 900000.0, neighborhood: "Belgrano C", total_area: 80.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Retiro", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 450000.0, neighborhood: "Villa del Parque", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 250000.0, neighborhood: "Congreso", total_area: 19.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 510000.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "San Telmo", total_area: 75.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 915000.0, neighborhood: "Barrio Norte", total_area: 82.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 860000.0, neighborhood: "Villa Urquiza", total_area: 81.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 670000.0, neighborhood: "Barrio Norte", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 390000.0, neighborhood: "Palermo Viejo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 670000.0, neighborhood: "Villa Urquiza", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Villa del Parque", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 350000.0, neighborhood: "Congreso", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "Palermo Soho", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Caballito Norte", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 81.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Retiro", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 350000.0, neighborhood: "Congreso", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 350000.0, neighborhood: "Belgrano C", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Retiro", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 850000.0, neighborhood: "Palermo Soho", total_area: 80.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 470000.0, neighborhood: "Recoleta", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa del Parque", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 370000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 380000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Belgrano R", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 485000.0, neighborhood: "Villa Crespo", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 420000.0, neighborhood: "Villa Lugano", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 790000.0, neighborhood: "Retiro", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 700000.0, neighborhood: "Palermo Nuevo", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "Barrio Norte", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Flores", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 320000.0, neighborhood: "Centro / Microcentro", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 380000.0, neighborhood: "Belgrano", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 375000.0, neighborhood: "Belgrano", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 300000.0, neighborhood: "Barrio Norte", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Villa Urquiza", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Balvanera", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 640000.0, neighborhood: "Belgrano R", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 488000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Monserrat", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 350000.0, neighborhood: "Liniers", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 320000.0, neighborhood: "Caballito", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Floresta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Villa del Parque", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 650000.0, neighborhood: "Parque Rivadavia", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 866200.0, neighborhood: "Almagro", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Villa Devoto", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 540000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 580000.0, neighborhood: "Villa Devoto", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 430000.0, neighborhood: "Belgrano", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Abasto", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "Villa Crespo", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 520000.0, neighborhood: "Almagro", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1342000.0, neighborhood: "San Telmo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 550000.0, neighborhood: "Villa Devoto", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 488000.0, neighborhood: "Palermo", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 756400.0, neighborhood: "Palermo", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Villa Urquiza", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "Villa Urquiza", total_area: 85.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Balvanera", total_area: 80.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 540000.0, neighborhood: "Caballito Norte", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Palermo Chico", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Caballito", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 300000.0, neighborhood: "Caballito", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Palermo Chico", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Palermo Soho", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Colegiales", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 793000.0, neighborhood: "Barrio Norte", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Villa Urquiza", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Tribunales", total_area: 60.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 580000.0, neighborhood: "Villa Urquiza", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 960000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Centro / Microcentro", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 720000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "San Telmo", total_area: 58.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 475800.0, neighborhood: "Palermo", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "San Telmo", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 410000.0, neighborhood: "Abasto", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 915000.0, neighborhood: "Villa Urquiza", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "Centro / Microcentro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1159000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Villa Devoto", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1037000.0, neighborhood: "Parque Rivadavia", total_area: 89.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 700000.0, neighborhood: "Palermo Soho", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 370000.0, neighborhood: "Flores", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Palermo Chico", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1037000.0, neighborhood: "Belgrano", total_area: 77.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1195600.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 310000.0, neighborhood: "Villa Crespo", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 732000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 488000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1037000.0, neighborhood: "Colegiales", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 550000.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 750000.0, neighborhood: "Villa Urquiza", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Centro / Microcentro", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 447000.0, neighborhood: "Villa Urquiza", total_area: 78.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1159000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Colegiales", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 370000.0, neighborhood: "Villa Santa Rita", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 976000.0, neighborhood: "Colegiales", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1000000.0, neighborhood: "Palermo Chico", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 370000.0, neighborhood: "Villa Santa Rita", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Palermo Soho", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1037000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 451400.0, neighborhood: "Flores", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 58.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Villa Luro", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 658800.0, neighborhood: "Palermo", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 701500.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 829600.0, neighborhood: "Palermo", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 488000.0, neighborhood: "San Nicol\u00e1s", total_area: 22.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1220000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1220000.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Retiro", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Congreso", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "Flores", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 854000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 854000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 671000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 24.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Liniers", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 610000.0, neighborhood: "Belgrano R", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Monserrat", total_area: 28.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 610000.0, neighborhood: "Belgrano", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 750000.0, neighborhood: "Retiro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 650000.0, neighborhood: "Centro / Microcentro", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Centro / Microcentro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Centro / Microcentro", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 732000.0, neighborhood: "Palermo Nuevo", total_area: 26.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Parque Chacabuco", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 976000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 360000.0, neighborhood: "Villa Urquiza", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1342000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 310000.0, neighborhood: "Barrio Norte", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Flores", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 732000.0, neighborhood: "Palermo Viejo", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Centro / Microcentro", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 350000.0, neighborhood: "San Telmo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 488000.0, neighborhood: "Balvanera", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 530000.0, neighborhood: "Caballito", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 540000.0, neighborhood: "Villa Luro", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1061400.0, neighborhood: "Palermo", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "San Telmo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 600000.0, neighborhood: "Monserrat", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 600000.0, neighborhood: "Flores", total_area: 69.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 300000.0, neighborhood: "Balvanera", total_area: 18.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 700000.0, neighborhood: "Flores Sur", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 900000.0, neighborhood: "Almagro Norte", total_area: 84.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 350000.0, neighborhood: "Tribunales", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 480000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Barracas", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 793000.0, neighborhood: "Villa Crespo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 380000.0, neighborhood: "Centro / Microcentro", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 280000.0, neighborhood: "Barrio Norte", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 300000.0, neighborhood: "Villa del Parque", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 340000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 854000.0, neighborhood: "Retiro", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1098000.0, neighborhood: "Belgrano C", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 671000.0, neighborhood: "Almagro", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1464000.0, neighborhood: "Belgrano", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1195600.0, neighborhood: "Palermo", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Centro / Microcentro", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 732000.0, neighborhood: "San Telmo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 420000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1098000.0, neighborhood: "Belgrano C", total_area: 64.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Barracas", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1098000.0, neighborhood: "Centro / Microcentro", total_area: 90.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 915000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Villa Luro", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1647000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 400000.0, neighborhood: "Liniers", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 732000.0, neighborhood: "Centro / Microcentro", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1220000.0, neighborhood: "Palermo Soho", total_area: 64.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Balvanera", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 650000.0, neighborhood: "Colegiales", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1159000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 320000.0, neighborhood: "Floresta", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1037000.0, neighborhood: "Recoleta", total_area: 79.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 460000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Belgrano C", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 360000.0, neighborhood: "Villa del Parque", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 300000.0, neighborhood: "Barrio Norte", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Monserrat", total_area: 27.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 480000.0, neighborhood: "Villa Urquiza", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 680000.0, neighborhood: "Barrio Norte", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 57.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 310000.0, neighborhood: "Barrio Norte", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Liniers", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Floresta", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 350000.0, neighborhood: "Belgrano", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 950000.0, neighborhood: "Palermo", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Flores Norte", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 250000.0, neighborhood: "Palermo Chico", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Villa Devoto", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 440000.0, neighborhood: "Monserrat", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 700000.0, neighborhood: "Villa Crespo", total_area: 82.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Almagro Sur", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 640000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 350000.0, neighborhood: "Almagro", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 750000.0, neighborhood: "Almagro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 340000.0, neighborhood: "Centro / Microcentro", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 350000.0, neighborhood: "Floresta", total_area: 53.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Palermo Soho", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 330000.0, neighborhood: "Almagro", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1220000.0, neighborhood: "Palermo Soho", total_area: 84.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Almagro", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 350000.0, neighborhood: "Belgrano", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 490000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 390000.0, neighborhood: "Almagro", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 580000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 65.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 460000.0, neighborhood: "Parque Patricios", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 690000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 390000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 540000.0, neighborhood: "Parque Rivadavia", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 430000.0, neighborhood: "Caballito Norte", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Colegiales", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 350000.0, neighborhood: "Villa Urquiza", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Monserrat", total_area: 15.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 600000.0, neighborhood: "Palermo Soho", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 495000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 366000.0, neighborhood: "Congreso", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 732000.0, neighborhood: "Centro / Microcentro", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 440000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 440000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 290000.0, neighborhood: "Flores", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 440000.0, neighborhood: "Parque Chas", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "Parque Chacabuco", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 488000.0, neighborhood: "Congreso", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Parque Chacabuco", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 671000.0, neighborhood: "Palermo Chico", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 380000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 420000.0, neighborhood: "Almagro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 590000.0, neighborhood: "Barrio Norte", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Flores", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 732000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 591700.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Villa Crespo", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 480000.0, neighborhood: "Villa del Parque", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 800000.0, neighborhood: "Caballito", total_area: 77.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 390000.0, neighborhood: "Barrio Norte", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 658800.0, neighborhood: "Almagro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 320000.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Palermo Chico", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 410000.0, neighborhood: "Almagro", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Flores", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1220000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 480000.0, neighborhood: "Parque Patricios", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Retiro", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 500000.0, neighborhood: "Mataderos", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Abasto", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 950000.0, neighborhood: "Palermo Viejo", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Parque Chas", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Barracas", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 650000.0, neighborhood: "Balvanera", total_area: 46.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Colegiales", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 475000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 680000.0, neighborhood: "Recoleta", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Palermo Chico", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Villa del Parque", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 379000.0, neighborhood: "Villa General Mitre", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 360000.0, neighborhood: "Parque Patricios", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 915000.0, neighborhood: "Palermo Chico", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 390000.0, neighborhood: "Colegiales", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Barrio Norte", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Palermo Nuevo", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 915000.0, neighborhood: "Retiro", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 470000.0, neighborhood: "Villa Crespo", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 390000.0, neighborhood: "Belgrano", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1037000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 950000.0, neighborhood: "Belgrano", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Villa General Mitre", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Congreso", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 395000.0, neighborhood: "Palermo Viejo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 950000.0, neighborhood: "Colegiales", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 854000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 900000.0, neighborhood: "Villa Urquiza", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Retiro", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 550000.0, neighborhood: "Barrio Norte", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Caballito Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 300000.0, neighborhood: "Barracas", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 795000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1159000.0, neighborhood: "Barrio Norte", total_area: 84.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Retiro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 500000.0, neighborhood: "Parque Centenario", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 72.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Caballito Sur", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Parque Patricios", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 465000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "San Telmo", total_area: 65.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 610000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 540000.0, neighborhood: "Villa Urquiza", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1000000.0, neighborhood: "Belgrano", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "San Nicol\u00e1s", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 695000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1159000.0, neighborhood: "Palermo", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 280000.0, neighborhood: "San Nicol\u00e1s", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Parque Chacabuco", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 730780.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 854000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 340000.0, neighborhood: "Barracas", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 680000.0, neighborhood: "Caballito", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Villa Urquiza", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1586000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 400000.0, neighborhood: "Colegiales", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 390000.0, neighborhood: "Colegiales", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 360000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 52.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 600000.0, neighborhood: "Caballito Norte", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Flores", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 530000.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "San Telmo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 320000.0, neighborhood: "Flores", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 380000.0, neighborhood: "Barrio Norte", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Flores Norte", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Flores", total_area: 85.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 300000.0, neighborhood: "Monserrat", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 1220000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1586000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Villa Crespo", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 360000.0, neighborhood: "Villa Crespo", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 380000.0, neighborhood: "Caballito Norte", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Congreso", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 490000.0, neighborhood: "Almagro", total_area: 68.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 480000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1464000.0, neighborhood: "Villa Urquiza", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 510000.0, neighborhood: "Villa Urquiza", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 530000.0, neighborhood: "Constituci\u00f3n", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 370000.0, neighborhood: "Barrio Norte", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 250000.0, neighborhood: "Pompeya", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 850000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Retiro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1098000.0, neighborhood: "Villa Crespo", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "San Nicol\u00e1s", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 420000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Palermo Chico", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Caballito", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 590000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Congreso", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 732000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 550000.0, neighborhood: "Flores", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 385000.0, neighborhood: "Palermo", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1037000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1464000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Caballito", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Almagro Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Belgrano", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1586000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Puerto Madero", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 800000.0, neighborhood: "Parque Centenario", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 380000.0, neighborhood: "Parque Patricios", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 370000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo Soho", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 530000.0, neighborhood: "Monserrat", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 490000.0, neighborhood: "Balvanera", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 480000.0, neighborhood: "Balvanera", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Villa Crespo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Flores", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Flores", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 530000.0, neighborhood: "Balvanera", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 680000.0, neighborhood: "Barrio Norte", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Centro / Microcentro", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Colegiales", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 671000.0, neighborhood: "Balvanera", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "Caballito Sur", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Barracas", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 320000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 500000.0, neighborhood: "Caballito Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 410000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1195600.0, neighborhood: "Puerto Madero", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 320000.0, neighborhood: "San Nicol\u00e1s", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 610000.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 390000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 525000.0, neighborhood: "Parque Patricios", total_area: 77.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 79.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 950000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Barrio Norte", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 54.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 370000.0, neighborhood: "Villa Devoto", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1342000.0, neighborhood: "Belgrano C", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 680000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 2.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 445000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 480000.0, neighborhood: "San Telmo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Palermo Soho", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Congreso", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 680000.0, neighborhood: "Palermo", total_area: 74.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "Caballito Sur", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 430000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Floresta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 549000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 590000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Centro / Microcentro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 67.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Almagro", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Flores Norte", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 650000.0, neighborhood: "San Nicol\u00e1s", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1037000.0, neighborhood: "Palermo", total_area: 59.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 360000.0, neighborhood: "Villa Luro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 380000.0, neighborhood: "Balvanera", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 850000.0, neighborhood: "Retiro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 300000.0, neighborhood: "Parque Patricios", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 854000.0, neighborhood: "San Nicol\u00e1s", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Almagro Sur", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 750000.0, neighborhood: "Flores", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 480000.0, neighborhood: "Villa Ortuzar", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 732000.0, neighborhood: "Barrio Norte", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "San Telmo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "San Telmo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 850000.0, neighborhood: "Colegiales", total_area: 88.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1464000.0, neighborhood: "San Telmo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Retiro", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 390000.0, neighborhood: "Villa del Parque", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Parque Centenario", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 420000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 300000.0, neighborhood: "Flores Norte", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 790000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Flores", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Belgrano C", total_area: 85.0, rooms: 1.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Villa Devoto", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 330000.0, neighborhood: "Flores", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 470000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 320000.0, neighborhood: "Centro / Microcentro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 370000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 620000.0, neighborhood: "Villa Crespo", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 580000.0, neighborhood: "Villa Crespo", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 549000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Retiro", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 300000.0, neighborhood: "Balvanera", total_area: 28.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 950000.0, neighborhood: "Caballito", total_area: 84.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 3.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Barrio Norte", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Colegiales", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 690000.0, neighborhood: "Floresta Sur", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 430000.0, neighborhood: "Villa Urquiza", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 330000.0, neighborhood: "Almagro Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 560000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Villa del Parque", total_area: 62.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 390000.0, neighborhood: "Monserrat", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Flores", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 700000.0, neighborhood: "Retiro", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 220000.0, neighborhood: "Recoleta", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 940000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 430000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 525000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 700000.0, neighborhood: "Almagro", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 915000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "Balvanera", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 530000.0, neighborhood: "Flores", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 740000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 56.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 350000.0, neighborhood: "Pompeya", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 680000.0, neighborhood: "Abasto", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 750000.0, neighborhood: "Flores", total_area: 70.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 350000.0, neighborhood: "Villa Urquiza", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Flores", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 915000.0, neighborhood: "Almagro", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 680000.0, neighborhood: "Almagro", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 671000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 725000.0, neighborhood: "Caballito Sur", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Centro / Microcentro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 750000.0, neighborhood: "Palermo Chico", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Barracas", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Parque Rivadavia", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Palermo Viejo", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Balvanera", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 750000.0, neighborhood: "Liniers", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 390000.0, neighborhood: "Barrio Norte", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "San Telmo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 630000.0, neighborhood: "Almagro Sur", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Congreso", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 793000.0, neighborhood: "Villa Crespo", total_area: 70.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1342000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1462780.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Centro / Microcentro", total_area: 70.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 700000.0, neighborhood: "Villa Crespo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 990000.0, neighborhood: "Villa Urquiza", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 470000.0, neighborhood: "Barrio Norte", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 860000.0, neighborhood: "Palermo Soho", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 850000.0, neighborhood: "Villa Crespo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Villa Devoto", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 530000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 990000.0, neighborhood: "Barracas", total_area: 70.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 390000.0, neighborhood: "Villa Luro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 380000.0, neighborhood: "Balvanera", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 330000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Abasto", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 53.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Caballito Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Barracas", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 490000.0, neighborhood: "Almagro", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Villa Devoto", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1586000.0, neighborhood: "Belgrano", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Villa del Parque", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 545000.0, neighborhood: "Villa Urquiza", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 310000.0, neighborhood: "Caballito", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 71.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 488000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 900000.0, neighborhood: "Almagro", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 485000.0, neighborhood: "Barrio Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Colegiales", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 400000.0, neighborhood: "Centro / Microcentro", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Centro / Microcentro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Barrio Norte", total_area: 75.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Villa Urquiza", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 695000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 590000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1037000.0, neighborhood: "Palermo", total_area: 64.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 790000.0, neighborhood: "Caballito Norte", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 87.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Barrio Norte", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Flores", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 850000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "Caballito", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "Palermo Viejo", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Villa Santa Rita", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Caballito Norte", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 920000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 610000.0, neighborhood: "Villa Devoto", total_area: 51.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 580000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Villa Urquiza", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1000000.0, neighborhood: "Villa Urquiza", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 854000.0, neighborhood: "Caballito Sur", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Villa Devoto", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 280000.0, neighborhood: "San Nicol\u00e1s", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 850000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 490000.0, neighborhood: "Villa del Parque", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 56.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 340000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "Caballito", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 340000.0, neighborhood: "Villa Crespo", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 560000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Caballito Sur", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Colegiales", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Palermo Viejo", total_area: 86.0, rooms: 5.0, bedrooms: 4.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 470000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Barrio Norte", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 590000.0, neighborhood: "Monserrat", total_area: 60.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 600000.0, neighborhood: "Flores", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 360000.0, neighborhood: "Floresta", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 200000.0, neighborhood: "Balvanera", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 580000.0, neighborhood: "Caballito Sur", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 854000.0, neighborhood: "Villa Urquiza", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 960000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Belgrano", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 780000.0, neighborhood: "Barrio Norte", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Barracas", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 355000.0, neighborhood: "Mataderos", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 530000.0, neighborhood: "Caballito Sur", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 976000.0, neighborhood: "Palermo Nuevo", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Balvanera", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Abasto", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 290000.0, neighborhood: "Monserrat", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 671000.0, neighborhood: "Belgrano", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 320000.0, neighborhood: "Belgrano", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 950000.0, neighborhood: "Villa Urquiza", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Villa Ortuzar", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 820000.0, neighborhood: "Belgrano C", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 850000.0, neighborhood: "Abasto", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 690000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Parque Patricios", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 690000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 671000.0, neighborhood: "Centro / Microcentro", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 380000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 890000.0, neighborhood: "Belgrano", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 610000.0, neighborhood: "Belgrano C", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Belgrano C", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 585000.0, neighborhood: "Barrio Norte", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Almagro", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 430000.0, neighborhood: "Centro / Microcentro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 490000.0, neighborhood: "Belgrano R", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 915000.0, neighborhood: "Recoleta", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 671000.0, neighborhood: "Barrio Norte", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Flores", total_area: 60.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Parque Rivadavia", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1098000.0, neighborhood: "Belgrano C", total_area: 82.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Retiro", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 732000.0, neighborhood: "Belgrano C", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 549000.0, neighborhood: "Palermo Soho", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Parque Chas", total_area: 84.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 854000.0, neighborhood: "Belgrano C", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Flores", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 650000.0, neighborhood: "Abasto", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "Parque Chas", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 732000.0, neighborhood: "Palermo Chico", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Barrio Norte", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 430000.0, neighborhood: "Palermo Soho", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 530000.0, neighborhood: "Barrio Norte", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 600000.0, neighborhood: "Flores", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1098000.0, neighborhood: "Puerto Madero", total_area: 83.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 930000.0, neighborhood: "Villa Urquiza", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 750000.0, neighborhood: "Retiro", total_area: 75.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Abasto", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Villa Luro", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Palermo Soho", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Caballito Sur", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Barrio Norte", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 330000.0, neighborhood: "Barrio Norte", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "Caballito", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "San Telmo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 430000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 900000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Almagro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 690000.0, neighborhood: "Villa Santa Rita", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1159000.0, neighborhood: "Palermo", total_area: 74.0, rooms: 5.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 575000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Belgrano", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Centro / Microcentro", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 320000.0, neighborhood: "San Nicol\u00e1s", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 430000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 330000.0, neighborhood: "Villa Urquiza", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Parque Chacabuco", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Congreso", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 340000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 630000.0, neighborhood: "Belgrano C", total_area: 48.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 350000.0, neighborhood: "Retiro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Retiro", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 427000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 950000.0, neighborhood: "Villa Urquiza", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 340000.0, neighborhood: "Villa Urquiza", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 890000.0, neighborhood: "Barracas", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 750000.0, neighborhood: "Palermo Nuevo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Villa Urquiza", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Villa Devoto", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 740000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 280000.0, neighborhood: "Recoleta", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 320000.0, neighborhood: "Centro / Microcentro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 480000.0, neighborhood: "Villa Devoto", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 350000.0, neighborhood: "Balvanera", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 700000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 620000.0, neighborhood: "Caballito", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 620000.0, neighborhood: "Barrio Norte", total_area: 62.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 640000.0, neighborhood: "Parque Patricios", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Almagro", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 350000.0, neighborhood: "Parque Chacabuco", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "San Nicol\u00e1s", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Palermo Chico", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 690000.0, neighborhood: "Recoleta", total_area: 62.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 620000.0, neighborhood: "Flores", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 350000.0, neighborhood: "Villa del Parque", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 18.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 730000.0, neighborhood: "Villa Ortuzar", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Retiro", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 440000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Barrio Norte", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Caballito Norte", total_area: 48.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 420000.0, neighborhood: "San Nicol\u00e1s", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Balvanera", total_area: 80.0, rooms: 5.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 829600.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Villa Urquiza", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Retiro", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 530000.0, neighborhood: "Floresta", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 70.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 590000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 590000.0, neighborhood: "Villa Urquiza", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 470000.0, neighborhood: "San Nicol\u00e1s", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 854000.0, neighborhood: "Palermo Soho", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Retiro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 800000.0, neighborhood: "Almagro", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 800000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 590000.0, neighborhood: "Monserrat", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 560000.0, neighborhood: "Liniers", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Constituci\u00f3n", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Flores", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Belgrano", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 500000.0, neighborhood: "Parque Chacabuco", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Palermo Viejo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Villa del Parque", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 470000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Parque Chacabuco", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 590000.0, neighborhood: "Tribunales", total_area: 73.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Villa del Parque", total_area: 83.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 650000.0, neighborhood: "Colegiales", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 400000.0, neighborhood: "Flores", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Floresta Norte", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 580000.0, neighborhood: "Retiro", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 470000.0, neighborhood: "Palermo Soho", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Almagro Norte", total_area: 70.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Villa Devoto", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 340000.0, neighborhood: "Monserrat", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Belgrano C", total_area: 80.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 27.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Belgrano", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Palermo Soho", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Villa Lugano", total_area: 86.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 915000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 620000.0, neighborhood: "Belgrano", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Caballito Sur", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1464000.0, neighborhood: "Palermo", total_area: 85.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 470000.0, neighborhood: "Retiro", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 360000.0, neighborhood: "Monserrat", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 950000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1098000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 420000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "San Telmo", total_area: 44.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 395000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 480000.0, neighborhood: "Villa Crespo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 380000.0, neighborhood: "Villa Crespo", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 410000.0, neighborhood: "Caballito", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 370000.0, neighborhood: "Barracas", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 460000.0, neighborhood: "Barracas", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 950000.0, neighborhood: "Palermo", total_area: 54.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Villa Devoto", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 420000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 580000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Liniers", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 380000.0, neighborhood: "Villa Luro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 430000.0, neighborhood: "Villa Urquiza", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 580000.0, neighborhood: "Colegiales", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 700000.0, neighborhood: "Palermo Nuevo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 660000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 420000.0, neighborhood: "Barracas", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Liniers", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 700000.0, neighborhood: "Floresta", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Flores Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 360000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 700000.0, neighborhood: "Barrio Norte", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Congreso", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "Villa Devoto", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 549000.0, neighborhood: "Centro / Microcentro", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 380000.0, neighborhood: "Balvanera", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 320000.0, neighborhood: "Flores", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 480000.0, neighborhood: "Floresta", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 575000.0, neighborhood: "Villa Crespo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 480000.0, neighborhood: "Centro / Microcentro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Villa Urquiza", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Villa del Parque", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 990000.0, neighborhood: "Otro", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RETIRO"},
    {price: 450000.0, neighborhood: "Parque Centenario", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 16.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 430000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 425000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Barrio Norte", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Colegiales", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 580000.0, neighborhood: "Flores", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 900000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 390000.0, neighborhood: "Caballito Norte", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 320000.0, neighborhood: "Palermo", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 380000.0, neighborhood: "Villa Santa Rita", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 976000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Colegiales", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1098000.0, neighborhood: "Palermo Soho", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Belgrano Chico", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "San Telmo", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1220000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 350000.0, neighborhood: "Villa Santa Rita", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 410000.0, neighborhood: "Barracas", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 370000.0, neighborhood: "Retiro", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 430000.0, neighborhood: "Barrio Norte", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 430000.0, neighborhood: "Congreso", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 650000.0, neighborhood: "Colegiales", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 600000.0, neighborhood: "Balvanera", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 360000.0, neighborhood: "Parque Patricios", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Villa del Parque", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 1098000.0, neighborhood: "Colegiales", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 610000.0, neighborhood: "Belgrano", total_area: 19.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Colegiales", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 390000.0, neighborhood: "Villa Urquiza", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 480000.0, neighborhood: "Flores", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Tribunales", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 700000.0, neighborhood: "Balvanera", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 590000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "Caballito", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 470000.0, neighborhood: "Palermo Soho", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Colegiales", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Congreso", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 490000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 460000.0, neighborhood: "San Nicol\u00e1s", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Balvanera", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 580000.0, neighborhood: "Palermo", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 597800.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa Luro", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 24.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Flores", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 320000.0, neighborhood: "Tribunales", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 430000.0, neighborhood: "Almagro", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 950000.0, neighborhood: "Villa del Parque", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 700000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 70.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 390000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 70.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 440000.0, neighborhood: "Balvanera", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 430000.0, neighborhood: "Monserrat", total_area: 50.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 370000.0, neighborhood: "Recoleta", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 370000.0, neighborhood: "Caballito", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Parque Chacabuco", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 915000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 390000.0, neighborhood: "Versalles", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 732000.0, neighborhood: "Centro / Microcentro", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 270000.0, neighborhood: "Parque Centenario", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 290000.0, neighborhood: "Villa Crespo", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 950000.0, neighborhood: "Recoleta", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "San Telmo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 900000.0, neighborhood: "Almagro Norte", total_area: 84.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Villa Santa Rita", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Belgrano C", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Caballito Norte", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Versalles", total_area: 77.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 530000.0, neighborhood: "Caballito Sur", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 490000.0, neighborhood: "Barracas", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 300000.0, neighborhood: "Centro / Microcentro", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1586000.0, neighborhood: "Recoleta", total_area: 84.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 470000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 585600.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Caballito Sur", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 660000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 420000.0, neighborhood: "Centro / Microcentro", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 488000.0, neighborhood: "Belgrano C", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 290000.0, neighborhood: "Barrio Norte", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 51.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 440000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 390000.0, neighborhood: "Floresta", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 430000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 425000.0, neighborhood: "Caballito", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Parque Rivadavia", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 610000.0, neighborhood: "Belgrano C", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Villa del Parque", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 385000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 390000.0, neighborhood: "Centro / Microcentro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 580000.0, neighborhood: "Barrio Norte", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 620000.0, neighborhood: "Parque Rivadavia", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 375000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Floresta Sur", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 950000.0, neighborhood: "Villa del Parque", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 793000.0, neighborhood: "Palermo Soho", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 640000.0, neighborhood: "Flores", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 350000.0, neighborhood: "Constituci\u00f3n", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 900000.0, neighborhood: "Recoleta", total_area: 87.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 330000.0, neighborhood: "Barrio Norte", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 73.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Flores", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 700000.0, neighborhood: "Palermo Soho", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "San Telmo", total_area: 59.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 425000.0, neighborhood: "Parque Centenario", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 425000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 360000.0, neighborhood: "Belgrano", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 250000.0, neighborhood: "Congreso", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 470000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Recoleta", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 520000.0, neighborhood: "Villa Devoto", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 360000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 600000.0, neighborhood: "Palermo Chico", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Villa Urquiza", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1647000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 780000.0, neighborhood: "Palermo", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 690000.0, neighborhood: "Villa Urquiza", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 495000.0, neighborhood: "Recoleta", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Congreso", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 530000.0, neighborhood: "Parque Chas", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "San Telmo", total_area: 84.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1281000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1159000.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 829600.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Palermo Chico", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "San Telmo", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 530000.0, neighborhood: "Villa del Parque", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 420000.0, neighborhood: "Floresta", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 550000.0, neighborhood: "Parque Chacabuco", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Congreso", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 650000.0, neighborhood: "San Telmo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 480000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 70.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Palermo Soho", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "San Telmo", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 700000.0, neighborhood: "Almagro", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 380000.0, neighborhood: "Parque Chas", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 340000.0, neighborhood: "Caballito Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Barrio Norte", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "Centro / Microcentro", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Monserrat", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 890000.0, neighborhood: "Villa Urquiza", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 460000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 520000.0, neighborhood: "Villa Urquiza", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Palermo Soho", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Colegiales", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 900000.0, neighborhood: "Palermo Chico", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Centro / Microcentro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Barrio Norte", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1098000.0, neighborhood: "Retiro", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Floresta", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 793000.0, neighborhood: "Balvanera", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Parque Rivadavia", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 21.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 480000.0, neighborhood: "Parque Chacabuco", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 620000.0, neighborhood: "Almagro", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Flores", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 380000.0, neighborhood: "Palermo", total_area: 17.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Barrio Norte", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 330000.0, neighborhood: "Villa Luro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 250000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Monserrat", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 700000.0, neighborhood: "Palermo Soho", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "Belgrano R", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Constituci\u00f3n", total_area: 57.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 200000.0, neighborhood: "Belgrano", total_area: 27.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 530000.0, neighborhood: "Villa Crespo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1342000.0, neighborhood: "Palermo Nuevo", total_area: 90.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 57.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 793000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 950000.0, neighborhood: "Barrio Norte", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1586000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 420000.0, neighborhood: "Centro / Microcentro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 460000.0, neighborhood: "San Telmo", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Belgrano C", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Caballito", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 579000.0, neighborhood: "Recoleta", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Caballito Norte", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 420000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Villa Devoto", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 320000.0, neighborhood: "Colegiales", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 395000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 420000.0, neighborhood: "Barracas", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 915000.0, neighborhood: "Villa Urquiza", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Villa General Mitre", total_area: 84.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 732000.0, neighborhood: "Centro / Microcentro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 280000.0, neighborhood: "Constituci\u00f3n", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 300000.0, neighborhood: "Belgrano R", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 360000.0, neighborhood: "San Nicol\u00e1s", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 480000.0, neighborhood: "Belgrano C", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Recoleta", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1159000.0, neighborhood: "Palermo Chico", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Abasto", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 730780.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Congreso", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 585600.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Belgrano R", total_area: 45.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 488000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Barracas", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 580000.0, neighborhood: "Caballito", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "San Telmo", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 59.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 73.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Villa Crespo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1647000.0, neighborhood: "Belgrano", total_area: 77.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Almagro Sur", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Villa Ortuzar", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 549000.0, neighborhood: "Barrio Norte", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 630000.0, neighborhood: "Villa Devoto", total_area: 72.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 470000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 490000.0, neighborhood: "Barrio Norte", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1586000.0, neighborhood: "Almagro", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 915000.0, neighborhood: "Colegiales", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Parque Centenario", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Liniers", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 580000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 750000.0, neighborhood: "Monserrat", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 573400.0, neighborhood: "Barrio Norte", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 620000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 430000.0, neighborhood: "Floresta", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 220000.0, neighborhood: "Monserrat", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1342000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Flores", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Retiro", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 480000.0, neighborhood: "Parque Patricios", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Palermo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 280000.0, neighborhood: "Palermo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "San Telmo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 650000.0, neighborhood: "Liniers", total_area: 83.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 280000.0, neighborhood: "Villa Crespo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 380000.0, neighborhood: "Villa Crespo", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Flores Norte", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 570000.0, neighborhood: "San Nicol\u00e1s", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 490000.0, neighborhood: "Caballito Norte", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "Caballito Norte", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 420000.0, neighborhood: "Parque Chas", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Villa del Parque", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 380000.0, neighborhood: "Parque Chas", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 360000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 430000.0, neighborhood: "Villa Urquiza", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 230000.0, neighborhood: "San Nicol\u00e1s", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 395000.0, neighborhood: "Barracas", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Flores", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Belgrano", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 840000.0, neighborhood: "Palermo Soho", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Palermo Viejo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 840000.0, neighborhood: "Palermo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Barrio Norte", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 366000.0, neighborhood: "Congreso", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 430000.0, neighborhood: "Barracas", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Palermo Viejo", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 350000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 650000.0, neighborhood: "San Nicol\u00e1s", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 800000.0, neighborhood: "San Nicol\u00e1s", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 300000.0, neighborhood: "Caballito", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Villa Luro", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 495000.0, neighborhood: "Villa Luro", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 590000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Caballito Norte", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Palermo Nuevo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "Villa Luro", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 340000.0, neighborhood: "Almagro", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Constituci\u00f3n", total_area: 82.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 370000.0, neighborhood: "Congreso", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 250000.0, neighborhood: "Constituci\u00f3n", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1464000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 530000.0, neighborhood: "Almagro", total_area: 51.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 75.0, rooms: 1.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Parque Chacabuco", total_area: 39.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "Caballito Norte", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Belgrano C", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 630000.0, neighborhood: "Recoleta", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 520000.0, neighborhood: "Retiro", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 490000.0, neighborhood: "Congreso", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 630000.0, neighborhood: "Villa Urquiza", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 900000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1464000.0, neighborhood: "Recoleta", total_area: 84.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Caballito", total_area: 54.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 300000.0, neighborhood: "Flores", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 650000.0, neighborhood: "Barracas", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "Belgrano R", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 370000.0, neighborhood: "Congreso", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Villa del Parque", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 900000.0, neighborhood: "Recoleta", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 680000.0, neighborhood: "Belgrano C", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "Retiro", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 756400.0, neighborhood: "N\u00fa\u00f1ez", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 430000.0, neighborhood: "Flores Norte", total_area: 40.0, rooms: 40.0, bedrooms: 1.0, bathrooms: 3.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 310000.0, neighborhood: "San Telmo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 390000.0, neighborhood: "Villa Urquiza", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 380000.0, neighborhood: "Monserrat", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 550000.0, neighborhood: "Villa Lugano", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 300000.0, neighborhood: "Almagro", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 410000.0, neighborhood: "Caballito", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 74.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 900000.0, neighborhood: "Caballito", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Palermo Viejo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Balvanera", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 57.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 610000.0, neighborhood: "San Telmo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 370000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 427000.0, neighborhood: "Barracas", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 63.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 340000.0, neighborhood: "Barrio Norte", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Flores Norte", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 585600.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 520000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Villa Crespo", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 460000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Retiro", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 425000.0, neighborhood: "San Telmo", total_area: 51.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "San Nicol\u00e1s", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Palermo Chico", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Floresta Norte", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 430000.0, neighborhood: "San Telmo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Villa Crespo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 470000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Centro / Microcentro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 900000.0, neighborhood: "Barrio Norte", total_area: 78.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa del Parque", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 550000.0, neighborhood: "Villa Devoto", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 680000.0, neighborhood: "Caballito", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 420000.0, neighborhood: "Palermo Chico", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 315000.0, neighborhood: "Almagro", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 488000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Flores", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 890000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 420000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Almagro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 320000.0, neighborhood: "Barracas", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1586000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Monserrat", total_area: 68.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 750000.0, neighborhood: "Colegiales", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Palermo Soho", total_area: 51.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 620000.0, neighborhood: "Belgrano", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 671000.0, neighborhood: "Belgrano", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1464000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Villa Crespo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 860000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Centro / Microcentro", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Retiro", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Palermo", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Almagro", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 750000.0, neighborhood: "Almagro", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1159000.0, neighborhood: "Villa Crespo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1159000.0, neighborhood: "Recoleta", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 530000.0, neighborhood: "Villa Urquiza", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 430000.0, neighborhood: "Almagro", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 630000.0, neighborhood: "Barrio Norte", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Almagro", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Flores Sur", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 488000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 530000.0, neighborhood: "San Nicol\u00e1s", total_area: 39.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Colegiales", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 400000.0, neighborhood: "Villa Luro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Parque Patricios", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 620000.0, neighborhood: "San Telmo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 350000.0, neighborhood: "Villa Ortuzar", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Flores", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 630000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Parque Patricios", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "Villa Lugano", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 50.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 588000.0, neighborhood: "Caballito", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Retiro", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Villa Santa Rita", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 390000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 320000.0, neighborhood: "Parque Centenario", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 625000.0, neighborhood: "Almagro", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 570000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 730000.0, neighborhood: "Barrio Norte", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Parque Centenario", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 470000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 899000.0, neighborhood: "Caballito", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "Villa Urquiza", total_area: 78.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Centro / Microcentro", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Palermo Chico", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 440000.0, neighborhood: "Palermo Chico", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Almagro", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 700000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 350000.0, neighborhood: "Monserrat", total_area: 28.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 350000.0, neighborhood: "Floresta", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 390000.0, neighborhood: "Caballito", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Palermo Chico", total_area: 82.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Villa Ortuzar", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1525000.0, neighborhood: "Belgrano", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 390000.0, neighborhood: "Floresta Norte", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 200000.0, neighborhood: "Villa Soldati", total_area: 16.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 345000.0, neighborhood: "San Nicol\u00e1s", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Parque Patricios", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Flores", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 460000.0, neighborhood: "Villa Santa Rita", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Parque Chacabuco", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Centro / Microcentro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Palermo Viejo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "Villa Crespo", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 530000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Barrio Norte", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 620000.0, neighborhood: "Colegiales", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 350000.0, neighborhood: "Flores", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 700000.0, neighborhood: "Caballito Sur", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Balvanera", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Parque Centenario", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 520000.0, neighborhood: "Villa Crespo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Belgrano R", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 951600.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1000000.0, neighborhood: "Almagro", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 630000.0, neighborhood: "Caballito", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Palermo Nuevo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 695400.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 573400.0, neighborhood: "Recoleta", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "Flores", total_area: 46.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 800000.0, neighborhood: "Palermo Soho", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 25.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 810000.0, neighborhood: "Belgrano", total_area: 80.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Villa Santa Rita", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Monserrat", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 300000.0, neighborhood: "Barrio Norte", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 420000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Mataderos", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "San Nicol\u00e1s", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Villa Crespo", total_area: 74.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 875000.0, neighborhood: "Palermo Nuevo", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 400000.0, neighborhood: "Congreso", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 310000.0, neighborhood: "Palermo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 320000.0, neighborhood: "Belgrano", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 410000.0, neighborhood: "Villa Urquiza", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 410000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Monserrat", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 830000.0, neighborhood: "Villa del Parque", total_area: 75.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 900000.0, neighborhood: "Caballito", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 390000.0, neighborhood: "Flores Norte", total_area: 48.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Barracas", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "Liniers", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 270000.0, neighborhood: "Congreso", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 380000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Flores", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 680000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1037000.0, neighborhood: "Palermo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 580000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Barracas", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1525000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 649000.0, neighborhood: "Belgrano", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 480000.0, neighborhood: "Caballito Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 67.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 520000.0, neighborhood: "Almagro", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Centro / Microcentro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Palermo Chico", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "Caballito Sur", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 360000.0, neighborhood: "Barrio Norte", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Parque Centenario", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 480000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 580000.0, neighborhood: "Barracas", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 370000.0, neighborhood: "Caballito", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 590000.0, neighborhood: "Almagro", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 340000.0, neighborhood: "Palermo", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Villa Santa Rita", total_area: 68.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "San Telmo", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 415000.0, neighborhood: "Tribunales", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Villa Urquiza", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 420000.0, neighborhood: "Monserrat", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 390000.0, neighborhood: "Monserrat", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 610000.0, neighborhood: "Tribunales", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 87.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 680000.0, neighborhood: "San Telmo", total_area: 86.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 380000.0, neighborhood: "Belgrano C", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Almagro Sur", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Barracas", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Floresta", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1037000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1037000.0, neighborhood: "Colegiales", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 658800.0, neighborhood: "Almagro", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Parque Chas", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 610000.0, neighborhood: "Palermo Chico", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Almagro", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Villa Santa Rita", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 805200.0, neighborhood: "Palermo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Belgrano C", total_area: 90.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1000000.0, neighborhood: "Recoleta", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 3.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 390000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Centro / Microcentro", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 340000.0, neighborhood: "Villa Urquiza", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 750000.0, neighborhood: "Villa Ortuzar", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 530000.0, neighborhood: "Almagro", total_area: 45.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 570000.0, neighborhood: "Villa Devoto", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 370000.0, neighborhood: "Caballito", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 560000.0, neighborhood: "Almagro", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 350000.0, neighborhood: "Almagro Sur", total_area: 22.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Palermo Soho", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 320000.0, neighborhood: "Almagro", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 370000.0, neighborhood: "Villa Urquiza", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 57.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Villa del Parque", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 580000.0, neighborhood: "Villa del Parque", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 750000.0, neighborhood: "Villa del Parque", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 580000.0, neighborhood: "Villa Crespo", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 850000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 390000.0, neighborhood: "Villa Urquiza", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 793000.0, neighborhood: "Belgrano", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Palermo Soho", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 915000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "Almagro", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Flores", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 280000.0, neighborhood: "Barrio Norte", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Flores", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 585600.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Balvanera", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Mataderos", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 300000.0, neighborhood: "Villa del Parque", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 400000.0, neighborhood: "Flores", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 490000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "San Nicol\u00e1s", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Floresta", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Almagro Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 380000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 480000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 793000.0, neighborhood: "Colegiales", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 500000.0, neighborhood: "Belgrano C", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 700000.0, neighborhood: "Villa del Parque", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 780000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 460000.0, neighborhood: "Villa Lugano", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 530000.0, neighborhood: "San Telmo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 340000.0, neighborhood: "Congreso", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 840000.0, neighborhood: "Almagro Sur", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 330000.0, neighborhood: "Almagro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 490000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "Belgrano", total_area: 80.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1159000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Belgrano", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Villa General Mitre", total_area: 71.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 915000.0, neighborhood: "Colegiales", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 671000.0, neighborhood: "Villa Crespo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 350000.0, neighborhood: "Caballito Norte", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 320000.0, neighborhood: "Palermo", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 520000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Parque Chacabuco", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 850000.0, neighborhood: "Villa Soldati", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Floresta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Parque Chas", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 360000.0, neighborhood: "Recoleta", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Barrio Norte", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Caballito", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 350000.0, neighborhood: "Villa Urquiza", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Villa Crespo", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Colegiales", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 290000.0, neighborhood: "Congreso", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 280000.0, neighborhood: "San Nicol\u00e1s", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Retiro", total_area: 65.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 350000.0, neighborhood: "Almagro", total_area: 19.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Villa Devoto", total_area: 58.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 590000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Parque Chacabuco", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 91.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 440000.0, neighborhood: "Caballito Sur", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 520000.0, neighborhood: "Caballito", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 550000.0, neighborhood: "Palermo Soho", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 320000.0, neighborhood: "Palermo", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 549000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Belgrano", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 488000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 750000.0, neighborhood: "Floresta Sur", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Flores", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1220000.0, neighborhood: "Belgrano R", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 850000.0, neighborhood: "Floresta", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "San Nicol\u00e1s", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 320000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Parque Rivadavia", total_area: 25.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 793000.0, neighborhood: "Flores Norte", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Flores Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Belgrano R", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Belgrano", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 495000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 395000.0, neighborhood: "Parque Patricios", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 480000.0, neighborhood: "Parque Patricios", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 395000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 420000.0, neighborhood: "San Nicol\u00e1s", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 380000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Villa Devoto", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 980000.0, neighborhood: "Belgrano", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 549000.0, neighborhood: "Palermo Soho", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Almagro", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 854000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Almagro", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Abasto", total_area: 57.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 580000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Flores", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 310000.0, neighborhood: "Barrio Norte", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 915000.0, neighborhood: "Belgrano", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Caballito Norte", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 115000.0, neighborhood: "Belgrano R", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 3.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 220000.0, neighborhood: "Barrio Norte", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 690000.0, neighborhood: "Palermo Soho", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Congreso", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Villa Lugano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "Retiro", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 488000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Centro / Microcentro", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 68.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Barrio Norte", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 780000.0, neighborhood: "Palermo", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Barrio Norte", total_area: 78.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 750000.0, neighborhood: "Palermo Nuevo", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 370000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 800000.0, neighborhood: "Parque Chacabuco", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "Abasto", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 39.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo Chico", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Caballito Sur", total_area: 82.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 440000.0, neighborhood: "Belgrano C", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Villa del Parque", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 512400.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 570000.0, neighborhood: "Almagro", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Palermo Soho", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Caballito", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Barrio Norte", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Belgrano C", total_area: 79.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Villa Luro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 480000.0, neighborhood: "Villa Urquiza", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Belgrano R", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Balvanera", total_area: 82.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 854000.0, neighborhood: "San Telmo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 700000.0, neighborhood: "Colegiales", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 295000.0, neighborhood: "Centro / Microcentro", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 671000.0, neighborhood: "Centro / Microcentro", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Villa Crespo", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 320000.0, neighborhood: "Barracas", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 420000.0, neighborhood: "Parque Chacabuco", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "Belgrano C", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 360000.0, neighborhood: "Balvanera", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Villa Urquiza", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Colegiales", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 600000.0, neighborhood: "Palermo Viejo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "Belgrano Chico", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Flores", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 671000.0, neighborhood: "Colegiales", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 350000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "San Telmo", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 350000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Villa Lugano", total_area: 71.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 841800.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Villa Lugano", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Centro / Microcentro", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 480000.0, neighborhood: "Belgrano", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Almagro Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 800000.0, neighborhood: "Caballito Sur", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 480000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Flores", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 850000.0, neighborhood: "Belgrano", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Almagro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 81.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 390000.0, neighborhood: "Retiro", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Abasto", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 595000.0, neighborhood: "Villa General Mitre", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 530000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Abasto", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Parque Chas", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Villa Luro", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 380000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 470000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 470000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1037000.0, neighborhood: "Almagro", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Monserrat", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 675000.0, neighborhood: "Balvanera", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 770000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Barracas", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1464000.0, neighborhood: "Belgrano", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 510000.0, neighborhood: "Palermo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 3.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 420000.0, neighborhood: "San Nicol\u00e1s", total_area: 19.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 900000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 850000.0, neighborhood: "Parque Rivadavia", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Belgrano R", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 58.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 380000.0, neighborhood: "Congreso", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 590000.0, neighborhood: "Villa del Parque", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 480000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Barrio Norte", total_area: 80.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1037000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa Devoto", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo Soho", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 520000.0, neighborhood: "Villa Crespo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Villa Crespo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 671000.0, neighborhood: "San Telmo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 950000.0, neighborhood: "Palermo Viejo", total_area: 77.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 590000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 854000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Caballito Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Colegiales", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 370000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 530000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 620000.0, neighborhood: "Barracas", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 320000.0, neighborhood: "Balvanera", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 360000.0, neighborhood: "Floresta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 900000.0, neighborhood: "San Telmo", total_area: 78.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 580000.0, neighborhood: "Villa Urquiza", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 580000.0, neighborhood: "Almagro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 690000.0, neighborhood: "Villa Urquiza", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Balvanera", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 850000.0, neighborhood: "Caballito Sur", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Parque Chacabuco", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 470000.0, neighborhood: "Versalles", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 750000.0, neighborhood: "Villa Crespo", total_area: 77.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 680000.0, neighborhood: "Balvanera", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 713700.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "Flores", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 540000.0, neighborhood: "Belgrano", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 976000.0, neighborhood: "Villa Urquiza", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 720000.0, neighborhood: "Belgrano", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 290000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 700000.0, neighborhood: "Palermo Viejo", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 330000.0, neighborhood: "Balvanera", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 900000.0, neighborhood: "Belgrano", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 320000.0, neighborhood: "Barracas", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "Parque Chacabuco", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "Barracas", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 700000.0, neighborhood: "Barracas", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 930000.0, neighborhood: "Villa Crespo", total_area: 84.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 550000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Barracas", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 380000.0, neighborhood: "Barrio Norte", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 680000.0, neighborhood: "Villa Crespo", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 510000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Barracas", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Monserrat", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "San Telmo", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "Retiro", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 900000.0, neighborhood: "Centro / Microcentro", total_area: 78.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1464000.0, neighborhood: "Palermo Chico", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Monserrat", total_area: 78.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 390000.0, neighborhood: "Barrio Norte", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Retiro", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 490000.0, neighborhood: "Barrio Norte", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Abasto", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "San Nicol\u00e1s", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 671000.0, neighborhood: "Abasto", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 640000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Retiro", total_area: 86.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 561200.0, neighborhood: "Barrio Norte", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Almagro", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Flores", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 854000.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Villa Devoto", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 86.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Villa Ortuzar", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Flores", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 480000.0, neighborhood: "Centro / Microcentro", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 610000.0, neighborhood: "Villa Devoto", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 630000.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 300000.0, neighborhood: "San Nicol\u00e1s", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Barrio Norte", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 580000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 875000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Flores Norte", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Colegiales", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Almagro Norte", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 320000.0, neighborhood: "Barracas", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "Caballito Norte", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 72.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "Parque Chacabuco", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 650000.0, neighborhood: "Belgrano R", total_area: 91.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 460000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 340000.0, neighborhood: "Caballito", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 660000.0, neighborhood: "San Telmo", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Barracas", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "San Telmo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 380000.0, neighborhood: "Balvanera", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 900000.0, neighborhood: "Villa Luro", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 390000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 520000.0, neighborhood: "Villa Devoto", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 800000.0, neighborhood: "Barrio Norte", total_area: 82.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 590000.0, neighborhood: "Balvanera", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Flores", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 420000.0, neighborhood: "Villa Santa Rita", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 430000.0, neighborhood: "Villa Crespo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 420000.0, neighborhood: "Congreso", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 590000.0, neighborhood: "Almagro", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 300000.0, neighborhood: "Flores", total_area: 17.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 630000.0, neighborhood: "Caballito Sur", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Villa Devoto", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 620000.0, neighborhood: "Palermo", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 990000.0, neighborhood: "Recoleta", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 950000.0, neighborhood: "Villa Crespo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 550000.0, neighborhood: "Belgrano R", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Congreso", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Villa Luro", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 820000.0, neighborhood: "Flores", total_area: 82.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 549000.0, neighborhood: "Floresta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 480000.0, neighborhood: "Belgrano", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Almagro", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Constituci\u00f3n", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 460000.0, neighborhood: "Villa Santa Rita", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Barracas", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 380000.0, neighborhood: "Flores", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 350000.0, neighborhood: "Congreso", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 420000.0, neighborhood: "Villa del Parque", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 450000.0, neighborhood: "Congreso", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 490000.0, neighborhood: "Caballito Sur", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "Belgrano C", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 630000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 570000.0, neighborhood: "Villa Crespo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 854000.0, neighborhood: "Barrio Norte", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 460000.0, neighborhood: "Palermo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Congreso", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 420000.0, neighborhood: "Retiro", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 560000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1000000.0, neighborhood: "Belgrano", total_area: 66.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Retiro", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 610000.0, neighborhood: "Retiro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 756400.0, neighborhood: "Palermo Nuevo", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 915000.0, neighborhood: "Villa Urquiza", total_area: 75.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 430000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Villa Devoto", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 26.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 490000.0, neighborhood: "Colegiales", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 360000.0, neighborhood: "Flores", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 340000.0, neighborhood: "Parque Centenario", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 26.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Flores", total_area: 81.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 530000.0, neighborhood: "Almagro", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 590000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Centro / Microcentro", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 976000.0, neighborhood: "Congreso", total_area: 51.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 749000.0, neighborhood: "Almagro", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 610000.0, neighborhood: "Villa Urquiza", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 671000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 549000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 550000.0, neighborhood: "Villa del Parque", total_area: 51.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 300000.0, neighborhood: "Villa Crespo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 780000.0, neighborhood: "Belgrano", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 599000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 366000.0, neighborhood: "Balvanera", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Barracas", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Flores", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 900000.0, neighborhood: "Palermo Soho", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 480000.0, neighborhood: "Caballito", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 598000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 470000.0, neighborhood: "Belgrano", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 690000.0, neighborhood: "Caballito", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 990000.0, neighborhood: "Villa Urquiza", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Balvanera", total_area: 90.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 380000.0, neighborhood: "Villa General Mitre", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 420000.0, neighborhood: "Almagro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Almagro Norte", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1037000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 850000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 650000.0, neighborhood: "Almagro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Flores", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Flores", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 68.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Barrio Norte", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Colegiales", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 500000.0, neighborhood: "Villa Devoto", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1342000.0, neighborhood: "San Telmo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 460000.0, neighborhood: "Almagro Norte", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Caballito Sur", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Parque Centenario", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Villa General Mitre", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 750000.0, neighborhood: "Floresta Norte", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Belgrano", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 900000.0, neighborhood: "Barrio Norte", total_area: 75.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 470000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 66.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Retiro", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 550000.0, neighborhood: "Villa del Parque", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Villa Ortuzar", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Flores Norte", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 580000.0, neighborhood: "Caballito", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Pompeya", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 490000.0, neighborhood: "Recoleta", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 875000.0, neighborhood: "Barrio Norte", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Tribunales", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 732000.0, neighborhood: "San Telmo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 420000.0, neighborhood: "Villa del Parque", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 1220000.0, neighborhood: "San Telmo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 550000.0, neighborhood: "Pompeya", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Parque Patricios", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 700000.0, neighborhood: "Villa Urquiza", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 610000.0, neighborhood: "Belgrano R", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1647000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1647000.0, neighborhood: "Colegiales", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 750000.0, neighborhood: "Belgrano C", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 250000.0, neighborhood: "Tribunales", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 671000.0, neighborhood: "Colegiales", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 780000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 630000.0, neighborhood: "Flores Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 350000.0, neighborhood: "Barracas", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1342000.0, neighborhood: "Palermo", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Belgrano", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1586000.0, neighborhood: "Recoleta", total_area: 80.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 400000.0, neighborhood: "Villa del Parque", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 630000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Villa General Mitre", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 854000.0, neighborhood: "Belgrano Chico", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Villa Crespo", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 570000.0, neighborhood: "Flores", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 415000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 300000.0, neighborhood: "Floresta", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 620000.0, neighborhood: "San Telmo", total_area: 55.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 800000.0, neighborhood: "Barrio Norte", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Congreso", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 730000.0, neighborhood: "Caballito", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "Palermo Chico", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 540000.0, neighborhood: "Villa Devoto", total_area: 73.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 430000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 750000.0, neighborhood: "Colegiales", total_area: 88.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 300000.0, neighborhood: "Palermo Viejo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 51.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Palermo Viejo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 841800.0, neighborhood: "Palermo Chico", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 370000.0, neighborhood: "Balvanera", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 793000.0, neighborhood: "Palermo Viejo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Mataderos", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 732000.0, neighborhood: "Congreso", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 390000.0, neighborhood: "Barrio Norte", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 366000.0, neighborhood: "Barrio Norte", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Flores", total_area: 86.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 290000.0, neighborhood: "Barrio Norte", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Palermo Viejo", total_area: 62.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 430000.0, neighborhood: "Villa Urquiza", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Villa Crespo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 950000.0, neighborhood: "Palermo", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 470000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "Belgrano C", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Colegiales", total_area: 45.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 440000.0, neighborhood: "Floresta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1195600.0, neighborhood: "Caballito", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 390000.0, neighborhood: "Belgrano", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 390000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Villa Luro", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 370000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Congreso", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 854000.0, neighborhood: "Barracas", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1586000.0, neighborhood: "Recoleta", total_area: 54.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 420000.0, neighborhood: "Villa Crespo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 280000.0, neighborhood: "Villa Devoto", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Monserrat", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "San Telmo", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Mataderos", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 976000.0, neighborhood: "Palermo Soho", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 671000.0, neighborhood: "Barrio Norte", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Palermo Soho", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Palermo Soho", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 530000.0, neighborhood: "Villa del Parque", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 854000.0, neighborhood: "Belgrano Chico", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 1403000.0, neighborhood: "Puerto Madero", total_area: 90.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 400000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 430000.0, neighborhood: "Villa Lugano", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 951600.0, neighborhood: "Puerto Madero", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 732000.0, neighborhood: "Retiro", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 951600.0, neighborhood: "Palermo", total_area: 70.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 670000.0, neighborhood: "Villa Devoto", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 530000.0, neighborhood: "Villa Devoto", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Palermo Nuevo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 488000.0, neighborhood: "Belgrano", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 570000.0, neighborhood: "Flores Norte", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 300000.0, neighborhood: "Flores", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1098000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 380000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Monserrat", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 1098000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1342000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 650000.0, neighborhood: "Almagro Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1342000.0, neighborhood: "Villa Crespo", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 793000.0, neighborhood: "Puerto Retiro", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 1037000.0, neighborhood: "Villa Urquiza", total_area: 85.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 525000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Villa Ortuzar", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 854000.0, neighborhood: "Puerto Madero", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 976000.0, neighborhood: "Villa Crespo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1281000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1525000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1342000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 600000.0, neighborhood: "Palermo Chico", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Recoleta", total_area: 80.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Villa Santa Rita", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 790000.0, neighborhood: "Villa del Parque", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 590000.0, neighborhood: "Villa del Parque", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 990000.0, neighborhood: "Villa del Parque", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Palermo", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1195600.0, neighborhood: "Palermo Chico", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 400000.0, neighborhood: "Palermo Soho", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Belgrano C", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 841800.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Belgrano", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1220000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 768600.0, neighborhood: "Colegiales", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1586000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Palermo Soho", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Villa Devoto", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "Puerto Madero", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 480000.0, neighborhood: "San Nicol\u00e1s", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 150000.0, neighborhood: "Belgrano", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1098000.0, neighborhood: "Congreso", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1098000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Palermo Chico", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 646600.0, neighborhood: "N\u00fa\u00f1ez", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Flores Norte", total_area: 75.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 420000.0, neighborhood: "Belgrano", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Abasto", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "Flores Sur", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 1159000.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 793000.0, neighborhood: "Barrio Norte", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Centro / Microcentro", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 690000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Palermo Nuevo", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 625000.0, neighborhood: "Palermo Soho", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Villa Devoto", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 488000.0, neighborhood: "Centro / Microcentro", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 499000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1220000.0, neighborhood: "Puerto Madero", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 460000.0, neighborhood: "Villa Luro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 427000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 520000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 590000.0, neighborhood: "Barracas", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 570000.0, neighborhood: "Flores Sur", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "Floresta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 480000.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Colegiales", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 440000.0, neighborhood: "Flores", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 490000.0, neighborhood: "Floresta Sur", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 260000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Congreso", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 976000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 430000.0, neighborhood: "Centro / Microcentro", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 420000.0, neighborhood: "Centro / Microcentro", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 480000.0, neighborhood: "San Telmo", total_area: 28.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 540000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 320000.0, neighborhood: "Balvanera", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 490000.0, neighborhood: "Recoleta", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1342000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 360000.0, neighborhood: "Retiro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 67.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Almagro Sur", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1342000.0, neighborhood: "San Telmo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "Retiro", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 580000.0, neighborhood: "Palermo Viejo", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 683200.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 951600.0, neighborhood: "Puerto Madero", total_area: 51.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 330000.0, neighborhood: "Balvanera", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "Flores Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 380000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 646600.0, neighborhood: "Villa Crespo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Barrio Norte", total_area: 57.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa Devoto", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Barrio Norte", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Caballito Norte", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Monserrat", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 440000.0, neighborhood: "Villa Santa Rita", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 690000.0, neighborhood: "Flores", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 380000.0, neighborhood: "Villa Urquiza", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 380000.0, neighborhood: "Villa Urquiza", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Balvanera", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 854000.0, neighborhood: "Puerto Madero", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Centro / Microcentro", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 490000.0, neighborhood: "Villa Urquiza", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Retiro", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 610000.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 1464000.0, neighborhood: "Colegiales", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "San Telmo", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 671000.0, neighborhood: "Retiro", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1098000.0, neighborhood: "Puerto Madero", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 671000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Palermo Soho", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 890000.0, neighborhood: "San Telmo", total_area: 75.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 800000.0, neighborhood: "Palermo Chico", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Colegiales", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 854000.0, neighborhood: "San Telmo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1464000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 380000.0, neighborhood: "San Nicol\u00e1s", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1012600.0, neighborhood: "Palermo", total_area: 75.0, rooms: 1.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 220000.0, neighborhood: "Barrio Norte", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 230000.0, neighborhood: "Barrio Norte", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 610000.0, neighborhood: "Almagro", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 800000.0, neighborhood: "Villa Urquiza", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 295000.0, neighborhood: "Tribunales", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 780000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Flores", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 83.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Palermo", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 1159000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 350000.0, neighborhood: "Centro / Microcentro", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Colegiales", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Almagro", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 850000.0, neighborhood: "Villa Luro", total_area: 73.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 430000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Palermo", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 320000.0, neighborhood: "Belgrano", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 990000.0, neighborhood: "Retiro", total_area: 74.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 740000.0, neighborhood: "Belgrano", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 915000.0, neighborhood: "Belgrano", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 330000.0, neighborhood: "Villa del Parque", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 1342000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 549000.0, neighborhood: "Centro / Microcentro", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 930000.0, neighborhood: "Barrio Norte", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 250000.0, neighborhood: "San Nicol\u00e1s", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 430000.0, neighborhood: "Floresta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 395000.0, neighborhood: "Barracas", total_area: 59.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 300000.0, neighborhood: "Congreso", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 610000.0, neighborhood: "Centro / Microcentro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Villa Ortuzar", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 380000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "Palermo Soho", total_area: 68.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 646600.0, neighborhood: "Centro / Microcentro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 260000.0, neighborhood: "Almagro", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1000000.0, neighborhood: "Retiro", total_area: 91.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Flores", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 480000.0, neighborhood: "Villa Urquiza", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 320000.0, neighborhood: "Congreso", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "Flores", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 380000.0, neighborhood: "Villa Crespo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Villa Devoto", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Almagro Norte", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Parque Chacabuco", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 260000.0, neighborhood: "Retiro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1342000.0, neighborhood: "Palermo Viejo", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 340000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 280000.0, neighborhood: "Caballito", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1403000.0, neighborhood: "Puerto Madero", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 1220000.0, neighborhood: "Puerto Madero", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Almagro Norte", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Villa del Parque", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 850000.0, neighborhood: "Balvanera", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 800000.0, neighborhood: "Villa Santa Rita", total_area: 88.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 440000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Balvanera", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 17.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "San Telmo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 320000.0, neighborhood: "San Nicol\u00e1s", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 427000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 790000.0, neighborhood: "Otro", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RETIRO"},
    {price: 530000.0, neighborhood: "Palermo", total_area: 66.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "San Nicol\u00e1s", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 690000.0, neighborhood: "Caballito", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 435000.0, neighborhood: "Congreso", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 793000.0, neighborhood: "Villa Devoto", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 750000.0, neighborhood: "Palermo Chico", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Balvanera", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 470000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 470000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Liniers", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 430000.0, neighborhood: "Palermo Nuevo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 290000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 780000.0, neighborhood: "Barrio Norte", total_area: 80.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Caballito", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Villa Devoto", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 320000.0, neighborhood: "San Nicol\u00e1s", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Belgrano C", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "Balvanera", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 480000.0, neighborhood: "Floresta", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 854000.0, neighborhood: "Retiro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 976000.0, neighborhood: "Villa Crespo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 270000.0, neighborhood: "Barrio Norte", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Caballito Norte", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 630000.0, neighborhood: "Balvanera", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 330000.0, neighborhood: "Palermo", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1586000.0, neighborhood: "Palermo", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 430000.0, neighborhood: "San Nicol\u00e1s", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 671000.0, neighborhood: "Balvanera", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1159000.0, neighborhood: "Belgrano C", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Palermo Chico", total_area: 65.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Caballito Sur", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 1464000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "San Telmo", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 700000.0, neighborhood: "Floresta", total_area: 76.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 650000.0, neighborhood: "Retiro", total_area: 72.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 549000.0, neighborhood: "Retiro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1342000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1037000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Monserrat", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 1159000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 793000.0, neighborhood: "Centro / Microcentro", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 976000.0, neighborhood: "Centro / Microcentro", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Barrio Norte", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Centro / Microcentro", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Barrio Norte", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "Villa Devoto", total_area: 40.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Caballito Sur", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Abasto", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Villa del Parque", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 549000.0, neighborhood: "Palermo Chico", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Barrio Norte", total_area: 78.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 690000.0, neighborhood: "Barrio Norte", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 680000.0, neighborhood: "Almagro", total_area: 61.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Almagro", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 730000.0, neighborhood: "Almagro", total_area: 90.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 415000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Caballito", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Congreso", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 380000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 680000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "Barrio Norte", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 488000.0, neighborhood: "Parque Chacabuco", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 610000.0, neighborhood: "Retiro", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 600000.0, neighborhood: "Villa Devoto", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 300000.0, neighborhood: "San Nicol\u00e1s", total_area: 18.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Belgrano R", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Pompeya", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 854000.0, neighborhood: "Belgrano", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Villa Lugano", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 549000.0, neighborhood: "Barrio Norte", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "San Telmo", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 370000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 740000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1342000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 200000.0, neighborhood: "Tribunales", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1220000.0, neighborhood: "Balvanera", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 420000.0, neighborhood: "Recoleta", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Monserrat", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 22.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Colegiales", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 370000.0, neighborhood: "Colegiales", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 732000.0, neighborhood: "Palermo Viejo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "San Nicol\u00e1s", total_area: 18.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1586000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "San Telmo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 530000.0, neighborhood: "Belgrano C", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 890000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 630000.0, neighborhood: "Palermo", total_area: 53.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1464000.0, neighborhood: "Centro / Microcentro", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 350000.0, neighborhood: "Flores Sur", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 854000.0, neighborhood: "Belgrano", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Congreso", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1403000.0, neighborhood: "Belgrano", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1098000.0, neighborhood: "Puerto Madero", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 700000.0, neighborhood: "San Telmo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 915000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Belgrano", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 950000.0, neighborhood: "Retiro", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 793000.0, neighborhood: "Belgrano", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 370000.0, neighborhood: "Caballito", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 793000.0, neighborhood: "Barrio Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "Palermo Soho", total_area: 60.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 630000.0, neighborhood: "Balvanera", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 671000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Villa Ortuzar", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 634400.0, neighborhood: "Retiro", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1220000.0, neighborhood: "Palermo Soho", total_area: 75.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 520000.0, neighborhood: "Belgrano", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "Colegiales", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 36600.0, neighborhood: "Villa Urquiza", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Belgrano R", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 460000.0, neighborhood: "Recoleta", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 430000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Villa Lugano", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 650000.0, neighborhood: "Almagro", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Liniers", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Villa del Parque", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 550000.0, neighborhood: "Villa Lugano", total_area: 64.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Mataderos", total_area: 64.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Flores", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1464000.0, neighborhood: "Retiro", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 500000.0, neighborhood: "Flores", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Retiro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 400000.0, neighborhood: "Retiro", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 250000.0, neighborhood: "San Nicol\u00e1s", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Caballito Sur", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 430000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Villa Ortuzar", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 800000.0, neighborhood: "Barrio Norte", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 680000.0, neighborhood: "Barracas", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1586000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 310000.0, neighborhood: "Belgrano", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 250000.0, neighborhood: "San Nicol\u00e1s", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 250000.0, neighborhood: "San Nicol\u00e1s", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1464000.0, neighborhood: "Palermo", total_area: 87.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "San Nicol\u00e1s", total_area: 77.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 854000.0, neighborhood: "Palermo Chico", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 320000.0, neighborhood: "Centro / Microcentro", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 536800.0, neighborhood: "Palermo", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 220000.0, neighborhood: "Villa Lugano", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Monserrat", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 900000.0, neighborhood: "Retiro", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 700000.0, neighborhood: "Villa Urquiza", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Flores", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 700000.0, neighborhood: "Colegiales", total_area: 78.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 976000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 665000.0, neighborhood: "Almagro", total_area: 82.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 360000.0, neighborhood: "Palermo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 829600.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Caballito Norte", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Belgrano Chico", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 963800.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Puerto Retiro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 395000.0, neighborhood: "Almagro Norte", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 44.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 490000.0, neighborhood: "Caballito", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "Parque Chacabuco", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 680000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Palermo Soho", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 415000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "Flores Norte", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 610000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "Constituci\u00f3n", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Tribunales", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 300000.0, neighborhood: "Flores", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 350000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 425000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 470000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Villa Riachuelo", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Caballito Norte", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Barrio Norte", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 330000.0, neighborhood: "Centro / Microcentro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Flores", total_area: 61.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 700000.0, neighborhood: "Villa Devoto", total_area: 75.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1586000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 488000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Liniers", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 870000.0, neighborhood: "Belgrano C", total_area: 68.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 630000.0, neighborhood: "Villa del Parque", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 430000.0, neighborhood: "Villa Urquiza", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1085800.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1342000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 590000.0, neighborhood: "Caballito Sur", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 854000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1451800.0, neighborhood: "Palermo Soho", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 320000.0, neighborhood: "Centro / Microcentro", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Villa del Parque", total_area: 51.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 650000.0, neighborhood: "Congreso", total_area: 79.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 300000.0, neighborhood: "Villa Devoto", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 707600.0, neighborhood: "Palermo", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 780000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 950000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "San Nicol\u00e1s", total_area: 77.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 69.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 1000000.0, neighborhood: "Palermo", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 730780.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Flores", total_area: 85.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1037000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 396500.0, neighborhood: "Almagro", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 420000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 915000.0, neighborhood: "Caballito", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Palermo Chico", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Congreso", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 700000.0, neighborhood: "Centro / Microcentro", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 430000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 430000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Congreso", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Belgrano R", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 595000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 597800.0, neighborhood: "Centro / Microcentro", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Belgrano R", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 762500.0, neighborhood: "Monserrat", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 671000.0, neighborhood: "Palermo Nuevo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Barrio Norte", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1342000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "Belgrano", total_area: 82.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Monserrat", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 488000.0, neighborhood: "Abasto", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 488000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 300000.0, neighborhood: "Palermo Chico", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 280000.0, neighborhood: "Balvanera", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 488000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 793000.0, neighborhood: "Palermo Chico", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 240000.0, neighborhood: "Liniers", total_area: 60.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Villa Devoto", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 915000.0, neighborhood: "Belgrano C", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Monserrat", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 854000.0, neighborhood: "Palermo Soho", total_area: 69.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 475000.0, neighborhood: "San Telmo", total_area: 43.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 450000.0, neighborhood: "Barracas", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 425000.0, neighborhood: "Mataderos", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "San Telmo", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 490000.0, neighborhood: "Villa Crespo", total_area: 65.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 250000.0, neighborhood: "Abasto", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Retiro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 900000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "Almagro", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 530000.0, neighborhood: "Monserrat", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Belgrano R", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "Mataderos", total_area: 79.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Villa del Parque", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 400000.0, neighborhood: "Villa General Mitre", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 390000.0, neighborhood: "Parque Patricios", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 854000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 390000.0, neighborhood: "San Nicol\u00e1s", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 420000.0, neighborhood: "Belgrano", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "San Telmo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 600000.0, neighborhood: "Monserrat", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 854000.0, neighborhood: "Palermo Viejo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 549000.0, neighborhood: "Constituci\u00f3n", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 320000.0, neighborhood: "Centro / Microcentro", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Parque Rivadavia", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "Monserrat", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 750000.0, neighborhood: "Barrio Norte", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 680000.0, neighborhood: "Villa Crespo", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 750000.0, neighborhood: "San Nicol\u00e1s", total_area: 59.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Colegiales", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 400000.0, neighborhood: "Caballito Norte", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 580000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Colegiales", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 370000.0, neighborhood: "Abasto", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Caballito Norte", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Balvanera", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 430000.0, neighborhood: "Monserrat", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 700000.0, neighborhood: "Palermo Soho", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 375000.0, neighborhood: "Balvanera", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 51.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Liniers", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1012600.0, neighborhood: "San Nicol\u00e1s", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 915000.0, neighborhood: "Puerto Madero", total_area: 66.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 545000.0, neighborhood: "Abasto", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Villa del Parque", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 800000.0, neighborhood: "Palermo Soho", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 320000.0, neighborhood: "Villa Luro", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 850000.0, neighborhood: "Villa Devoto", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 550000.0, neighborhood: "San Telmo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 550000.0, neighborhood: "Villa Crespo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Colegiales", total_area: 80.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 310000.0, neighborhood: "San Telmo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Monserrat", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 450000.0, neighborhood: "Parque Rivadavia", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Monserrat", total_area: 80.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1000000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Parque Rivadavia", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 380000.0, neighborhood: "Monserrat", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1098000.0, neighborhood: "Belgrano C", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 463600.0, neighborhood: "San Nicol\u00e1s", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 950000.0, neighborhood: "Palermo", total_area: 88.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Villa Luro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1464000.0, neighborhood: "Palermo", total_area: 87.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Villa del Parque", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 480000.0, neighborhood: "Villa Urquiza", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Villa del Parque", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 580000.0, neighborhood: "Caballito", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "Flores", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 380000.0, neighborhood: "Caballito", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Monserrat", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 540000.0, neighborhood: "Centro / Microcentro", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Barracas", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1586000.0, neighborhood: "Caballito Norte", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "Villa General Mitre", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 700000.0, neighborhood: "Colegiales", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 671000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 500000.0, neighborhood: "Retiro", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 400000.0, neighborhood: "Centro / Microcentro", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 380000.0, neighborhood: "Centro / Microcentro", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Villa del Parque", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 590000.0, neighborhood: "Abasto", total_area: 55.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Almagro Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 380000.0, neighborhood: "Flores", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 360000.0, neighborhood: "Recoleta", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1220000.0, neighborhood: "Colegiales", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 370000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 850000.0, neighborhood: "Villa Crespo", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 976000.0, neighborhood: "Colegiales", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 890000.0, neighborhood: "Caballito", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Palermo Viejo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 820000.0, neighborhood: "Belgrano C", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 390000.0, neighborhood: "Monserrat", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 25.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 854000.0, neighborhood: "Parque Patricios", total_area: 52.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 280600.0, neighborhood: "Centro / Microcentro", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 732000.0, neighborhood: "Barrio Norte", total_area: 88.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Colegiales", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 680000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 590000.0, neighborhood: "Colegiales", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 500000.0, neighborhood: "Caballito Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 300000.0, neighborhood: "Balvanera", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 640000.0, neighborhood: "Villa Crespo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Congreso", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Villa del Parque", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 500000.0, neighborhood: "Parque Chas", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 510000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "Villa Luro", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 854000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 680000.0, neighborhood: "Barracas", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Barracas", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "Flores", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Parque Centenario", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Liniers", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 490000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 530700.0, neighborhood: "Palermo", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Barracas", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 470000.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Colegiales", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 350000.0, neighborhood: "Almagro Norte", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 690000.0, neighborhood: "Barracas", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Monserrat", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 770000.0, neighborhood: "Villa Urquiza", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "San Telmo", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 375000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Retiro", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 72.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Villa Lugano", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 540000.0, neighborhood: "Belgrano", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 732000.0, neighborhood: "Almagro", total_area: 60.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Retiro", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 340000.0, neighborhood: "Villa Devoto", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 793000.0, neighborhood: "Almagro", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 610000.0, neighborhood: "Barrio Norte", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Mataderos", total_area: 48.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Balvanera", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 730000.0, neighborhood: "Barrio Norte", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Monserrat", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 600000.0, neighborhood: "Centro / Microcentro", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 430000.0, neighborhood: "Almagro", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Flores", total_area: 66.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 485000.0, neighborhood: "Palermo Soho", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 630000.0, neighborhood: "San Telmo", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 410000.0, neighborhood: "Flores Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "San Telmo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 650000.0, neighborhood: "Congreso", total_area: 68.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 350000.0, neighborhood: "Retiro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 430000.0, neighborhood: "Villa Urquiza", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 530000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 310000.0, neighborhood: "San Telmo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 850000.0, neighborhood: "Belgrano", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 250000.0, neighborhood: "Congreso", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 380000.0, neighborhood: "Palermo Soho", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Villa del Parque", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 680000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 990000.0, neighborhood: "Colegiales", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 854000.0, neighborhood: "Flores", total_area: 77.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa Lugano", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 480000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Congreso", total_area: 88.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "Palermo Viejo", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 470000.0, neighborhood: "Almagro", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "San Nicol\u00e1s", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Floresta Norte", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 900000.0, neighborhood: "Retiro", total_area: 90.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 330000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Villa Luro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 350000.0, neighborhood: "San Telmo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 390000.0, neighborhood: "Palermo Soho", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Congreso", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 820000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1037000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Villa del Parque", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 850000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 690000.0, neighborhood: "Parque Centenario", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 370000.0, neighborhood: "Barracas", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 320000.0, neighborhood: "Palermo", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1000000.0, neighborhood: "Caballito Sur", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 760000.0, neighborhood: "Colegiales", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 500000.0, neighborhood: "Belgrano R", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Villa Urquiza", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Villa Urquiza", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Caballito", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "Villa Urquiza", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Retiro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 380000.0, neighborhood: "San Telmo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 750000.0, neighborhood: "Tribunales", total_area: 70.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Palermo Chico", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 780000.0, neighborhood: "Palermo Soho", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 740000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 580000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 600000.0, neighborhood: "Villa Santa Rita", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 390000.0, neighborhood: "Villa Urquiza", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Caballito", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 690000.0, neighborhood: "Villa Urquiza", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 740000.0, neighborhood: "Colegiales", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1000000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 89.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 605000.0, neighborhood: "Caballito Norte", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Villa Devoto", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Villa del Parque", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 500000.0, neighborhood: "Parque Centenario", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 320000.0, neighborhood: "Palermo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 250000.0, neighborhood: "Constituci\u00f3n", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "Flores", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 430000.0, neighborhood: "Tribunales", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Palermo Nuevo", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Villa del Parque", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 480000.0, neighborhood: "Caballito", total_area: 44.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 378000.0, neighborhood: "Floresta Norte", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 1098000.0, neighborhood: "Palermo Soho", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 625000.0, neighborhood: "Palermo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Palermo Chico", total_area: 50.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 570000.0, neighborhood: "Villa Urquiza", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1342000.0, neighborhood: "Almagro", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Villa Crespo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 380000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 390000.0, neighborhood: "Balvanera", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 595000.0, neighborhood: "Caballito", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Almagro", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 510000.0, neighborhood: "Caballito Norte", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "San Telmo", total_area: 60.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 530000.0, neighborhood: "Belgrano", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 690000.0, neighborhood: "Flores", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1000000.0, neighborhood: "Palermo", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Parque Rivadavia", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "Monserrat", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 1342000.0, neighborhood: "Retiro", total_area: 70.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1000000.0, neighborhood: "Villa Urquiza", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 380000.0, neighborhood: "Tribunales", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1220000.0, neighborhood: "Puerto Madero", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 375000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Almagro Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 470000.0, neighborhood: "Villa Devoto", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 280000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Balvanera", total_area: 46.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 850000.0, neighborhood: "Palermo Soho", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 580000.0, neighborhood: "Retiro", total_area: 85.0, rooms: 6.0, bedrooms: 5.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 430000.0, neighborhood: "Barrio Norte", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 385000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 380000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 670000.0, neighborhood: "Belgrano", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Villa Crespo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 440000.0, neighborhood: "Palermo Viejo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1207800.0, neighborhood: "Palermo", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Retiro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "San Telmo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 610000.0, neighborhood: "Congreso", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 854000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 713700.0, neighborhood: "Colegiales", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 390000.0, neighborhood: "Floresta", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 488000.0, neighborhood: "Congreso", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Villa Luro", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 488000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 430000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Palermo Soho", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 290000.0, neighborhood: "Retiro", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 795000.0, neighborhood: "Almagro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 53.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 475800.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 300000.0, neighborhood: "Belgrano", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 610000.0, neighborhood: "Congreso", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "Belgrano R", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Palermo Soho", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Palermo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 475800.0, neighborhood: "Congreso", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 370000.0, neighborhood: "Balvanera", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "Flores", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 75.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 976000.0, neighborhood: "Puerto Madero", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 750000.0, neighborhood: "Palermo Nuevo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1464000.0, neighborhood: "Puerto Madero", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Palermo", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 370000.0, neighborhood: "Parque Patricios", total_area: 27.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 650000.0, neighborhood: "Congreso", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 850000.0, neighborhood: "Palermo Chico", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1342000.0, neighborhood: "Retiro", total_area: 75.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 790000.0, neighborhood: "Parque Chas", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 390000.0, neighborhood: "Almagro", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1220000.0, neighborhood: "Belgrano R", total_area: 78.0, rooms: 6.0, bedrooms: 4.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1098000.0, neighborhood: "Belgrano", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 520000.0, neighborhood: "Villa Luro", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 360000.0, neighborhood: "Barrio Norte", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Congreso", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 390000.0, neighborhood: "Caballito", total_area: 24.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 300000.0, neighborhood: "Pompeya", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Monserrat", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 850000.0, neighborhood: "Belgrano", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Mataderos", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 480000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 610000.0, neighborhood: "Villa Devoto", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 420000.0, neighborhood: "Flores", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 380000.0, neighborhood: "Parque Chas", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 570000.0, neighborhood: "Caballito Sur", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa Devoto", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 599000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 430000.0, neighborhood: "Caballito", total_area: 80.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 430000.0, neighborhood: "Parque Chacabuco", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 430000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1000000.0, neighborhood: "Palermo", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Parque Chas", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 390000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 350000.0, neighborhood: "Villa Real", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 340000.0, neighborhood: "Parque Chacabuco", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 490000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 488000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 420000.0, neighborhood: "San Telmo", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 671000.0, neighborhood: "Palermo Viejo", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Villa Ortuzar", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 480000.0, neighborhood: "Villa Devoto", total_area: 30.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 900000.0, neighborhood: "Belgrano R", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 300000.0, neighborhood: "Caballito", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 680000.0, neighborhood: "Villa Urquiza", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Congreso", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Balvanera", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 829600.0, neighborhood: "Almagro", total_area: 45.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 976000.0, neighborhood: "Barrio Norte", total_area: 78.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Liniers", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "Villa Luro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 750000.0, neighborhood: "Parque Centenario", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 560000.0, neighborhood: "Villa Crespo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 84.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 680000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 840000.0, neighborhood: "Villa Ortuzar", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 732000.0, neighborhood: "Puerto Madero", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Villa Luro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 420000.0, neighborhood: "Villa Crespo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 671000.0, neighborhood: "Palermo Soho", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Flores", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 732000.0, neighborhood: "San Telmo", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 330000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 671000.0, neighborhood: "Retiro", total_area: 74.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1159000.0, neighborhood: "Colegiales", total_area: 88.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Belgrano C", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Retiro", total_area: 69.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 400000.0, neighborhood: "Parque Chacabuco", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Congreso", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "San Telmo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1220000.0, neighborhood: "Belgrano", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 320000.0, neighborhood: "Barracas", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 950000.0, neighborhood: "Caballito", total_area: 81.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Colegiales", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1342000.0, neighborhood: "Belgrano", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 320000.0, neighborhood: "Barrio Norte", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Flores", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 54.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1342000.0, neighborhood: "Palermo", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Liniers", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Palermo Chico", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 320000.0, neighborhood: "Monserrat", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 485000.0, neighborhood: "Balvanera", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 330000.0, neighborhood: "Villa Urquiza", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 790000.0, neighborhood: "Villa del Parque", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Villa Urquiza", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 415000.0, neighborhood: "Belgrano", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "San Telmo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 700000.0, neighborhood: "Palermo Chico", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Colegiales", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 475000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 410000.0, neighborhood: "Caballito Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 79.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 690000.0, neighborhood: "Caballito", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 600000.0, neighborhood: "Colegiales", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 350000.0, neighborhood: "Flores", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 650000.0, neighborhood: "Tribunales", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Retiro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 400000.0, neighborhood: "Belgrano", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 330000.0, neighborhood: "Caballito Norte", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 470000.0, neighborhood: "San Nicol\u00e1s", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Villa del Parque", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "San Telmo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 800000.0, neighborhood: "Parque Centenario", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 780000.0, neighborhood: "Balvanera", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 300000.0, neighborhood: "Parque Chacabuco", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "Palermo Viejo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Floresta", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Monserrat", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 590000.0, neighborhood: "Villa Urquiza", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 390000.0, neighborhood: "San Telmo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 854000.0, neighborhood: "Retiro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 350000.0, neighborhood: "Almagro", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 370000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 370000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "San Telmo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 976000.0, neighborhood: "Colegiales", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1586000.0, neighborhood: "Belgrano", total_area: 90.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Flores", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1000400.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 530000.0, neighborhood: "San Telmo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 560000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 850000.0, neighborhood: "Barracas", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Flores", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 380000.0, neighborhood: "Constituci\u00f3n", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 365000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 395000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 200000.0, neighborhood: "Pompeya", total_area: 18.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "Parque Patricios", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Palermo Soho", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Colegiales", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 370000.0, neighborhood: "Villa Urquiza", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 480000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 950000.0, neighborhood: "Flores", total_area: 89.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 561200.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 290000.0, neighborhood: "Palermo Soho", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 549000.0, neighborhood: "Palermo", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Retiro", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 390000.0, neighborhood: "Centro / Microcentro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 460000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 950000.0, neighborhood: "Belgrano", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 535000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 732000.0, neighborhood: "San Nicol\u00e1s", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 490000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 488000.0, neighborhood: "San Nicol\u00e1s", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Liniers", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 380000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 470000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 890000.0, neighborhood: "Belgrano R", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 420000.0, neighborhood: "Flores", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 700000.0, neighborhood: "Almagro", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 510000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 850000.0, neighborhood: "Palermo Soho", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Almagro", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 425000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 470000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Barracas", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 400000.0, neighborhood: "Villa del Parque", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 330000.0, neighborhood: "Flores", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 380000.0, neighborhood: "Liniers", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 590000.0, neighborhood: "Barrio Norte", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 590000.0, neighborhood: "Congreso", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 350000.0, neighborhood: "Tribunales", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 790000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Villa Devoto", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 350000.0, neighborhood: "Villa del Parque", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 549000.0, neighborhood: "Villa Santa Rita", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 490000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 390000.0, neighborhood: "Caballito", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "Balvanera", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 22.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 950000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 540000.0, neighborhood: "Villa Urquiza", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 590000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Barrio Norte", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Flores", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 330000.0, neighborhood: "Villa Crespo", total_area: 28.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Villa Urquiza", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 550000.0, neighborhood: "Flores", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 320000.0, neighborhood: "Barrio Norte", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Barrio Norte", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "Belgrano", total_area: 88.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 490000.0, neighborhood: "Retiro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 540000.0, neighborhood: "Retiro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "Belgrano", total_area: 82.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1220000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 630000.0, neighborhood: "Belgrano C", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 370000.0, neighborhood: "Caballito", total_area: 13.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Palermo Viejo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Parque Patricios", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "San Nicol\u00e1s", total_area: 88.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Belgrano", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 250000.0, neighborhood: "Floresta Norte", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 680000.0, neighborhood: "Villa Crespo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 440000.0, neighborhood: "Villa Lugano", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 430000.0, neighborhood: "Almagro", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 430000.0, neighborhood: "Parque Patricios", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 700000.0, neighborhood: "Flores", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Barracas", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 350000.0, neighborhood: "Parque Patricios", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 700000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 549000.0, neighborhood: "San Telmo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 549000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Caballito Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 320000.0, neighborhood: "Belgrano", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Flores", total_area: 77.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 610000.0, neighborhood: "Villa Crespo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 980000.0, neighborhood: "Villa Urquiza", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 800000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 350000.0, neighborhood: "Centro / Microcentro", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 380000.0, neighborhood: "Flores", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Barracas", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 420000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Balvanera", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 700000.0, neighborhood: "Balvanera", total_area: 76.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Congreso", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 470000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1220000.0, neighborhood: "Colegiales", total_area: 67.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 600000.0, neighborhood: "Caballito Sur", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Barrio Norte", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 680000.0, neighborhood: "Barracas", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 480000.0, neighborhood: "Villa Santa Rita", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Congreso", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1464000.0, neighborhood: "Palermo Nuevo", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 470000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 600000.0, neighborhood: "Villa Santa Rita", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Villa Crespo", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 890000.0, neighborhood: "Villa Ortuzar", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 240000.0, neighborhood: "Barrio Norte", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 330000.0, neighborhood: "Balvanera", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 700000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 850000.0, neighborhood: "Villa Crespo", total_area: 70.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 630000.0, neighborhood: "Almagro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 580000.0, neighborhood: "Colegiales", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Colegiales", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 360000.0, neighborhood: "Villa del Parque", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Almagro Norte", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 366000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 280000.0, neighborhood: "Recoleta", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Floresta", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 280000.0, neighborhood: "Villa Santa Rita", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 800000.0, neighborhood: "Colegiales", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1586000.0, neighborhood: "Retiro", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 671000.0, neighborhood: "Caballito", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 470000.0, neighborhood: "Belgrano C", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 300000.0, neighborhood: "Flores", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 480000.0, neighborhood: "Balvanera", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 430000.0, neighborhood: "Villa Urquiza", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 48.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 970000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Belgrano", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1037000.0, neighborhood: "Retiro", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 650000.0, neighborhood: "Villa Urquiza", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 671000.0, neighborhood: "Caballito", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 488000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 690000.0, neighborhood: "Palermo Soho", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 580000.0, neighborhood: "Barrio Norte", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Colegiales", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 610000.0, neighborhood: "Balvanera", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 340000.0, neighborhood: "Retiro", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Palermo Nuevo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "San Telmo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 370000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "Congreso", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 390000.0, neighborhood: "Colegiales", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Villa del Parque", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 250000.0, neighborhood: "San Telmo", total_area: 19.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 495000.0, neighborhood: "Villa Urquiza", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 610000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 600000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 480000.0, neighborhood: "Palermo Chico", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 68.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Retiro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Parque Chacabuco", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "Constituci\u00f3n", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Belgrano R", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 430000.0, neighborhood: "Recoleta", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Villa Luro", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 580000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 700000.0, neighborhood: "Monserrat", total_area: 60.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 549000.0, neighborhood: "Parque Rivadavia", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 430000.0, neighborhood: "Congreso", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 83.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Centro / Microcentro", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 680000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 570000.0, neighborhood: "Palermo Soho", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Balvanera", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Barrio Norte", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 470000.0, neighborhood: "Caballito", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1000000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 410000.0, neighborhood: "Centro / Microcentro", total_area: 25.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 680000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 900000.0, neighborhood: "Barracas", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 449000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 793000.0, neighborhood: "Versalles", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 470000.0, neighborhood: "Almagro", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 370000.0, neighborhood: "Caballito", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 420000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1000000.0, neighborhood: "Villa Crespo", total_area: 86.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 980000.0, neighborhood: "Caballito Sur", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 310000.0, neighborhood: "Almagro", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Flores", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 82.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Balvanera", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 320000.0, neighborhood: "San Telmo", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 370000.0, neighborhood: "Flores", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 890000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 360000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 220000.0, neighborhood: "Pompeya", total_area: 17.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 950000.0, neighborhood: "Barrio Norte", total_area: 89.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 690000.0, neighborhood: "Caballito", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Monserrat", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 380000.0, neighborhood: "Mataderos", total_area: 24.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 430000.0, neighborhood: "Caballito", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Floresta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 350000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 950000.0, neighborhood: "Retiro", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 480000.0, neighborhood: "San Telmo", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "Barracas", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 65.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 350000.0, neighborhood: "Congreso", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Villa del Parque", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 1403000.0, neighborhood: "Barrio Norte", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Flores", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Colegiales", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 570000.0, neighborhood: "Centro / Microcentro", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 680000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 976000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 700000.0, neighborhood: "Villa Urquiza", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Barracas", total_area: 60.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Almagro Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 410000.0, neighborhood: "Villa Urquiza", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Villa del Parque", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 550000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 976000.0, neighborhood: "Barrio Norte", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 950000.0, neighborhood: "Barrio Norte", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 680000.0, neighborhood: "San Nicol\u00e1s", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 850000.0, neighborhood: "Palermo Soho", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 470000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Palermo Chico", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Belgrano", total_area: 88.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 915000.0, neighborhood: "Centro / Microcentro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Monserrat", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 950000.0, neighborhood: "Palermo", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Monserrat", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 330000.0, neighborhood: "Balvanera", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 890600.0, neighborhood: "Recoleta", total_area: 84.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Villa del Parque", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 480000.0, neighborhood: "Parque Chas", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 64.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 300000.0, neighborhood: "San Telmo", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "San Telmo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 330000.0, neighborhood: "Parque Chas", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Villa Devoto", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Barracas", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 915000.0, neighborhood: "Belgrano", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Pompeya", total_area: 75.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 680000.0, neighborhood: "Colegiales", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 976000.0, neighborhood: "Almagro", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 280000.0, neighborhood: "Barrio Norte", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 410000.0, neighborhood: "Congreso", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 590000.0, neighborhood: "Congreso", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 707600.0, neighborhood: "Palermo", total_area: 81.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 805200.0, neighborhood: "Almagro", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 440000.0, neighborhood: "Villa Urquiza", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Palermo Viejo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Mataderos", total_area: 84.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Belgrano C", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 390000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 430000.0, neighborhood: "Belgrano C", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 470000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 366000.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Belgrano Chico", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Colegiales", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 500000.0, neighborhood: "Monserrat", total_area: 65.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 890000.0, neighborhood: "Colegiales", total_area: 79.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 550000.0, neighborhood: "Villa Luro", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 570000.0, neighborhood: "Palermo Chico", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Villa Crespo", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 590000.0, neighborhood: "Recoleta", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 370000.0, neighborhood: "Floresta", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Floresta", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Villa Santa Rita", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Floresta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Barrio Norte", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 510000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 280000.0, neighborhood: "Floresta", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 390000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 480000.0, neighborhood: "Parque Chacabuco", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Colegiales", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1000000.0, neighborhood: "Belgrano C", total_area: 86.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 610000.0, neighborhood: "San Nicol\u00e1s", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Caballito", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 380000.0, neighborhood: "Liniers", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 854000.0, neighborhood: "Barrio Norte", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 430000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 451400.0, neighborhood: "Monserrat", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 370000.0, neighborhood: "Balvanera", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Villa Crespo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 440000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 250000.0, neighborhood: "Flores", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Parque Chas", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 430000.0, neighborhood: "Liniers", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 350000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Barrio Norte", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Colegiales", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 290000.0, neighborhood: "Belgrano", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 390000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Villa Santa Rita", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 370000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Flores", total_area: 69.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Palermo Chico", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Balvanera", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Barracas", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 915000.0, neighborhood: "Villa Urquiza", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Palermo Viejo", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Almagro", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 300000.0, neighborhood: "Balvanera", total_area: 28.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Villa Santa Rita", total_area: 55.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 380000.0, neighborhood: "Almagro", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 915000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 549000.0, neighborhood: "Retiro", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 841800.0, neighborhood: "Palermo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Parque Centenario", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Monserrat", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1464000.0, neighborhood: "Villa Crespo", total_area: 66.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 380000.0, neighborhood: "Villa Crespo", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 700000.0, neighborhood: "Almagro", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 590000.0, neighborhood: "Caballito Sur", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 380000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Mataderos", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Colegiales", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 900000.0, neighborhood: "Recoleta", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Floresta", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 700000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Barracas", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Centro / Microcentro", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 463600.0, neighborhood: "Retiro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 670000.0, neighborhood: "Villa Crespo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 620000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 620000.0, neighborhood: "Palermo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Caballito", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 580000.0, neighborhood: "Caballito", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Abasto", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Almagro Sur", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 380000.0, neighborhood: "Palermo Soho", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Villa Urquiza", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 488000.0, neighborhood: "Almagro", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 800000.0, neighborhood: "Barrio Norte", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1647000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 320000.0, neighborhood: "San Nicol\u00e1s", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 425000.0, neighborhood: "Caballito", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 720000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1525000.0, neighborhood: "Puerto Madero", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 850000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 720000.0, neighborhood: "Almagro", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Almagro Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Retiro", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 480000.0, neighborhood: "Barrio Norte", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Almagro", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 750000.0, neighborhood: "Barracas", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1098000.0, neighborhood: "Belgrano C", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 380000.0, neighborhood: "Monserrat", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 1098000.0, neighborhood: "Palermo Soho", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Colegiales", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1525000.0, neighborhood: "Palermo Soho", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 720000.0, neighborhood: "Parque Rivadavia", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 380000.0, neighborhood: "Villa Real", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "San Telmo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 530000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1098000.0, neighborhood: "Villa Urquiza", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 750000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Parque Chas", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 490000.0, neighborhood: "Almagro", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 490000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 405000.0, neighborhood: "Barrio Norte", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Caballito", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "Barrio Norte", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "San Telmo", total_area: 60.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 650000.0, neighborhood: "Flores", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 650000.0, neighborhood: "Barracas", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 590000.0, neighborhood: "Villa Ortuzar", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 380000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Flores", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 732000.0, neighborhood: "Villa Urquiza", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1098000.0, neighborhood: "Villa Urquiza", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 260000.0, neighborhood: "Caballito", total_area: 17.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Belgrano", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 580000.0, neighborhood: "Recoleta", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Villa Santa Rita", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "San Telmo", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 830000.0, neighborhood: "Recoleta", total_area: 64.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 740000.0, neighborhood: "Palermo", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 790000.0, neighborhood: "Recoleta", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 841800.0, neighborhood: "Barrio Norte", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Villa del Parque", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Villa del Parque", total_area: 79.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 915000.0, neighborhood: "Palermo Viejo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 470000.0, neighborhood: "Parque Chacabuco", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 700000.0, neighborhood: "Parque Chacabuco", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 495000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Villa Crespo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Flores", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Flores", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 690000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Belgrano C", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Parque Patricios", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 580000.0, neighborhood: "Villa Urquiza", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 380000.0, neighborhood: "Villa General Mitre", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 430000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 854000.0, neighborhood: "Puerto Madero", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Villa Santa Rita", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 680000.0, neighborhood: "Recoleta", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 620000.0, neighborhood: "Villa Luro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Retiro", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 780000.0, neighborhood: "Abasto", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 620000.0, neighborhood: "Almagro", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 340000.0, neighborhood: "Villa General Mitre", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 430000.0, neighborhood: "Floresta", total_area: 84.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 425000.0, neighborhood: "Mataderos", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 915000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Centro / Microcentro", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 811000.0, neighborhood: "Belgrano", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 460000.0, neighborhood: "Palermo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Barracas", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1342000.0, neighborhood: "Palermo Chico", total_area: 79.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 550000.0, neighborhood: "Villa Crespo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 549000.0, neighborhood: "Colegiales", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 370000.0, neighborhood: "Palermo", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "San Nicol\u00e1s", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 580000.0, neighborhood: "Flores", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1000000.0, neighborhood: "Belgrano", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1342000.0, neighborhood: "Puerto Madero", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 800000.0, neighborhood: "Villa Crespo", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 900000.0, neighborhood: "Villa Ortuzar", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1342000.0, neighborhood: "Palermo", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 610000.0, neighborhood: "Barracas", total_area: 64.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 1220000.0, neighborhood: "Belgrano", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Palermo Nuevo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Palermo Soho", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 549000.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1342000.0, neighborhood: "Belgrano", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 580000.0, neighborhood: "Caballito", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 950000.0, neighborhood: "Villa Urquiza", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 370000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 590000.0, neighborhood: "Flores", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 480000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Colegiales", total_area: 76.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 350000.0, neighborhood: "Villa Luro", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "San Telmo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "San Telmo", total_area: 18.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 470000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 590000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Palermo Nuevo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 590000.0, neighborhood: "Villa Devoto", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 750000.0, neighborhood: "Congreso", total_area: 70.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 620000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 671000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Centro / Microcentro", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 732000.0, neighborhood: "Palermo Soho", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 780000.0, neighborhood: "Caballito", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Constituci\u00f3n", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1647000.0, neighborhood: "Recoleta", total_area: 87.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 64.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 280000.0, neighborhood: "Caballito", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 360000.0, neighborhood: "Villa Crespo", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 725000.0, neighborhood: "Villa Urquiza", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 488000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Retiro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 420000.0, neighborhood: "Retiro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 470000.0, neighborhood: "Centro / Microcentro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 854000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 950000.0, neighborhood: "Retiro", total_area: 85.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Retiro", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "Retiro", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 850000.0, neighborhood: "Retiro", total_area: 70.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 854000.0, neighborhood: "Retiro", total_area: 74.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 500000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Villa Crespo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Colegiales", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 366000.0, neighborhood: "Puerto Retiro", total_area: 60.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 509960.0, neighborhood: "Barrio Norte", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 580000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 750000.0, neighborhood: "Parque Patricios", total_area: 85.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 730000.0, neighborhood: "Flores", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 420000.0, neighborhood: "Floresta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Liniers", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 350000.0, neighborhood: "Almagro", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 680000.0, neighborhood: "Caballito", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Barracas", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 800000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 950000.0, neighborhood: "Belgrano", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Flores", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 640000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Almagro", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 405000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 585600.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 860000.0, neighborhood: "Palermo", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 680000.0, neighborhood: "Almagro", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "Belgrano R", total_area: 90.0, rooms: 5.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Belgrano", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Caballito Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 488000.0, neighborhood: "Belgrano", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 950000.0, neighborhood: "Caballito", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "Parque Chacabuco", total_area: 60.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 480000.0, neighborhood: "Almagro", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 976000.0, neighborhood: "Palermo Chico", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 690000.0, neighborhood: "Barrio Norte", total_area: 81.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Centro / Microcentro", total_area: 47.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 915000.0, neighborhood: "Palermo Nuevo", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Palermo Soho", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 480000.0, neighborhood: "Colegiales", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Palermo Chico", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Flores Norte", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 700000.0, neighborhood: "Belgrano R", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 680000.0, neighborhood: "Floresta Sur", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Parque Patricios", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 290000.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Villa General Mitre", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 370000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 650000.0, neighborhood: "Monserrat", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 360000.0, neighborhood: "Monserrat", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 480000.0, neighborhood: "Almagro Norte", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1000000.0, neighborhood: "Belgrano", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1000000.0, neighborhood: "Belgrano C", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 370000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 360000.0, neighborhood: "Floresta", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 380000.0, neighborhood: "Centro / Microcentro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Villa Devoto", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 550000.0, neighborhood: "Flores Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 350000.0, neighborhood: "Colegiales", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 420000.0, neighborhood: "Barrio Norte", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Belgrano", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Retiro", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 435000.0, neighborhood: "Centro / Microcentro", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 420000.0, neighborhood: "Palermo Soho", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Belgrano C", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 950000.0, neighborhood: "Recoleta", total_area: 74.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 488000.0, neighborhood: "Palermo Soho", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Barrio Norte", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 470000.0, neighborhood: "Parque Patricios", total_area: 45.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 976000.0, neighborhood: "Colegiales", total_area: 61.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 630000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 440000.0, neighborhood: "Villa Urquiza", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Liniers", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 380000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 350000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 470000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "Belgrano", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 440000.0, neighborhood: "Caballito", total_area: 45.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 620000.0, neighborhood: "Belgrano R", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 300000.0, neighborhood: "Constituci\u00f3n", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Parque Chacabuco", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 370000.0, neighborhood: "Almagro", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1220000.0, neighborhood: "Colegiales", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 480000.0, neighborhood: "Tribunales", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1403000.0, neighborhood: "Palermo Chico", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 670000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 525000.0, neighborhood: "Palermo", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo Viejo", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 580000.0, neighborhood: "Floresta", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "Palermo Soho", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Liniers", total_area: 64.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 320000.0, neighborhood: "San Nicol\u00e1s", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Villa Santa Rita", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 430000.0, neighborhood: "Belgrano", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 488000.0, neighborhood: "Centro / Microcentro", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 460000.0, neighborhood: "Villa Devoto", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1586000.0, neighborhood: "Puerto Madero", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 570000.0, neighborhood: "Almagro", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 700000.0, neighborhood: "Villa Luro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "Villa Urquiza", total_area: 71.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Colegiales", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 430000.0, neighborhood: "San Telmo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 370000.0, neighborhood: "Liniers", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 1098000.0, neighborhood: "San Nicol\u00e1s", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Barrio Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Floresta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 720000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Centro / Microcentro", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 790000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 2.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 350000.0, neighborhood: "Versalles", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 732000.0, neighborhood: "Belgrano C", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Flores", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 480000.0, neighborhood: "Balvanera", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1342000.0, neighborhood: "Belgrano", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1037000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Belgrano R", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Recoleta", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Caballito", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 1159000.0, neighborhood: "San Telmo", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 430000.0, neighborhood: "Parque Chas", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 520000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 700000.0, neighborhood: "Barrio Norte", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Almagro", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Parque Chacabuco", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 370000.0, neighborhood: "Palermo", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 915000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Belgrano", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 690000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 490000.0, neighborhood: "Palermo", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 520000.0, neighborhood: "Villa Crespo", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 395000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1220000.0, neighborhood: "Palermo Chico", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 990000.0, neighborhood: "Almagro", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1647000.0, neighborhood: "Palermo", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 380000.0, neighborhood: "San Telmo", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Recoleta", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Barrio Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 900000.0, neighborhood: "Barracas", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 976000.0, neighborhood: "Belgrano R", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 300000.0, neighborhood: "Retiro", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 732000.0, neighborhood: "Villa Crespo", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Almagro", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 25.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 300000.0, neighborhood: "Constituci\u00f3n", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1037000.0, neighborhood: "Retiro", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 350000.0, neighborhood: "San Telmo", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1220000.0, neighborhood: "Colegiales", total_area: 57.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Belgrano C", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "Colegiales", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1000000.0, neighborhood: "Caballito Norte", total_area: 82.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 390000.0, neighborhood: "Villa Urquiza", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 750000.0, neighborhood: "Villa Urquiza", total_area: 77.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 490000.0, neighborhood: "Almagro Norte", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 350000.0, neighborhood: "San Telmo", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 415000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Villa Crespo", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 488000.0, neighborhood: "Palermo", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Flores", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 300000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "San Nicol\u00e1s", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1464000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Palermo Chico", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Caballito Norte", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 1464000.0, neighborhood: "Belgrano", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 3.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 300000.0, neighborhood: "Almagro", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 420000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 990000.0, neighborhood: "Puerto Madero", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 20.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 700000.0, neighborhood: "Villa Crespo", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Flores Norte", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 51.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 590000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Flores", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 488000.0, neighborhood: "San Nicol\u00e1s", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1464000.0, neighborhood: "Palermo Chico", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1464000.0, neighborhood: "Palermo Nuevo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 585600.0, neighborhood: "Villa Ortuzar", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1464000.0, neighborhood: "Monserrat", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Villa Crespo", total_area: 75.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 475000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 240000.0, neighborhood: "Almagro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Balvanera", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 320000.0, neighborhood: "Barrio Norte", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 310000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Villa Crespo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 480000.0, neighborhood: "Colegiales", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Villa Urquiza", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 320000.0, neighborhood: "Parque Patricios", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 700000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 320000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Parque Chacabuco", total_area: 16.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 640000.0, neighborhood: "Barrio Norte", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "San Telmo", total_area: 82.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 470000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "San Telmo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "Villa Urquiza", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 880000.0, neighborhood: "Barrio Norte", total_area: 82.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 490000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Villa Crespo", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 455000.0, neighborhood: "Palermo", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Colegiales", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 380000.0, neighborhood: "Tribunales", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Flores", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 549000.0, neighborhood: "San Telmo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 430000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 640000.0, neighborhood: "Palermo", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 900000.0, neighborhood: "Versalles", total_area: 74.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 850000.0, neighborhood: "Almagro", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 360000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Almagro", total_area: 81.0, rooms: 3.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Centro / Microcentro", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "San Telmo", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 380000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Monserrat", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 590000.0, neighborhood: "Colegiales", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 280000.0, neighborhood: "Barracas", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 380000.0, neighborhood: "Almagro", total_area: 47.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Caballito", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 430000.0, neighborhood: "Parque Chacabuco", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 800000.0, neighborhood: "Versalles", total_area: 74.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Monserrat", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 1220000.0, neighborhood: "Palermo", total_area: 88.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 580000.0, neighborhood: "Villa Urquiza", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 520000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "Villa Ortuzar", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 750000.0, neighborhood: "Congreso", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 640000.0, neighborhood: "Recoleta", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 320000.0, neighborhood: "San Telmo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 690000.0, neighborhood: "Caballito", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 42.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 499000.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 980000.0, neighborhood: "Flores Norte", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 1342000.0, neighborhood: "Palermo Soho", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 640000.0, neighborhood: "Caballito", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 490000.0, neighborhood: "Congreso", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 520000.0, neighborhood: "Villa Crespo", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 680000.0, neighborhood: "Palermo Viejo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 440000.0, neighborhood: "Villa del Parque", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 671000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Belgrano C", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "Belgrano", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 320000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 549000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Balvanera", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "Parque Chacabuco", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Tribunales", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 793000.0, neighborhood: "Palermo Viejo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 701500.0, neighborhood: "Palermo Chico", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 1342000.0, neighborhood: "Barrio Norte", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 370000.0, neighborhood: "Constituci\u00f3n", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 732000.0, neighborhood: "Monserrat", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 1207800.0, neighborhood: "Palermo Soho", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Parque Centenario", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 950000.0, neighborhood: "Parque Rivadavia", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 585000.0, neighborhood: "Congreso", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1342000.0, neighborhood: "Belgrano Chico", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 480000.0, neighborhood: "Mataderos", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 48800.0, neighborhood: "Colegiales", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 1525000.0, neighborhood: "Palermo Nuevo", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Flores Sur", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 500000.0, neighborhood: "Barrio Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1098000.0, neighborhood: "San Nicol\u00e1s", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Parque Patricios", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 790000.0, neighborhood: "Recoleta", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 460000.0, neighborhood: "Recoleta", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 620000.0, neighborhood: "Centro / Microcentro", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 671000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 470000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 580000.0, neighborhood: "Abasto", total_area: 78.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 530000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 390000.0, neighborhood: "Villa Ortuzar", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 680000.0, neighborhood: "San Nicol\u00e1s", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Parque Chacabuco", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 670000.0, neighborhood: "Belgrano C", total_area: 46.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 460000.0, neighborhood: "Barracas", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 854000.0, neighborhood: "Belgrano", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 340000.0, neighborhood: "Monserrat", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 793000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 320000.0, neighborhood: "Centro / Microcentro", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 800000.0, neighborhood: "Parque Rivadavia", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 671000.0, neighborhood: "Belgrano C", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 939400.0, neighborhood: "Palermo", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 720000.0, neighborhood: "Palermo Soho", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 341600.0, neighborhood: "Palermo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 520000.0, neighborhood: "Monserrat", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 750000.0, neighborhood: "Floresta Norte", total_area: 76.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 990000.0, neighborhood: "San Telmo", total_area: 74.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 520000.0, neighborhood: "Villa Santa Rita", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 800000.0, neighborhood: "Villa Devoto", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Villa Ortuzar", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 350000.0, neighborhood: "Constituci\u00f3n", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 600000.0, neighborhood: "Belgrano Chico", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Belgrano C", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 360000.0, neighborhood: "Almagro Sur", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1037000.0, neighborhood: "Caballito Norte", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 365000.0, neighborhood: "Recoleta", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1464000.0, neighborhood: "Barrio Norte", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 660000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 560000.0, neighborhood: "Centro / Microcentro", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 756400.0, neighborhood: "Flores Norte", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 490000.0, neighborhood: "Retiro", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 400000.0, neighborhood: "Barrio Norte", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Villa Ortuzar", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 620000.0, neighborhood: "Villa Urquiza", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 300000.0, neighborhood: "Monserrat", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 732000.0, neighborhood: "Palermo Nuevo", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Palermo Viejo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 440000.0, neighborhood: "Palermo Viejo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 530000.0, neighborhood: "Palermo Chico", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1159000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Belgrano R", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 610000.0, neighborhood: "Caballito Sur", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 350000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 800000.0, neighborhood: "Colegiales", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 854000.0, neighborhood: "San Telmo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "Almagro Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 850000.0, neighborhood: "Barrio Norte", total_area: 60.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 330000.0, neighborhood: "Monserrat", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 854000.0, neighborhood: "Palermo Soho", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "Villa del Parque", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 980000.0, neighborhood: "Palermo Soho", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 610000.0, neighborhood: "Villa Urquiza", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 370000.0, neighborhood: "Parque Chacabuco", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 490000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Congreso", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 380000.0, neighborhood: "Villa Urquiza", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1000000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Balvanera", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 732000.0, neighborhood: "Barracas", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 680000.0, neighborhood: "Belgrano R", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 390000.0, neighborhood: "Villa Crespo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 800000.0, neighborhood: "Balvanera", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "Villa Lugano", total_area: 73.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "Barrio Norte", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Parque Rivadavia", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 590000.0, neighborhood: "Recoleta", total_area: 62.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 480000.0, neighborhood: "Almagro Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Belgrano R", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 380000.0, neighborhood: "Floresta Sur", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Flores Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 610000.0, neighborhood: "Belgrano C", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 854000.0, neighborhood: "Almagro Norte", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 690000.0, neighborhood: "Belgrano Chico", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1098000.0, neighborhood: "Palermo Viejo", total_area: 85.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Parque Patricios", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 380000.0, neighborhood: "Retiro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 650000.0, neighborhood: "Caballito", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 990000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Caballito Sur", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 732000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 440000.0, neighborhood: "Villa Ortuzar", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 800000.0, neighborhood: "Barracas", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 915000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 420000.0, neighborhood: "Barrio Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Almagro Norte", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 580000.0, neighborhood: "Parque Chas", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 250000.0, neighborhood: "Constituci\u00f3n", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 550000.0, neighborhood: "Balvanera", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 790000.0, neighborhood: "Recoleta", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1085800.0, neighborhood: "Palermo Soho", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 976000.0, neighborhood: "Barracas", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 854000.0, neighborhood: "San Telmo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 750000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Monserrat", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 320000.0, neighborhood: "San Nicol\u00e1s", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Almagro Norte", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 430000.0, neighborhood: "San Nicol\u00e1s", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Flores Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 610000.0, neighborhood: "Villa General Mitre", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 380000.0, neighborhood: "Villa del Parque", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 495000.0, neighborhood: "Flores Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Almagro Sur", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Colegiales", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 550000.0, neighborhood: "Liniers", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 512400.0, neighborhood: "Almagro Norte", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 488000.0, neighborhood: "Belgrano", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 841800.0, neighborhood: "Palermo Soho", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Villa Santa Rita", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Liniers", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 680000.0, neighborhood: "Recoleta", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 340000.0, neighborhood: "Villa Crespo", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 470000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 250000.0, neighborhood: "Constituci\u00f3n", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 390400.0, neighborhood: "Balvanera", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 530000.0, neighborhood: "Flores", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 700000.0, neighborhood: "Balvanera", total_area: 76.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Retiro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 671000.0, neighborhood: "Belgrano", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Belgrano R", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Parque Centenario", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 460000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 420000.0, neighborhood: "San Telmo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 450000.0, neighborhood: "Almagro Sur", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Constituci\u00f3n", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 450000.0, neighborhood: "Parque Rivadavia", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 695000.0, neighborhood: "Palermo Viejo", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 744200.0, neighborhood: "Palermo Nuevo", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 460000.0, neighborhood: "Parque Chas", total_area: 56.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 380000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 700000.0, neighborhood: "Congreso", total_area: 82.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "Villa del Parque", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 500000.0, neighborhood: "Almagro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 350000.0, neighborhood: "Caballito", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 370000.0, neighborhood: "Barrio Norte", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Belgrano Chico", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 420000.0, neighborhood: "Balvanera", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 300000.0, neighborhood: "Monserrat", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 270000.0, neighborhood: "Constituci\u00f3n", total_area: 18.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 1464000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 80.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 366000.0, neighborhood: "Balvanera", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 435000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 520000.0, neighborhood: "Palermo Chico", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Barracas", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 550000.0, neighborhood: "San Telmo", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 82.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 615000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 549000.0, neighborhood: "Puerto Retiro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 350000.0, neighborhood: "Retiro", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 1037000.0, neighborhood: "San Nicol\u00e1s", total_area: 50.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 400000.0, neighborhood: "Villa Lugano", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 460000.0, neighborhood: "Congreso", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 854000.0, neighborhood: "Recoleta", total_area: 31.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 290000.0, neighborhood: "Tribunales", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 480000.0, neighborhood: "Almagro Sur", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 671000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Parque Centenario", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 580000.0, neighborhood: "Villa del Parque", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 579500.0, neighborhood: "Palermo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 488000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Parque Patricios", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "Flores Sur", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 350000.0, neighborhood: "Almagro Norte", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 450000.0, neighborhood: "Caballito Norte", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Flores Norte", total_area: 61.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Puerto Retiro", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 976000.0, neighborhood: "Caballito Norte", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Barracas", total_area: 72.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "San Nicol\u00e1s", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 750000.0, neighborhood: "Villa Crespo", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 550000.0, neighborhood: "Palermo Chico", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Palermo", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Flores Sur", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 150000.0, neighborhood: "Recoleta", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 410000.0, neighborhood: "Villa Devoto", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 850000.0, neighborhood: "Villa Ortuzar", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Floresta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Palermo Soho", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 620000.0, neighborhood: "Almagro Sur", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Flores Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 66.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 670000.0, neighborhood: "Recoleta", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Floresta Sur", total_area: 56.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 650000.0, neighborhood: "Villa Urquiza", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Villa Urquiza", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 290000.0, neighborhood: "Constituci\u00f3n", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 488000.0, neighborhood: "San Nicol\u00e1s", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 380000.0, neighborhood: "San Nicol\u00e1s", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 315000.0, neighborhood: "Retiro", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 360000.0, neighborhood: "Caballito Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Belgrano C", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Palermo Nuevo", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Belgrano R", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 730000.0, neighborhood: "Palermo Soho", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 340000.0, neighborhood: "Caballito Norte", total_area: 28.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 530000.0, neighborhood: "Flores Norte", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 375000.0, neighborhood: "Monserrat", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Colegiales", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 550000.0, neighborhood: "Belgrano R", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 485000.0, neighborhood: "Villa Urquiza", total_area: 52.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Villa del Parque", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 580000.0, neighborhood: "Monserrat", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 390000.0, neighborhood: "Villa Devoto", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 573400.0, neighborhood: "Palermo Chico", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 450000.0, neighborhood: "Palermo Nuevo", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 488000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Belgrano C", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 671000.0, neighborhood: "Colegiales", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 470000.0, neighborhood: "Palermo Nuevo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 1159000.0, neighborhood: "Belgrano C", total_area: 85.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Flores Norte", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Belgrano R", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Villa Ortuzar", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 710000.0, neighborhood: "Caballito", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 250000.0, neighborhood: "Caballito Sur", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 220000.0, neighborhood: "Congreso", total_area: 44.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Villa Urquiza", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Almagro Sur", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 370000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Constituci\u00f3n", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 550000.0, neighborhood: "Caballito Sur", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 716000.0, neighborhood: "Belgrano C", total_area: 47.0, rooms: 3.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 795000.0, neighborhood: "Belgrano", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 490000.0, neighborhood: "Villa Urquiza", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 325000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 325000.0, neighborhood: "Barrio Norte", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Flores Norte", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 550000.0, neighborhood: "Balvanera", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Almagro", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1159000.0, neighborhood: "Palermo Soho", total_area: 70.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 790000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 610000.0, neighborhood: "Balvanera", total_area: 57.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 650000.0, neighborhood: "Caballito Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Caballito", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Villa Lugano", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 345000.0, neighborhood: "Villa Devoto", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Barracas", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 750000.0, neighborhood: "Colegiales", total_area: 66.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 650000.0, neighborhood: "Barrio Norte", total_area: 53.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Palermo Nuevo", total_area: 49.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 598000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 580000.0, neighborhood: "Parque Rivadavia", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 398000.0, neighborhood: "San Nicol\u00e1s", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Belgrano C", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1000400.0, neighborhood: "Balvanera", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Villa Devoto", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 340000.0, neighborhood: "Balvanera", total_area: 26.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 520000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "San Nicol\u00e1s", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Villa Devoto", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 330000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 530000.0, neighborhood: "Palermo Nuevo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Congreso", total_area: 48.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1098000.0, neighborhood: "Palermo", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "San Telmo", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "San Nicol\u00e1s", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1403000.0, neighborhood: "Palermo Nuevo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 414800.0, neighborhood: "Monserrat", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 512400.0, neighborhood: "Recoleta", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 300000.0, neighborhood: "Monserrat", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 700000.0, neighborhood: "Caballito", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 550000.0, neighborhood: "Belgrano R", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 490000.0, neighborhood: "Palermo Viejo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 770000.0, neighborhood: "Recoleta", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Colegiales", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 732000.0, neighborhood: "Villa Crespo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Parque Rivadavia", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 793000.0, neighborhood: "Caballito Sur", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "Villa Santa Rita", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 400000.0, neighborhood: "Almagro Norte", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 650000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "San Telmo", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Belgrano", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 440000.0, neighborhood: "Palermo Viejo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 350000.0, neighborhood: "Palermo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 31.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 250000.0, neighborhood: "San Nicol\u00e1s", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Caballito Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Caballito Norte", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 790000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Palermo Soho", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 398000.0, neighborhood: "Villa Crespo", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Flores Norte", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 1220000.0, neighborhood: "Belgrano C", total_area: 84.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Caballito", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 915000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 707600.0, neighborhood: "Villa Crespo", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 850000.0, neighborhood: "Palermo", total_area: 83.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Belgrano C", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 790000.0, neighborhood: "Palermo Soho", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 680000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 555000.0, neighborhood: "Caballito Sur", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 900000.0, neighborhood: "Palermo Soho", total_area: 68.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 580000.0, neighborhood: "Belgrano C", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 430000.0, neighborhood: "Villa Urquiza", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 365000.0, neighborhood: "Flores Norte", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 850000.0, neighborhood: "San Nicol\u00e1s", total_area: 82.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 990000.0, neighborhood: "Belgrano C", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Caballito Sur", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 430000.0, neighborhood: "Villa del Parque", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 250000.0, neighborhood: "Recoleta", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 460000.0, neighborhood: "Parque Rivadavia", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 380000.0, neighborhood: "Villa Ortuzar", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 520000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Belgrano", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 620000.0, neighborhood: "Palermo", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Palermo", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Palermo Viejo", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 495000.0, neighborhood: "Belgrano C", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 854000.0, neighborhood: "San Nicol\u00e1s", total_area: 82.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 549000.0, neighborhood: "Palermo Nuevo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 600000.0, neighborhood: "Palermo Soho", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Almagro Sur", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 620000.0, neighborhood: "Flores Norte", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 470000.0, neighborhood: "Palermo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 425000.0, neighborhood: "Villa Urquiza", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa Urquiza", total_area: 48.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Barrio Norte", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 620000.0, neighborhood: "Villa Urquiza", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 600000.0, neighborhood: "Caballito Sur", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 854000.0, neighborhood: "Palermo Viejo", total_area: 66.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 399000.0, neighborhood: "Belgrano", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 330000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Palermo Chico", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 790000.0, neighborhood: "Parque Patricios", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 488000.0, neighborhood: "Constituci\u00f3n", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 890000.0, neighborhood: "Barracas", total_area: 68.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 810000.0, neighborhood: "Colegiales", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 470000.0, neighborhood: "Villa Santa Rita", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 520000.0, neighborhood: "Almagro Norte", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 400000.0, neighborhood: "Almagro Sur", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Almagro Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 430000.0, neighborhood: "Villa Ortuzar", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 360000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 360000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Parque Centenario", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 360000.0, neighborhood: "Villa Crespo", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 610000.0, neighborhood: "Retiro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 420000.0, neighborhood: "San Nicol\u00e1s", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 610000.0, neighborhood: "Balvanera", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 1220000.0, neighborhood: "Belgrano C", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 610000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Villa Ortuzar", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Belgrano C", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "San Telmo", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Parque Patricios", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 700000.0, neighborhood: "Caballito Norte", total_area: 48.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "Palermo", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "San Telmo", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 320000.0, neighborhood: "Centro / Microcentro", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 550000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 671000.0, neighborhood: "Palermo Chico", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 280000.0, neighborhood: "Liniers", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 560000.0, neighborhood: "Almagro", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Belgrano C", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 671000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Belgrano C", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "San Nicol\u00e1s", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "Almagro Sur", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 480000.0, neighborhood: "San Nicol\u00e1s", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 610000.0, neighborhood: "Palermo", total_area: 71.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Barrio Norte", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Almagro", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 59.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Caballito Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 700000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1037000.0, neighborhood: "Belgrano", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 671000.0, neighborhood: "Colegiales", total_area: 70.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 52.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 880000.0, neighborhood: "Recoleta", total_area: 87.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 390000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 350000.0, neighborhood: "Balvanera", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 380000.0, neighborhood: "Colegiales", total_area: 26.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 450000.0, neighborhood: "Palermo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 560000.0, neighborhood: "Belgrano C", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 951600.0, neighborhood: "Recoleta", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 310000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 390000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 475000.0, neighborhood: "Villa Devoto", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 470000.0, neighborhood: "Villa Santa Rita", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 854000.0, neighborhood: "Colegiales", total_area: 64.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 440000.0, neighborhood: "Belgrano C", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Parque Centenario", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 732000.0, neighborhood: "Parque Rivadavia", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Villa Luro", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 480000.0, neighborhood: "Villa Crespo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 550000.0, neighborhood: "Almagro", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 800000.0, neighborhood: "Parque Rivadavia", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 470000.0, neighborhood: "Villa Urquiza", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Caballito Sur", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 646600.0, neighborhood: "Palermo Soho", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Almagro Sur", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 700000.0, neighborhood: "Flores Norte", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 600000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 650000.0, neighborhood: "Liniers", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 560000.0, neighborhood: "Villa Ortuzar", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 430000.0, neighborhood: "Parque Chacabuco", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 630000.0, neighborhood: "Belgrano", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Centro / Microcentro", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 490000.0, neighborhood: "Villa Crespo", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 700000.0, neighborhood: "Recoleta", total_area: 49.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 510000.0, neighborhood: "Parque Chacabuco", total_area: 40.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 650000.0, neighborhood: "Belgrano R", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 732000.0, neighborhood: "Parque Rivadavia", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Villa Ortuzar", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 380000.0, neighborhood: "Constituci\u00f3n", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 66.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 530000.0, neighborhood: "Balvanera", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 366000.0, neighborhood: "Palermo Viejo", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 430000.0, neighborhood: "Belgrano C", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 320000.0, neighborhood: "Mataderos", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 450000.0, neighborhood: "Caballito", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 750000.0, neighborhood: "Palermo Soho", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 340000.0, neighborhood: "Constituci\u00f3n", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 805200.0, neighborhood: "Palermo Soho", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 795000.0, neighborhood: "Almagro Norte", total_area: 80.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 793000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 580000.0, neighborhood: "Balvanera", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 480000.0, neighborhood: "Palermo Soho", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Tribunales", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 380000.0, neighborhood: "Centro / Microcentro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 1000000.0, neighborhood: "Villa Devoto", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 330000.0, neighborhood: "Flores Norte", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 280000.0, neighborhood: "Palermo", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 340000.0, neighborhood: "Flores Norte", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 750000.0, neighborhood: "Recoleta", total_area: 54.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Congreso", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 420000.0, neighborhood: "Recoleta", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 73200.0, neighborhood: "Parque Centenario", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 590000.0, neighborhood: "Almagro", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 270000.0, neighborhood: "Recoleta", total_area: 18.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 671000.0, neighborhood: "San Telmo", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 610000.0, neighborhood: "Parque Patricios", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 300000.0, neighborhood: "San Nicol\u00e1s", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 400000.0, neighborhood: "Palermo Soho", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 520000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 650000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "Caballito Sur", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 350000.0, neighborhood: "Villa del Parque", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 420000.0, neighborhood: "Retiro", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 610000.0, neighborhood: "Abasto", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Villa del Parque", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 490000.0, neighborhood: "Almagro Sur", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 475000.0, neighborhood: "Caballito", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "Parque Patricios", total_area: 43.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 420000.0, neighborhood: "Villa Urquiza", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 900000.0, neighborhood: "Congreso", total_area: 78.0, rooms: 5.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 600000.0, neighborhood: "Congreso", total_area: 70.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Villa Luro", total_area: 85.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 854000.0, neighborhood: "Retiro", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 800000.0, neighborhood: "Belgrano C", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 480000.0, neighborhood: "Palermo Soho", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 420000.0, neighborhood: "San Nicol\u00e1s", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 600000.0, neighborhood: "Congreso", total_area: 44.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 610000.0, neighborhood: "Almagro Norte", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 870000.0, neighborhood: "Villa Ortuzar", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Flores Norte", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 420000.0, neighborhood: "Caballito", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Caballito Sur", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Villa Santa Rita", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 61000.0, neighborhood: "Palermo Nuevo", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 950000.0, neighborhood: "Belgrano R", total_area: 78.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Palermo", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 370000.0, neighborhood: "Almagro Norte", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Villa Urquiza", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Caballito Sur", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 800000.0, neighborhood: "Caballito Sur", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 470000.0, neighborhood: "Villa Urquiza", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 640000.0, neighborhood: "San Nicol\u00e1s", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 800000.0, neighborhood: "Recoleta", total_area: 56.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 290000.0, neighborhood: "Balvanera", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 841800.0, neighborhood: "Recoleta", total_area: 64.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Balvanera", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 490000.0, neighborhood: "Centro / Microcentro", total_area: 69.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 330000.0, neighborhood: "Recoleta", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 732000.0, neighborhood: "Palermo", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Villa Devoto", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 390000.0, neighborhood: "Villa Crespo", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 790000.0, neighborhood: "Flores Norte", total_area: 68.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 370000.0, neighborhood: "Balvanera", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 350000.0, neighborhood: "Retiro", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 366000.0, neighborhood: "Balvanera", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 580000.0, neighborhood: "Caballito Sur", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 950000.0, neighborhood: "Villa Crespo", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 500000.0, neighborhood: "Belgrano C", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 470000.0, neighborhood: "Belgrano", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 915000.0, neighborhood: "Palermo Nuevo", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Monserrat", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 732000.0, neighborhood: "Colegiales", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 500000.0, neighborhood: "Balvanera", total_area: 66.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 915000.0, neighborhood: "Villa Crespo", total_area: 63.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 370000.0, neighborhood: "Flores Norte", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 510000.0, neighborhood: "Belgrano C", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 550000.0, neighborhood: "Belgrano C", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 700000.0, neighborhood: "Belgrano R", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 1098000.0, neighborhood: "Recoleta", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1037000.0, neighborhood: "Palermo Soho", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Belgrano C", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 450000.0, neighborhood: "Retiro", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 780000.0, neighborhood: "Palermo Soho", total_area: 60.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 414800.0, neighborhood: "Recoleta", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 550000.0, neighborhood: "Balvanera", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "Retiro", total_area: 34.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 451400.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 915000.0, neighborhood: "Palermo Soho", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 850000.0, neighborhood: "Caballito Sur", total_area: 84.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 976000.0, neighborhood: "Recoleta", total_area: 56.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 793000.0, neighborhood: "Villa Crespo", total_area: 46.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 490000.0, neighborhood: "Almagro Norte", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Belgrano", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Recoleta", total_area: 46.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 265000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 270000.0, neighborhood: "San Nicol\u00e1s", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 500000.0, neighborhood: "Belgrano C", total_area: 52.0, rooms: 1.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 650000.0, neighborhood: "Villa Crespo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Palermo", total_area: 51.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 360000.0, neighborhood: "Retiro", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 375000.0, neighborhood: "San Nicol\u00e1s", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 430000.0, neighborhood: "Villa Luro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 430000.0, neighborhood: "Villa Ortuzar", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 400000.0, neighborhood: "Villa Urquiza", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 450000.0, neighborhood: "San Telmo", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 540000.0, neighborhood: "Parque Centenario", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 660000.0, neighborhood: "Caballito Sur", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Colegiales", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 707600.0, neighborhood: "Belgrano Chico", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Balvanera", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "Almagro Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 370000.0, neighborhood: "Palermo", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 700000.0, neighborhood: "Villa Urquiza", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 550000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 320000.0, neighborhood: "Constituci\u00f3n", total_area: 28.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 615000.0, neighborhood: "Villa Urquiza", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Parque Patricios", total_area: 45.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 900000.0, neighborhood: "Villa Urquiza", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1000000.0, neighborhood: "Caballito Sur", total_area: 74.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 390000.0, neighborhood: "San Telmo", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 380000.0, neighborhood: "Balvanera", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 570000.0, neighborhood: "San Nicol\u00e1s", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 732000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 370000.0, neighborhood: "Flores Sur", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 460000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1647000.0, neighborhood: "Belgrano R", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 390000.0, neighborhood: "Almagro", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 500000.0, neighborhood: "Recoleta", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 330000.0, neighborhood: "Villa Luro", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 854000.0, neighborhood: "Villa Crespo", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 530000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 620000.0, neighborhood: "Balvanera", total_area: 40.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 800000.0, neighborhood: "Almagro", total_area: 73.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 360000.0, neighborhood: "Parque Patricios", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 549000.0, neighborhood: "Monserrat", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 750000.0, neighborhood: "Caballito Norte", total_area: 67.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Parque Chas", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 671000.0, neighborhood: "Puerto Retiro", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 350000.0, neighborhood: "Villa Lugano", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 850000.0, neighborhood: "Recoleta", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 500000.0, neighborhood: "Congreso", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Abasto", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 440000.0, neighborhood: "San Nicol\u00e1s", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 340000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 350000.0, neighborhood: "Villa del Parque", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 1000000.0, neighborhood: "Belgrano R", total_area: 87.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 488000.0, neighborhood: "San Nicol\u00e1s", total_area: 22.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 450000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 30500.0, neighborhood: "Recoleta", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1037000.0, neighborhood: "Retiro", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 800000.0, neighborhood: "Colegiales", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 680000.0, neighborhood: "Parque Rivadavia", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 900000.0, neighborhood: "Colegiales", total_area: 62.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 500000.0, neighborhood: "Villa Luro", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 750000.0, neighborhood: "Almagro Norte", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 1220000.0, neighborhood: "Caballito Sur", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 300000.0, neighborhood: "Balvanera", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 732000.0, neighborhood: "Palermo Viejo", total_area: 58.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 600000.0, neighborhood: "Belgrano Chico", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 400000.0, neighborhood: "Balvanera", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 32.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 650000.0, neighborhood: "Puerto Retiro", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 450000.0, neighborhood: "San Nicol\u00e1s", total_area: 49.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 732000.0, neighborhood: "Belgrano Chico", total_area: 55.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 695400.0, neighborhood: "Caballito", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 480000.0, neighborhood: "Recoleta", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 495000.0, neighborhood: "Palermo Soho", total_area: 42.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 44.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 580000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 400000.0, neighborhood: "Villa General Mitre", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 850000.0, neighborhood: "N\u00fa\u00f1ez", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "NUNEZ"},
    {price: 1037000.0, neighborhood: "Villa Luro", total_area: 75.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 550000.0, neighborhood: "Villa Crespo", total_area: 41.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 280000.0, neighborhood: "Palermo", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1098000.0, neighborhood: "Colegiales", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 500000.0, neighborhood: "Palermo Soho", total_area: 37.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 854000.0, neighborhood: "Villa Crespo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Villa del Parque", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 610000.0, neighborhood: "Balvanera", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 550000.0, neighborhood: "Belgrano C", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 680000.0, neighborhood: "Villa General Mitre", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 370000.0, neighborhood: "Villa del Parque", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 300000.0, neighborhood: "Constituci\u00f3n", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 830000.0, neighborhood: "Belgrano C", total_area: 80.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 525000.0, neighborhood: "Puerto Retiro", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 300000.0, neighborhood: "Recoleta", total_area: 23.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 600000.0, neighborhood: "Caballito Sur", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 340000.0, neighborhood: "Villa Ortuzar", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 280000.0, neighborhood: "Balvanera", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 750000.0, neighborhood: "Belgrano Chico", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 40.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Palermo", total_area: 43.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 580000.0, neighborhood: "Almagro", total_area: 46.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 854000.0, neighborhood: "San Nicol\u00e1s", total_area: 60.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 650000.0, neighborhood: "Flores Norte", total_area: 56.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 320000.0, neighborhood: "Floresta Norte", total_area: 28.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 480000.0, neighborhood: "Villa del Parque", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 700000.0, neighborhood: "Almagro Norte", total_area: 59.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 793000.0, neighborhood: "Palermo", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 360000.0, neighborhood: "Villa Urquiza", total_area: 36.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 560000.0, neighborhood: "Monserrat", total_area: 20.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 29.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 580000.0, neighborhood: "Palermo Soho", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 820000.0, neighborhood: "Belgrano C", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 512400.0, neighborhood: "Belgrano", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 500000.0, neighborhood: "Colegiales", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 650000.0, neighborhood: "Flores", total_area: 48.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "FLORES"},
    {price: 549000.0, neighborhood: "Villa del Parque", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA DEL PARQUE"},
    {price: 610000.0, neighborhood: "Palermo Soho", total_area: 33.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 580000.0, neighborhood: "Recoleta", total_area: 53.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 800000.0, neighborhood: "Caballito Sur", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 600000.0, neighborhood: "Villa Urquiza", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 650000.0, neighborhood: "Caballito Norte", total_area: 50.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 650000.0, neighborhood: "Caballito Norte", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 670000.0, neighborhood: "Parque Rivadavia", total_area: 62.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 520000.0, neighborhood: "Barrio Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 280000.0, neighborhood: "Balvanera", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 320000.0, neighborhood: "San Nicol\u00e1s", total_area: 33.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 860000.0, neighborhood: "Recoleta", total_area: 90.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 610000.0, neighborhood: "Palermo Nuevo", total_area: 38.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 30.0, normalized_neighborhood: "PALERMO"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Almagro Norte", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 830000.0, neighborhood: "Parque Chas", total_area: 86.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 380000.0, neighborhood: "Puerto Retiro", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "PUERTO MADERO"},
    {price: 700000.0, neighborhood: "Villa Urquiza", total_area: 42.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 370000.0, neighborhood: "Retiro", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "RETIRO"},
    {price: 480000.0, neighborhood: "Belgrano", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 650000.0, neighborhood: "Palermo Soho", total_area: 47.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 650000.0, neighborhood: "Recoleta", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 700000.0, neighborhood: "Almagro Norte", total_area: 55.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 36.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 1037000.0, neighborhood: "Abasto", total_area: 65.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "CABALLITO"},
    {price: 500000.0, neighborhood: "Villa Crespo", total_area: 34.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 295000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 580000.0, neighborhood: "Belgrano C", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 750000.0, neighborhood: "Villa Luro", total_area: 71.0, rooms: 4.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 460000.0, neighborhood: "San Telmo", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 350000.0, neighborhood: "San Nicol\u00e1s", total_area: 29.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 800000.0, neighborhood: "Palermo Viejo", total_area: 79.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 295000.0, neighborhood: "Barrio Norte", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 640000.0, neighborhood: "Constituci\u00f3n", total_area: 58.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "SAN TELMO"},
    {price: 290000.0, neighborhood: "Recoleta", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Villa Crespo", total_area: 41.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1464000.0, neighborhood: "Recoleta", total_area: 85.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 980000.0, neighborhood: "Villa Urquiza", total_area: 70.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 2.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 800000.0, neighborhood: "Belgrano C", total_area: 61.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 560000.0, neighborhood: "Villa Crespo", total_area: 45.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 600000.0, neighborhood: "Recoleta", total_area: 40.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 875000.0, neighborhood: "Belgrano", total_area: 82.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 350000.0, neighborhood: "Villa Urquiza", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 854000.0, neighborhood: "Palermo", total_area: 63.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 1220000.0, neighborhood: "Recoleta", total_area: 48.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 1.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 365000.0, neighborhood: "Villa Urquiza", total_area: 39.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 732000.0, neighborhood: "San Telmo", total_area: 50.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 900000.0, neighborhood: "Monserrat", total_area: 72.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 80.0, normalized_neighborhood: "MONTSERRAT"},
    {price: 270000.0, neighborhood: "San Nicol\u00e1s", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 230000.0, neighborhood: "San Nicol\u00e1s", total_area: 39.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 415000.0, neighborhood: "Almagro Norte", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "ALMAGRO"},
    {price: 470000.0, neighborhood: "Palermo", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 340000.0, neighborhood: "Barrio Norte", total_area: 37.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 400000.0, neighborhood: "Villa Luro", total_area: 48.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 370000.0, neighborhood: "Flores Norte", total_area: 38.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "FLORES"},
    {price: 750000.0, neighborhood: "Villa Crespo", total_area: 71.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1586000.0, neighborhood: "Balvanera", total_area: 90.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 3.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 915000.0, neighborhood: "Balvanera", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BALVANERA"},
    {price: 500000.0, neighborhood: "Parque Centenario", total_area: 32.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PARQUE PATRICIOS"},
    {price: 450000.0, neighborhood: "Caballito Norte", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 60.0, normalized_neighborhood: "CABALLITO"},
    {price: 590000.0, neighborhood: "Villa Urquiza", total_area: 45.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 793000.0, neighborhood: "Belgrano C", total_area: 35.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "Villa Crespo", total_area: 38.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 1037000.0, neighborhood: "Belgrano C", total_area: 55.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 610000.0, neighborhood: "Palermo Soho", total_area: 50.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 40.0, normalized_neighborhood: "PALERMO"},
    {price: 750000.0, neighborhood: "Colegiales", total_area: 52.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "COLEGIALES"},
    {price: 510000.0, neighborhood: "Villa Urquiza", total_area: 30.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA URQUIZA"},
    {price: 460000.0, neighborhood: "Recoleta", total_area: 35.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 850000.0, neighborhood: "Palermo Viejo", total_area: 68.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 490000.0, neighborhood: "Recoleta", total_area: 27.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 976000.0, neighborhood: "Palermo", total_area: 54.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "PALERMO"},
    {price: 450000.0, neighborhood: "Recoleta", total_area: 24.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"},
    {price: 330000.0, neighborhood: "Almagro", total_area: 25.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "ALMAGRO"},
    {price: 700000.0, neighborhood: "Belgrano C", total_area: 60.0, rooms: 3.0, bedrooms: 2.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "BELGRANO"},
    {price: 600000.0, neighborhood: "San Telmo", total_area: 65.0, rooms: 2.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "SAN TELMO"},
    {price: 335000.0, neighborhood: "Centro / Microcentro", total_area: 30.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 70.0, normalized_neighborhood: "SAN NICOLAS"},
    {price: 820000.0, neighborhood: "Villa Crespo", total_area: 77.0, rooms: 4.0, bedrooms: 3.0, bathrooms: 2.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "VILLA CRESPO"},
    {price: 7000.0, neighborhood: "Recoleta", total_area: 21.0, rooms: 1.0, bedrooms: 1.0, bathrooms: 1.0, garages: 0.0, antiquity: 50.0, normalized_neighborhood: "RECOLETA"}]