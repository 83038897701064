import React from "react";
import { Card, Row, Col } from "react-bootstrap";
const LabelComponent = ({ title, subtitle, label, style = 1, img=null }) => {
  return (
    <>
      {(() => {
        switch (style) {
          case 1:
            return (
              <Card className="mb-2">
                <Card.Body>
                  <Row className="align-items-center">
                    {/* Center: Title & Subtitle */}
                    <Col xs="8" className="text">
                      <span className="fw-bold fs-6 d-block">
                        {title || "Title"}
                      </span>
                      <span className="fw-light d-block">
                        {subtitle || "Subtitle"}
                      </span>
                    </Col>

                    {/* Right: Number in Blue Bold Text */}
                    <Col xs="4" className="text-end">
                      <span className="fw-bold fs-4 text-primary">
                        {label || 0}
                      </span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          case 2:
            return (
              <Card className="mb-2" style={{ color: "#546C8F" }}>
                <Card.Body>
                  <Row className="align-items-center">
                    {/* Center: Title & Subtitle */}
                    <Col xs={12} lg={7} className="text">
                      <span className="fw-bold fs-6 d-block">
                        {title || "Title"}
                      </span>
                      <span className="fw-light d-block" style={{ fontSize: "0.75rem" }}>
                        {subtitle || "Subtitle"}
                      </span>
                    </Col>

                    {/* Right: Number in Blue Bold Text */}
                    <Col xs={12} lg={5} className="text-end">
                      <span className="fw-bold fs-6">{label || 0}</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          case 3:
            return (
              <Card className="mb-2" style={{ color: "#546C8F", position: "relative", overflow: "hidden" }}>
                {img && (
                  <div
                    style={{
                      position: "absolute",
                      right: 0,
                      top: 0,
                      width: "40%",
                      height: "100%",
                      opacity: 0.4,
                      backgroundImage: `url(${img})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                      zIndex: 1
                    }}
                  />
                )}
                <Card.Body style={{ position: "relative", zIndex: 2 }}>
                  <Row className="align-items-center">
                    {/* Center: Title & Subtitle */}
                    <Col xs="8" className="text">
                      <span className="fw-bold fs-6 d-block">
                        {title || "Title"}
                      </span>
                      <span
                        className="fw-light d-block"
                        style={{ fontSize: "0.75rem" }}
                      >
                        {subtitle || "Subtitle"}
                      </span>
                    </Col>

                    {/* Right: Number in Blue Bold Text */}
                    <Col xs="4" className="text-end">
                      <span className="fw-bold fs-5">{label || 0}</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
          default:
            return (
              <Card className="mb-2">
                <Card.Body>
                  <Row className="align-items-center">
                    {/* Center: Title & Subtitle */}
                    <Col xs="8" className="text">
                      <span className="fw-bold fs-6 d-block">{title}</span>
                      <span className="fw-light d-block">{subtitle}</span>
                    </Col>

                    {/* Right: Number in Blue Bold Text */}
                    <Col xs="4" className="text-end">
                      <span className="fw-bold fs-6">{label}</span>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            );
        }
      })()}
    </>
  );
};

export default LabelComponent;
