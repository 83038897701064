import { Col, Container, Row } from "react-bootstrap";
import PoweredByUade from "../components/PoweredByUade";

const Header = () => {
  return (
    <Container className="bg-light" fluid>
      <header>
        <Container className="header-bg">
          <Row>
            <Col className="text-center my-4">
              <img
                src="images/valorar_color.png"
                alt="Valorar by UADE"
                height={40}
              />
            </Col>
          </Row>
          <Row>
            <Col style={{ marginLeft: 150 }}>
              <PoweredByUade />
              <div className="fw-light my-4">
                <span className="text-start fs-1">
                  <span className="text-primary fw-bold">
                    Inteligencia <br /> Artificial
                  </span>{" "}
                  para <br /> Calcular <br /> tus operaciones
                </span>
              </div>
              <div className="my-4">
                <small className="d-block w-25 text-primary">
                  Calculá tus operaciones inmobiliarias de manera rápida y
                  precisa, utilizando la tecnología de la Inteligencia
                  Artificial.
                </small>
              </div>
            </Col>
          </Row>
        </Container>
      </header>
    </Container>
  );
};

export default Header;
