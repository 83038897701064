import React from "react";
import { Row, Col, Card, Container, Image } from "react-bootstrap";
import LabelComponent from "./components/LabelComponent";

const FirstRowContainer = ({ data }) => {
  return (
    <Row className="justify-content-center mb-3 align-items-stretch">
      <Col xs={12} lg={4}>
        <LabelComponent
          title="Inmueble para alquilar"
          subtitle="Última semana"
          label={data.total_properties_neighborhood}
        />
      </Col>
      <Col xs={12} lg={4}>
        <LabelComponent
          title="Publicaciones Removidas"
          subtitle="Última semana"
          label={data.removed_properties_since_last_report_neighborhood}
        />
      </Col>
      <Col xs={12} lg={4}>
        <LabelComponent
          title="Publicaciones nuevas"
          subtitle="Última semana"
          label={data.new_properties_since_last_report_neighborhood}
        />
      </Col>
    </Row>
  );
};

const InputDataContainer = ({ data }) => {
  return (
    <>
      <div className="bg-light rounded px-4 py-3">
        <Row>
          <Col md={8} className="mb-2">
            <span className="d-block fw-bold fs-3 text-primary">
              Información General
            </span>
            <span className="d-block fw-light">
              Descripción de datos básicos del inmueble
            </span>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={8}>
            <Row>
              <Col xs={12} lg={6}>
                <LabelComponent
                  title="Ubicación"
                  subtitle="CABA"
                  label={data.neighborhood}
                  style={2}
                />
              </Col>
              <Col xs={12} lg={6}>
                <LabelComponent
                  title="Ambientes"
                  subtitle="Espacios"
                  label={data.rooms}
                  style={2}
                />
              </Col>
              <Col xs={12} lg={6}>
                <LabelComponent
                  title="Metros Cuadrados"
                  subtitle="De la propiedad"
                  label={data.total_area}
                  style={2}
                />
              </Col>
              <Col xs={12} lg={6}>
                <LabelComponent
                  title="Dormitorios"
                  subtitle="Habitables"
                  label={data.bedrooms}
                  style={2}
                />
              </Col>
              <Col xs={12} lg={6}>
                <LabelComponent
                  title="Baños"
                  subtitle="Espacios de Higiene"
                  label={data.bathroom}
                  style={2}
                />
              </Col>
              <Col xs={12} lg={6}>
                <LabelComponent
                  title="Antigüedad"
                  subtitle="En el mercado"
                  label={data.antiquity}
                  style={2}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={4}>
            <Card.Img
              className="rounded"
              src="https://static.vecteezy.com/system/resources/previews/034/104/446/non_2x/city-urban-area-map-isometric-night-lights-ultraviolet-3d-illustration-architecture-town-street-with-a-lot-of-building-houses-and-skyscrapers-streets-trees-and-vehicles-vector.jpg"
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

const AlertAreaContainer = ({ data }) => {
  return (
    <Row className="p-3 my-3 rounded">
      <Row className="mb-2">
        <Col md={12} className="justify-content-center">
          <Card className="report-box alert-box border-0 bg-transparent shadow-none">
            <Card.Body>
              <span className="d-block fw-bold fs-3 text-primary">
                Alertas en el Área
              </span>
              <span className="d-block fw-light">
                Características destacadas del inmueble para que tengas en
                cuenta.
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={4}>
          <LabelComponent
            style={3}
            title="Transportes"
            subtitle="Trenes, Colectivos, Subtes"
            img="/images/pngs/alertas_lineas-transportes.png"
            label={data.nearby_places_data?.sitios_interes?.total}
          />
        </Col>
        <Col md={4}>
          <LabelComponent
            style={3}
            title="Sitios de Interés"
            subtitle="Cines, Teatros, Museos"
            img="/images/pngs/alertas_sitios-interes.png"
            label={data.nearby_places_data?.transporte?.total}
          />
        </Col>
        
        <Col md={4}>
          <LabelComponent
            style={3}
            title="Edificios Administrativos"
            subtitle="Sedes de Comuna, Municipalidad"
            img="/images/pngs/alertas_espacios-administrativos.png"
            label={data.nearby_places_data?.edificios_administrativos?.total}
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={4}>
          <LabelComponent
            style={3}
            title="Centros de Salud"
            subtitle="Hospitales, Clínicas, Sanatorios"
            img="/images/pngs/alertas_centros-salud.png"
            label={data.nearby_places_data?.centros_salud?.total}
          />
        </Col>
        <Col md={4}>
          <LabelComponent
            style={3}
            title="Instituciones Educativas"
            subtitle="Colegios, Jardines, Universidades"
            img="/images/pngs/alertas_instituciones-educativas.png"
            label={data.nearby_places_data?.instituciones_educativas?.total}
          />
        </Col>
        <Col md={4}>
          <LabelComponent
            style={3}
            title="Gastronomía"
            subtitle="Restaurantes, Bares, Cafes"
            img="/images/pngs/alertas_centros-gastronomicos.png"
            label={data.nearby_places_data?.restaurantes?.total}
          />
        </Col>
      </Row>
    </Row>
  );
};

const PriceEvolutionContainer = ({ data }) => {
  return (
    <Row className="p-3 my-3 rounded">
      <Row className="">
        <Col md={6}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#4A91F1",
                  fontSize: "1.3rem",
                }}
              >
                Evolución de Precio por m2
              </span>
              <br />
              <span style={{ color: "#575F6E", fontSize: "0.7rem" }}>
                Durante el último año en el barrio
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#4A91F1",
                  fontSize: "1.3rem",
                }}
              >
                Evolución de Precio promedio
              </span>
              <br />
              <span style={{ color: "#575F6E", fontSize: "0.7rem" }}>
                Durante el último año en el barrio
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={6}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <Image
                fluid
                src={data.price_by_m2_evolution}
                className="w-auto h-auto"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <Image
                fluid
                src={data.price_evolution}
                className="w-auto h-auto"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Row>
  );
};

const PieGraphContainer = ({ data }) => {
  console.log("Pie Graph Container Data");
  console.log(data);
  return (
    <Row className="p-3 my-3 rounded">
      <Row className="">
        <Col md={4}>
          <Card className="report-box border-0 shadow-none text-center">
            <Card.Body>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#4A91F1",
                  fontSize: "0.9rem",
                }}
              >
                Cant. de Propiedades por Ambientes
              </span>
              <br />
              <span style={{ color: "#575F6E", fontSize: "0.7rem" }}>
                Distribución por ambiente en el barrio
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="report-box border-0 shadow-none text-center">
            <Card.Body>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#4A91F1",
                  fontSize: "0.9rem",
                }}
              >
                Cant. de Propiedades por m2
              </span>
              <br />
              <span style={{ color: "#575F6E", fontSize: "0.7rem" }}>
                Distribución por m2 en el barrio
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="report-box border-0 shadow-none text-center">
            <Card.Body>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#4A91F1",
                  fontSize: "0.9rem",
                }}
              >
                Cant. de Propiedades por Ambientes
              </span>
              <br />
              <span style={{ color: "#575F6E", fontSize: "0.7rem" }}>
                Distribución por ambiente en CABA
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={4}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <Image
                fluid
                src={`${
                  data.pie_property_amb_distribution_neighborhood
                }?t=${Date.now()}`}
                className="w-auto h-auto"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <Image
                fluid
                src={`${
                  data.pie_property_m2_distribution_neighborhood
                }?t=${Date.now()}`}
                className="w-auto h-auto"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <Image
                fluid
                src={`${data.pie_property_amb_distribution}?t=${Date.now()}`}
                className="w-auto h-auto"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Row>
  );
};

const AmbAverageGraphContainer = ({ data }) => {
  return (
    <Row className="p-3 my-3 rounded">
      <Row className="">
        <Col md={6}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#4A91F1",
                  fontSize: "1.3rem",
                }}
              >
                Promedio de precios (Ambientes)
              </span>
              <br />
              <span style={{ color: "#575F6E", fontSize: "0.7rem" }}>
                Precio por cantidad de ambientes en el barrio
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#4A91F1",
                  fontSize: "1.3rem",
                }}
              >
                Promedio de precios (Ambientes)
              </span>
              <br />
              <span style={{ color: "#575F6E", fontSize: "0.7rem" }}>
                Precio por cantidad de ambientes en CABA
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={6}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <Image
                fluid
                src={data.bar_price_by_amb_neighborhood}
                className="w-auto h-auto"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <Image
                fluid
                src={data.bar_price_by_amb}
                className="w-auto h-auto"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Row>
  );
};

const M2AverageGraphContainer = ({ data }) => {
  return (
    <Row className="p-3 my-3 rounded">
      <Row className="">
        <Col md={6}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#4A91F1",
                  fontSize: "1.3rem",
                }}
              >
                Promedio de precios (m2)
              </span>
              <br />
              <span style={{ color: "#575F6E", fontSize: "0.7rem" }}>
                Precio por m2 en el barrio
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#4A91F1",
                  fontSize: "1.3rem",
                }}
              >
                Promedio de precios (m2)
              </span>
              <br />
              <span style={{ color: "#575F6E", fontSize: "0.7rem" }}>
                Precio por m2 en CABA
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={6}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <Image
                fluid
                src={data.bar_m2_price_by_amb_neighborhood}
                className="w-auto h-auto"
              />
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <Image
                fluid
                src={data.bar_m2_price_by_amb}
                className="w-auto h-auto"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Row>
  );
};

const PredictionContainer = ({ data }) => {
  return (
    <div className="border-top border-bottom my-2">
      <Row className="p-3 my-3 rounded">
        <Col md={6}>
          <Card className="report-box border-0 shadow-none">
            <Card.Body>
              <span className="d-block fw-bold fs-4 text-primary">
                Precio Final
              </span>
              <span className="fw-light small">
                El valor estimado de alquiler se calcula con un modelo de
                Machine Learning basado en datos reales del mercado
                inmobiliario. Se actualiza periódicamente con información de
                propiedades en oferta para reflejar las condiciones actuales.
                <strong>
                  El valor final puede variar según factores específicos,
                  negociación y condiciones del contrato.
                </strong>
              </span>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="bg-primary text-center py-2 mt-5">
            <Card.Body>
              <span className="text-white fw-bold fs-1">
                $
                {Math.trunc(
                  Math.min(data.rent_result_min, data.rent_result_max)
                )}{" "}
                ~ $
                {Math.trunc(
                  Math.max(data.rent_result_min, data.rent_result_max)
                )}
              </span>
              <span className="d-block fw-light small text-white">
                <i>
                  Cálculo estimado de alquiler en base a los datos ingresados y
                  los valores de mercado actuales.
                </i>
              </span>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

const ReportViewNew = ({ input_data, output_data }) => {
  return (
    <>
      <Container>
        {/* Primera Fila - 3 columnas */}
        <FirstRowContainer data={output_data} />

        {/* Sección Input y Maps */}
        <InputDataContainer data={input_data} />

        {/* Sección de Predicciones */}
        <PredictionContainer data={output_data} />

        {/* Sección de Alertas */}
        <AlertAreaContainer data={output_data} />

        {/* Sección de Evolución de Precios */}
        <PriceEvolutionContainer data={output_data} />

        {/* Sección de Gráficos Pie */}
        <PieGraphContainer data={output_data} />

        {/* Sección de Gráficos de Promedio de Ambientes */}
        <AmbAverageGraphContainer data={output_data} />

        {/* Sección de Gráficos de Promedio de m2 */}
        <M2AverageGraphContainer data={output_data} />
      </Container>
    </>
  );
};

export default ReportViewNew;
