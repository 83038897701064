export const prediction = {
  result: {
    rent_result_min: 756004.11839728,
    rent_result_max: 783036.26659083,
    total_properties: 8185,
    total_properties_neighborhood: 1290,
    average_price_neighborhood: "603836",
    min_price_neighborhood: "7000",
    max_price_neighborhood: "1647000",
    total_properties_amb_neighborhood: 214,
    average_price_amb_neighborhood: "866477",
    min_price_amb_neighborhood: "150000",
    max_price_amb_neighborhood: "1647000",
    new_properties_since_last_report_neighborhood: 450,
    removed_properties_since_last_report_neighborhood: 585,
    new_properties_since_last_report: 3111,
    removed_properties_since_last_report: 3397,
    nearby_places_data: {
      transporte: {
        total: 46,
        data: [],
      },
      sitios_interes: {
        total: 1,
        data: [],
      },
      edificios_administrativos: {
        total: 0,
        data: [],
      },
      instituciones_educativas: {
        total: 4,
        data: [],
      },
      centros_salud: {
        total: 0,
        data: [],
      },
      restaurantes: {
        total: 39,
        data: [],
      },
    },
    price_by_m2_evolution:
      "https://observatorio-inmobiliario.s3.us-east-2.amazonaws.com/reporting/reports/52818b90-a24b-43f4-afb7-118a3f6a7a22/price_by_m2_evolution.png",
    price_evolution:
      "https://observatorio-inmobiliario.s3.us-east-2.amazonaws.com/reporting/reports/52818b90-a24b-43f4-afb7-118a3f6a7a22/price_evolution.png",
    bar_price_by_amb:
      "https://observatorio-inmobiliario.s3.us-east-2.amazonaws.com/reporting/reports/52818b90-a24b-43f4-afb7-118a3f6a7a22/bar_price_by_amb.png",
    bar_m2_price_by_amb:
      "https://observatorio-inmobiliario.s3.us-east-2.amazonaws.com/reporting/reports/52818b90-a24b-43f4-afb7-118a3f6a7a22/bar_m2_price_by_amb.png",
    bar_price_by_amb_neighborhood:
      "https://observatorio-inmobiliario.s3.us-east-2.amazonaws.com/reporting/reports/52818b90-a24b-43f4-afb7-118a3f6a7a22/bar_price_by_amb_neighborhood.png",
    bar_m2_price_by_amb_neighborhood:
      "https://observatorio-inmobiliario.s3.us-east-2.amazonaws.com/reporting/reports/52818b90-a24b-43f4-afb7-118a3f6a7a22/bar_m2_price_by_amb_neighborhood.png",
    pie_property_amb_distribution:
      "https://observatorio-inmobiliario.s3.us-east-2.amazonaws.com/reporting/reports/52818b90-a24b-43f4-afb7-118a3f6a7a22/pie_property_amb_distribution.png",
    pie_property_m2_distribution_neighborhood:
      "https://observatorio-inmobiliario.s3.us-east-2.amazonaws.com/reporting/reports/52818b90-a24b-43f4-afb7-118a3f6a7a22/pie_property_m2_distribution_neighborhood.png",
    pie_property_amb_distribution_neighborhood:
      "https://observatorio-inmobiliario.s3.us-east-2.amazonaws.com/reporting/reports/52818b90-a24b-43f4-afb7-118a3f6a7a22/pie_property_amb_distribution_neighborhood.png",
  },
};
