const ValorarByUade = () => {
  return (
    <>
      <span className="border-end">
        <img src="images/valorar_color.png" alt="Valorar by UADE" height={40} />
      </span>
      <span className="ms-1">
        By <img src="images/uade_arg.png" alt="UADE" height={20} />
      </span>
    </>
  );
};

export default ValorarByUade;
