const barrios = [
  "ALMAGRO",
  "BALVANERA",
  "BELGRANO",
  "CABALLITO",
  "COLEGIALES",
  "DEVOTO",
  "FLORES",
  "MONTSERRAT",
  "NUNEZ",
  "PALERMO",
  "PARQUE PATRICIOS",
  "PUERTO MADERO",
  "RECOLETA",
  "RETIRO",
  "SAN NICOLAS",
  "SAN TELMO",
  "VILLA CRESPO",
  "VILLA DEL PARQUE",
  "VILLA URQUIZA",
];

export default barrios;
