import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col, Spinner, Modal } from "react-bootstrap";
import barrios from "../config/barrios";
import areas from "../config/areas";
import PredictionService from "../services/PredictionService";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReportViewNew from "../views/ReportViewNew";
import { IoSparklesSharp } from "react-icons/io5";

const validationSchema = Yup.object({
  total_area: Yup.string().required("El área total es requerida"),
  rooms: Yup.number()
    .min(1, "Debe ser al menos 1")
    .required("Este campo es obligatorio"),
  bedrooms: Yup.number()
    .min(0, "Debe ser al menos 0")
    .required("Este campo es obligatorio"),
  bathroom: Yup.number()
    .min(1, "Debe ser al menos 1")
    .required("Este campo es obligatorio"),
  garages: Yup.number()
    .min(0, "Debe ser al menos 0")
    .required("Este campo es obligatorio"),
  antiquity: Yup.number()
    .min(0, "Debe ser al menos 0")
    .required("Este campo es obligatorio"),
  neighborhood: Yup.string().required("El barrio es obligatorio"),
  street: Yup.string().required("La calle es obligatoria"),
});

const PropertyForm = () => {
  const [loading, setLoading] = useState(false);
  const [prediction, setPrediction] = useState(null);
  const [input, setInput] = useState(null);
  const [error, setError] = useState(null);
  const [history, setHistory] = useState([]);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const savedHistory = JSON.parse(localStorage.getItem("predictions")) || [];
    setHistory(savedHistory);
  }, []);

  const formik = useFormik({
    initialValues: {
      total_area: null,
      rooms: null,
      bedrooms:  null,
      bathroom: null,
      garages: null,
      antiquity: null,
      neighborhood: null,
      street: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      if (!window.confirm("¿Estás seguro de enviar la predicción?")) return;
      setLoading(true);
      setError(null);
      try {
        const response = await PredictionService.getPrediction(values);
        setPrediction(response);
        setInput(values);

        const newHistory = [response, ...history].slice(0, 5);
        setHistory(newHistory);
        localStorage.setItem("predictions", JSON.stringify(newHistory));
        setShowModal(true);
      } catch (error) {
        setError("Error al obtener la predicción. Intente nuevamente.");
      }
      setLoading(false);
    },
  });

  return (
    <>
      <Row>
        <Col>
          <Form onSubmit={formik.handleSubmit}>
            <Row className="mb-4">
              <Col md={12} className="mb-4">
                <Form.Group>
                  <Form.Label className="text-start d-block text-dark">
                    Barrio
                  </Form.Label>
                  <Form.Select
                    {...formik.getFieldProps("neighborhood")}
                    className={`form-control form-control-sm ${
                      formik.touched.neighborhood && formik.errors.neighborhood
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    {barrios.map((barrio, index) => (
                      <option key={index} value={barrio}>
                        {barrio}
                      </option>
                    ))}
                  </Form.Select>
                  {formik.touched.neighborhood &&
                    formik.errors.neighborhood && (
                      <div className="text-danger small">
                        {formik.errors.neighborhood}
                      </div>
                    )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4">
              {[
                { name: "rooms", label: "Ambientes" },
                { name: "bedrooms", label: "Dormitorios" },
                { name: "bathroom", label: "Baños" },
              ].map((field, index) => (
                <Col md={6} key={index} className="mb-4">
                  <Form.Group>
                    <Form.Label className="text-start d-block text-dark">
                      {field.label}
                    </Form.Label>
                    <Form.Control
                      type="number"
                      {...formik.getFieldProps(field.name)}
                      className={`form-control form-control-sm ${
                        formik.touched[field.name] && formik.errors[field.name]
                          ? "is-invalid"
                          : ""
                      }`}
                    />
                    {formik.touched[field.name] &&
                      formik.errors[field.name] && (
                        <div className="text-danger small">
                          {formik.errors[field.name]}
                        </div>
                      )}
                  </Form.Group>
                </Col>
              ))}
              <Col md={6} className="mb-4">
                <Form.Group>
                  <Form.Label className="text-start d-block text-dark">
                    Superficie Total (m2)
                  </Form.Label>
                  <Form.Select
                    {...formik.getFieldProps("total_area")}
                    className={`form-control form-control-sm ${
                      formik.touched.total_area && formik.errors.total_area
                        ? "is-invalid"
                        : ""
                    }`}
                  >
                    {areas.map((area, index) => (
                      <option key={index} value={area}>
                        {area}
                      </option>
                    ))}
                  </Form.Select>
                  {formik.touched.total_area && formik.errors.total_area && (
                    <div className="text-danger small">
                      {formik.errors.total_area}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group>
                  <Form.Label className="text-start d-block text-dark ">
                    Garajes
                  </Form.Label>
                  <Form.Control
                    type="number"
                    {...formik.getFieldProps("garages")}
                    className={`form-control form-control-sm ${
                      formik.touched.garages && formik.errors.garages
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {formik.touched.garages && formik.errors.garages && (
                    <div className="text-danger small">
                      {formik.errors.garages}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group>
                  <Form.Label className="text-start d-block text-dark">
                    Antigüedad
                  </Form.Label>
                  <Form.Control
                    type="number"
                    {...formik.getFieldProps("antiquity")}
                    className={`form-control form-control-sm ${
                      formik.touched.antiquity && formik.errors.antiquity
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {formik.touched.antiquity && formik.errors.antiquity && (
                    <div className="text-danger small">
                      {formik.errors.antiquity}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={12}>
                <Form.Group>
                  <Form.Label className="text-start d-block text-dark">
                    Calle
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...formik.getFieldProps("street")}
                    className={`form-control form-control-sm ${
                      formik.touched.street && formik.errors.street
                        ? "is-invalid"
                        : ""
                    }`}
                  />
                  {formik.touched.street && formik.errors.street && (
                    <div className="text-danger small">
                      {formik.errors.street}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className="d-flex text-center my-4">
              <Col md={12} className="mb-4">
                <Button
                  type="submit"
                  variant="primary"
                  className="m-auto px-4"
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Spinner animation="border" size="sm" />{" "}
                      <span className="text-capitalize">
                        Estimando el Valor
                      </span>
                      ...
                    </>
                  ) : (
                    <>
                      Valorar <IoSparklesSharp className="ms-2" />
                    </>
                  )}
                </Button>
              </Col>
            </Row>
          </Form>
          {error && <div className="text-danger mt-2">{error}</div>}
        </Col>
      </Row>

      <Modal
        size="xl"
        show={showModal}
        onHide={() => setShowModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="fs-1">Reporte Inmobiliario <span className="fs-5 fw-light d-block">A continuación el informe de acuerdo a los datos ingresados</span>  </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {prediction && (
            <ReportViewNew input_data={input} output_data={prediction.result} />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PropertyForm;
