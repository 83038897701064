import React from "react";
import { Row, Col, Card } from "react-bootstrap";

const MarketNumbers = ({ total_properties, removed_propierties, new_properties }) => {
    return (
      <Row className="justify-content-center mb-3 align-items-stretch">
        <Col md={4}>
          <Card>
            <Card.Body>
              <Row className="align-items-center">
                {/* Center: Title & Subtitle */}
                <Col xs="8" className="text">
                  <span style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                    Inmuebles para alquilar
                  </span>
                  <br />
                  <span style={{ fontSize: "0.6rem" }}>
                    Última semana
                  </span>
                </Col>
        
                {/* Right: Number in Blue Bold Text */}
                <Col xs="4" className="text-end">
                  <span style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
                    {total_properties}
                  </span>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Row className="align-items-center">
                {/* Center: Title & Subtitle */}
                <Col xs="8" className="text">
                  <span style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                    Propiedades alquiladas
                  </span>
                  <br />
                  <span style={{ fontSize: "0.6rem" }}>
                    Última semana
                  </span>
                </Col>
        
                {/* Right: Number in Blue Bold Text */}
                <Col xs="4" className="text-end">
                  <span style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
                    {removed_propierties}
                  </span>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Row className="align-items-center">
                {/* Center: Title & Subtitle */}
                <Col xs="8" className="text">
                  <span style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                    Propiedades Incorporadas
                  </span>
                  <br />
                  <span style={{ fontSize: "0.6rem" }}>
                    Última semana
                  </span>
                </Col>
        
                {/* Right: Number in Blue Bold Text */}
                <Col xs="4" className="text-end">
                  <span style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
                    {new_properties}
                  </span>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    );
  }

export default MarketNumbers;