import React from "react";
import { Row, Col, Card, Image} from "react-bootstrap";

const HeaderComponent = () => {
  return (
    <Row className="mt-3 mb-3 align-items-stretch">
      <Col md={9}>
        <Card className="border-0 shadow-none">
          <Card.Title className="mt-3">
          </Card.Title>
          <Card.Body>
            Este reporte interactivo proporciona una visión detallada del estado y evolución del mercado inmobiliario, basado en datos reales de publicaciones. Nuestro objetivo es ofrecer información clara y útil para propietarios, inquilinos e inversores, ayudándolos a tomar decisiones informadas. Si bien nos esforzamos por garantizar la precisión de los datos, es importante considerar que los valores reflejan tendencias de mercado y pueden variar según la disponibilidad y actualización de las publicaciones.
          </Card.Body>
        </Card>
      </Col>
      <Col md={3}>
        <Image
          roundedCircle
          fluid
          src={'https://png.pngtree.com/png-clipart/20230927/original/pngtree-3d-minimal-blue-house-real-estate-concept-3d-rendering-illustration-png-image_13144115.png'}
          alt="Image"
          width="70%"
        />
      </Col>
    </Row>
  );
}

export default HeaderComponent;