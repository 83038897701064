const PoweredByUade = () => {
  return (
    <div className="powered-by-uade">
      <a href="https://www.uade.edu.ar/" target="_blank" rel="noreferrer">
        <small>Powered by</small>
        <img src="images/uade_arg.png" alt="Uade" />
      </a>
    </div>
  );
};

export default PoweredByUade;
