import React from "react";
import { Form, Card } from "react-bootstrap";

const NeighborhoodSelector = ({ neighborhoods, selected, onChange }) => {
  return (
    <Card className="border-0 shadow-sm p-3 mb-3">
      <Card.Body>
        <Form.Group controlId="neighborhoodSelector">
          <Form.Label className="fw-bold text-primary">🏙️ Selecciona un barrio</Form.Label>
          <Form.Select value={selected} onChange={(e) => onChange(e.target.value)} className="form-control-lg">
            {neighborhoods.map((neighborhood, index) => (
              <option key={index} value={neighborhood}>
                {neighborhood}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Card.Body>
    </Card>
  );
};

export default NeighborhoodSelector;
