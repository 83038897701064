import { Col, Container, Row } from "react-bootstrap";
import ValorarByUade from "../components/ValorarByUade";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaX } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer>
      <div className="footer-overlay"></div>
      <div className="footer-content">
        <Container fluid>
          <Row>
            <Col xs={12} md={6}>
              <p className="text-start">
                <ValorarByUade /> <br />
                <small className="fw-light">
                  © 2021 Valorar. Todos los derechos reservados.
                </small>
              </p>
            </Col>
            <Col xs={12} md={6}>
              <p className="text-end">
                <FaInstagram className="social-icons" />{" "}
                <FaX className="social-icons" />{" "}
                <FaLinkedin className="social-icons" />
                <small className="d-block">
                  Valorar es una herramienta para calcular precios de
                  alquieleres, rápida y precisa, utilizando la inteligencia
                  artificial.
                </small>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};
export default Footer;
