import React from "react";
import { Pie } from "react-chartjs-2";
import { Row, Col, Card } from "react-bootstrap";
import "chart.js/auto";

const PropertyDistribution = ({ data, neighborhood }) => {
  // Filtrar por barrio si no es "Todos"
  const filteredData =
    neighborhood === "Todos"
      ? data
      : data.filter((item) => item.normalized_neighborhood === neighborhood);

  // Función para categorizar ambientes en "1", "2", "3", "4 o más"
  const categorizeRooms = (rooms) => (rooms >= 4 ? "4 o más" : rooms.toString());

  // Función para categorizar metros cuadrados
  const categorizeArea = (area) => {
    if (area <= 30) return "0-30";
    if (area <= 60) return "30-60";
    if (area <= 100) return "60-100";
    return ">100";
  };

  // Contar cantidad de propiedades por categoría de ambientes
  const roomDistribution = filteredData.reduce((acc, { rooms }) => {
    const category = categorizeRooms(rooms);
    acc[category] = (acc[category] || 0) + 1;
    return acc;
  }, {});

  // Contar cantidad de propiedades por categoría de metros cuadrados
  const areaDistribution = filteredData.reduce((acc, { total_area }) => {
    const category = categorizeArea(total_area);
    acc[category] = (acc[category] || 0) + 1;
    return acc;
  }, {});

  // Filtrar solo las propiedades de 30-60 m² y contar cuántas hay por cantidad de ambientes
  const properties30to60 = filteredData.filter(
    ({ total_area }) => total_area > 30 && total_area <= 60
  );

  const roomCountIn30to60 = properties30to60.reduce((acc, { rooms }) => {
    const category = categorizeRooms(rooms);
    acc[category] = (acc[category] || 0) + 1;
    return acc;
  }, {});

  // Datos para el gráfico de distribución por ambientes
  const roomChartData = {
    labels: Object.keys(roomDistribution),
    datasets: [
      {
        data: Object.values(roomDistribution),
        backgroundColor: ["#81B4F9", "#F29100", '#F6C75C', '#4A91F1'],
        hoverBackgroundColor: ["#81B4F9", "#F29100", '#F6C75C', '#4A91F1'],
      },
    ],
  };

  // Datos para el gráfico de distribución por metros cuadrados
  const areaChartData = {
    labels: Object.keys(areaDistribution),
    datasets: [
      {
        data: Object.values(areaDistribution),
        backgroundColor: ["#81B4F9", "#F29100", '#F6C75C', '#4A91F1'],
        hoverBackgroundColor: ["#81B4F9", "#F29100", '#F6C75C', '#4A91F1'],
      },
    ],
  };

  return (
    <Row className="mt-4">
      {/* Tarjeta de Insight */}
      <Col md={4}>
        <Card className="border-0 shadow">
          <Card.Title className="mt-3 text-center">Insight 📊</Card.Title>
          <Card.Body>
            <h6>Distribución de ambientes en 30-60 m²:</h6>
            <ul>
              <li><strong>1 ambiente:</strong> {roomCountIn30to60["1"] || 0} propiedades</li>
              <li><strong>2 ambientes:</strong> {roomCountIn30to60["2"] || 0} propiedades</li>
              <li><strong>3 ambientes:</strong> {roomCountIn30to60["3"] || 0} propiedades</li>
              <li><strong>4 o más:</strong> {roomCountIn30to60["4 o más"] || 0} propiedades</li>
            </ul>
            <p className="text-muted">
              Este análisis ayuda a entender qué tipos de propiedades predominan en el segmento de 30-60 m² dentro de {neighborhood}.
            </p>
          </Card.Body>
        </Card>
      </Col>

      {/* Gráfico de Pie - Distribución por ambientes */}
      <Col md={4}>
        <h5 className="text-center">Distribución por cantidad de ambientes</h5>
        <Pie data={roomChartData} />
      </Col>

      {/* Gráfico de Pie - Distribución por metros cuadrados */}
      <Col md={4}>
        <h5 className="text-center">Distribución por metros cuadrados</h5>
        <Pie data={areaChartData} />
      </Col>
    </Row>
  );
};

export default PropertyDistribution;