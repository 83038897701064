import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import PropertyForm from "../forms/PropertyForm";

function HomePage() {

  const navigate = useNavigate();

  return (
    <Container>
      <Row className="my-4 justify-content-center">
        <Col xs={12} className="text-center mb-3">
          <Button variant="primary" size="md" uppercase={false} onClick={() => navigate("/statistics")}>
            ¿Quieres saber más sobre el mercado inmobiliario? Haz click aquí
          </Button>
        </Col>
      </Row>
      <Row className="my-4 justify-content-center">
        <Col xs={8} className="my-4">
          <Card className="p-4 mx-auto shadow cover cover-line">
            <Card.Body>
              <Row>
                <Col xs={12}>
                  <h1 className="fw-light">Perfil Inmobiliario</h1>
                  <p>
                    Complete los campos de acuerdo a la propiedad que desea
                    valorar.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} md={12} className="p-4 pe-5">
                  <Row>
                    <Col xs={6}>
                      <PropertyForm />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default HomePage;
