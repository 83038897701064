import React from "react";
import { Bar } from "react-chartjs-2";
import { Row, Col } from "react-bootstrap";
import "chart.js/auto";

const RoomPriceCharts = ({ data, neighborhood }) => {
  // Filtrar por barrio si no es "Todos"
  const filteredData = neighborhood === "Todos" 
    ? data 
    : data.filter(item => item.normalized_neighborhood === neighborhood);

  // Función para categorizar ambientes en "1", "2", "3", "4 o más"
  const categorizeRooms = (rooms) => {
    if (rooms >= 4) return "4 o más";
    return rooms.toString();
  };

  // Agrupar por cantidad de ambientes
  const groupedData = filteredData.reduce((acc, { rooms, price, total_area }) => {
    const category = categorizeRooms(rooms);
    if (!acc[category]) {
      acc[category] = { totalPrice: 0, totalArea: 0, count: 0 };
    }
    acc[category].totalPrice += price;
    acc[category].totalArea += total_area;
    acc[category].count += 1;
    return acc;
  }, {});

  // Crear datasets para gráficos
  const labels = ["1", "2", "3", "4 o más"];
  const avgPrices = labels.map(room => groupedData[room] ? groupedData[room].totalPrice / groupedData[room].count : 0);
  const avgPricePerM2 = labels.map(room => groupedData[room] ? (groupedData[room].totalPrice / groupedData[room].totalArea) : 0);

  // Datos para el gráfico de **precio promedio**
  const priceChartData = {
    labels,
    datasets: [
      {
        label: "Precio promedio (ARS)",
        data: avgPrices,
        backgroundColor: "#81B4F9",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Datos para el gráfico de **precio por metro cuadrado**
  const pricePerM2ChartData = {
    labels,
    datasets: [
      {
        label: "Precio promedio por m² (ARS)",
        data: avgPricePerM2,
        backgroundColor: "#F6C75C",
        borderColor: "rgb(243, 193, 107)",
        borderWidth: 1,
      },
    ],
  };

  // Opciones de los gráficos
  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
    scales: {
      x: { title: { display: true, text: "Cantidad de Ambientes" } },
      y: { title: { display: true, text: "Precio (ARS)" }, beginAtZero: true },
    },
  };

  return (
    <Row className="mt-4">
      {/* Gráfico de Precio Promedio */}
      <Col md={6}>
        <h5 className="text-center">Precio promedio por cantidad de ambientes</h5>
        <Bar data={priceChartData} options={options} />
      </Col>

      {/* Gráfico de Precio por m² */}
      <Col md={6}>
        <h5 className="text-center">Precio por metro cuadrado por cantidad de ambientes</h5>
        <Bar data={pricePerM2ChartData} options={options} />
      </Col>
    </Row>
  );
};

export default RoomPriceCharts;
