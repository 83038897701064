import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootswatch/dist/materia/bootstrap.min.css";
import "./App.css";
import Layout from "./layout/Layout";
import HomePage from "./pages/HomePage";
import StatisticsPage from "./pages/StatisticsPage";

function App() {
  return (
    <Router>
      <Routes>
        {/* Rutas con el Layout */}
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
        </Route>
        <Route path="/statistics" element={<Layout />}>
          <Route index element={<StatisticsPage />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
