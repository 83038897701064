import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { Card, Col, Row } from "react-bootstrap";

const MedianPriceByM2 = ({ data }) => {
  // Definir los intervalos del histograma (bins de 5 m²)
  const minArea = 20;
  const maxArea = 90;
  const binSize = 3;
  const bins = Array.from({ length: (maxArea - minArea) / binSize + 1 }, (_, i) => minArea + i * binSize);
  const propertyCount = data.length;


  // Agrupar datos en bins
  const binStats = bins.map((binStart) => ({
    bin: `${binStart}-${binStart + binSize}`,
    totalPrice: 0,
    count: 0,
  }));

  data.forEach(({ total_area, price }) => {
    if (total_area >= minArea && total_area <= maxArea) {
      const binIndex = Math.floor((total_area - minArea) / binSize);
      binStats[binIndex].totalPrice += price;
      binStats[binIndex].count += 1;
    }
  });

  // Calcular precio promedio en cada bin
  const labels = binStats.map((bin) => bin.bin);
  const avgPrice = binStats.map((bin) => (bin.count > 0 ? bin.totalPrice / bin.count : 0));

  const chartData = {
    labels,
    datasets: [
      {
        label: "Precio promedio de alquiler de la propiedad (ARS)",
        data: avgPrice,
        backgroundColor: "rgba(54, 162, 235, 0.6)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: false },
      tooltip: { enabled: true },
    },
    scales: {
      y: { beginAtZero: true, title: { display: true, text: "Precio promedio de alquiler (ARS)" } },
      x: { title: { display: true, text: "Área total (m²)" } },
    },
  };

  return <Row className="mt-3 mb-3">
    <Col md={9}>
        <Bar data={chartData} options={options} />
    </Col>
    <Col md={3}>
      <Card className="border-0 shadow">
        <Card.Title className="mt-3 text-center">Insight 📊</Card.Title>
        <Card.Body>
          <h6>Propiedades disponibles en el mercado</h6>
          <p>
            Actualmente, se están utilizando <strong>{propertyCount}</strong> propiedades para generar estas estadísticas.
          </p>
          <p className="text-muted">
            Este dato se actualiza semanalmente y puede variar según la disponibilidad de las publicaciones.
          </p>
        </Card.Body>
      </Card>
    </Col>
  </Row>
};

export default MedianPriceByM2;
