import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

const MonthlyPriceEvolution = ({ data, neighborhood }) => {
  console.log(data)
  console.log(neighborhood)
  // Filtrar por barrio si no es "Todos"
  const filteredData = neighborhood === "Todos" 
    ? data 
    : data.filter(item => item.Barrio === neighborhood);

  // Agrupar por mes y calcular el precio promedio
  const groupedData = filteredData.reduce((acc, { Mes, Medianaaprecioscorrientes }) => {
    if (!acc[Mes]) {
      acc[Mes] = { totalPrice: 0, count: 0 };
    }
    acc[Mes].totalPrice += Medianaaprecioscorrientes;
    acc[Mes].count += 1;
    return acc;
  }, {});

  // Crear arrays de labels y valores promedio
  const labels = Object.keys(groupedData).sort(); // Ordenar por fecha
  const avgPrices = labels.map(mes => groupedData[mes].totalPrice / groupedData[mes].count);

  // Cálculo de variación porcentual
  const lastMonth = labels[labels.length - 1]; // Último mes registrado
  const lastYear = labels.find(mes => mes.startsWith((parseInt(lastMonth.split("-")[0]) - 1).toString()));

  const lastPrice = avgPrices[avgPrices.length - 1]; // Último precio
  const lastMonthPrice = avgPrices[avgPrices.length - 2] || null; // Precio hace un mes
  const lastYearPrice = lastYear ? avgPrices[labels.indexOf(lastYear)] : null; // Precio hace un año

  const monthChange = lastMonthPrice ? ((lastPrice - lastMonthPrice) / lastMonthPrice) * 100 : null;
  const yearChange = lastYearPrice ? ((lastPrice - lastYearPrice) / lastYearPrice) * 100 : null;

  // Configuración del gráfico
  const chartData = {
    labels,
    datasets: [
      {
        label: "Precio promedio de propiedades (ARS)",
        data: avgPrices,
        borderColor: "#F29100",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderWidth: 2,
        tension: 0.3, // Suaviza la línea
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: { display: true },
      tooltip: { enabled: true },
    },
    scales: {
      x: { title: { display: true, text: "Mes" } },
      y: { title: { display: true, text: "Precio Promedio (ARS)" }, beginAtZero: false },
    },
  };

  return (
    <Row className="mt-3 mb-3">
      {/* Card de Insights */}
      <Col md={3}>
        <Card className="border-0 shadow">
          <Card.Title className="mt-3 text-center">Insight 📊</Card.Title>
          <Card.Body>
            <h6>Variación porcentual en el precio promedio</h6>
            <p>Se identifican las siguientes variaciones porcentuales en el precio de alquiler de las propiedades para el barrio {neighborhood}</p>
            <p><strong>Variación Mensual: </strong>{monthChange !== null ? `${monthChange.toFixed(2)}%` : "N/A"}</p>
            <p><strong>Variación Anual: </strong>{yearChange !== null ? `${yearChange.toFixed(2)}%` : "N/A"}</p>
          </Card.Body>
        </Card>
      </Col>

      {/* Gráfico de Evolución */}
      <Col md={9}>
        <Line data={chartData} options={options} />
      </Col>
    </Row>
  );
};

export default MonthlyPriceEvolution;
