export const historic_data = [{X: 1, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 9500.0, Medianaapreciosconstantes: 9500.0, Medianaporm2aprecioscorrientes: 217.948717948718, Medianaporm2apreciosconstantes: 217.948717948718},
 {X: 196, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 8500.0, Medianaapreciosconstantes: 8500.0, Medianaporm2aprecioscorrientes: 200.0, Medianaporm2apreciosconstantes: 200.0},
 {X: 669, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 14950.0, Medianaapreciosconstantes: 14950.0, Medianaporm2aprecioscorrientes: 243.212530712531, Medianaporm2apreciosconstantes: 243.212530712531},
 {X: 1084, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 9500.0, Medianaapreciosconstantes: 9500.0, Medianaporm2aprecioscorrientes: 217.948717948718, Medianaporm2apreciosconstantes: 217.948717948718},
 {X: 1464, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 12650.0, Medianaapreciosconstantes: 12650.0, Medianaporm2aprecioscorrientes: 230.555555555556, Medianaporm2apreciosconstantes: 230.555555555556},
 {X: 1545, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 9000.0, Medianaapreciosconstantes: 9000.0, Medianaporm2aprecioscorrientes: 215.250965250965, Medianaporm2apreciosconstantes: 215.250965250965},
 {X: 1612, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 8200.0, Medianaapreciosconstantes: 8200.0, Medianaporm2aprecioscorrientes: 187.5, Medianaporm2apreciosconstantes: 187.5},
 {X: 1877, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 9850.0, Medianaapreciosconstantes: 9850.0, Medianaporm2aprecioscorrientes: 211.323763955343, Medianaporm2apreciosconstantes: 211.323763955343},
 {X: 2111, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 13500.0, Medianaapreciosconstantes: 13500.0, Medianaporm2aprecioscorrientes: 252.631578947368, Medianaporm2apreciosconstantes: 252.631578947368},
 {X: 2471, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 16000.0, Medianaporm2aprecioscorrientes: 276.190476190476, Medianaporm2apreciosconstantes: 276.190476190476},
 {X: 3110, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 38000.0, Medianaapreciosconstantes: 38000.0, Medianaporm2aprecioscorrientes: 401.344696969697, Medianaporm2apreciosconstantes: 401.344696969697},
 {X: 3481, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 16000.0, Medianaporm2aprecioscorrientes: 244.017094017094, Medianaporm2apreciosconstantes: 244.017094017094},
 {X: 3956, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 12000.0, Medianaporm2aprecioscorrientes: 206.493506493506, Medianaporm2apreciosconstantes: 206.493506493506},
 {X: 4178, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 8500.0, Medianaapreciosconstantes: 8500.0, Medianaporm2aprecioscorrientes: 211.111111111111, Medianaporm2apreciosconstantes: 211.111111111111},
 {X: 4417, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 10650.0, Medianaapreciosconstantes: 10650.0, Medianaporm2aprecioscorrientes: 220.833333333333, Medianaporm2apreciosconstantes: 220.833333333333},
 {X: 4632, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 9500.0, Medianaapreciosconstantes: 9500.0, Medianaporm2aprecioscorrientes: 214.285714285714, Medianaporm2apreciosconstantes: 214.285714285714},
 {X: 4982, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 8500.0, Medianaapreciosconstantes: 8500.0, Medianaporm2aprecioscorrientes: 193.023255813953, Medianaporm2apreciosconstantes: 193.023255813953},
 {X: 5038, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2018-01", Medianaaprecioscorrientes: 9500.0, Medianaapreciosconstantes: 9500.0, Medianaporm2aprecioscorrientes: 227.272727272727, Medianaporm2apreciosconstantes: 227.272727272727},
 {X: 48, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 9765.625, Medianaporm2aprecioscorrientes: 223.911070780399, Medianaporm2apreciosconstantes: 218.663155058983},
 {X: 201, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 8400.0, Medianaapreciosconstantes: 8203.125, Medianaporm2aprecioscorrientes: 203.125, Medianaporm2apreciosconstantes: 198.3642578125},
 {X: 648, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 14648.4375, Medianaporm2aprecioscorrientes: 259.259259259259, Medianaporm2apreciosconstantes: 253.18287037037},
 {X: 1102, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 9500.0, Medianaapreciosconstantes: 9277.34375, Medianaporm2aprecioscorrientes: 225.352112676056, Medianaporm2apreciosconstantes: 220.070422535211},
 {X: 1458, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 11718.75, Medianaporm2aprecioscorrientes: 243.843843843844, Medianaporm2apreciosconstantes: 238.128753753754},
 {X: 1552, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 9800.0, Medianaapreciosconstantes: 9570.3125, Medianaporm2aprecioscorrientes: 200.0, Medianaporm2apreciosconstantes: 195.3125},
 {X: 1646, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 8500.0, Medianaapreciosconstantes: 8300.78125, Medianaporm2aprecioscorrientes: 194.202898550725, Medianaporm2apreciosconstantes: 189.651268115942},
 {X: 1908, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 9765.625, Medianaporm2aprecioscorrientes: 210.526315789474, Medianaporm2apreciosconstantes: 205.592105263158},
 {X: 2157, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 14500.0, Medianaapreciosconstantes: 14160.15625, Medianaporm2aprecioscorrientes: 258.844023584063, Medianaporm2apreciosconstantes: 252.777366781312},
 {X: 2452, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 15500.0, Medianaapreciosconstantes: 15136.71875, Medianaporm2aprecioscorrientes: 280.701754385965, Medianaporm2apreciosconstantes: 274.122807017544},
 {X: 3075, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 36000.0, Medianaapreciosconstantes: 35156.25, Medianaporm2aprecioscorrientes: 413.585869313354, Medianaporm2apreciosconstantes: 403.892450501322},
 {X: 3473, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 15500.0, Medianaapreciosconstantes: 15136.71875, Medianaporm2aprecioscorrientes: 255.31914893617, Medianaporm2apreciosconstantes: 249.335106382978},
 {X: 3933, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 12450.0, Medianaapreciosconstantes: 12158.203125, Medianaporm2aprecioscorrientes: 236.993243243243, Medianaporm2apreciosconstantes: 231.43871410473},
 {X: 4170, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 9750.0, Medianaapreciosconstantes: 9521.484375, Medianaporm2aprecioscorrientes: 211.111111111111, Medianaporm2apreciosconstantes: 206.163194444444},
 {X: 4433, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 11718.75, Medianaporm2aprecioscorrientes: 254.391371340524, Medianaporm2apreciosconstantes: 248.42907357473},
 {X: 4600, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 9765.625, Medianaporm2aprecioscorrientes: 225.806451612903, Medianaporm2apreciosconstantes: 220.514112903226},
 {X: 4948, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 8500.0, Medianaapreciosconstantes: 8300.78125, Medianaporm2aprecioscorrientes: 201.754385964912, Medianaporm2apreciosconstantes: 197.025767543859},
 {X: 5058, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2018-02", Medianaaprecioscorrientes: 9500.0, Medianaapreciosconstantes: 9277.34375, Medianaporm2aprecioscorrientes: 220.779220779221, Medianaporm2apreciosconstantes: 215.604707792208},
 {X: 77, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 10500.0, Medianaapreciosconstantes: 10023.3687683284, Medianaporm2aprecioscorrientes: 226.201372997712, Medianaporm2apreciosconstantes: 215.933312138884},
 {X: 235, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 8500.0, Medianaapreciosconstantes: 8114.15566959922, Medianaporm2aprecioscorrientes: 208.333333333333, Medianaporm2apreciosconstantes: 198.876364450961},
 {X: 666, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 15500.0, Medianaapreciosconstantes: 14796.4015151515, Medianaporm2aprecioscorrientes: 266.666666666667, Medianaporm2apreciosconstantes: 254.561746497231},
 {X: 1061, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 9546.06549364614, Medianaporm2aprecioscorrientes: 225.352112676056, Medianaporm2apreciosconstantes: 215.122602673715},
 {X: 1452, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 11932.5818670577, Medianaporm2aprecioscorrientes: 236.363636363636, Medianaporm2apreciosconstantes: 225.634275304363},
 {X: 1524, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 9546.06549364614, Medianaporm2aprecioscorrientes: 209.22632618939, Medianaporm2apreciosconstantes: 199.728821279889},
 {X: 1626, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 9300.0, Medianaapreciosconstantes: 8877.84090909091, Medianaporm2aprecioscorrientes: 200.0, Medianaporm2apreciosconstantes: 190.921309872923},
 {X: 1862, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 9900.0, Medianaapreciosconstantes: 9450.60483870968, Medianaporm2aprecioscorrientes: 224.37300501596, Medianaporm2apreciosconstantes: 214.187940088855},
 {X: 2122, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 14319.0982404692, Medianaporm2aprecioscorrientes: 273.294226613152, Medianaporm2apreciosconstantes: 260.888458628452},
 {X: 2482, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 15273.7047898338, Medianaporm2aprecioscorrientes: 288.888888888889, Medianaporm2apreciosconstantes: 275.775225372},
 {X: 3106, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 39475.0, Medianaapreciosconstantes: 37683.0935361681, Medianaporm2aprecioscorrientes: 416.402032391235, Medianaporm2apreciosconstantes: 397.500107289409},
 {X: 3477, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 16250.0, Medianaapreciosconstantes: 15512.356427175, Medianaporm2aprecioscorrientes: 263.363095238095, Medianaporm2apreciosconstantes: 251.408135575222},
 {X: 3925, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 11932.5818670577, Medianaporm2aprecioscorrientes: 250.0, Medianaporm2apreciosconstantes: 238.651637341153},
 {X: 4176, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 9850.0, Medianaapreciosconstantes: 9402.87451124145, Medianaporm2aprecioscorrientes: 230.285714285714, Medianaporm2apreciosconstantes: 219.832251082251},
 {X: 4476, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 11500.0, Medianaapreciosconstantes: 10977.9753176931, Medianaporm2aprecioscorrientes: 255.555555555556, Medianaporm2apreciosconstantes: 243.955007059846},
 {X: 4604, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 9546.06549364614, Medianaporm2aprecioscorrientes: 232.876712328767, Medianaporm2apreciosconstantes: 222.30563478354},
 {X: 4980, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 8700.0, Medianaapreciosconstantes: 8305.07697947214, Medianaporm2aprecioscorrientes: 212.158167036216, Medianaporm2apreciosconstantes: 202.527575753964},
 {X: 5086, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2018-03", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 9546.06549364614, Medianaporm2aprecioscorrientes: 240.0, Medianaporm2apreciosconstantes: 229.105571847507},
 {X: 60, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 10200.0, Medianaapreciosconstantes: 9480.99980868458, Medianaporm2aprecioscorrientes: 240.0, Medianaporm2apreciosconstantes: 223.082348439637},
 {X: 251, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 9000.0, Medianaapreciosconstantes: 8365.58806648639, Medianaporm2aprecioscorrientes: 216.515151515152, Medianaporm2apreciosconstantes: 201.25295196985},
 {X: 658, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 15500.0, Medianaapreciosconstantes: 14407.4016700599, Medianaporm2aprecioscorrientes: 267.774725274725, Medianaporm2apreciosconstantes: 248.899227362768},
 {X: 1090, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 10050.0, Medianaapreciosconstantes: 9341.5733409098, Medianaporm2aprecioscorrientes: 233.333333333333, Medianaporm2apreciosconstantes: 216.885616538536},
 {X: 1459, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 11154.1174219819, Medianaporm2aprecioscorrientes: 240.0, Medianaporm2apreciosconstantes: 223.082348439637},
 {X: 1562, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 10500.0, Medianaapreciosconstantes: 9759.85274423412, Medianaporm2aprecioscorrientes: 221.052631578947, Medianaporm2apreciosconstantes: 205.470584089139},
 {X: 1619, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 9500.0, Medianaapreciosconstantes: 8830.34295906897, Medianaporm2aprecioscorrientes: 211.95652173913, Medianaporm2apreciosconstantes: 197.015660986092},
 {X: 1857, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 9250.0, Medianaapreciosconstantes: 8597.96551277768, Medianaporm2aprecioscorrientes: 233.809523809524, Medianaporm2apreciosconstantes: 217.328240245758},
 {X: 2129, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 13942.6467774773, Medianaporm2aprecioscorrientes: 281.25, Medianaporm2apreciosconstantes: 261.4246270777},
 {X: 2476, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 16500.0, Medianaapreciosconstantes: 15336.9114552251, Medianaporm2aprecioscorrientes: 291.666666666667, Medianaporm2apreciosconstantes: 271.10702067317},
 {X: 3112, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 38516.0, Medianaapreciosconstantes: 35800.9988854211, Medianaporm2aprecioscorrientes: 419.165448570167, Medianaporm2apreciosconstantes: 389.618386049112},
 {X: 3453, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 14872.1565626425, Medianaporm2aprecioscorrientes: 268.817204301075, Medianaporm2apreciosconstantes: 249.86822181859},
 {X: 3917, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 12083.627207147, Medianaporm2aprecioscorrientes: 254.87012987013, Medianaporm2apreciosconstantes: 236.9042796606},
 {X: 4197, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 9500.0, Medianaapreciosconstantes: 8830.34295906897, Medianaporm2aprecioscorrientes: 237.28813559322, Medianaporm2apreciosconstantes: 220.561643937494},
 {X: 4447, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 10350.0, Medianaapreciosconstantes: 9620.42627645935, Medianaporm2aprecioscorrientes: 258.201058201058, Medianaporm2apreciosconstantes: 240.000410137881},
 {X: 4637, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 10224.6076368167, Medianaporm2aprecioscorrientes: 253.333333333333, Medianaporm2apreciosconstantes: 235.475812241839},
 {X: 4946, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 8700.0, Medianaapreciosconstantes: 8086.73513093685, Medianaporm2aprecioscorrientes: 215.384615384615, Medianaporm2apreciosconstantes: 200.202107574033},
 {X: 5055, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2018-04", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 9295.09785165155, Medianaporm2aprecioscorrientes: 243.690349946978, Medianaporm2apreciosconstantes: 226.512564826037},
 {X: 85, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 10500.0, Medianaapreciosconstantes: 9559.11140459545, Medianaporm2aprecioscorrientes: 240.565664556962, Medianaporm2apreciosconstantes: 219.008951201956},
 {X: 254, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 9500.0, Medianaapreciosconstantes: 8648.71984225303, Medianaporm2aprecioscorrientes: 216.666666666667, Medianaporm2apreciosconstantes: 197.251505174192},
 {X: 611, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 16500.0, Medianaapreciosconstantes: 15021.46077865, Medianaporm2aprecioscorrientes: 273.684210526316, Medianaporm2apreciosconstantes: 249.159796009506},
 {X: 1069, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 10850.0, Medianaapreciosconstantes: 9877.7484514153, Medianaporm2aprecioscorrientes: 238.095238095238, Medianaporm2apreciosconstantes: 216.759895795815},
 {X: 1438, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 11379.8945292803, Medianaporm2aprecioscorrientes: 260.526315789474, Medianaporm2apreciosconstantes: 237.180959662895},
 {X: 1523, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 9103.91562342424, Medianaporm2aprecioscorrientes: 229.166666666667, Medianaporm2apreciosconstantes: 208.631399703473},
 {X: 1625, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 9500.0, Medianaapreciosconstantes: 8648.71984225303, Medianaporm2aprecioscorrientes: 216.666666666667, Medianaporm2apreciosconstantes: 197.251505174192},
 {X: 1865, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 9250.0, Medianaapreciosconstantes: 8421.12195166742, Medianaporm2aprecioscorrientes: 232.380952380952, Medianaporm2apreciosconstantes: 211.557658296715},
 {X: 2134, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 13655.8734351364, Medianaporm2aprecioscorrientes: 273.394495412844, Medianaporm2apreciosconstantes: 248.896041814718},
 {X: 2468, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 15476.6565598212, Medianaporm2aprecioscorrientes: 300.0, Medianaporm2apreciosconstantes: 273.117468702727},
 {X: 3091, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 40594.380952381, Medianaapreciosconstantes: 36956.7818975617, Medianaporm2aprecioscorrientes: 446.153846153846, Medianaporm2apreciosconstantes: 406.174697045081},
 {X: 3506, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 16387.0481221636, Medianaporm2aprecioscorrientes: 274.804523809524, Medianaporm2apreciosconstantes: 250.179719769719},
 {X: 3940, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 14900.0, Medianaapreciosconstantes: 13564.8342789021, Medianaporm2aprecioscorrientes: 263.414634146341, Medianaporm2apreciosconstantes: 239.810460324345},
 {X: 4218, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 9103.91562342424, Medianaporm2aprecioscorrientes: 237.619047619048, Medianaporm2apreciosconstantes: 216.326376004224},
 {X: 4454, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 9103.91562342424, Medianaporm2aprecioscorrientes: 245.0, Medianaporm2apreciosconstantes: 223.045932773894},
 {X: 4633, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 10924.6987481091, Medianaporm2aprecioscorrientes: 257.359307359307, Medianaporm2apreciosconstantes: 234.297741910204},
 {X: 4997, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 9000.0, Medianaapreciosconstantes: 8193.52406108182, Medianaporm2aprecioscorrientes: 217.541208791209, Medianaporm2apreciosconstantes: 198.047680945288},
 {X: 5088, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2018-05", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 10014.3071857667, Medianaporm2aprecioscorrientes: 260.13698630137, Medianaporm2apreciosconstantes: 236.826517381954},
 {X: 59, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 9656.99825004042, Medianaporm2aprecioscorrientes: 243.243243243243, Medianaporm2apreciosconstantes: 213.54541584856},
 {X: 241, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 8779.08931821857, Medianaporm2aprecioscorrientes: 226.676398138856, Medianaporm2apreciosconstantes: 199.001234559309},
 {X: 659, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 14046.5429091497, Medianaporm2aprecioscorrientes: 285.714285714286, Medianaporm2apreciosconstantes: 250.831123377674},
 {X: 1104, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 9656.99825004042, Medianaporm2aprecioscorrientes: 241.935483870968, Medianaporm2apreciosconstantes: 212.397322214966},
 {X: 1473, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 12290.725045506, Medianaporm2aprecioscorrientes: 272.727272727273, Medianaporm2apreciosconstantes: 239.429708678688},
 {X: 1507, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 8779.08931821857, Medianaporm2aprecioscorrientes: 228.416149068323, Medianaporm2apreciosconstantes: 200.528577439433},
 {X: 1605, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 9600.0, Medianaapreciosconstantes: 8427.92574548982, Medianaporm2aprecioscorrientes: 218.349358974359, Medianaporm2apreciosconstantes: 191.690852501167},
 {X: 1849, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 10750.0, Medianaapreciosconstantes: 9437.52101708496, Medianaporm2aprecioscorrientes: 239.439655172414, Medianaporm2apreciosconstantes: 210.206211908208},
 {X: 2128, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 13168.6339773278, Medianaporm2aprecioscorrientes: 288.659793814433, Medianaporm2apreciosconstantes: 253.417011247546},
 {X: 2461, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 14924.4518409716, Medianaporm2aprecioscorrientes: 312.5, Medianaporm2apreciosconstantes: 274.34654119433},
 {X: 3078, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 48000.0, Medianaapreciosconstantes: 42139.6287274491, Medianaporm2aprecioscorrientes: 500.0, Medianaporm2apreciosconstantes: 438.954465910928},
 {X: 3452, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 19500.0, Medianaapreciosconstantes: 17119.2241705262, Medianaporm2aprecioscorrientes: 287.5, Medianaporm2apreciosconstantes: 252.398817898784},
 {X: 3919, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 15802.3607727934, Medianaporm2aprecioscorrientes: 260.162601626016, Medianaporm2apreciosconstantes: 228.399071693491},
 {X: 4199, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 8779.08931821857, Medianaporm2aprecioscorrientes: 250.0, Medianaporm2apreciosconstantes: 219.477232955464},
 {X: 4448, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 12250.0, Medianaapreciosconstantes: 10754.3844148177, Medianaporm2aprecioscorrientes: 265.686274509804, Medianaporm2apreciosconstantes: 233.248353454631},
 {X: 4625, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 10534.9071818623, Medianaporm2aprecioscorrientes: 270.714285714286, Medianaporm2apreciosconstantes: 237.662489400346},
 {X: 4988, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 9000.0, Medianaapreciosconstantes: 7901.18038639671, Medianaporm2aprecioscorrientes: 226.66908563135, Medianaporm2apreciosconstantes: 198.994814843655},
 {X: 5083, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2018-06", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 10534.9071818623, Medianaporm2aprecioscorrientes: 279.449472096531, Medianaporm2apreciosconstantes: 245.331187546447},
 {X: 79, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 10218.1446931541, Medianaporm2aprecioscorrientes: 258.064516129032, Medianaporm2apreciosconstantes: 219.745047164603},
 {X: 258, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 8515.12057762838, Medianaporm2aprecioscorrientes: 228.571428571429, Medianaporm2apreciosconstantes: 194.631327488649},
 {X: 679, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 16500.0, Medianaapreciosconstantes: 14049.9489530868, Medianaporm2aprecioscorrientes: 290.191387559809, Medianaporm2apreciosconstantes: 247.101465566106},
 {X: 1096, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 9366.63263539122, Medianaporm2aprecioscorrientes: 250.0, Medianaporm2apreciosconstantes: 212.87801444071},
 {X: 1465, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 13500.0, Medianaapreciosconstantes: 11495.4127797983, Medianaporm2aprecioscorrientes: 275.0, Medianaporm2apreciosconstantes: 234.16581588478},
 {X: 1546, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 10000.0, Medianaapreciosconstantes: 8515.12057762838, Medianaporm2aprecioscorrientes: 237.5, Medianaporm2apreciosconstantes: 202.234113718674},
 {X: 1640, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 9366.63263539122, Medianaporm2aprecioscorrientes: 219.58904109589, Medianaporm2apreciosconstantes: 186.98271624573},
 {X: 1888, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 9366.63263539122, Medianaporm2aprecioscorrientes: 240.0, Medianaporm2apreciosconstantes: 204.362893863081},
 {X: 2117, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 11921.1688086797, Medianaporm2aprecioscorrientes: 291.838235294118, Medianaporm2apreciosconstantes: 248.50377626917},
 {X: 2483, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 14475.7049819682, Medianaporm2aprecioscorrientes: 318.181818181818, Medianaporm2apreciosconstantes: 270.935654742721},
 {X: 3129, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 55467.4904761905, Medianaapreciosconstantes: 47231.2369543216, Medianaporm2aprecioscorrientes: 544.48158131177, Medianaporm2apreciosconstantes: 463.632631716749},
 {X: 3446, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 19500.0, Medianaapreciosconstantes: 16604.4851263753, Medianaporm2aprecioscorrientes: 300.0, Medianaporm2apreciosconstantes: 255.453617328851},
 {X: 3895, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 20500.0, Medianaapreciosconstantes: 17455.9971841382, Medianaporm2aprecioscorrientes: 283.482142857143, Medianaporm2apreciosconstantes: 241.388462803305},
 {X: 4189, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 10250.0, Medianaapreciosconstantes: 8727.99859206909, Medianaporm2aprecioscorrientes: 260.13698630137, Medianaporm2apreciosconstantes: 221.509780505703},
 {X: 4455, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 10450.0, Medianaapreciosconstantes: 8898.30100362166, Medianaporm2aprecioscorrientes: 265.686274509804, Medianaporm2apreciosconstantes: 226.235066327185},
 {X: 4653, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 9366.63263539122, Medianaporm2aprecioscorrientes: 278.125, Medianaporm2apreciosconstantes: 236.826791065289},
 {X: 4995, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 9250.0, Medianaapreciosconstantes: 7876.48653430625, Medianaporm2aprecioscorrientes: 231.822971548999, Medianaporm2apreciosconstantes: 197.400055540384},
 {X: 5062, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2018-07", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 10218.1446931541, Medianaporm2aprecioscorrientes: 278.688524590164, Medianaporm2apreciosconstantes: 237.30663904866},
 {X: 57, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 9834.59547103159, Medianaporm2aprecioscorrientes: 258.722109533469, Medianaporm2apreciosconstantes: 212.035607222799},
 {X: 257, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 9015.04584844563, Medianaporm2aprecioscorrientes: 230.500894454383, Medianaporm2apreciosconstantes: 188.906921055817},
 {X: 670, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 13932.3435839614, Medianaporm2aprecioscorrientes: 297.67316017316, Medianaporm2apreciosconstantes: 243.957926073885},
 {X: 1136, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 9834.59547103159, Medianaporm2aprecioscorrientes: 250.0, Medianaporm2apreciosconstantes: 204.887405646492},
 {X: 1487, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 13500.0, Medianaapreciosconstantes: 11063.9199049105, Medianaporm2aprecioscorrientes: 276.744186046512, Medianaporm2apreciosconstantes: 226.805593227279},
 {X: 1585, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 9800.0, Medianaapreciosconstantes: 8031.58630134247, Medianaporm2aprecioscorrientes: 250.0, Medianaporm2apreciosconstantes: 204.887405646492},
 {X: 1665, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 9015.04584844563, Medianaporm2aprecioscorrientes: 229.150579150579, Medianaporm2apreciosconstantes: 187.800270658213},
 {X: 1867, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 10654.1450936176, Medianaporm2aprecioscorrientes: 240.0, Medianaporm2apreciosconstantes: 196.691909420632},
 {X: 2143, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 12293.2443387895, Medianaporm2aprecioscorrientes: 300.0, Medianaporm2apreciosconstantes: 245.86488677579},
 {X: 2477, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 14751.8932065474, Medianaporm2aprecioscorrientes: 337.5, Medianaporm2apreciosconstantes: 276.597997622764},
 {X: 3080, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 69000.0, Medianaapreciosconstantes: 56548.9239584317, Medianaporm2aprecioscorrientes: 648.227272727273, Medianaporm2apreciosconstantes: 531.254416713567},
 {X: 3458, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 16390.9924517193, Medianaporm2aprecioscorrientes: 293.911970382559, Medianaporm2apreciosconstantes: 240.875444400524},
 {X: 3942, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 18500.0, Medianaapreciosconstantes: 15161.6680178404, Medianaporm2aprecioscorrientes: 280.093657499356, Medianaporm2apreciosconstantes: 229.55065129232},
 {X: 4224, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 9015.04584844563, Medianaporm2aprecioscorrientes: 250.0, Medianaporm2apreciosconstantes: 204.887405646492},
 {X: 4460, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 10500.0, Medianaapreciosconstantes: 8605.27103715264, Medianaporm2aprecioscorrientes: 283.482142857143, Medianaporm2apreciosconstantes: 232.327683188432},
 {X: 4598, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 10244.3702823246, Medianaporm2aprecioscorrientes: 278.846153846154, Medianaporm2apreciosconstantes: 228.528260144164},
 {X: 4973, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 9500.0, Medianaapreciosconstantes: 7785.72141456668, Medianaporm2aprecioscorrientes: 237.602459016393, Medianaporm2apreciosconstantes: 194.727005612382},
 {X: 5071, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2018-08", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 9834.59547103159, Medianaporm2aprecioscorrientes: 276.388888888889, Medianaporm2apreciosconstantes: 226.514409575844},
 {X: 73, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 9619.12702554584, Medianaporm2aprecioscorrientes: 272.727272727273, Medianaporm2apreciosconstantes: 209.871862375546},
 {X: 204, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 9234.36194452401, Medianaporm2aprecioscorrientes: 242.857142857143, Medianaporm2apreciosconstantes: 186.885896496319},
 {X: 685, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 13851.542916786, Medianaporm2aprecioscorrientes: 307.692307692308, Medianaporm2apreciosconstantes: 236.778511398052},
 {X: 1110, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 9234.36194452401, Medianaporm2aprecioscorrientes: 257.142857142857, Medianaporm2apreciosconstantes: 197.879184525514},
 {X: 1453, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 10773.4222686113, Medianaporm2aprecioscorrientes: 285.714285714286, Medianaporm2apreciosconstantes: 219.865760583905},
 {X: 1547, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 11100.0, Medianaapreciosconstantes: 8541.78479868471, Medianaporm2aprecioscorrientes: 257.575757575758, Medianaporm2apreciosconstantes: 198.212314465793},
 {X: 1641, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 8464.83178248034, Medianaporm2aprecioscorrientes: 233.333333333333, Medianaporm2apreciosconstantes: 179.557037810189},
 {X: 1902, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 12250.0, Medianaapreciosconstantes: 9426.74448503493, Medianaporm2aprecioscorrientes: 262.224108658744, Medianaporm2apreciosconstantes: 201.78936082792},
 {X: 2177, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 12312.4825926987, Medianaporm2aprecioscorrientes: 309.498746867168, Medianaporm2apreciosconstantes: 238.168620829004},
 {X: 2499, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 19000.0, Medianaapreciosconstantes: 14621.0730788297, Medianaporm2aprecioscorrientes: 348.837209302326, Medianaporm2apreciosconstantes: 268.44075420128},
 {X: 3126, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 82511.0, Medianaapreciosconstantes: 63494.703200385, Medianaporm2aprecioscorrientes: 724.734299516908, Medianaporm2apreciosconstantes: 557.70490294585},
 {X: 3502, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 19000.0, Medianaapreciosconstantes: 14621.0730788297, Medianaporm2aprecioscorrientes: 309.278350515464, Medianaporm2apreciosconstantes: 237.999019188763},
 {X: 3902, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 15500.0, Medianaapreciosconstantes: 11927.7175116768, Medianaporm2aprecioscorrientes: 277.777777777778, Medianaporm2apreciosconstantes: 213.758378345463},
 {X: 4202, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 13250.0, Medianaapreciosconstantes: 10196.2746470786, Medianaporm2aprecioscorrientes: 260.869565217391, Medianaporm2apreciosconstantes: 200.746998794},
 {X: 4427, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 10003.8921065677, Medianaporm2aprecioscorrientes: 321.428571428571, Medianaporm2apreciosconstantes: 247.348980656893},
 {X: 4660, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 9619.12702554584, Medianaporm2aprecioscorrientes: 285.714285714286, Medianaporm2apreciosconstantes: 219.865760583905},
 {X: 4977, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 10250.0, Medianaapreciosconstantes: 7887.68416094759, Medianaporm2aprecioscorrientes: 249.285714285714, Medianaporm2apreciosconstantes: 191.832876109457},
 {X: 5061, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2018-09", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 10003.8921065677, Medianaporm2aprecioscorrientes: 286.842105263158, Medianaporm2apreciosconstantes: 220.733651744105},
 {X: 43, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 9491.35873517499, Medianaporm2aprecioscorrientes: 279.06976744186, Medianaporm2apreciosconstantes: 203.750098071735},
 {X: 247, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 9126.3064761298, Medianaporm2aprecioscorrientes: 255.31914893617, Medianaporm2apreciosconstantes: 186.409664193289},
 {X: 667, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 18110.0, Medianaapreciosconstantes: 13222.1928226169, Medianaporm2aprecioscorrientes: 318.840579710145, Medianaporm2apreciosconstantes: 232.786947796934},
 {X: 1128, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 9491.35873517499, Medianaporm2aprecioscorrientes: 270.63246351172, Medianaporm2apreciosconstantes: 197.589984351838},
 {X: 1436, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 10221.4632532654, Medianaporm2aprecioscorrientes: 288.888888888889, Medianaporm2apreciosconstantes: 210.919083003889},
 {X: 1526, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 11500.0, Medianaapreciosconstantes: 8396.20195803942, Medianaporm2aprecioscorrientes: 260.27397260274, Medianaporm2apreciosconstantes: 190.027203338593},
 {X: 1620, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 8031.14969899423, Medianaporm2aprecioscorrientes: 237.5, Medianaporm2apreciosconstantes: 173.399823046466},
 {X: 1872, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 8031.14969899423, Medianaporm2aprecioscorrientes: 259.592529711375, Medianaporm2apreciosconstantes: 189.529678804787},
 {X: 2115, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 16500.0, Medianaapreciosconstantes: 12046.7245484913, Medianaporm2aprecioscorrientes: 333.333333333333, Medianaporm2apreciosconstantes: 243.368172696794},
 {X: 2464, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 14602.0903618077, Medianaporm2aprecioscorrientes: 359.856115107914, Medianaporm2apreciosconstantes: 262.732575502741},
 {X: 3088, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 78820.0, Medianaapreciosconstantes: 57546.8381158841, Medianaporm2aprecioscorrientes: 690.551891290528, Medianaporm2apreciosconstantes: 504.175055807074},
 {X: 3466, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 18500.0, Medianaapreciosconstantes: 13506.9335846721, Medianaporm2aprecioscorrientes: 316.122584943371, Medianaporm2apreciosconstantes: 230.802527537566},
 {X: 3897, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 12411.7768075365, Medianaporm2aprecioscorrientes: 283.653846153846, Medianaporm2apreciosconstantes: 207.096954650638},
 {X: 4188, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 8761.25421708461, Medianaporm2aprecioscorrientes: 262.5, Medianaporm2apreciosconstantes: 191.652435998726},
 {X: 4430, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 9491.35873517499, Medianaporm2aprecioscorrientes: 328.409992069786, Medianaporm2apreciosconstantes: 239.773618996178},
 {X: 4640, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 8761.25421708461, Medianaporm2aprecioscorrientes: 286.796536796537, Medianaporm2apreciosconstantes: 209.391447287827},
 {X: 4960, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 8031.14969899423, Medianaporm2aprecioscorrientes: 254.195804195804, Medianaporm2apreciosconstantes: 185.589505122975},
 {X: 5091, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2018-10", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 9491.35873517499, Medianaporm2aprecioscorrientes: 301.369863013699, Medianaporm2apreciosconstantes: 220.031498602582},
 {X: 63, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 9197.05303894927, Medianaporm2aprecioscorrientes: 285.714285714286, Medianaporm2apreciosconstantes: 202.133033823061},
 {X: 245, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 9197.05303894927, Medianaporm2aprecioscorrientes: 265.30612244898, Medianaporm2apreciosconstantes: 187.694959978557},
 {X: 665, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 14149.3123676143, Medianaporm2aprecioscorrientes: 324.730824730825, Medianaporm2apreciosconstantes: 229.735893725472},
 {X: 1133, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 8843.32022975891, Medianaporm2aprecioscorrientes: 272.727272727273, Medianaporm2apreciosconstantes: 192.945168649286},
 {X: 1478, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 14500.0, Medianaapreciosconstantes: 10258.2514665203, Medianaporm2aprecioscorrientes: 296.703296703297, Medianaporm2apreciosconstantes: 209.907381277794},
 {X: 1534, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 8489.58742056856, Medianaporm2aprecioscorrientes: 254.545454545455, Medianaporm2apreciosconstantes: 180.082157406},
 {X: 1614, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 7782.12180218784, Medianaporm2aprecioscorrientes: 244.173441734417, Medianaporm2apreciosconstantes: 172.744314948786},
 {X: 1874, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 7782.12180218784, Medianaporm2aprecioscorrientes: 269.69696969697, Medianaporm2apreciosconstantes: 190.801333442071},
 {X: 2149, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 16500.0, Medianaapreciosconstantes: 11673.1827032818, Medianaporm2aprecioscorrientes: 335.570469798658, Medianaporm2apreciosconstantes: 237.404569926414},
 {X: 2485, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 21600.0, Medianaapreciosconstantes: 15281.2573570234, Medianaporm2aprecioscorrientes: 372.093023255814, Medianaporm2apreciosconstantes: 263.243020792823},
 {X: 3076, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 75964.8421052632, Medianaapreciosconstantes: 53742.5139951933, Medianaporm2aprecioscorrientes: 652.105440102069, Medianaporm2apreciosconstantes: 461.342178431237},
 {X: 3454, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 14149.3123676143, Medianaporm2aprecioscorrientes: 325.0, Medianaporm2apreciosconstantes: 229.926325973732},
 {X: 3903, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 12026.9155124721, Medianaporm2aprecioscorrientes: 292.892156862745, Medianaporm2apreciosconstantes: 207.211130873763},
 {X: 4152, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 8489.58742056856, Medianaporm2aprecioscorrientes: 285.714285714286, Medianaporm2apreciosconstantes: 202.133033823061},
 {X: 4407, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 10611.9842757107, Medianaporm2aprecioscorrientes: 344.827586206897, Medianaporm2apreciosconstantes: 243.953661510591},
 {X: 4599, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 12900.0, Medianaapreciosconstantes: 9126.3064771112, Medianaporm2aprecioscorrientes: 288.517707192406, Medianaporm2apreciosconstantes: 204.116358132661},
 {X: 4998, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 11000.0, Medianaapreciosconstantes: 7782.12180218784, Medianaporm2aprecioscorrientes: 250.0, Medianaporm2apreciosconstantes: 176.866404595178},
 {X: 5043, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2018-11", Medianaaprecioscorrientes: 13227.5, Medianaapreciosconstantes: 9358.00146713088, Medianaporm2aprecioscorrientes: 304.777777777778, Medianaporm2apreciosconstantes: 215.619799024255},
 {X: 15, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 8619.22050012173, Medianaporm2aprecioscorrientes: 288.444444444444, Medianaporm2apreciosconstantes: 198.893301496142},
 {X: 187, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 8963.9893201266, Medianaporm2aprecioscorrientes: 272.827092001849, Medianaporm2apreciosconstantes: 188.124549149675},
 {X: 631, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 19900.0, Medianaapreciosconstantes: 13721.7990361938, Medianaporm2aprecioscorrientes: 325.0, Medianaporm2apreciosconstantes: 224.099733003165},
 {X: 1103, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 8963.9893201266, Medianaporm2aprecioscorrientes: 285.714285714286, Medianaporm2apreciosconstantes: 197.010754288497},
 {X: 1502, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 10343.0646001461, Medianaporm2aprecioscorrientes: 303.571428571429, Medianaporm2apreciosconstantes: 209.323926431528},
 {X: 1577, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 8963.9893201266, Medianaporm2aprecioscorrientes: 259.259259259259, Medianaporm2apreciosconstantes: 178.769017780302},
 {X: 1657, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 11900.0, Medianaapreciosconstantes: 8205.49791611589, Medianaporm2aprecioscorrientes: 244.315245478036, Medianaporm2apreciosconstantes: 168.464557785325},
 {X: 1870, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 11500.0, Medianaapreciosconstantes: 7929.682860112, Medianaporm2aprecioscorrientes: 282.051282051282, Medianaporm2apreciosconstantes: 194.484975387362},
 {X: 2109, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 11722.1398801656, Medianaporm2aprecioscorrientes: 338.905011720058, Medianaporm2apreciosconstantes: 233.687761968922},
 {X: 2491, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 15169.8280802143, Medianaporm2aprecioscorrientes: 366.666666666667, Medianaporm2apreciosconstantes: 252.830468003571},
 {X: 3111, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 86940.0, Medianaapreciosconstantes: 59948.4024224467, Medianaporm2aprecioscorrientes: 684.566929133858, Medianaporm2apreciosconstantes: 472.034664743675},
 {X: 3479, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 13790.7528001948, Medianaporm2aprecioscorrientes: 324.324324324324, Medianaporm2apreciosconstantes: 223.633829192347},
 {X: 3907, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 19500.0, Medianaapreciosconstantes: 13445.9839801899, Medianaporm2aprecioscorrientes: 301.515151515152, Medianaporm2apreciosconstantes: 207.906046002937},
 {X: 4154, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 13750.0, Medianaapreciosconstantes: 9481.14255013391, Medianaporm2aprecioscorrientes: 304.563492063492, Medianaporm2apreciosconstantes: 210.007991550585},
 {X: 4478, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 10343.0646001461, Medianaporm2aprecioscorrientes: 326.238390092879, Medianaporm2apreciosconstantes: 224.95364958522},
 {X: 4605, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 13500.0, Medianaapreciosconstantes: 9308.75814013147, Medianaporm2aprecioscorrientes: 292.206410426919, Medianaporm2apreciosconstantes: 201.487318641495},
 {X: 4968, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 8274.45168011686, Medianaporm2aprecioscorrientes: 254.545454545455, Medianaporm2apreciosconstantes: 175.518672002479},
 {X: 5079, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2018-12", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 8963.9893201266, Medianaporm2aprecioscorrientes: 300.0, Medianaporm2apreciosconstantes: 206.861292002922},
 {X: 76, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 8376.30757729589, Medianaporm2aprecioscorrientes: 296.875, Medianaporm2apreciosconstantes: 198.937304960777},
 {X: 230, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 8376.30757729589, Medianaporm2aprecioscorrientes: 276.243093922652, Medianaporm2apreciosconstantes: 185.111769663998},
 {X: 651, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 13402.0921236734, Medianaporm2aprecioscorrientes: 333.333333333333, Medianaporm2apreciosconstantes: 223.368202061223},
 {X: 1122, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 8711.35988038772, Medianaporm2aprecioscorrientes: 293.054919908467, Medianaporm2apreciosconstantes: 196.377451695451},
 {X: 1466, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 10051.5690927551, Medianaporm2aprecioscorrientes: 314.96279966933, Medianaporm2apreciosconstantes: 211.058022834923},
 {X: 1536, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 13500.0, Medianaapreciosconstantes: 9046.41218347956, Medianaporm2aprecioscorrientes: 277.777777777778, Medianaporm2apreciosconstantes: 186.140168384353},
 {X: 1655, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 8041.25527420405, Medianaporm2aprecioscorrientes: 244.863731656184, Medianaporm2apreciosconstantes: 164.084314470131},
 {X: 1889, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 8376.30757729589, Medianaporm2aprecioscorrientes: 300.0, Medianaporm2apreciosconstantes: 201.031381855101},
 {X: 2151, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 12061.8829113061, Medianaporm2aprecioscorrientes: 339.651320754717, Medianaporm2apreciosconstantes: 227.601914534103},
 {X: 2500, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 21000.0, Medianaapreciosconstantes: 14072.1967298571, Medianaporm2aprecioscorrientes: 375.864160097029, Medianaporm2apreciosconstantes: 251.868304980376},
 {X: 3079, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 85230.6818181818, Medianaapreciosconstantes: 57113.4724745385, Medianaporm2aprecioscorrientes: 705.844155844156, Medianaporm2apreciosconstantes: 472.989420078994},
 {X: 3441, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 13402.0921236734, Medianaporm2aprecioscorrientes: 336.406117247239, Medianaporm2apreciosconstantes: 225.427288715739},
 {X: 3911, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 18250.0, Medianaapreciosconstantes: 12229.409062852, Medianaporm2aprecioscorrientes: 312.270259796022, Medianaporm2apreciosconstantes: 209.253739463486},
 {X: 4161, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 13900.0, Medianaapreciosconstantes: 9314.45402595303, Medianaporm2aprecioscorrientes: 307.692307692308, Medianaporm2apreciosconstantes: 206.186032671899},
 {X: 4414, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 10051.5690927551, Medianaporm2aprecioscorrientes: 337.5, Medianaporm2apreciosconstantes: 226.160304586989},
 {X: 4616, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 8711.35988038772, Medianaporm2aprecioscorrientes: 302.631578947368, Medianaporm2apreciosconstantes: 202.794815029269},
 {X: 4923, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 12750.0, Medianaapreciosconstantes: 8543.8337288418, Medianaporm2aprecioscorrientes: 271.008403361345, Medianaporm2apreciosconstantes: 181.60397940692},
 {X: 5057, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2019-01", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 8711.35988038772, Medianaporm2aprecioscorrientes: 300.782443914665, Medianaporm2apreciosconstantes: 201.555701126399},
 {X: 13, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 9038.01973795829, Medianaporm2aprecioscorrientes: 301.369863013699, Medianaporm2apreciosconstantes: 194.556197881686},
 {X: 173, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 8069.6604803199, Medianaporm2aprecioscorrientes: 291.899724677583, Medianaporm2apreciosconstantes: 188.442533795756},
 {X: 678, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 21500.0, Medianaapreciosconstantes: 13879.8160261502, Medianaporm2aprecioscorrientes: 347.826086956522, Medianaporm2apreciosconstantes: 224.547074234989},
 {X: 1086, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 8392.4468995327, Medianaporm2aprecioscorrientes: 300.0, Medianaporm2apreciosconstantes: 193.671851527678},
 {X: 1496, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 15500.0, Medianaapreciosconstantes: 10006.3789955967, Medianaporm2aprecioscorrientes: 321.666666666667, Medianaporm2apreciosconstantes: 207.659263026899},
 {X: 1578, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 13500.0, Medianaapreciosconstantes: 8715.2333187455, Medianaporm2aprecioscorrientes: 277.777777777778, Medianaporm2apreciosconstantes: 179.325788451554},
 {X: 1649, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 12000.0, Medianaapreciosconstantes: 7746.87406110711, Medianaporm2aprecioscorrientes: 262.5, Medianaporm2apreciosconstantes: 169.462870086718},
 {X: 1884, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 12750.0, Medianaapreciosconstantes: 8231.0536899263, Medianaporm2aprecioscorrientes: 314.871481028152, Medianaporm2apreciosconstantes: 203.272475746614},
 {X: 2145, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 18500.0, Medianaapreciosconstantes: 11943.0975108735, Medianaporm2aprecioscorrientes: 350.0, Medianaporm2apreciosconstantes: 225.950493448957},
 {X: 2504, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 16139.3209606398, Medianaporm2aprecioscorrientes: 400.0, Medianaporm2apreciosconstantes: 258.229135370237},
 {X: 3122, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 89166.0, Medianaapreciosconstantes: 57563.1477110564, Medianaporm2aprecioscorrientes: 721.47128342034, Medianaporm2apreciosconstantes: 465.762264280224},
 {X: 3482, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 23000.0, Medianaapreciosconstantes: 14848.1752837886, Medianaporm2aprecioscorrientes: 350.0, Medianaporm2apreciosconstantes: 225.950493448957},
 {X: 3964, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 19000.0, Medianaapreciosconstantes: 12265.8839300863, Medianaporm2aprecioscorrientes: 318.181818181818, Medianaporm2apreciosconstantes: 205.409539499052},
 {X: 4156, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 9038.01973795829, Medianaporm2aprecioscorrientes: 290.130083354382, Medianaporm2apreciosconstantes: 187.300101423742},
 {X: 4426, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 9683.59257638388, Medianaporm2aprecioscorrientes: 344.875331564987, Medianaporm2apreciosconstantes: 222.642146701376},
 {X: 4579, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 13500.0, Medianaapreciosconstantes: 8715.2333187455, Medianaporm2aprecioscorrientes: 301.369863013699, Medianaporm2apreciosconstantes: 194.556197881686},
 {X: 4989, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 8069.6604803199, Medianaporm2aprecioscorrientes: 281.481481481481, Medianaporm2apreciosconstantes: 181.71679896424},
 {X: 5008, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2019-02", Medianaaprecioscorrientes: 13500.0, Medianaapreciosconstantes: 8715.2333187455, Medianaporm2aprecioscorrientes: 314.384151593454, Medianaporm2apreciosconstantes: 202.957869100208},
 {X: 69, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 8632.30156252458, Medianaporm2aprecioscorrientes: 314.285714285714, Medianaporm2apreciosconstantes: 193.786361607695},
 {X: 250, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 8015.70859377283, Medianaporm2aprecioscorrientes: 288.888888888889, Medianaporm2apreciosconstantes: 178.126857639396},
 {X: 688, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 13565.0453125386, Medianaporm2aprecioscorrientes: 357.142857142857, Medianaporm2apreciosconstantes: 220.211774554198},
 {X: 1123, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 8632.30156252458, Medianaporm2aprecioscorrientes: 305.555555555556, Medianaporm2apreciosconstantes: 188.403407118592},
 {X: 1480, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 15900.0, Medianaapreciosconstantes: 9803.82820315292, Medianaporm2aprecioscorrientes: 315.789473684211, Medianaporm2apreciosconstantes: 194.713569079502},
 {X: 1573, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 13800.0, Medianaapreciosconstantes: 8508.98296877423, Medianaporm2aprecioscorrientes: 288.888888888889, Medianaporm2apreciosconstantes: 178.126857639396},
 {X: 1629, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 7707.41210939695, Medianaporm2aprecioscorrientes: 263.157894736842, Medianaporm2apreciosconstantes: 162.261307566252},
 {X: 1863, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 12500.0, Medianaapreciosconstantes: 7707.41210939695, Medianaporm2aprecioscorrientes: 304.878048780488, Medianaporm2apreciosconstantes: 187.985661204804},
 {X: 2136, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 19350.0, Medianaapreciosconstantes: 11931.0739453465, Medianaporm2aprecioscorrientes: 390.701678863539, Medianaporm2apreciosconstantes: 240.903908066765},
 {X: 2496, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 15414.8242187939, Medianaporm2aprecioscorrientes: 419.467629145048, Medianaporm2apreciosconstantes: 258.640790749806},
 {X: 3094, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 100809.210526316, Medianaapreciosconstantes: 62158.2503959419, Medianaporm2aprecioscorrientes: 787.362084443652, Medianaporm2apreciosconstantes: 485.481925129682},
 {X: 3447, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 24500.0, Medianaapreciosconstantes: 15106.527734418, Medianaporm2aprecioscorrientes: 363.636363636364, Medianaporm2apreciosconstantes: 224.215625000639},
 {X: 3912, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 21000.0, Medianaapreciosconstantes: 12948.4523437869, Medianaporm2aprecioscorrientes: 323.076923076923, Medianaporm2apreciosconstantes: 199.206959135183},
 {X: 4177, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 13500.0, Medianaapreciosconstantes: 8324.0050781487, Medianaporm2aprecioscorrientes: 300.0, Medianaporm2apreciosconstantes: 184.977890625527},
 {X: 4439, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 9248.89453127634, Medianaporm2aprecioscorrientes: 366.666666666667, Medianaporm2apreciosconstantes: 226.084088542311},
 {X: 4602, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 8632.30156252458, Medianaporm2aprecioscorrientes: 311.111111111111, Medianaporm2apreciosconstantes: 191.828923611657},
 {X: 4954, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 8015.70859377283, Medianaporm2aprecioscorrientes: 279.569892473118, Medianaporm2apreciosconstantes: 172.380829973609},
 {X: 5026, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2019-03", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 8632.30156252458, Medianaporm2aprecioscorrientes: 324.324324324324, Medianaporm2apreciosconstantes: 199.976097973542},
 {X: 12, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 8348.45412301138, Medianaporm2aprecioscorrientes: 312.771739130435, Medianaporm2apreciosconstantes: 186.511465364637},
 {X: 206, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 7752.13597136771, Medianaporm2aprecioscorrientes: 291.334238669156, Medianaporm2apreciosconstantes: 173.727894713707},
 {X: 614, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 13118.9993361607, Medianaporm2aprecioscorrientes: 368.932038834951, Medianaporm2apreciosconstantes: 220.000871480189},
 {X: 1072, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 8348.45412301138, Medianaporm2aprecioscorrientes: 308.10147299509, Medianaporm2apreciosconstantes: 183.726500895124},
 {X: 1471, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 16500.0, Medianaapreciosconstantes: 9839.24950212056, Medianaporm2aprecioscorrientes: 324.324324324324, Medianaporm2apreciosconstantes: 193.400481614163},
 {X: 1565, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 8348.45412301138, Medianaporm2aprecioscorrientes: 300.0, Medianaporm2apreciosconstantes: 178.895445493101},
 {X: 1647, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 7752.13597136771, Medianaporm2aprecioscorrientes: 264.057579847053, Medianaporm2apreciosconstantes: 157.462327941895},
 {X: 1894, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 12900.0, Medianaapreciosconstantes: 7692.50415620334, Medianaporm2aprecioscorrientes: 311.179577464789, Medianaporm2apreciosconstantes: 185.562030463061},
 {X: 2144, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 11926.3630328734, Medianaporm2aprecioscorrientes: 376.811594202899, Medianaporm2apreciosconstantes: 224.699593372977},
 {X: 2479, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 15504.2719427354, Medianaporm2aprecioscorrientes: 437.5, Medianaporm2apreciosconstantes: 260.889191344106},
 {X: 3116, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 105887.710526316, Medianaapreciosconstantes: 63142.7638228328, Medianaporm2aprecioscorrientes: 834.921650717703, Medianaporm2apreciosconstantes: 497.878935523263},
 {X: 3493, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 14907.9537910918, Medianaporm2aprecioscorrientes: 377.085042384468, Medianaporm2apreciosconstantes: 224.862655487181},
 {X: 3908, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 19500.0, Medianaapreciosconstantes: 11628.2039570516, Medianaporm2aprecioscorrientes: 355.555555555556, Medianaporm2apreciosconstantes: 212.024231695527},
 {X: 4157, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 7752.13597136771, Medianaporm2aprecioscorrientes: 311.688311688312, Medianaporm2apreciosconstantes: 185.86539791491},
 {X: 4406, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 8944.77227465505, Medianaporm2aprecioscorrientes: 354.942810457516, Medianaporm2apreciosconstantes: 211.658840671235},
 {X: 4582, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 8348.45412301138, Medianaporm2aprecioscorrientes: 325.0, Medianaporm2apreciosconstantes: 193.803399284193},
 {X: 4930, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 7752.13597136771, Medianaporm2aprecioscorrientes: 284.033613445378, Medianaporm2apreciosconstantes: 169.37439937442},
 {X: 5030, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2019-04", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 8348.45412301138, Medianaporm2aprecioscorrientes: 338.983050847458, Medianaporm2apreciosconstantes: 202.141746319888},
 {X: 32, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 8097.43368022805, Medianaporm2aprecioscorrientes: 316.666666666667, Medianaporm2apreciosconstantes: 183.156238005158},
 {X: 240, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 8097.43368022805, Medianaporm2aprecioscorrientes: 288.057040998217, Medianaporm2apreciosconstantes: 166.608770400414},
 {X: 682, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 23000.0, Medianaapreciosconstantes: 13302.9267603747, Medianaporm2aprecioscorrientes: 379.310344827586, Medianaporm2apreciosconstantes: 219.388597247558},
 {X: 1119, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 8097.43368022805, Medianaporm2aprecioscorrientes: 315.789473684211, Medianaporm2apreciosconstantes: 182.648880005144},
 {X: 1491, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 9832.59804027691, Medianaporm2aprecioscorrientes: 337.5, Medianaporm2apreciosconstantes: 195.205990505498},
 {X: 1579, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 8675.82180024434, Medianaporm2aprecioscorrientes: 298.275862068966, Medianaporm2apreciosconstantes: 172.519215108307},
 {X: 1664, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 13500.0, Medianaapreciosconstantes: 7808.2396202199, Medianaporm2aprecioscorrientes: 270.833333333333, Medianaporm2apreciosconstantes: 156.646782504411},
 {X: 1890, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 12800.0, Medianaapreciosconstantes: 7403.3679362085, Medianaporm2aprecioscorrientes: 308.775731310943, Medianaporm2apreciosconstantes: 178.592214739591},
 {X: 2137, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 11567.7624003258, Medianaporm2aprecioscorrientes: 375.811688311688, Medianaporm2apreciosconstantes: 217.365015882745},
 {X: 2484, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 15038.0911204235, Medianaporm2aprecioscorrientes: 439.298245614035, Medianaporm2apreciosconstantes: 254.084886407156},
 {X: 3108, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 98390.0, Medianaapreciosconstantes: 56907.6071284027, Medianaporm2aprecioscorrientes: 844.914904338564, Medianaporm2apreciosconstantes: 488.688743094125},
 {X: 3445, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 14459.7030004072, Medianaporm2aprecioscorrientes: 382.843137254902, Medianaporm2apreciosconstantes: 221.431922418001},
 {X: 3913, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 11567.7624003258, Medianaporm2aprecioscorrientes: 341.163793103448, Medianaporm2apreciosconstantes: 197.32508491073},
 {X: 4165, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 13458.4090909091, Medianaapreciosconstantes: 7784.18393250105, Medianaporm2aprecioscorrientes: 311.269841269841, Medianaporm2apreciosconstantes: 180.034778309832},
 {X: 4424, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 9254.20992026063, Medianaporm2aprecioscorrientes: 357.043650793651, Medianaporm2apreciosconstantes: 206.509805946292},
 {X: 4583, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 8675.82180024434, Medianaporm2aprecioscorrientes: 340.784215784216, Medianaporm2apreciosconstantes: 197.105541898658},
 {X: 4924, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 7519.04556021176, Medianaporm2aprecioscorrientes: 290.088826554465, Medianaporm2apreciosconstantes: 167.783931028568},
 {X: 5034, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2019-05", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 8097.43368022805, Medianaporm2aprecioscorrientes: 337.289325842697, Medianaporm2apreciosconstantes: 195.084139075719},
 {X: 11, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7884.55080722006, Medianaporm2aprecioscorrientes: 327.586206896552, Medianaporm2apreciosconstantes: 184.490720858598},
 {X: 183, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7884.55080722006, Medianaporm2aprecioscorrientes: 305.084745762712, Medianaporm2apreciosconstantes: 171.818298462423},
 {X: 621, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 12390.0084113458, Medianaporm2aprecioscorrientes: 381.898120072771, Medianaporm2apreciosconstantes: 215.078223635399},
 {X: 1098, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7884.55080722006, Medianaporm2aprecioscorrientes: 318.301435406699, Medianaporm2apreciosconstantes: 179.261702819657},
 {X: 1427, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 17250.0, Medianaapreciosconstantes: 9714.89295889614, Medianaporm2aprecioscorrientes: 344.827586206897, Medianaporm2apreciosconstantes: 194.200758798524},
 {X: 1571, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 14500.0, Medianaapreciosconstantes: 8166.14190747792, Medianaporm2aprecioscorrientes: 292.682926829268, Medianaporm2apreciosconstantes: 164.833814785088},
 {X: 1672, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7884.55080722006, Medianaporm2aprecioscorrientes: 281.650641025641, Medianaporm2apreciosconstantes: 158.620627789483},
 {X: 1911, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 7321.36860670434, Medianaporm2aprecioscorrientes: 309.859154929577, Medianaporm2apreciosconstantes: 174.50716072318},
 {X: 2172, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 11263.6440103144, Medianaporm2aprecioscorrientes: 383.582860092927, Medianaporm2apreciosconstantes: 216.027039227248},
 {X: 2480, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 14642.7372134087, Medianaporm2aprecioscorrientes: 447.368421052632, Medianaporm2apreciosconstantes: 251.949931809664},
 {X: 3109, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 87126.6666666667, Medianaapreciosconstantes: 49068.1878569328, Medianaporm2aprecioscorrientes: 816.8125, Medianaporm2apreciosconstantes: 460.014261158745},
 {X: 3494, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 24900.0, Medianaapreciosconstantes: 14023.2367928414, Medianaporm2aprecioscorrientes: 391.752577319588, Medianaporm2apreciosconstantes: 220.62807855255},
 {X: 3909, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 11263.6440103144, Medianaporm2aprecioscorrientes: 355.555555555556, Medianaporm2apreciosconstantes: 200.242560183367},
 {X: 4198, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7884.55080722006, Medianaporm2aprecioscorrientes: 320.550458715596, Medianaporm2apreciosconstantes: 180.528312715772},
 {X: 4432, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 9010.91520825149, Medianaporm2aprecioscorrientes: 368.421052631579, Medianaporm2apreciosconstantes: 207.48817913737},
 {X: 4584, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 8447.73300773578, Medianaporm2aprecioscorrientes: 333.333333333333, Medianaporm2apreciosconstantes: 187.727400171906},
 {X: 4940, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 7321.36860670434, Medianaporm2aprecioscorrientes: 304.292929292929, Medianaporm2apreciosconstantes: 171.372361520566},
 {X: 5013, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2019-06", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 8447.73300773578, Medianaporm2aprecioscorrientes: 351.351351351351, Medianaporm2apreciosconstantes: 197.874827208225},
 {X: 58, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7714.82466705615, Medianaporm2aprecioscorrientes: 328.125, Medianaporm2apreciosconstantes: 180.816203134128},
 {X: 212, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7714.82466705615, Medianaporm2aprecioscorrientes: 318.420289855072, Medianaporm2apreciosconstantes: 175.468336190363},
 {X: 637, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 23000.0, Medianaapreciosconstantes: 12674.3548101637, Medianaporm2aprecioscorrientes: 392.857142857143, Medianaporm2apreciosconstantes: 216.487426881678},
 {X: 1134, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 8265.88357184587, Medianaporm2aprecioscorrientes: 325.20325203252, Medianaporm2apreciosconstantes: 179.206147899097},
 {X: 1499, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 8265.88357184587, Medianaporm2aprecioscorrientes: 346.666666666667, Medianaporm2apreciosconstantes: 191.033753660438},
 {X: 1582, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7714.82466705615, Medianaporm2aprecioscorrientes: 309.52380952381, Medianaporm2apreciosconstantes: 170.565851482534},
 {X: 1643, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7714.82466705615, Medianaporm2aprecioscorrientes: 288.888888888889, Medianaporm2apreciosconstantes: 159.194794717032},
 {X: 1901, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7714.82466705615, Medianaporm2aprecioscorrientes: 314.005602240896, Medianaporm2apreciosconstantes: 173.035583268706},
 {X: 2189, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 19500.0, Medianaapreciosconstantes: 10745.6486433996, Medianaporm2aprecioscorrientes: 403.530377668309, Medianaporm2apreciosconstantes: 222.369007967282},
 {X: 2460, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 14327.5315245328, Medianaporm2aprecioscorrientes: 455.847953216374, Medianaporm2apreciosconstantes: 251.199073850053},
 {X: 3119, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 93463.3333333333, Medianaapreciosconstantes: 51503.8021046636, Medianaporm2aprecioscorrientes: 786.728395061728, Medianaporm2apreciosconstantes: 433.533687749694},
 {X: 3510, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 14327.5315245328, Medianaporm2aprecioscorrientes: 400.0, Medianaporm2apreciosconstantes: 220.42356191589},
 {X: 3901, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 20500.0, Medianaapreciosconstantes: 11296.7075481894, Medianaporm2aprecioscorrientes: 357.142857142857, Medianaporm2apreciosconstantes: 196.806751710616},
 {X: 4205, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 7163.76576226642, Medianaporm2aprecioscorrientes: 316.666666666667, Medianaporm2apreciosconstantes: 174.501986516746},
 {X: 4463, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 8265.88357184587, Medianaporm2aprecioscorrientes: 350.0, Medianaporm2apreciosconstantes: 192.870616676404},
 {X: 4641, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 14750.0, Medianaapreciosconstantes: 8128.11884564844, Medianaporm2aprecioscorrientes: 341.746200070696, Medianaporm2apreciosconstantes: 188.322286727008},
 {X: 4949, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 7163.76576226642, Medianaporm2aprecioscorrientes: 310.344827586207, Medianaporm2apreciosconstantes: 171.018280796811},
 {X: 5010, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2019-07", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 8816.9424766356, Medianaporm2aprecioscorrientes: 366.412213740458, Medianaporm2apreciosconstantes: 201.914713205395},
 {X: 8, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 7947.96497006501, Medianaporm2aprecioscorrientes: 351.351351351351, Medianaporm2apreciosconstantes: 186.168548848369},
 {X: 178, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7418.10063872734, Medianaporm2aprecioscorrientes: 327.815468113976, Medianaporm2apreciosconstantes: 173.697723814356},
 {X: 640, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 24000.0, Medianaapreciosconstantes: 12716.743952104, Medianaporm2aprecioscorrientes: 416.666666666667, Medianaporm2apreciosconstantes: 220.776804724028},
 {X: 1097, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 15500.0, Medianaapreciosconstantes: 8212.89713573384, Medianaporm2aprecioscorrientes: 341.176470588235, Medianaporm2apreciosconstantes: 180.77724245638},
 {X: 1468, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 18500.0, Medianaapreciosconstantes: 9802.49012974685, Medianaporm2aprecioscorrientes: 379.583574320416, Medianaporm2apreciosconstantes: 201.127796794049},
 {X: 1551, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 14500.0, Medianaapreciosconstantes: 7683.03280439618, Medianaporm2aprecioscorrientes: 309.475032010243, Medianaporm2apreciosconstantes: 163.979780901811},
 {X: 1666, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7418.10063872734, Medianaporm2aprecioscorrientes: 300.0, Medianaporm2apreciosconstantes: 158.9592994013},
 {X: 1883, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 13900.0, Medianaapreciosconstantes: 7365.11420559358, Medianaporm2aprecioscorrientes: 342.857142857143, Medianaporm2apreciosconstantes: 181.667770744343},
 {X: 2146, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 10597.2866267533, Medianaporm2aprecioscorrientes: 400.0, Medianaporm2apreciosconstantes: 211.945732535067},
 {X: 2492, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 13776.4726147793, Medianaporm2aprecioscorrientes: 468.294862248697, Medianaporm2apreciosconstantes: 248.132744054271},
 {X: 3100, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 115148.357142857, Medianaapreciosconstantes: 61013.0072621308, Medianaporm2aprecioscorrientes: 927.320463320463, Medianaporm2apreciosconstantes: 491.354037233033},
 {X: 3480, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 27750.0, Medianaapreciosconstantes: 14703.7351946203, Medianaporm2aprecioscorrientes: 416.666666666667, Medianaporm2apreciosconstantes: 220.776804724028},
 {X: 3894, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 21000.0, Medianaapreciosconstantes: 11127.150958091, Medianaporm2aprecioscorrientes: 356.349206349206, Medianaporm2apreciosconstantes: 188.81673394493},
 {X: 4171, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7418.10063872734, Medianaporm2aprecioscorrientes: 322.401433691756, Medianaporm2apreciosconstantes: 170.829020085388},
 {X: 4440, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 16500.0, Medianaapreciosconstantes: 8742.76146707151, Medianaporm2aprecioscorrientes: 367.34693877551, Medianaporm2apreciosconstantes: 194.644040083225},
 {X: 4586, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 7947.96497006501, Medianaporm2aprecioscorrientes: 363.636363636364, Medianaporm2apreciosconstantes: 192.677938668243},
 {X: 4927, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 13000.0, Medianaapreciosconstantes: 6888.23630738967, Medianaporm2aprecioscorrientes: 308.608058608059, Medianaporm2apreciosconstantes: 163.520402619775},
 {X: 5020, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2019-08", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 8477.82930140268, Medianaporm2aprecioscorrientes: 375.735294117647, Medianaporm2apreciosconstantes: 199.088730377609},
 {X: 68, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 7505.16049957596, Medianaporm2aprecioscorrientes: 355.995599559956, Medianaporm2apreciosconstantes: 178.120274122683},
 {X: 194, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7004.8164662709, Medianaporm2aprecioscorrientes: 328.571428571429, Medianaporm2apreciosconstantes: 164.398753800236},
 {X: 604, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 12508.6008326266, Medianaporm2aprecioscorrientes: 432.666666666667, Medianaporm2apreciosconstantes: 216.482185076658},
 {X: 1057, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 8005.50453288103, Medianaporm2aprecioscorrientes: 346.534653465347, Medianaporm2apreciosconstantes: 173.386546194824},
 {X: 1498, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 10006.8806661013, Medianaporm2aprecioscorrientes: 383.233532934132, Medianaporm2apreciosconstantes: 191.748611566013},
 {X: 1583, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 8505.84856618609, Medianaporm2aprecioscorrientes: 318.511198945982, Medianaporm2apreciosconstantes: 159.365177933464},
 {X: 1669, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 14200.0, Medianaapreciosconstantes: 7104.88527293191, Medianaporm2aprecioscorrientes: 301.587301587302, Medianaporm2apreciosconstantes: 150.897406869782},
 {X: 1916, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 7505.16049957596, Medianaporm2aprecioscorrientes: 383.561643835616, Medianaporm2apreciosconstantes: 191.912779897833},
 {X: 2125, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 21000.0, Medianaapreciosconstantes: 10507.2246994063, Medianaporm2aprecioscorrientes: 424.038461538462, Medianaporm2apreciosconstantes: 212.165114122628},
 {X: 2472, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 27000.0, Medianaapreciosconstantes: 13509.2888992367, Medianaporm2aprecioscorrientes: 485.714285714286, Medianaporm2apreciosconstantes: 243.024244748174},
 {X: 3096, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 131976.19047619, Medianaapreciosconstantes: 66033.4994430943, Medianaporm2aprecioscorrientes: 1064.50227410415, Medianaporm2apreciosconstantes: 532.617361287684},
 {X: 3487, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 14009.6329325418, Medianaporm2aprecioscorrientes: 426.666666666667, Medianaporm2apreciosconstantes: 213.480120876828},
 {X: 3899, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 11007.5687327114, Medianaporm2aprecioscorrientes: 369.870041039672, Medianaporm2apreciosconstantes: 185.062268132499},
 {X: 4175, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7004.8164662709, Medianaporm2aprecioscorrientes: 337.5, Medianaporm2apreciosconstantes: 168.866111240459},
 {X: 4451, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 17750.0, Medianaapreciosconstantes: 8881.10659116489, Medianaporm2aprecioscorrientes: 384.845005740528, Medianaporm2apreciosconstantes: 192.554902369526},
 {X: 4591, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 8005.50453288103, Medianaporm2aprecioscorrientes: 368.421052631579, Medianaporm2apreciosconstantes: 184.337275428182},
 {X: 4936, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 7004.8164662709, Medianaporm2aprecioscorrientes: 325.925925925926, Medianaporm2apreciosconstantes: 163.075092336465},
 {X: 5019, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2019-09", Medianaaprecioscorrientes: 16750.0, Medianaapreciosconstantes: 8380.76255785983, Medianaporm2aprecioscorrientes: 388.888888888889, Medianaporm2apreciosconstantes: 194.578235174192},
 {X: 7, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 7749.76237763361, Medianaporm2aprecioscorrientes: 364.109848484848, Medianaporm2apreciosconstantes: 176.360300319609},
 {X: 188, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 6781.04208042941, Medianaporm2aprecioscorrientes: 347.826086956522, Medianaporm2apreciosconstantes: 168.473095165948},
 {X: 609, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 12109.0037150525, Medianaporm2aprecioscorrientes: 440.47619047619, Medianaporm2apreciosconstantes: 213.349113074735},
 {X: 1092, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 7749.76237763361, Medianaporm2aprecioscorrientes: 365.228131357164, Medianaporm2apreciosconstantes: 176.901951977823},
 {X: 1448, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 9687.20297204201, Medianaporm2aprecioscorrientes: 388.888888888889, Medianaporm2apreciosconstantes: 188.362280011928},
 {X: 1530, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 7749.76237763361, Medianaporm2aprecioscorrientes: 333.333333333333, Medianaporm2apreciosconstantes: 161.453382867367},
 {X: 1628, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 7265.40222903151, Medianaporm2aprecioscorrientes: 312.213302752294, Medianaporm2apreciosconstantes: 151.223681716654},
 {X: 1897, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 7749.76237763361, Medianaporm2aprecioscorrientes: 347.826086956522, Medianaporm2apreciosconstantes: 168.473095165948},
 {X: 2130, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 22500.0, Medianaapreciosconstantes: 10898.1033435473, Medianaporm2aprecioscorrientes: 431.876720845546, Medianaporm2apreciosconstantes: 209.183872686537},
 {X: 2453, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 26500.0, Medianaapreciosconstantes: 12835.5439379557, Medianaporm2aprecioscorrientes: 496.736819727891, Medianaporm2apreciosconstantes: 240.599519819536},
 {X: 3090, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 156818.181818182, Medianaapreciosconstantes: 75956.4778489658, Medianaporm2aprecioscorrientes: 1176.13636363636, Medianaporm2apreciosconstantes: 569.673583867242},
 {X: 3471, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 13562.0841608588, Medianaporm2aprecioscorrientes: 435.690653432589, Medianaporm2apreciosconstantes: 211.031189641155},
 {X: 3906, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 12109.0037150525, Medianaporm2aprecioscorrientes: 410.25641025641, Medianaporm2apreciosconstantes: 198.711855836759},
 {X: 4181, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 6781.04208042941, Medianaporm2aprecioscorrientes: 333.333333333333, Medianaporm2apreciosconstantes: 161.453382867367},
 {X: 4411, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 8234.12252623571, Medianaporm2aprecioscorrientes: 387.096774193548, Medianaporm2apreciosconstantes: 187.494251071781},
 {X: 4606, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 7749.76237763361, Medianaporm2aprecioscorrientes: 376.470588235294, Medianaporm2apreciosconstantes: 182.347350061967},
 {X: 4943, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 6781.04208042941, Medianaporm2aprecioscorrientes: 325.0, Medianaporm2apreciosconstantes: 157.417048295683},
 {X: 5012, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2019-10", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 8234.12252623571, Medianaporm2aprecioscorrientes: 400.0, Medianaporm2apreciosconstantes: 193.74405944084},
 {X: 86, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 7430.26114664708, Medianaporm2aprecioscorrientes: 365.384615384615, Medianaporm2apreciosconstantes: 169.681444454681},
 {X: 222, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 6965.86982498164, Medianaporm2aprecioscorrientes: 360.555555555556, Medianaporm2apreciosconstantes: 167.438870978263},
 {X: 613, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 12074.1743633015, Medianaporm2aprecioscorrientes: 456.140350877193, Medianaporm2apreciosconstantes: 211.827620408798},
 {X: 1064, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 16500.0, Medianaapreciosconstantes: 7662.45680747981, Medianaporm2aprecioscorrientes: 372.481406355646, Medianaporm2apreciosconstantes: 172.977132593301},
 {X: 1456, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 9287.82643330886, Medianaporm2aprecioscorrientes: 397.590361445783, Medianaporm2apreciosconstantes: 184.637513433248},
 {X: 1514, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 7430.26114664708, Medianaporm2aprecioscorrientes: 350.0, Medianaporm2apreciosconstantes: 162.536962582905},
 {X: 1675, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 6965.86982498164, Medianaporm2aprecioscorrientes: 315.789473684211, Medianaporm2apreciosconstantes: 146.649891052245},
 {X: 1909, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 15900.0, Medianaapreciosconstantes: 7383.82201448054, Medianaporm2aprecioscorrientes: 352.941176470588, Medianaporm2apreciosconstantes: 163.902819411333},
 {X: 2192, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 10216.6090766397, Medianaporm2aprecioscorrientes: 438.202247191011, Medianaporm2apreciosconstantes: 203.497320729801},
 {X: 2512, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 13002.9570066324, Medianaporm2aprecioscorrientes: 514.928698752228, Medianaporm2apreciosconstantes: 239.128418977014},
 {X: 3085, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 140250.0, Medianaapreciosconstantes: 65130.8828635784, Medianaporm2aprecioscorrientes: 1146.33064516129, Medianaporm2apreciosconstantes: 532.346003372051},
 {X: 3459, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 13002.9570066324, Medianaporm2aprecioscorrientes: 451.833848873177, Medianaporm2apreciosconstantes: 209.827718251399},
 {X: 3915, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 11609.7830416361, Medianaporm2aprecioscorrientes: 437.5, Medianaporm2apreciosconstantes: 203.171203228631},
 {X: 4163, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 14000.0, Medianaapreciosconstantes: 6501.4785033162, Medianaporm2aprecioscorrientes: 338.410444342648, Medianaporm2apreciosconstantes: 157.154873513672},
 {X: 4465, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 8359.04378997797, Medianaporm2aprecioscorrientes: 406.25, Medianaporm2apreciosconstantes: 188.658974426586},
 {X: 4651, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 7894.65246831253, Medianaporm2aprecioscorrientes: 371.681415929204, Medianaporm2apreciosconstantes: 172.605623981846},
 {X: 4937, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 6965.86982498164, Medianaporm2aprecioscorrientes: 337.867647058824, Medianaporm2apreciosconstantes: 156.902803165641},
 {X: 5017, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2019-11", Medianaaprecioscorrientes: 17500.0, Medianaapreciosconstantes: 8126.84812914525, Medianaporm2aprecioscorrientes: 411.103599797195, Medianaporm2apreciosconstantes: 190.912944051241},
 {X: 6, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 7612.97248576985, Medianaporm2aprecioscorrientes: 374.45652173913, Medianaporm2apreciosconstantes: 167.689835124534},
 {X: 200, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 15250.0, Medianaapreciosconstantes: 6829.28414164648, Medianaporm2aprecioscorrientes: 362.5, Medianaporm2apreciosconstantes: 162.335442711269},
 {X: 624, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 27000.0, Medianaapreciosconstantes: 12091.1915950462, Medianaporm2aprecioscorrientes: 462.264150943396, Medianaporm2apreciosconstantes: 207.012015428814},
 {X: 1068, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 7612.97248576985, Medianaporm2aprecioscorrientes: 387.096774193548, Medianaporm2apreciosconstantes: 173.350417133279},
 {X: 1474, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 8956.43821855276, Medianaporm2aprecioscorrientes: 411.764705882353, Medianaporm2apreciosconstantes: 184.397257440792},
 {X: 1556, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 16500.0, Medianaapreciosconstantes: 7389.06153030603, Medianaporm2aprecioscorrientes: 366.025420817588, Medianaporm2apreciosconstantes: 163.914203398625},
 {X: 1653, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 7165.15057484221, Medianaporm2aprecioscorrientes: 330.645161290323, Medianaporm2apreciosconstantes: 148.07014796801},
 {X: 1881, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 7165.15057484221, Medianaporm2aprecioscorrientes: 369.86301369863, Medianaporm2apreciosconstantes: 165.632761575976},
 {X: 2171, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 9852.08204040804, Medianaporm2aprecioscorrientes: 447.058823529412, Medianaporm2apreciosconstantes: 200.202736650003},
 {X: 2505, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 29000.0, Medianaapreciosconstantes: 12986.8354169015, Medianaporm2aprecioscorrientes: 515.388257575758, Medianaporm2apreciosconstantes: 230.802154377242},
 {X: 3092, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 149831.578947368, Medianaapreciosconstantes: 67097.8640015156, Medianaporm2aprecioscorrientes: 1191.84210526316, Medianaporm2apreciosconstantes: 533.733009102967},
 {X: 3483, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 29000.0, Medianaapreciosconstantes: 12986.8354169015, Medianaporm2aprecioscorrientes: 470.238095238095, Medianaporm2apreciosconstantes: 210.582922400496},
 {X: 3898, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 23000.0, Medianaapreciosconstantes: 10299.9039513357, Medianaporm2aprecioscorrientes: 437.5, Medianaporm2apreciosconstantes: 195.922086030842},
 {X: 4155, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 14500.0, Medianaapreciosconstantes: 6493.41770845075, Medianaporm2aprecioscorrientes: 343.137254901961, Medianaporm2apreciosconstantes: 153.66438120066},
 {X: 4408, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 8060.79439669748, Medianaporm2aprecioscorrientes: 413.793103448276, Medianaporm2apreciosconstantes: 185.305618314885},
 {X: 4592, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 7612.97248576985, Medianaporm2aprecioscorrientes: 385.542168674699, Medianaporm2apreciosconstantes: 172.654230719089},
 {X: 4926, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 15500.0, Medianaapreciosconstantes: 6941.23961937839, Medianaporm2aprecioscorrientes: 357.142857142857, Medianaporm2apreciosconstantes: 159.936396759871},
 {X: 5009, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2019-12", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 8060.79439669748, Medianaporm2aprecioscorrientes: 405.797101449275, Medianaporm2apreciosconstantes: 181.724833419911},
 {X: 66, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 16500.0, Medianaapreciosconstantes: 7222.93404796617, Medianaporm2aprecioscorrientes: 383.132530120482, Medianaporm2apreciosconstantes: 167.71763616307},
 {X: 237, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 6566.30367996924, Medianaporm2aprecioscorrientes: 363.636363636364, Medianaporm2apreciosconstantes: 159.183119514406},
 {X: 606, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 26700.0, Medianaapreciosconstantes: 11688.0205503453, Medianaporm2aprecioscorrientes: 472.584108804581, Medianaporm2apreciosconstantes: 206.875384849234},
 {X: 1060, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7879.56441596309, Medianaporm2aprecioscorrientes: 390.243902439024, Medianaporm2apreciosconstantes: 170.830664844728},
 {X: 1443, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 8755.07157329232, Medianaporm2aprecioscorrientes: 418.181818181818, Medianaporm2apreciosconstantes: 183.060587441567},
 {X: 1528, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 7441.81083729848, Medianaporm2aprecioscorrientes: 385.135135135135, Medianaporm2apreciosconstantes: 168.594283674886},
 {X: 1668, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 6566.30367996924, Medianaporm2aprecioscorrientes: 343.428571428571, Medianaporm2apreciosconstantes: 150.337086158534},
 {X: 1934, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 16850.0, Medianaapreciosconstantes: 7376.14780049878, Medianaporm2aprecioscorrientes: 375.0, Medianaporm2apreciosconstantes: 164.157591999231},
 {X: 2166, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 23000.0, Medianaapreciosconstantes: 10068.3323092862, Medianaporm2aprecioscorrientes: 456.336238198983, Medianaporm2apreciosconstantes: 199.762821345954},
 {X: 2523, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 13132.6073599385, Medianaporm2aprecioscorrientes: 541.176470588235, Medianaporm2apreciosconstantes: 236.901936689086},
 {X: 3099, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 160850.0, Medianaapreciosconstantes: 70412.6631282035, Medianaporm2aprecioscorrientes: 1302.65110489078, Medianaporm2apreciosconstantes: 570.240182917355},
 {X: 3474, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 13132.6073599385, Medianaporm2aprecioscorrientes: 488.002873563218, Medianaporm2apreciosconstantes: 213.625004300915},
 {X: 3930, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 23000.0, Medianaapreciosconstantes: 10068.3323092862, Medianaporm2aprecioscorrientes: 459.459459459459, Medianaporm2apreciosconstantes: 201.130022629688},
 {X: 4193, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 6566.30367996924, Medianaporm2aprecioscorrientes: 368.421052631579, Medianaporm2apreciosconstantes: 161.277634244859},
 {X: 4438, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7879.56441596309, Medianaporm2aprecioscorrientes: 400.0, Medianaporm2apreciosconstantes: 175.101431465846},
 {X: 4627, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7879.56441596309, Medianaporm2aprecioscorrientes: 408.163265306122, Medianaporm2apreciosconstantes: 178.67493006719},
 {X: 4950, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 7004.05725863386, Medianaporm2aprecioscorrientes: 352.941176470588, Medianaporm2apreciosconstantes: 154.5012630581},
 {X: 5018, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2020-01", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7879.56441596309, Medianaporm2aprecioscorrientes: 425.0, Medianaporm2apreciosconstantes: 186.045270932462},
 {X: 4, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 16500.0, Medianaapreciosconstantes: 7081.3078891904, Medianaporm2aprecioscorrientes: 387.548449612403, Medianaporm2apreciosconstantes: 166.324235980837},
 {X: 175, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 6866.72280163918, Medianaporm2aprecioscorrientes: 370.37037037037, Medianaporm2apreciosconstantes: 158.95191670461},
 {X: 608, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 12875.1052530735, Medianaporm2aprecioscorrientes: 500.0, Medianaporm2apreciosconstantes: 214.585087551224},
 {X: 1058, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7725.06315184407, Medianaporm2aprecioscorrientes: 400.0, Medianaporm2apreciosconstantes: 171.668070040979},
 {X: 1489, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 8583.40350204897, Medianaporm2aprecioscorrientes: 414.473684210526, Medianaporm2apreciosconstantes: 177.879743627988},
 {X: 1555, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 7295.89297674163, Medianaporm2aprecioscorrientes: 400.0, Medianaporm2apreciosconstantes: 171.668070040979},
 {X: 1661, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 16000.0, Medianaapreciosconstantes: 6866.72280163918, Medianaporm2aprecioscorrientes: 349.698795180723, Medianaporm2apreciosconstantes: 150.080293160826},
 {X: 1913, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 7295.89297674163, Medianaporm2aprecioscorrientes: 375.0, Medianaporm2apreciosconstantes: 160.938815663418},
 {X: 2190, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 24000.0, Medianaapreciosconstantes: 10300.0842024588, Medianaporm2aprecioscorrientes: 471.910112359551, Medianaporm2apreciosconstantes: 202.529745553965},
 {X: 2515, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 15020.9561285857, Medianaporm2aprecioscorrientes: 585.080587647982, Medianaporm2apreciosconstantes: 251.099138249928},
 {X: 3087, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 165699.668333333, Medianaapreciosconstantes: 71113.3556730342, Medianaporm2aprecioscorrientes: 1311.12006031055, Medianaporm2apreciosconstantes: 562.693625863812},
 {X: 3469, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 12875.1052530735, Medianaporm2aprecioscorrientes: 500.0, Medianaporm2apreciosconstantes: 214.585087551224},
 {X: 3923, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 23000.0, Medianaapreciosconstantes: 9870.91402735632, Medianaporm2aprecioscorrientes: 450.0, Medianaporm2apreciosconstantes: 193.126578796102},
 {X: 4160, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 15000.0, Medianaapreciosconstantes: 6437.55262653673, Medianaporm2aprecioscorrientes: 384.920634920635, Medianaporm2apreciosconstantes: 165.196456289435},
 {X: 4420, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 17500.0, Medianaapreciosconstantes: 7510.47806429285, Medianaporm2aprecioscorrientes: 427.799227799228, Medianaporm2apreciosconstantes: 183.598669503287},
 {X: 4634, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7725.06315184407, Medianaporm2aprecioscorrientes: 435.483870967742, Medianaporm2apreciosconstantes: 186.896689157518},
 {X: 4931, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 16500.0, Medianaapreciosconstantes: 7081.3078891904, Medianaporm2aprecioscorrientes: 367.581515364767, Medianaporm2apreciosconstantes: 157.75502331352},
 {X: 5023, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2020-02", Medianaaprecioscorrientes: 19000.0, Medianaapreciosconstantes: 8154.23332694652, Medianaporm2aprecioscorrientes: 437.140804597701, Medianaporm2apreciosconstantes: 187.607795653621},
 {X: 64, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7478.27991555197, Medianaporm2aprecioscorrientes: 400.0, Medianaporm2apreciosconstantes: 166.183998123377},
 {X: 180, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 16500.0, Medianaapreciosconstantes: 6855.08992258931, Medianaporm2aprecioscorrientes: 376.179245283019, Medianaporm2apreciosconstantes: 156.287427480417},
 {X: 626, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 14541.0998357955, Medianaporm2aprecioscorrientes: 526.315789473684, Medianaporm2apreciosconstantes: 218.663155425496},
 {X: 1082, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 18500.0, Medianaapreciosconstantes: 7686.00991320619, Medianaporm2aprecioscorrientes: 415.229885057471, Medianaporm2apreciosconstantes: 172.511406097902},
 {X: 1428, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 9140.11989678574, Medianaporm2aprecioscorrientes: 417.582417582418, Medianaporm2apreciosconstantes: 173.48878924968},
 {X: 1510, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7478.27991555197, Medianaporm2aprecioscorrientes: 405.405405405405, Medianaporm2apreciosconstantes: 168.429727827747},
 {X: 1599, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 7062.81992024353, Medianaporm2aprecioscorrientes: 358.974358974359, Medianaporm2apreciosconstantes: 149.139485495339},
 {X: 1922, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 17750.0, Medianaapreciosconstantes: 7374.41491672486, Medianaporm2aprecioscorrientes: 390.524250070087, Medianaporm2apreciosconstantes: 162.247203101952},
 {X: 2176, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 10386.4998827111, Medianaporm2aprecioscorrientes: 504.225352112676, Medianaporm2apreciosconstantes: 209.48546242313},
 {X: 2535, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 42000.0, Medianaapreciosconstantes: 17449.3198029546, Medianaporm2aprecioscorrientes: 641.025641025641, Medianaporm2apreciosconstantes: 266.320509813104},
 {X: 3117, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 179672.368421053, Medianaapreciosconstantes: 74646.6813412675, Medianaporm2aprecioscorrientes: 1388.77192982456, Medianaporm2apreciosconstantes: 576.979179449409},
 {X: 3488, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 14541.0998357955, Medianaporm2aprecioscorrientes: 538.047973531844, Medianaporm2apreciosconstantes: 223.537408559257},
 {X: 3905, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 28500.0, Medianaapreciosconstantes: 11840.6098662906, Medianaporm2aprecioscorrientes: 487.5, Medianaporm2apreciosconstantes: 202.536747712866},
 {X: 4207, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 16500.0, Medianaapreciosconstantes: 6855.08992258931, Medianaporm2aprecioscorrientes: 400.0, Medianaporm2apreciosconstantes: 166.183998123377},
 {X: 4468, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7478.27991555197, Medianaporm2aprecioscorrientes: 427.027027027027, Medianaporm2apreciosconstantes: 177.412646645227},
 {X: 4639, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 8309.19990616886, Medianaporm2aprecioscorrientes: 442.307692307692, Medianaporm2apreciosconstantes: 183.761151771042},
 {X: 4991, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 7062.81992024353, Medianaporm2aprecioscorrientes: 375.0, Medianaporm2apreciosconstantes: 155.797498240666},
 {X: 5087, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2020-03", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 8309.19990616886, Medianaporm2aprecioscorrientes: 452.649456521739, Medianaporm2apreciosconstantes: 188.057741082891},
 {X: 3, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7367.76346423132, Medianaporm2aprecioscorrientes: 412.333965844402, Medianaporm2apreciosconstantes: 168.77661825611},
 {X: 184, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 6958.44327177402, Medianaporm2aprecioscorrientes: 400.0, Medianaporm2apreciosconstantes: 163.728076982918},
 {X: 605, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 16372.8076982918, Medianaporm2aprecioscorrientes: 582.364341085271, Medianaporm2apreciosconstantes: 238.373484173289},
 {X: 1065, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 8186.40384914591, Medianaporm2aprecioscorrientes: 421.132376395534, Medianaporm2apreciosconstantes: 172.377985356218},
 {X: 1424, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 23717.1875, Medianaapreciosconstantes: 9707.92375204576, Medianaporm2aprecioscorrientes: 441.269841269841, Medianaporm2apreciosconstantes: 180.620656354171},
 {X: 1506, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7367.76346423132, Medianaporm2aprecioscorrientes: 414.507772020725, Medianaporm2apreciosconstantes: 169.666401018568},
 {X: 1609, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 6958.44327177402, Medianaporm2aprecioscorrientes: 368.421052631579, Medianaporm2apreciosconstantes: 150.802176168477},
 {X: 1921, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 19000.0, Medianaapreciosconstantes: 7777.08365668861, Medianaporm2aprecioscorrientes: 417.635658914729, Medianaporm2apreciosconstantes: 170.946708284006},
 {X: 2152, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 11460.9653888043, Medianaporm2aprecioscorrientes: 535.714285714286, Medianaporm2apreciosconstantes: 219.278674530694},
 {X: 2525, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 24559.2115474377, Medianaporm2aprecioscorrientes: 836.513994910942, Medianaporm2apreciosconstantes: 342.402069390168},
 {X: 3128, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 215596.25, Medianaapreciosconstantes: 88247.8985430711, Medianaporm2aprecioscorrientes: 1651.05823863636, Medianaporm2apreciosconstantes: 675.811475996838},
 {X: 3457, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 43000.0, Medianaapreciosconstantes: 17600.7682756637, Medianaporm2aprecioscorrientes: 574.712643678161, Medianaporm2apreciosconstantes: 235.241489917986},
 {X: 3937, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 31000.0, Medianaapreciosconstantes: 12688.9259661762, Medianaporm2aprecioscorrientes: 531.135531135531, Medianaporm2apreciosconstantes: 217.404497825303},
 {X: 4174, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 17000.0, Medianaapreciosconstantes: 6958.44327177402, Medianaporm2aprecioscorrientes: 413.888888888889, Medianaporm2apreciosconstantes: 169.413079655936},
 {X: 4434, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 19000.0, Medianaapreciosconstantes: 7777.08365668861, Medianaporm2aprecioscorrientes: 447.49373433584, Medianaporm2apreciosconstantes: 183.16822146178},
 {X: 4609, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 20900.0, Medianaapreciosconstantes: 8554.79202235747, Medianaporm2aprecioscorrientes: 468.085106382979, Medianaporm2apreciosconstantes: 191.596685831075},
 {X: 4971, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 17250.0, Medianaapreciosconstantes: 7060.77331988834, Medianaporm2aprecioscorrientes: 383.585858585859, Medianaporm2apreciosconstantes: 157.009437460261},
 {X: 5089, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2020-04", Medianaaprecioscorrientes: 21000.0, Medianaapreciosconstantes: 8595.7240416032, Medianaporm2aprecioscorrientes: 470.588235294118, Medianaporm2apreciosconstantes: 192.621267038727},
 {X: 23, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7258.88025914086, Medianaporm2aprecioscorrientes: 432.989690721649, Medianaporm2apreciosconstantes: 174.612239910605},
 {X: 191, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 17500.0, Medianaapreciosconstantes: 7057.24469638695, Medianaporm2aprecioscorrientes: 400.0, Medianaporm2apreciosconstantes: 161.30845020313},
 {X: 616, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 36000.0, Medianaapreciosconstantes: 14517.7605182817, Medianaporm2aprecioscorrientes: 571.428571428571, Medianaporm2apreciosconstantes: 230.440643147329},
 {X: 1070, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 8065.42251015652, Medianaporm2aprecioscorrientes: 433.333333333333, Medianaporm2apreciosconstantes: 174.750821053391},
 {X: 1451, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 24000.0, Medianaapreciosconstantes: 9678.50701218782, Medianaporm2aprecioscorrientes: 478.260869565217, Medianaporm2apreciosconstantes: 192.868799155917},
 {X: 1531, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7258.88025914086, Medianaporm2aprecioscorrientes: 415.584415584416, Medianaporm2apreciosconstantes: 167.593195016239},
 {X: 1593, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7258.88025914086, Medianaporm2aprecioscorrientes: 375.0, Medianaporm2apreciosconstantes: 151.226672065435},
 {X: 1860, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 8065.42251015652, Medianaporm2aprecioscorrientes: 480.0, Medianaporm2apreciosconstantes: 193.570140243756},
 {X: 2174, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 27000.0, Medianaapreciosconstantes: 10888.3203887113, Medianaporm2aprecioscorrientes: 541.875208541875, Medianaporm2apreciosconstantes: 218.52262523347},
 {X: 2532, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 16130.845020313, Medianaporm2aprecioscorrientes: 689.655172413793, Medianaporm2apreciosconstantes: 278.118017591604},
 {X: 3140, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 232473.684210526, Medianaapreciosconstantes: 93749.9242825297, Medianaporm2aprecioscorrientes: 1890.93427348275, Medianaporm2apreciosconstantes: 762.559192728712},
 {X: 3512, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 38000.0, Medianaapreciosconstantes: 15324.3027692974, Medianaporm2aprecioscorrientes: 557.142857142857, Medianaporm2apreciosconstantes: 224.679627068646},
 {X: 3945, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 27000.0, Medianaapreciosconstantes: 10888.3203887113, Medianaporm2aprecioscorrientes: 537.634408602151, Medianaporm2apreciosconstantes: 216.812433068724},
 {X: 4208, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 8065.42251015652, Medianaporm2aprecioscorrientes: 428.571428571429, Medianaporm2apreciosconstantes: 172.830482360497},
 {X: 4482, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 21000.0, Medianaapreciosconstantes: 8468.69363566434, Medianaporm2aprecioscorrientes: 466.666666666667, Medianaporm2apreciosconstantes: 188.193191903652},
 {X: 4661, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 21500.0, Medianaapreciosconstantes: 8670.32919841826, Medianaporm2aprecioscorrientes: 463.188798554652, Medianaporm2apreciosconstantes: 186.790668115752},
 {X: 4992, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7258.88025914086, Medianaporm2aprecioscorrientes: 395.348837209302, Medianaporm2apreciosconstantes: 159.432770549605},
 {X: 5092, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2020-05", Medianaaprecioscorrientes: 21000.0, Medianaapreciosconstantes: 8468.69363566434, Medianaporm2aprecioscorrientes: 470.588235294118, Medianaporm2apreciosconstantes: 189.774647297801},
 {X: 42, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 18500.0, Medianaapreciosconstantes: 7299.91763573958, Medianaporm2aprecioscorrientes: 447.368421052632, Medianaporm2apreciosconstantes: 176.527169000815},
 {X: 211, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7102.62256450337, Medianaporm2aprecioscorrientes: 407.575757575758, Medianaporm2apreciosconstantes: 160.825376250119},
 {X: 603, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 13810.6549865343, Medianaporm2aprecioscorrientes: 560.487804878049, Medianaporm2apreciosconstantes: 221.162962780878},
 {X: 1088, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 7891.80284944819, Medianaporm2aprecioscorrientes: 447.410008779631, Medianaporm2apreciosconstantes: 176.543579107937},
 {X: 1425, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 23000.0, Medianaapreciosconstantes: 9075.57327686542, Medianaporm2aprecioscorrientes: 476.190476190476, Medianaporm2apreciosconstantes: 187.900067844005},
 {X: 1513, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7102.62256450337, Medianaporm2aprecioscorrientes: 427.619047619048, Medianaporm2apreciosconstantes: 168.734260923916},
 {X: 1608, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 7102.62256450337, Medianaporm2aprecioscorrientes: 380.349608197709, Medianaporm2apreciosconstantes: 150.082206088059},
 {X: 1915, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 19500.0, Medianaapreciosconstantes: 7694.50777821199, Medianaporm2aprecioscorrientes: 458.333333333333, Medianaporm2apreciosconstantes: 180.853815299854},
 {X: 2164, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 11048.5239892275, Medianaporm2aprecioscorrientes: 554.21686746988, Medianaporm2apreciosconstantes: 218.688512695553},
 {X: 2501, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 36000.0, Medianaapreciosconstantes: 14205.2451290067, Medianaporm2aprecioscorrientes: 650.0, Medianaporm2apreciosconstantes: 256.483592607066},
 {X: 3137, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 230555.714285714, Medianaapreciosconstantes: 90975.0121478281, Medianaporm2aprecioscorrientes: 1817.36694677871, Medianaporm2apreciosconstantes: 717.115082454059},
 {X: 3484, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 11837.7042741723, Medianaporm2aprecioscorrientes: 550.36231884058, Medianaporm2apreciosconstantes: 217.16754580275},
 {X: 3952, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 24750.0, Medianaapreciosconstantes: 9766.10602619214, Medianaporm2aprecioscorrientes: 507.142857142857, Medianaporm2apreciosconstantes: 200.113572253865},
 {X: 4200, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 19850.0, Medianaapreciosconstantes: 7832.61432807733, Medianaporm2aprecioscorrientes: 435.731132075472, Medianaporm2apreciosconstantes: 171.935209485325},
 {X: 4461, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 8680.98313439301, Medianaporm2aprecioscorrientes: 500.0, Medianaporm2apreciosconstantes: 197.295071236205},
 {X: 4594, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 21000.0, Medianaapreciosconstantes: 8286.3929919206, Medianaporm2aprecioscorrientes: 453.333333333333, Medianaporm2apreciosconstantes: 178.880864587492},
 {X: 4958, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 17500.0, Medianaapreciosconstantes: 6905.32749326717, Medianaporm2aprecioscorrientes: 406.84591914569, Medianaporm2apreciosconstantes: 160.537389200016},
 {X: 5052, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2020-06", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 7891.80284944819, Medianaporm2aprecioscorrientes: 479.166666666667, Medianaporm2apreciosconstantes: 189.07444326803},
 {X: 62, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 20300.0, Medianaapreciosconstantes: 7860.82422938429, Medianaporm2aprecioscorrientes: 489.342403628118, Medianaporm2apreciosconstantes: 189.48939029089},
 {X: 190, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 19000.0, Medianaapreciosconstantes: 7357.42169252717, Medianaporm2aprecioscorrientes: 439.393939393939, Medianaporm2apreciosconstantes: 170.147710592733},
 {X: 638, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 37000.0, Medianaapreciosconstantes: 14327.610664395, Medianaporm2aprecioscorrientes: 612.903225806452, Medianaporm2apreciosconstantes: 237.336183629909},
 {X: 1066, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 8519.11985450514, Medianaporm2aprecioscorrientes: 484.385304729022, Medianaporm2apreciosconstantes: 187.569839397615},
 {X: 1429, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 24000.0, Medianaapreciosconstantes: 9293.58529582379, Medianaporm2aprecioscorrientes: 529.411764705882, Medianaporm2apreciosconstantes: 205.005557996113},
 {X: 1515, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 7744.65441318649, Medianaporm2aprecioscorrientes: 446.360153256705, Medianaporm2apreciosconstantes: 172.845256539507},
 {X: 1601, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 7744.65441318649, Medianaporm2aprecioscorrientes: 422.680412371134, Medianaporm2apreciosconstantes: 163.67568605188},
 {X: 1866, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 20500.0, Medianaapreciosconstantes: 7938.27077351616, Medianaporm2aprecioscorrientes: 470.238095238095, Medianaporm2apreciosconstantes: 182.091576976706},
 {X: 2181, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 29750.0, Medianaapreciosconstantes: 11520.1734396149, Medianaporm2aprecioscorrientes: 614.709851551957, Medianaporm2apreciosconstantes: 238.035768232554},
 {X: 2519, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 36000.0, Medianaapreciosconstantes: 13940.3779437357, Medianaporm2aprecioscorrientes: 676.470588235294, Medianaporm2apreciosconstantes: 261.951546328367},
 {X: 3150, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 246347.826086957, Medianaapreciosconstantes: 95393.9389241625, Medianaporm2aprecioscorrientes: 1901.75824428887, Medianaporm2apreciosconstantes: 736.42301897228},
 {X: 3499, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 11616.9816197797, Medianaporm2aprecioscorrientes: 571.428571428571, Medianaporm2apreciosconstantes: 221.275840376757},
 {X: 3892, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 9680.81801648312, Medianaporm2aprecioscorrientes: 537.634408602151, Medianaporm2apreciosconstantes: 208.189634763078},
 {X: 4179, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 6970.18897186784, Medianaporm2aprecioscorrientes: 462.019230769231, Medianaporm2apreciosconstantes: 178.908963727698},
 {X: 4442, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 24000.0, Medianaapreciosconstantes: 9293.58529582379, Medianaporm2aprecioscorrientes: 535.714285714286, Medianaporm2apreciosconstantes: 207.44610035321},
 {X: 4644, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 21000.0, Medianaapreciosconstantes: 8131.88713384582, Medianaporm2aprecioscorrientes: 507.142857142857, Medianaporm2apreciosconstantes: 196.382308334372},
 {X: 5002, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 18000.0, Medianaapreciosconstantes: 6970.18897186784, Medianaporm2aprecioscorrientes: 444.444444444444, Medianaporm2apreciosconstantes: 172.103431404144},
 {X: 5031, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2020-07", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 8519.11985450514, Medianaporm2aprecioscorrientes: 516.705111987499, Medianaporm2apreciosconstantes: 200.0851262935},
 {X: 40, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 7541.04616880712, Medianaporm2aprecioscorrientes: 529.857397504456, Medianaporm2apreciosconstantes: 199.783954873255},
 {X: 207, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 19000.0, Medianaapreciosconstantes: 7163.99386036677, Medianaporm2aprecioscorrientes: 464.285714285714, Medianaporm2apreciosconstantes: 175.060000347308},
 {X: 644, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 13196.8307954125, Medianaporm2aprecioscorrientes: 640.0, Medianaporm2apreciosconstantes: 241.313477401828},
 {X: 1073, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 23000.0, Medianaapreciosconstantes: 8672.20309412819, Medianaporm2aprecioscorrientes: 518.999518999519, Medianaporm2apreciosconstantes: 195.689966718203},
 {X: 1442, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 25500.0, Medianaapreciosconstantes: 9614.83386522908, Medianaporm2aprecioscorrientes: 594.439728353141, Medianaporm2apreciosconstantes: 224.13487180421},
 {X: 1533, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 21000.0, Medianaapreciosconstantes: 7918.09847724748, Medianaporm2aprecioscorrientes: 505.747126436782, Medianaporm2apreciosconstantes: 190.693121510065},
 {X: 1618, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 7541.04616880712, Medianaporm2aprecioscorrientes: 461.538461538462, Medianaporm2apreciosconstantes: 174.024142357088},
 {X: 1871, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 8295.15078568784, Medianaporm2aprecioscorrientes: 538.461538461538, Medianaporm2apreciosconstantes: 203.028166083269},
 {X: 2178, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 11311.5692532107, Medianaporm2aprecioscorrientes: 614.510489510489, Medianaporm2apreciosconstantes: 231.702598630743},
 {X: 2511, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 36000.0, Medianaapreciosconstantes: 13573.8831038528, Medianaporm2aprecioscorrientes: 704.385403329065, Medianaporm2apreciosconstantes: 265.590142356915},
 {X: 3114, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 218756.0, Medianaapreciosconstantes: 82482.4547851786, Medianaporm2aprecioscorrientes: 1908.98901098901, Medianaporm2apreciosconstantes: 719.788713380679},
 {X: 3508, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 11311.5692532107, Medianaporm2aprecioscorrientes: 583.333333333333, Medianaporm2apreciosconstantes: 219.947179923541},
 {X: 3910, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 29000.0, Medianaapreciosconstantes: 10934.5169447703, Medianaporm2aprecioscorrientes: 553.191489361702, Medianaporm2apreciosconstantes: 208.582128073389},
 {X: 4214, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 7541.04616880712, Medianaporm2aprecioscorrientes: 465.144230769231, Medianaporm2apreciosconstantes: 175.383705969252},
 {X: 4471, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 23000.0, Medianaapreciosconstantes: 8672.20309412819, Medianaporm2aprecioscorrientes: 539.393939393939, Medianaporm2apreciosconstantes: 203.379730007222},
 {X: 4621, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 8295.15078568784, Medianaporm2aprecioscorrientes: 527.027027027027, Medianaporm2apreciosconstantes: 198.716757150999},
 {X: 4959, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 7541.04616880712, Medianaporm2aprecioscorrientes: 473.349056603774, Medianaporm2apreciosconstantes: 178.477354490518},
 {X: 5066, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2020-08", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 8295.15078568784, Medianaporm2aprecioscorrientes: 540.740740740741, Medianaporm2apreciosconstantes: 203.887544564045},
 {X: 74, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 21000.0, Medianaapreciosconstantes: 7702.43042386896, Medianaporm2aprecioscorrientes: 547.619047619048, Medianaporm2apreciosconstantes: 200.857029193862},
 {X: 231, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 7335.64802273234, Medianaporm2aprecioscorrientes: 494.623655913978, Medianaporm2apreciosconstantes: 181.419252175101},
 {X: 676, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 38000.0, Medianaapreciosconstantes: 13937.7312431914, Medianaporm2aprecioscorrientes: 666.666666666667, Medianaporm2apreciosconstantes: 244.521600757745},
 {X: 1095, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 24000.0, Medianaapreciosconstantes: 8802.77762727881, Medianaporm2aprecioscorrientes: 564.523589269195, Medianaporm2apreciosconstantes: 207.057317570417},
 {X: 1422, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 27000.0, Medianaapreciosconstantes: 9903.12483068866, Medianaporm2aprecioscorrientes: 611.111111111111, Medianaporm2apreciosconstantes: 224.144800694599},
 {X: 1516, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 24000.0, Medianaapreciosconstantes: 8802.77762727881, Medianaporm2aprecioscorrientes: 523.733583489681, Medianaporm2apreciosconstantes: 192.09626130823},
 {X: 1596, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 21250.0, Medianaapreciosconstantes: 7794.12602415311, Medianaporm2aprecioscorrientes: 491.330587220998, Medianaporm2apreciosconstantes: 180.211412532782},
 {X: 1906, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 7335.64802273234, Medianaporm2aprecioscorrientes: 558.257918552036, Medianaporm2apreciosconstantes: 204.759179820046},
 {X: 2191, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 11003.4720340985, Medianaporm2aprecioscorrientes: 638.772175536881, Medianaporm2apreciosconstantes: 234.290392322678},
 {X: 2531, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 36000.0, Medianaapreciosconstantes: 13204.1664409182, Medianaporm2aprecioscorrientes: 726.599326599327, Medianaporm2apreciosconstantes: 266.50384567435},
 {X: 3118, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 199159.090909091, Medianaapreciosconstantes: 73048.0495718222, Medianaporm2aprecioscorrientes: 1926.23645784268, Medianaporm2apreciosconstantes: 706.50963316443},
 {X: 3476, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 29950.0, Medianaapreciosconstantes: 10985.1329140417, Medianaporm2aprecioscorrientes: 600.0, Medianaporm2apreciosconstantes: 220.06944068197},
 {X: 3900, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 29000.0, Medianaapreciosconstantes: 10636.6896329619, Medianaporm2aprecioscorrientes: 545.454545454545, Medianaporm2apreciosconstantes: 200.0631278927},
 {X: 4153, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 7335.64802273234, Medianaporm2aprecioscorrientes: 528.705882352941, Medianaporm2apreciosconstantes: 193.920013024465},
 {X: 4441, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 23000.0, Medianaapreciosconstantes: 8435.99522614219, Medianaporm2aprecioscorrientes: 547.368421052632, Medianaporm2apreciosconstantes: 200.765103780043},
 {X: 4630, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 8069.21282500557, Medianaporm2aprecioscorrientes: 571.428571428571, Medianaporm2apreciosconstantes: 209.589943506638},
 {X: 4976, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 8069.21282500557, Medianaporm2aprecioscorrientes: 514.285714285714, Medianaporm2apreciosconstantes: 188.630949155974},
 {X: 5060, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2020-09", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 9169.56002841542, Medianaporm2aprecioscorrientes: 610.687022900763, Medianaporm2apreciosconstantes: 223.989252602514},
 {X: 39, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 7773.80811721298, Medianaporm2aprecioscorrientes: 576.923076923077, Medianaporm2apreciosconstantes: 203.858604472368},
 {X: 234, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 20000.0, Medianaapreciosconstantes: 7067.09828837544, Medianaporm2aprecioscorrientes: 514.955357142857, Medianaporm2apreciosconstantes: 181.962006152702},
 {X: 655, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 38000.0, Medianaapreciosconstantes: 13427.4867479133, Medianaporm2aprecioscorrientes: 700.0, Medianaporm2apreciosconstantes: 247.34844009314},
 {X: 1087, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 8833.8728604693, Medianaporm2aprecioscorrientes: 587.5, Medianaporm2apreciosconstantes: 207.596012221028},
 {X: 1421, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 28900.0, Medianaapreciosconstantes: 10211.9570267025, Medianaporm2aprecioscorrientes: 603.174603174603, Medianaporm2apreciosconstantes: 213.134710284339},
 {X: 1553, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 8833.8728604693, Medianaporm2aprecioscorrientes: 564.705882352941, Medianaporm2apreciosconstantes: 199.541598730601},
 {X: 1635, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 23000.0, Medianaapreciosconstantes: 8127.16303163175, Medianaporm2aprecioscorrientes: 518.257491675916, Medianaporm2apreciosconstantes: 183.128831618031},
 {X: 1854, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 7773.80811721298, Medianaporm2aprecioscorrientes: 525.0, Medianaporm2apreciosconstantes: 185.511330069855},
 {X: 2162, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 32000.0, Medianaapreciosconstantes: 11307.3572614007, Medianaporm2aprecioscorrientes: 705.882352941176, Medianaporm2apreciosconstantes: 249.426998413251},
 {X: 2486, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 38000.0, Medianaapreciosconstantes: 13427.4867479133, Medianaporm2aprecioscorrientes: 757.575757575758, Medianaporm2apreciosconstantes: 267.693116983918},
 {X: 3148, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 246928.571428571, Medianaapreciosconstantes: 87253.4242246923, Medianaporm2aprecioscorrientes: 2400.69444444444, Medianaporm2apreciosconstantes: 848.297179962286},
 {X: 3509, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 10600.6474325632, Medianaporm2aprecioscorrientes: 634.76800976801, Medianaporm2apreciosconstantes: 224.298395767349},
 {X: 3962, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 10600.6474325632, Medianaporm2aprecioscorrientes: 572.172619047619, Medianaporm2apreciosconstantes: 202.180006836336},
 {X: 4228, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 7773.80811721298, Medianaporm2aprecioscorrientes: 547.945205479452, Medianaporm2apreciosconstantes: 193.619131188368},
 {X: 4459, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 8833.8728604693, Medianaporm2aprecioscorrientes: 513.523391812865, Medianaporm2apreciosconstantes: 181.456014166072},
 {X: 4623, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 24000.0, Medianaapreciosconstantes: 8480.51794605053, Medianaporm2aprecioscorrientes: 581.231671554252, Medianaporm2apreciosconstantes: 205.381067559532},
 {X: 4941, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 24000.0, Medianaapreciosconstantes: 8480.51794605053, Medianaporm2aprecioscorrientes: 558.823529411765, Medianaporm2apreciosconstantes: 197.46304041049},
 {X: 5021, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2020-10", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 9187.22777488807, Medianaporm2aprecioscorrientes: 626.865671641791, Medianaporm2apreciosconstantes: 221.506065755051},
 {X: 82, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 23000.0, Medianaapreciosconstantes: 7875.15797755222, Medianaporm2aprecioscorrientes: 595.744680851064, Medianaporm2apreciosconstantes: 203.981890260372},
 {X: 255, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 21000.0, Medianaapreciosconstantes: 7190.36163167811, Medianaporm2aprecioscorrientes: 538.893690579084, Medianaporm2apreciosconstantes: 184.516215061584},
 {X: 677, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 38000.0, Medianaapreciosconstantes: 13011.130571608, Medianaporm2aprecioscorrientes: 724.390901165095, Medianaporm2apreciosconstantes: 248.030121051154},
 {X: 1116, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 8559.95432342633, Medianaporm2aprecioscorrientes: 609.035326086957, Medianaporm2apreciosconstantes: 208.532582906296},
 {X: 1490, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 10271.9451881116, Medianaporm2aprecioscorrientes: 642.857142857143, Medianaporm2apreciosconstantes: 220.11311117382},
 {X: 1537, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 25500.0, Medianaapreciosconstantes: 8731.15340989485, Medianaporm2aprecioscorrientes: 589.278937381404, Medianaporm2apreciosconstantes: 201.768031509681},
 {X: 1616, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 23500.0, Medianaapreciosconstantes: 8046.35706402075, Medianaporm2aprecioscorrientes: 536.585365853659, Medianaporm2apreciosconstantes: 183.725848893053},
 {X: 1919, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 8559.95432342633, Medianaporm2aprecioscorrientes: 555.555555555556, Medianaporm2apreciosconstantes: 190.221207187252},
 {X: 2156, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 32750.0, Medianaapreciosconstantes: 11213.5401636885, Medianaporm2aprecioscorrientes: 697.108701695858, Medianaporm2apreciosconstantes: 238.688745799183},
 {X: 2516, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 38000.0, Medianaapreciosconstantes: 13011.130571608, Medianaporm2aprecioscorrientes: 777.777777777778, Medianaporm2apreciosconstantes: 266.309690062152},
 {X: 3149, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 260000.0, Medianaapreciosconstantes: 89023.5249636338, Medianaporm2aprecioscorrientes: 2473.29523809524, Medianaporm2apreciosconstantes: 846.851770657724},
 {X: 3523, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 10271.9451881116, Medianaporm2aprecioscorrientes: 642.857142857143, Medianaporm2apreciosconstantes: 220.11311117382},
 {X: 3957, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 10271.9451881116, Medianaporm2aprecioscorrientes: 577.254641909814, Medianaporm2apreciosconstantes: 197.650934709353},
 {X: 4196, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 7532.75980461517, Medianaporm2aprecioscorrientes: 564.583333333333, Medianaporm2apreciosconstantes: 193.312301804044},
 {X: 4428, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 8902.35249636338, Medianaporm2aprecioscorrientes: 529.411764705882, Medianaporm2apreciosconstantes: 181.269620966675},
 {X: 4618, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 8559.95432342633, Medianaporm2aprecioscorrientes: 606.060606060606, Medianaporm2apreciosconstantes: 207.514044204275},
 {X: 4979, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 8559.95432342633, Medianaporm2aprecioscorrientes: 574.712643678161, Medianaporm2apreciosconstantes: 196.780559159226},
 {X: 5073, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2020-11", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 8902.35249636338, Medianaporm2aprecioscorrientes: 625.0, Medianaporm2apreciosconstantes: 213.998858085658},
 {X: 38, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 23500.0, Medianaapreciosconstantes: 7736.88179049365, Medianaporm2aprecioscorrientes: 615.384615384615, Medianaporm2apreciosconstantes: 202.602469145496},
 {X: 233, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 21000.0, Medianaapreciosconstantes: 6913.80925959007, Medianaporm2aprecioscorrientes: 551.393983859134, Medianaporm2apreciosconstantes: 181.534896727978},
 {X: 654, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 13169.1604944573, Medianaporm2aprecioscorrientes: 740.740740740741, Medianaporm2apreciosconstantes: 243.87334248995},
 {X: 1055, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 8230.7253090358, Medianaporm2aprecioscorrientes: 619.0128637898, Medianaporm2apreciosconstantes: 203.796993784537},
 {X: 1449, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 9876.87037084296, Medianaporm2aprecioscorrientes: 650.70564516129, Medianaporm2apreciosconstantes: 214.23117689446},
 {X: 1521, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 9218.4123461201, Medianaporm2aprecioscorrientes: 632.911392405063, Medianaporm2apreciosconstantes: 208.372792633818},
 {X: 1634, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 8230.7253090358, Medianaporm2aprecioscorrientes: 560.0, Medianaporm2apreciosconstantes: 184.368246922402},
 {X: 1864, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 8230.7253090358, Medianaporm2aprecioscorrientes: 561.797752808989, Medianaporm2apreciosconstantes: 184.960119304175},
 {X: 2163, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 33000.0, Medianaapreciosconstantes: 10864.5574079273, Medianaporm2aprecioscorrientes: 706.564163217031, Medianaporm2apreciosconstantes: 232.621421625925},
 {X: 2498, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 13169.1604944573, Medianaporm2aprecioscorrientes: 833.333333333333, Medianaporm2apreciosconstantes: 274.357510301193},
 {X: 3146, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 264694.736842105, Medianaapreciosconstantes: 87145.1867877954, Medianaporm2aprecioscorrientes: 2492.41748438894, Medianaporm2apreciosconstantes: 820.576146777736},
 {X: 3498, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 33000.0, Medianaapreciosconstantes: 10864.5574079273, Medianaporm2aprecioscorrientes: 659.722222222222, Medianaporm2apreciosconstantes: 217.199695655111},
 {X: 3924, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 11523.0154326501, Medianaporm2aprecioscorrientes: 644.177840508116, Medianaporm2apreciosconstantes: 212.082034215607},
 {X: 4148, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 7243.0382719515, Medianaporm2aprecioscorrientes: 571.428571428571, Medianaporm2apreciosconstantes: 188.130864206532},
 {X: 4410, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 8559.95432139723, Medianaporm2aprecioscorrientes: 579.159212880143, Medianaporm2apreciosconstantes: 190.676015656554},
 {X: 4596, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 8230.7253090358, Medianaporm2aprecioscorrientes: 635.294117647059, Medianaporm2apreciosconstantes: 209.157254911969},
 {X: 4953, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 8230.7253090358, Medianaporm2aprecioscorrientes: 583.974358974359, Medianaporm2apreciosconstantes: 192.261301449529},
 {X: 5011, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2020-12", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 8559.95432139723, Medianaporm2aprecioscorrientes: 636.363636363636, Medianaporm2apreciosconstantes: 209.509371502729},
 {X: 83, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 7914.15895209832, Medianaporm2aprecioscorrientes: 619.047619047619, Medianaporm2apreciosconstantes: 195.969650242435},
 {X: 246, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 23000.0, Medianaapreciosconstantes: 7281.02623593045, Medianaporm2aprecioscorrientes: 574.074074074074, Medianaporm2apreciosconstantes: 181.732538900035},
 {X: 683, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 40950.0, Medianaapreciosconstantes: 12963.392363537, Medianaporm2aprecioscorrientes: 765.986394557823, Medianaporm2apreciosconstantes: 242.485523267012},
 {X: 1106, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 8230.72531018225, Medianaporm2aprecioscorrientes: 638.888888888889, Medianaporm2apreciosconstantes: 202.250728775846},
 {X: 1485, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 9496.99074251798, Medianaporm2aprecioscorrientes: 695.351351351351, Medianaporm2apreciosconstantes: 220.124844886038},
 {X: 1572, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 9496.99074251798, Medianaporm2aprecioscorrientes: 661.603613777527, Medianaporm2apreciosconstantes: 209.441446508721},
 {X: 1602, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 7914.15895209832, Medianaporm2aprecioscorrientes: 563.380281690141, Medianaporm2apreciosconstantes: 178.347243990948},
 {X: 1892, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 7914.15895209832, Medianaporm2aprecioscorrientes: 594.444444444444, Medianaporm2apreciosconstantes: 188.181112861004},
 {X: 2170, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 36000.0, Medianaapreciosconstantes: 11396.3888910216, Medianaporm2aprecioscorrientes: 735.294117647059, Medianaporm2apreciosconstantes: 232.769380944068},
 {X: 2508, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 43000.0, Medianaapreciosconstantes: 13612.3533976091, Medianaporm2aprecioscorrientes: 862.068965517241, Medianaporm2apreciosconstantes: 272.902032830976},
 {X: 3158, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 304800.0, Medianaapreciosconstantes: 96489.4259439827, Medianaporm2aprecioscorrientes: 2600.0, Medianaporm2apreciosconstantes: 823.072531018225},
 {X: 3505, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 11079.8225329376, Medianaporm2aprecioscorrientes: 684.210526315789, Medianaporm2apreciosconstantes: 216.59803447848},
 {X: 3947, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 33250.0, Medianaapreciosconstantes: 10525.8314062908, Medianaporm2aprecioscorrientes: 621.137131820377, Medianaporm2apreciosconstantes: 196.631119691076},
 {X: 4159, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 22000.0, Medianaapreciosconstantes: 6964.45987784652, Medianaporm2aprecioscorrientes: 588.712241653418, Medianaporm2apreciosconstantes: 186.366490299651},
 {X: 4466, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 7914.15895209832, Medianaporm2aprecioscorrientes: 622.222222222222, Medianaporm2apreciosconstantes: 196.97462280778},
 {X: 4614, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 8230.72531018225, Medianaporm2aprecioscorrientes: 645.161290322581, Medianaporm2apreciosconstantes: 204.23636005415},
 {X: 4951, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 25500.0, Medianaapreciosconstantes: 8072.44213114029, Medianaporm2aprecioscorrientes: 600.0, Medianaporm2apreciosconstantes: 189.93981485036},
 {X: 5048, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2021-01", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 8230.72531018225, Medianaporm2aprecioscorrientes: 666.666666666667, Medianaporm2apreciosconstantes: 211.044238722622},
 {X: 35, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 7639.14956765313, Medianaporm2aprecioscorrientes: 640.0, Medianaporm2apreciosconstantes: 195.56222893192},
 {X: 244, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 24000.0, Medianaapreciosconstantes: 7333.58358494701, Medianaporm2aprecioscorrientes: 600.0, Medianaporm2apreciosconstantes: 183.339589623675},
 {X: 686, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 15278.2991353063, Medianaporm2aprecioscorrientes: 819.672131147541, Medianaporm2apreciosconstantes: 250.463920250922},
 {X: 1107, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 26850.0, Medianaapreciosconstantes: 8204.44663565946, Medianaporm2aprecioscorrientes: 652.475845410628, Medianaporm2apreciosconstantes: 199.374422894908},
 {X: 1440, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 32000.0, Medianaapreciosconstantes: 9778.11144659601, Medianaporm2aprecioscorrientes: 733.333333333333, Medianaporm2apreciosconstantes: 224.081720651158},
 {X: 1509, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 32000.0, Medianaapreciosconstantes: 9778.11144659601, Medianaporm2aprecioscorrientes: 670.833333333333, Medianaporm2apreciosconstantes: 204.983846732026},
 {X: 1592, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 7639.14956765313, Medianaporm2aprecioscorrientes: 585.365853658537, Medianaporm2apreciosconstantes: 178.867892315781},
 {X: 1893, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 7639.14956765313, Medianaporm2aprecioscorrientes: 574.522178146412, Medianaporm2apreciosconstantes: 175.554433951772},
 {X: 2118, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 12222.639308245, Medianaporm2aprecioscorrientes: 796.780813133256, Medianaporm2apreciosconstantes: 243.469112166449},
 {X: 2507, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 49000.0, Medianaapreciosconstantes: 14972.7331526001, Medianaporm2aprecioscorrientes: 906.828703703704, Medianaporm2apreciosconstantes: 277.096003993344},
 {X: 3157, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 260314.285714286, Medianaapreciosconstantes: 79543.1905267289, Medianaporm2aprecioscorrientes: 2410.31746031746, Medianaporm2apreciosconstantes: 736.511023395637},
 {X: 3520, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 38000.0, Medianaapreciosconstantes: 11611.5073428328, Medianaporm2aprecioscorrientes: 725.031928480204, Medianaporm2apreciosconstantes: 221.545093719371},
 {X: 3974, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 10694.8093947144, Medianaporm2aprecioscorrientes: 644.009216589862, Medianaporm2apreciosconstantes: 196.787309139083},
 {X: 4230, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 24000.0, Medianaapreciosconstantes: 7333.58358494701, Medianaporm2aprecioscorrientes: 597.222222222222, Medianaporm2apreciosconstantes: 182.490795227269},
 {X: 4413, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 7944.71555035926, Medianaporm2aprecioscorrientes: 616.902834008097, Medianaporm2apreciosconstantes: 188.504520707878},
 {X: 4593, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 28750.0, Medianaapreciosconstantes: 8785.0220028011, Medianaporm2aprecioscorrientes: 666.666666666667, Medianaporm2apreciosconstantes: 203.710655137417},
 {X: 4928, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 25300.0, Medianaapreciosconstantes: 7730.81936246497, Medianaporm2aprecioscorrientes: 631.006864988558, Medianaporm2apreciosconstantes: 192.81423279454},
 {X: 5036, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2021-02", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 8555.84751577151, Medianaporm2aprecioscorrientes: 698.262952979934, Medianaporm2apreciosconstantes: 213.365405414595},
 {X: 55, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 27000.0, Medianaapreciosconstantes: 7872.40604188118, Medianaporm2aprecioscorrientes: 671.641791044776, Medianaporm2apreciosconstantes: 195.830996066696},
 {X: 195, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 7289.26485359369, Medianaporm2aprecioscorrientes: 615.384615384615, Medianaporm2apreciosconstantes: 179.428057934614},
 {X: 645, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 54500.0, Medianaapreciosconstantes: 15890.5973808342, Medianaporm2aprecioscorrientes: 888.888888888889, Medianaporm2apreciosconstantes: 259.173861461109},
 {X: 1138, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 8163.97663602493, Medianaporm2aprecioscorrientes: 683.235566532881, Medianaporm2apreciosconstantes: 199.211400074132},
 {X: 1460, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 10204.9707950312, Medianaporm2aprecioscorrientes: 734.331797235023, Medianaporm2apreciosconstantes: 214.109558418462},
 {X: 1512, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 32000.0, Medianaapreciosconstantes: 9330.25901259992, Medianaporm2aprecioscorrientes: 758.655899006776, Medianaporm2apreciosconstantes: 221.201751224065},
 {X: 1610, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 7580.83544773744, Medianaporm2aprecioscorrientes: 626.506024096386, Medianaporm2apreciosconstantes: 182.67073368042},
 {X: 1858, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 7289.26485359369, Medianaporm2aprecioscorrientes: 620.192307692308, Medianaporm2apreciosconstantes: 180.829839637228},
 {X: 2140, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 42000.0, Medianaapreciosconstantes: 12245.9649540374, Medianaporm2aprecioscorrientes: 884.955752212389, Medianaporm2apreciosconstantes: 258.027074463493},
 {X: 2474, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 14578.5297071874, Medianaporm2aprecioscorrientes: 964.516129032258, Medianaporm2apreciosconstantes: 281.224540803163},
 {X: 3105, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 277230.769230769, Medianaapreciosconstantes: 80832.3400995435, Medianaporm2aprecioscorrientes: 2410.70234113712, Medianaporm2apreciosconstantes: 702.889913909073},
 {X: 3521, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 11662.8237657499, Medianaporm2aprecioscorrientes: 760.0, Medianaporm2apreciosconstantes: 221.593651549248},
 {X: 3963, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 10204.9707950312, Medianaporm2aprecioscorrientes: 714.285714285714, Medianaporm2apreciosconstantes: 208.264710102677},
 {X: 4233, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 24000.0, Medianaapreciosconstantes: 6997.69425944994, Medianaporm2aprecioscorrientes: 622.361111111111, Medianaporm2apreciosconstantes: 181.46219893863},
 {X: 4453, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 26500.0, Medianaapreciosconstantes: 7726.62074480931, Medianaporm2aprecioscorrientes: 637.68115942029, Medianaporm2apreciosconstantes: 185.929074526448},
 {X: 4629, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 29150.0, Medianaapreciosconstantes: 8499.28281929024, Medianaporm2aprecioscorrientes: 707.106782106782, Medianaporm2apreciosconstantes: 206.171544581948},
 {X: 4938, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 27000.0, Medianaapreciosconstantes: 7872.40604188118, Medianaporm2aprecioscorrientes: 666.666666666667, Medianaporm2apreciosconstantes: 194.380396095832},
 {X: 5032, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2021-03", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 8163.97663602493, Medianaporm2aprecioscorrientes: 742.857142857143, Medianaporm2apreciosconstantes: 216.595298506784},
 {X: 34, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 7842.43673198683, Medianaporm2aprecioscorrientes: 700.0, Medianaporm2apreciosconstantes: 196.060918299671},
 {X: 242, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 7282.26267970205, Medianaporm2aprecioscorrientes: 650.0, Medianaporm2apreciosconstantes: 182.056566992551},
 {X: 664, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 14004.3513071193, Medianaporm2aprecioscorrientes: 918.103448275862, Medianaporm2apreciosconstantes: 257.148864518657},
 {X: 1108, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 8402.6107842716, Medianaporm2aprecioscorrientes: 714.285714285714, Medianaporm2apreciosconstantes: 200.062161530276},
 {X: 1479, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 32000.0, Medianaapreciosconstantes: 8962.78483655637, Medianaporm2aprecioscorrientes: 783.783783783784, Medianaporm2apreciosconstantes: 219.527669138627},
 {X: 1550, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 32000.0, Medianaapreciosconstantes: 8962.78483655637, Medianaporm2aprecioscorrientes: 754.248366013072, Medianaporm2apreciosconstantes: 211.255181809356},
 {X: 1613, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 7282.26267970205, Medianaporm2aprecioscorrientes: 655.586206896552, Medianaporm2apreciosconstantes: 183.621191069623},
 {X: 1851, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 26500.0, Medianaapreciosconstantes: 7422.30619277325, Medianaporm2aprecioscorrientes: 631.578947368421, Medianaporm2apreciosconstantes: 176.89706914256},
 {X: 2114, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 42000.0, Medianaapreciosconstantes: 11763.6550979802, Medianaporm2aprecioscorrientes: 923.076923076923, Medianaporm2apreciosconstantes: 258.54187028528},
 {X: 2463, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 14004.3513071193, Medianaporm2aprecioscorrientes: 1011.90476190476, Medianaporm2apreciosconstantes: 283.421395501224},
 {X: 3107, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 310750.0, Medianaapreciosconstantes: 87037.0433737467, Medianaporm2aprecioscorrientes: 2500.0, Medianaporm2apreciosconstantes: 700.217565355967},
 {X: 3448, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 42000.0, Medianaapreciosconstantes: 11763.6550979802, Medianaporm2aprecioscorrientes: 811.781609195402, Medianaporm2apreciosconstantes: 227.369496796621},
 {X: 3918, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 9803.04591498353, Medianaporm2aprecioscorrientes: 750.0, Medianaporm2apreciosconstantes: 210.06526960679},
 {X: 4222, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 7002.17565355967, Medianaporm2aprecioscorrientes: 642.857142857143, Medianaporm2apreciosconstantes: 180.055945377249},
 {X: 4421, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 7842.43673198683, Medianaporm2aprecioscorrientes: 680.0, Medianaporm2apreciosconstantes: 190.459177776823},
 {X: 4587, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 8402.6107842716, Medianaporm2aprecioscorrientes: 736.842105263158, Medianaporm2apreciosconstantes: 206.379913999653},
 {X: 4956, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 28500.0, Medianaapreciosconstantes: 7982.48024505802, Medianaporm2aprecioscorrientes: 690.47619047619, Medianaporm2apreciosconstantes: 193.3934228126},
 {X: 5022, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2021-04", Medianaaprecioscorrientes: 34750.0, Medianaapreciosconstantes: 9733.02415844794, Medianaporm2aprecioscorrientes: 807.481125600549, Medianaporm2apreciosconstantes: 226.164987135565},
 {X: 14, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 29000.0, Medianaapreciosconstantes: 7863.04332688196, Medianaporm2aprecioscorrientes: 739.428231089935, Medianaporm2apreciosconstantes: 200.488145454477},
 {X: 209, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 26000.0, Medianaapreciosconstantes: 7049.62505168727, Medianaporm2aprecioscorrientes: 657.894736842105, Medianaporm2apreciosconstantes: 178.381200700589},
 {X: 642, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 55000.0, Medianaapreciosconstantes: 14912.6683785692, Medianaporm2aprecioscorrientes: 968.585526315789, Medianaporm2apreciosconstantes: 262.621722731442},
 {X: 1062, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 33000.0, Medianaapreciosconstantes: 8947.60102714154, Medianaporm2aprecioscorrientes: 745.283018867925, Medianaporm2apreciosconstantes: 202.075609246479},
 {X: 1501, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 31500.0, Medianaapreciosconstantes: 8540.8918895442, Medianaporm2aprecioscorrientes: 803.225806451613, Medianaporm2apreciosconstantes: 217.786183358577},
 {X: 1563, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 9489.87987727133, Medianaporm2aprecioscorrientes: 830.242966751918, Medianaporm2apreciosconstantes: 225.111600669288},
 {X: 1603, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 29000.0, Medianaapreciosconstantes: 7863.04332688196, Medianaporm2aprecioscorrientes: 690.47619047619, Medianaporm2apreciosconstantes: 187.215317306713},
 {X: 1898, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 26700.0, Medianaapreciosconstantes: 7239.4226492327, Medianaporm2aprecioscorrientes: 663.333333333333, Medianaporm2apreciosconstantes: 179.85581862638},
 {X: 2126, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 42500.0, Medianaapreciosconstantes: 11523.425565258, Medianaporm2aprecioscorrientes: 937.46875, Medianaporm2apreciosconstantes: 254.184737891306},
 {X: 2488, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 54000.0, Medianaapreciosconstantes: 14641.5289535043, Medianaporm2aprecioscorrientes: 1014.49275362319, Medianaporm2apreciosconstantes: 275.068981949894},
 {X: 3132, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 326370.0, Medianaapreciosconstantes: 88491.7741584298, Medianaporm2aprecioscorrientes: 2575.52083333333, Medianaporm2apreciosconstantes: 698.325237992659},
 {X: 3507, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 12201.2741279203, Medianaporm2aprecioscorrientes: 840.336134453781, Medianaporm2apreciosconstantes: 227.848256357055},
 {X: 3973, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 9489.87987727133, Medianaporm2aprecioscorrientes: 800.0, Medianaporm2apreciosconstantes: 216.911540051916},
 {X: 4226, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 6778.48562662238, Medianaporm2aprecioscorrientes: 657.894736842105, Medianaporm2apreciosconstantes: 178.381200700589},
 {X: 4488, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 29250.0, Medianaapreciosconstantes: 7930.82818314818, Medianaporm2aprecioscorrientes: 755.952380952381, Medianaporm2apreciosconstantes: 204.968493947867},
 {X: 4663, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 30500.0, Medianaapreciosconstantes: 8269.7524644793, Medianaporm2aprecioscorrientes: 760.699216395419, Medianaporm2apreciosconstantes: 206.25554818077},
 {X: 4939, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 8134.18275194686, Medianaporm2aprecioscorrientes: 720.538720538721, Medianaporm2apreciosconstantes: 195.366454423864},
 {X: 5024, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2021-05", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 9489.87987727133, Medianaporm2aprecioscorrientes: 833.333333333333, Medianaporm2apreciosconstantes: 225.949520887413},
 {X: 81, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 7881.96003140356, Medianaporm2aprecioscorrientes: 773.763440860215, Medianaporm2apreciosconstantes: 203.29241715405},
 {X: 228, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 7356.49602930999, Medianaporm2aprecioscorrientes: 671.641791044776, Medianaporm2apreciosconstantes: 176.461791747841},
 {X: 684, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 56000.0, Medianaapreciosconstantes: 14712.99205862, Medianaporm2aprecioscorrientes: 991.735537190083, Medianaporm2apreciosconstantes: 260.560662195159},
 {X: 1132, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 34750.0, Medianaapreciosconstantes: 9129.93703637579, Medianaporm2aprecioscorrientes: 765.686274509804, Medianaporm2apreciosconstantes: 201.170287076019},
 {X: 1494, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 32250.0, Medianaapreciosconstantes: 8473.10703375882, Medianaporm2aprecioscorrientes: 825.244618395303, Medianaporm2apreciosconstantes: 216.818169944089},
 {X: 1581, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 35500.0, Medianaapreciosconstantes: 9326.98603716088, Medianaporm2aprecioscorrientes: 824.829931972789, Medianaporm2apreciosconstantes: 216.709218550495},
 {X: 1630, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 29450.0, Medianaapreciosconstantes: 7737.45743082783, Medianaporm2aprecioscorrientes: 698.262952979934, Medianaporm2apreciosconstantes: 183.456022893255},
 {X: 1855, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 7356.49602930999, Medianaporm2aprecioscorrientes: 712.698412698413, Medianaporm2apreciosconstantes: 187.248680111122},
 {X: 2116, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 11822.9400471053, Medianaporm2aprecioscorrientes: 932.203389830509, Medianaporm2apreciosconstantes: 244.919661992766},
 {X: 2466, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 13136.6000523393, Medianaporm2aprecioscorrientes: 1028.57142857143, Medianaporm2apreciosconstantes: 270.238629648122},
 {X: 3074, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 345400.0, Medianaapreciosconstantes: 90747.6331615596, Medianaporm2aprecioscorrientes: 2789.111747851, Medianaporm2apreciosconstantes: 732.78891065599},
 {X: 3513, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 42000.0, Medianaapreciosconstantes: 11034.744043965, Medianaporm2aprecioscorrientes: 857.142857142857, Medianaporm2apreciosconstantes: 225.198858040102},
 {X: 3946, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 38000.0, Medianaapreciosconstantes: 9983.81603977784, Medianaporm2aprecioscorrientes: 813.953488372093, Medianaporm2apreciosconstantes: 213.851628759011},
 {X: 4195, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 25000.0, Medianaapreciosconstantes: 6568.30002616963, Medianaporm2aprecioscorrientes: 675.0, Medianaporm2apreciosconstantes: 177.34410070658},
 {X: 4477, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 32000.0, Medianaapreciosconstantes: 8407.42403349713, Medianaporm2aprecioscorrientes: 766.233766233766, Medianaporm2apreciosconstantes: 201.314130672212},
 {X: 4624, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 33000.0, Medianaapreciosconstantes: 8670.15603454391, Medianaporm2aprecioscorrientes: 777.777777777778, Medianaporm2apreciosconstantes: 204.347111925277},
 {X: 4945, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 30750.0, Medianaapreciosconstantes: 8079.00903218865, Medianaporm2aprecioscorrientes: 735.294117647059, Medianaporm2apreciosconstantes: 193.185294887342},
 {X: 5037, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2021-06", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 9195.62003663748, Medianaporm2aprecioscorrientes: 875.856164383562, Medianaporm2apreciosconstantes: 230.115442697655},
 {X: 53, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 7652.38838043289, Medianaporm2aprecioscorrientes: 781.25, Medianaporm2apreciosconstantes: 199.280947407106},
 {X: 249, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 7142.22915507069, Medianaporm2aprecioscorrientes: 694.444444444444, Medianaporm2apreciosconstantes: 177.138619917428},
 {X: 657, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 15304.7767608658, Medianaporm2aprecioscorrientes: 989.350855330237, Medianaporm2apreciosconstantes: 252.363232983348},
 {X: 1113, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 8927.78644383837, Medianaporm2aprecioscorrientes: 804.123711340206, Medianaporm2apreciosconstantes: 205.115564836345},
 {X: 1472, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 37000.0, Medianaapreciosconstantes: 9437.94566920056, Medianaporm2aprecioscorrientes: 866.666666666667, Medianaporm2apreciosconstantes: 221.06899765695},
 {X: 1586, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 36000.0, Medianaapreciosconstantes: 9182.86605651946, Medianaporm2aprecioscorrientes: 820.512820512821, Medianaporm2apreciosconstantes: 209.296092456284},
 {X: 1648, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 7652.38838043289, Medianaporm2aprecioscorrientes: 724.63768115942, Medianaporm2apreciosconstantes: 184.840299044273},
 {X: 1912, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 7142.22915507069, Medianaporm2aprecioscorrientes: 725.0, Medianaporm2apreciosconstantes: 184.932719193795},
 {X: 2120, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 47000.0, Medianaapreciosconstantes: 11988.7417960115, Medianaporm2aprecioscorrientes: 977.777777777778, Medianaporm2apreciosconstantes: 249.411176843739},
 {X: 2475, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 12753.9806340548, Medianaporm2aprecioscorrientes: 1034.48275862069, Medianaporm2apreciosconstantes: 263.875461394238},
 {X: 3155, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 399000.0, Medianaapreciosconstantes: 101776.765459757, Medianaporm2aprecioscorrientes: 3168.5527099464, Medianaporm2apreciosconstantes: 808.233198012765},
 {X: 3503, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 11478.5825706493, Medianaporm2aprecioscorrientes: 900.0, Medianaporm2apreciosconstantes: 229.571651412987},
 {X: 3967, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 10203.1845072438, Medianaporm2aprecioscorrientes: 821.917808219178, Medianaporm2apreciosconstantes: 209.654476176243},
 {X: 4221, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 27000.0, Medianaapreciosconstantes: 6887.1495423896, Medianaporm2aprecioscorrientes: 713.307240704501, Medianaporm2apreciosconstantes: 181.950134681526},
 {X: 4490, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 32000.0, Medianaapreciosconstantes: 8162.54760579508, Medianaporm2aprecioscorrientes: 766.233766233766, Medianaporm2apreciosconstantes: 195.450612314087},
 {X: 4655, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 33500.0, Medianaapreciosconstantes: 8545.16702481672, Medianaporm2aprecioscorrientes: 810.810810810811, Medianaporm2apreciosconstantes: 206.821307579267},
 {X: 4981, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 32000.0, Medianaapreciosconstantes: 8162.54760579508, Medianaporm2aprecioscorrientes: 796.825396825397, Medianaporm2apreciosconstantes: 203.253913596683},
 {X: 5074, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2021-07", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 8927.78644383837, Medianaporm2aprecioscorrientes: 904.109589041096, Medianaporm2apreciosconstantes: 230.619923793868},
 {X: 31, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 33000.0, Medianaapreciosconstantes: 8212.31923702681, Medianaporm2aprecioscorrientes: 821.917808219178, Medianaporm2apreciosconstantes: 204.540952354341},
 {X: 176, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 29000.0, Medianaapreciosconstantes: 7216.88660223568, Medianaporm2aprecioscorrientes: 722.222222222222, Medianaporm2apreciosconstantes: 179.730892392842},
 {X: 610, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 54000.0, Medianaapreciosconstantes: 13438.3405696802, Medianaporm2aprecioscorrientes: 1000.0, Medianaporm2apreciosconstantes: 248.858158697782},
 {X: 1139, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 8710.03555442237, Medianaporm2aprecioscorrientes: 831.168831168831, Medianaporm2apreciosconstantes: 206.843144891663},
 {X: 1495, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 9954.32634791128, Medianaporm2aprecioscorrientes: 882.352941176471, Medianaporm2apreciosconstantes: 219.580728262749},
 {X: 1567, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 36000.0, Medianaapreciosconstantes: 8958.89371312015, Medianaporm2aprecioscorrientes: 835.443037974684, Medianaporm2apreciosconstantes: 207.906816127261},
 {X: 1662, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 33000.0, Medianaapreciosconstantes: 8212.31923702681, Medianaporm2aprecioscorrientes: 742.857142857143, Medianaporm2apreciosconstantes: 184.866060746924},
 {X: 1868, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 7465.74476093346, Medianaporm2aprecioscorrientes: 747.222222222222, Medianaporm2apreciosconstantes: 185.952346360287},
 {X: 2127, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 12442.9079348891, Medianaporm2aprecioscorrientes: 1000.0, Medianaporm2apreciosconstantes: 248.858158697782},
 {X: 2456, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 12442.9079348891, Medianaporm2aprecioscorrientes: 1049.86187845304, Medianaporm2apreciosconstantes: 261.266693958818},
 {X: 3095, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 386933.333333333, Medianaapreciosconstantes: 96291.5168721284, Medianaporm2aprecioscorrientes: 3185.42320684028, Medianaporm2apreciosconstantes: 792.718553927456},
 {X: 3467, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 11198.6171414002, Medianaporm2aprecioscorrientes: 916.666666666667, Medianaporm2apreciosconstantes: 228.1199788063},
 {X: 3928, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 9954.32634791128, Medianaporm2aprecioscorrientes: 851.851851851852, Medianaporm2apreciosconstantes: 211.990283335148},
 {X: 4183, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 28000.0, Medianaapreciosconstantes: 6968.0284435379, Medianaporm2aprecioscorrientes: 739.717741935484, Medianaporm2apreciosconstantes: 184.084795214146},
 {X: 4472, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 33000.0, Medianaapreciosconstantes: 8212.31923702681, Medianaporm2aprecioscorrientes: 771.084337349398, Medianaporm2apreciosconstantes: 191.890628393471},
 {X: 4638, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 34950.0, Medianaapreciosconstantes: 8697.59264648748, Medianaporm2aprecioscorrientes: 832.207207207207, Medianaporm2apreciosconstantes: 207.101553240609},
 {X: 4986, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 8710.03555442237, Medianaporm2aprecioscorrientes: 800.0, Medianaporm2apreciosconstantes: 199.086526958226},
 {X: 5016, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2021-08", Medianaaprecioscorrientes: 36000.0, Medianaapreciosconstantes: 8958.89371312015, Medianaporm2aprecioscorrientes: 911.764705882353, Medianaporm2apreciosconstantes: 226.900085871507},
 {X: 51, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 8415.49328938803, Medianaporm2aprecioscorrientes: 842.927631578947, Medianaporm2apreciosconstantes: 202.675766485496},
 {X: 174, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 7213.2799623326, Medianaporm2aprecioscorrientes: 758.534714230917, Medianaporm2apreciosconstantes: 182.384108496519},
 {X: 641, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 58000.0, Medianaapreciosconstantes: 13945.674593843, Medianaporm2aprecioscorrientes: 1050.50505050505, Medianaporm2apreciosconstantes: 252.586234371242},
 {X: 1112, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 39000.0, Medianaapreciosconstantes: 9377.26395103238, Medianaporm2aprecioscorrientes: 873.256840247132, Medianaporm2apreciosconstantes: 209.968202257484},
 {X: 1445, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 9617.70661644347, Medianaporm2aprecioscorrientes: 933.035714285714, Medianaporm2apreciosconstantes: 224.341594066594},
 {X: 1541, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 39250.0, Medianaapreciosconstantes: 9437.37461738515, Medianaporm2aprecioscorrientes: 890.618336886994, Medianaporm2apreciosconstantes: 214.142646785098},
 {X: 1658, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 8415.49328938803, Medianaporm2aprecioscorrientes: 770.979020979021, Medianaporm2apreciosconstantes: 185.376250780226},
 {X: 1895, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 7213.2799623326, Medianaporm2aprecioscorrientes: 741.935483870968, Medianaporm2apreciosconstantes: 178.392945305},
 {X: 2147, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 12022.1332705543, Medianaporm2aprecioscorrientes: 1039.35185185185, Medianaporm2apreciosconstantes: 249.904529559208},
 {X: 2495, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 55000.0, Medianaapreciosconstantes: 13224.3465976098, Medianaporm2aprecioscorrientes: 1081.08108108108, Medianaporm2apreciosconstantes: 259.938016660634},
 {X: 3143, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 450738.636363636, Medianaapreciosconstantes: 108376.799131031, Medianaporm2aprecioscorrientes: 3373.02746212121, Medianaporm2apreciosconstantes: 811.019713497217},
 {X: 3490, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 12022.1332705543, Medianaporm2aprecioscorrientes: 950.0, Medianaporm2apreciosconstantes: 228.420532140532},
 {X: 3921, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 43000.0, Medianaapreciosconstantes: 10339.0346126767, Medianaporm2aprecioscorrientes: 890.873015873016, Medianaporm2apreciosconstantes: 214.203882479321},
 {X: 4215, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 29000.0, Medianaapreciosconstantes: 6972.83729692151, Medianaporm2aprecioscorrientes: 789.473684210526, Medianaporm2apreciosconstantes: 189.823156903489},
 {X: 4489, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 8415.49328938803, Medianaporm2aprecioscorrientes: 794.294294294294, Medianaporm2apreciosconstantes: 190.982237240938},
 {X: 4657, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 8415.49328938803, Medianaporm2aprecioscorrientes: 869.565217391304, Medianaporm2apreciosconstantes: 209.080578618336},
 {X: 4990, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 8415.49328938803, Medianaporm2aprecioscorrientes: 836.666666666667, Medianaporm2apreciosconstantes: 201.170363393943},
 {X: 5085, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2021-09", Medianaaprecioscorrientes: 38000.0, Medianaapreciosconstantes: 9136.82128562129, Medianaporm2aprecioscorrientes: 937.5, Medianaporm2apreciosconstantes: 225.414998822894},
 {X: 30, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 36000.0, Medianaapreciosconstantes: 8363.22314438373, Medianaporm2aprecioscorrientes: 877.19298245614, Medianaporm2apreciosconstantes: 203.782240360227},
 {X: 198, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 6969.35262031977, Medianaporm2aprecioscorrientes: 785.714285714286, Medianaporm2apreciosconstantes: 182.530663865518},
 {X: 661, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 13938.7052406395, Medianaporm2aprecioscorrientes: 1075.0, Medianaporm2apreciosconstantes: 249.735135561459},
 {X: 1129, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 38250.0, Medianaapreciosconstantes: 8885.92459090771, Medianaporm2aprecioscorrientes: 894.35637285986, Medianaporm2apreciosconstantes: 207.769497689685},
 {X: 1492, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 42000.0, Medianaapreciosconstantes: 9757.09366844769, Medianaporm2aprecioscorrientes: 939.724037763253, Medianaporm2apreciosconstantes: 218.30893949876},
 {X: 1589, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 41000.0, Medianaapreciosconstantes: 9524.78191443703, Medianaporm2aprecioscorrientes: 927.536231884058, Medianaporm2apreciosconstantes: 215.477568937423},
 {X: 1670, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 8130.91139037307, Medianaporm2aprecioscorrientes: 801.639344262295, Medianaporm2apreciosconstantes: 186.230242149528},
 {X: 1904, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 30000.0, Medianaapreciosconstantes: 6969.35262031977, Medianaporm2aprecioscorrientes: 768.177028451001, Medianaporm2apreciosconstantes: 178.456552870148},
 {X: 2121, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 11615.587700533, Medianaporm2aprecioscorrientes: 1082.35294117647, Medianaporm2apreciosconstantes: 251.443310223302},
 {X: 2455, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 62000.0, Medianaapreciosconstantes: 14403.3287486609, Medianaporm2aprecioscorrientes: 1129.45119396732, Medianaporm2apreciosconstantes: 262.384787939981},
 {X: 3082, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 463289.473684211, Medianaapreciosconstantes: 107627.590246254, Medianaporm2aprecioscorrientes: 3383.04686665955, Medianaporm2apreciosconstantes: 785.921551493945},
 {X: 3455, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 55000.0, Medianaapreciosconstantes: 12777.1464705863, Medianaporm2aprecioscorrientes: 983.606557377049, Medianaporm2apreciosconstantes: 228.503364600648},
 {X: 3939, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 51000.0, Medianaapreciosconstantes: 11847.8994545436, Medianaporm2aprecioscorrientes: 992.287074011268, Medianaporm2apreciosconstantes: 230.519950645662},
 {X: 4216, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 29000.0, Medianaapreciosconstantes: 6737.04086630912, Medianaporm2aprecioscorrientes: 791.666666666667, Medianaporm2apreciosconstantes: 183.913471925105},
 {X: 4444, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 8130.91139037307, Medianaporm2aprecioscorrientes: 816.666666666667, Medianaporm2apreciosconstantes: 189.721265775372},
 {X: 4626, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 37000.0, Medianaapreciosconstantes: 8595.53489839439, Medianaporm2aprecioscorrientes: 886.075949367089, Medianaporm2apreciosconstantes: 205.845857984128},
 {X: 4985, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 8130.91139037307, Medianaporm2aprecioscorrientes: 857.142857142857, Medianaporm2apreciosconstantes: 199.124360580565},
 {X: 5025, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2021-10", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 9292.47016042637, Medianaporm2aprecioscorrientes: 957.746478873239, Medianaporm2apreciosconstantes: 222.495764404575},
 {X: 50, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 36000.0, Medianaapreciosconstantes: 8159.24209300632, Medianaporm2aprecioscorrientes: 888.888888888889, Medianaporm2apreciosconstantes: 201.462767728551},
 {X: 218, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 32000.0, Medianaapreciosconstantes: 7252.65963822784, Medianaporm2aprecioscorrientes: 800.0, Medianaporm2apreciosconstantes: 181.316490955696},
 {X: 650, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 64000.0, Medianaapreciosconstantes: 14505.3192764557, Medianaporm2aprecioscorrientes: 1084.62150488747, Medianaporm2apreciosconstantes: 245.824706601603},
 {X: 1126, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 9065.8245477848, Medianaporm2aprecioscorrientes: 933.333333333333, Medianaporm2apreciosconstantes: 211.535906114979},
 {X: 1482, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 42000.0, Medianaapreciosconstantes: 9519.11577517404, Medianaporm2aprecioscorrientes: 985.915492957746, Medianaporm2apreciosconstantes: 223.453421952442},
 {X: 1574, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 42000.0, Medianaapreciosconstantes: 9519.11577517404, Medianaporm2aprecioscorrientes: 969.69696969697, Medianaporm2apreciosconstantes: 219.777564794783},
 {X: 1671, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 36000.0, Medianaapreciosconstantes: 8159.24209300632, Medianaporm2aprecioscorrientes: 843.75, Medianaporm2apreciosconstantes: 191.232236554836},
 {X: 1861, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 32000.0, Medianaapreciosconstantes: 7252.65963822784, Medianaporm2aprecioscorrientes: 800.0, Medianaporm2apreciosconstantes: 181.316490955696},
 {X: 2161, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 55000.0, Medianaapreciosconstantes: 12465.5087532041, Medianaporm2aprecioscorrientes: 1111.11111111111, Medianaporm2apreciosconstantes: 251.828459660689},
 {X: 2522, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 64000.0, Medianaapreciosconstantes: 14505.3192764557, Medianaporm2aprecioscorrientes: 1169.21090605301, Medianaporm2apreciosconstantes: 264.996523340827},
 {X: 3130, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 491309.523809524, Medianaapreciosconstantes: 111353.148537821, Medianaporm2aprecioscorrientes: 3628.13186813187, Medianaporm2apreciosconstantes: 822.300173817755},
 {X: 3496, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 13598.7368216772, Medianaporm2aprecioscorrientes: 1024.84472049689, Medianaporm2apreciosconstantes: 232.276560618709},
 {X: 3936, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 55000.0, Medianaapreciosconstantes: 12465.5087532041, Medianaporm2aprecioscorrientes: 1000.0, Medianaporm2apreciosconstantes: 226.64561369462},
 {X: 4187, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 31000.0, Medianaapreciosconstantes: 7026.01402453322, Medianaporm2aprecioscorrientes: 785.164835164835, Medianaporm2apreciosconstantes: 177.954165917369},
 {X: 4491, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 37000.0, Medianaapreciosconstantes: 8385.88770670094, Medianaporm2aprecioscorrientes: 833.333333333333, Medianaporm2apreciosconstantes: 188.871344745516},
 {X: 4662, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 38000.0, Medianaapreciosconstantes: 8612.53332039556, Medianaporm2aprecioscorrientes: 947.368421052632, Medianaporm2apreciosconstantes: 214.716897184377},
 {X: 4967, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 9065.8245477848, Medianaporm2aprecioscorrientes: 883.28173374613, Medianaporm2apreciosconstantes: 200.191930610139},
 {X: 5035, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2021-11", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 9065.8245477848, Medianaporm2aprecioscorrientes: 973.684210526316, Medianaporm2apreciosconstantes: 220.681255439498},
 {X: 29, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 38000.0, Medianaapreciosconstantes: 8297.23826529147, Medianaporm2aprecioscorrientes: 941.176470588235, Medianaporm2apreciosconstantes: 205.504353319913},
 {X: 197, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 34500.0, Medianaapreciosconstantes: 7533.01895138305, Medianaporm2aprecioscorrientes: 819.672131147541, Medianaporm2apreciosconstantes: 178.974078198694},
 {X: 646, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 68000.0, Medianaapreciosconstantes: 14847.6895273637, Medianaporm2aprecioscorrientes: 1126.76056338028, Medianaporm2apreciosconstantes: 246.026338481585},
 {X: 1117, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 42000.0, Medianaapreciosconstantes: 9170.6317669011, Medianaporm2aprecioscorrientes: 960.0, Medianaporm2apreciosconstantes: 209.614440386311},
 {X: 1504, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 8733.93501609628, Medianaporm2aprecioscorrientes: 1000.0, Medianaporm2apreciosconstantes: 218.348375402407},
 {X: 1580, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 9825.67689310832, Medianaporm2aprecioscorrientes: 1000.0, Medianaporm2apreciosconstantes: 218.348375402407},
 {X: 1673, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 38250.0, Medianaapreciosconstantes: 8351.82535914207, Medianaporm2aprecioscorrientes: 850.905049221975, Medianaporm2apreciosconstantes: 185.793735119323},
 {X: 1925, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 32000.0, Medianaapreciosconstantes: 6987.14801287703, Medianaporm2aprecioscorrientes: 833.333333333333, Medianaporm2apreciosconstantes: 181.956979502006},
 {X: 2124, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 56000.0, Medianaapreciosconstantes: 12227.5090225348, Medianaporm2aprecioscorrientes: 1098.59154929577, Medianaporm2apreciosconstantes: 239.875680019545},
 {X: 2489, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 66000.0, Medianaapreciosconstantes: 14410.9927765589, Medianaporm2aprecioscorrientes: 1200.0, Medianaporm2apreciosconstantes: 262.018050482889},
 {X: 3102, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 510900.0, Medianaapreciosconstantes: 111554.18499309, Medianaporm2aprecioscorrientes: 3861.84971098266, Medianaporm2apreciosconstantes: 843.228610441319},
 {X: 3462, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 65000.0, Medianaapreciosconstantes: 14192.6444011565, Medianaporm2aprecioscorrientes: 1066.66666666667, Medianaporm2apreciosconstantes: 232.904933762568},
 {X: 3953, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 59000.0, Medianaapreciosconstantes: 12882.554148742, Medianaporm2aprecioscorrientes: 1021.42857142857, Medianaporm2apreciosconstantes: 223.02726916103},
 {X: 4231, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 7642.19313908425, Medianaporm2aprecioscorrientes: 827.586206896552, Medianaporm2apreciosconstantes: 180.702103781302},
 {X: 4443, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 37500.0, Medianaapreciosconstantes: 8188.06407759027, Medianaporm2aprecioscorrientes: 865.76317383404, Medianaporm2apreciosconstantes: 189.037982489894},
 {X: 4620, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 8733.93501609628, Medianaporm2aprecioscorrientes: 964.240102171137, Medianaporm2apreciosconstantes: 210.540259806919},
 {X: 4999, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 39750.0, Medianaapreciosconstantes: 8679.34792224568, Medianaporm2aprecioscorrientes: 894.725177304965, Medianaporm2apreciosconstantes: 195.36178889617},
 {X: 5068, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2021-12", Medianaaprecioscorrientes: 43000.0, Medianaapreciosconstantes: 9388.98014230351, Medianaporm2aprecioscorrientes: 1016.28707627119, Medianaporm2apreciosconstantes: 221.904632046277},
 {X: 84, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 8406.09722543496, Medianaporm2aprecioscorrientes: 990.654205607477, Medianaporm2apreciosconstantes: 208.188389228062},
 {X: 232, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 7355.33507225559, Medianaporm2aprecioscorrientes: 875.0, Medianaporm2apreciosconstantes: 183.88337680639},
 {X: 639, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 15761.4322976906, Medianaporm2aprecioscorrientes: 1197.18309859155, Medianaporm2apreciosconstantes: 251.590938085202},
 {X: 1099, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 9456.85937861433, Medianaporm2aprecioscorrientes: 980.392156862745, Medianaporm2apreciosconstantes: 206.031794741053},
 {X: 1469, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 10507.6215317937, Medianaporm2aprecioscorrientes: 1079.80308330095, Medianaporm2apreciosconstantes: 226.923242563806},
 {X: 1590, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 9456.85937861433, Medianaporm2aprecioscorrientes: 1013.33333333333, Medianaporm2apreciosconstantes: 212.954463044352},
 {X: 1650, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 39750.0, Medianaapreciosconstantes: 8353.55911777599, Medianaporm2aprecioscorrientes: 915.47619047619, Medianaporm2apreciosconstantes: 192.389546617842},
 {X: 1873, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 7355.33507225559, Medianaporm2aprecioscorrientes: 873.78640776699, Medianaporm2apreciosconstantes: 183.628337448822},
 {X: 2148, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 12609.1458381524, Medianaporm2aprecioscorrientes: 1150.25641025641, Medianaporm2apreciosconstantes: 241.72918046988},
 {X: 2497, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 79000.0, Medianaapreciosconstantes: 16602.0420202341, Medianaporm2aprecioscorrientes: 1318.18181818182, Medianaporm2apreciosconstantes: 277.019113110925},
 {X: 3133, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 520059.523809524, Medianaapreciosconstantes: 109291.773003907, Medianaporm2aprecioscorrientes: 4088.32920451418, Medianaporm2apreciosconstantes: 859.172319568284},
 {X: 3515, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 15761.4322976906, Medianaporm2aprecioscorrientes: 1147.91666666667, Medianaporm2apreciosconstantes: 241.237477667431},
 {X: 3951, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 12609.1458381524, Medianaporm2aprecioscorrientes: 1053.57142857143, Medianaporm2apreciosconstantes: 221.410596562796},
 {X: 4173, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 35000.0, Medianaapreciosconstantes: 7355.33507225559, Medianaporm2aprecioscorrientes: 866.025641025641, Medianaporm2apreciosconstantes: 181.997393454529},
 {X: 4467, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 8406.09722543496, Medianaporm2aprecioscorrientes: 865.384615384615, Medianaporm2apreciosconstantes: 181.862680357968},
 {X: 4652, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 42000.0, Medianaapreciosconstantes: 8826.40208670671, Medianaporm2aprecioscorrientes: 989.690721649485, Medianaporm2apreciosconstantes: 207.985910732412},
 {X: 4952, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 39000.0, Medianaapreciosconstantes: 8195.94479479909, Medianaporm2aprecioscorrientes: 917.792792792793, Medianaporm2apreciosconstantes: 192.876386225493},
 {X: 5077, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2022-01", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 9456.85937861433, Medianaporm2aprecioscorrientes: 1055.55555555556, Medianaporm2apreciosconstantes: 221.827565671201},
 {X: 27, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 44500.0, Medianaapreciosconstantes: 8931.98009726818, Medianaporm2aprecioscorrientes: 1021.27659574468, Medianaporm2apreciosconstantes: 204.989263528029},
 {X: 208, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 39000.0, Medianaapreciosconstantes: 7828.02750097661, Medianaporm2aprecioscorrientes: 921.052631578947, Medianaporm2apreciosconstantes: 184.872444355048},
 {X: 615, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 89000.0, Medianaapreciosconstantes: 17863.9601945364, Medianaporm2aprecioscorrientes: 1323.52941176471, Medianaporm2apreciosconstantes: 265.657041888347},
 {X: 1054, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 9032.33942420378, Medianaporm2aprecioscorrientes: 1011.94709145214, Medianaporm2apreciosconstantes: 203.116657985145},
 {X: 1433, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 12043.1192322717, Medianaporm2aprecioscorrientes: 1134.0206185567, Medianaporm2apreciosconstantes: 227.619092018881},
 {X: 1587, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 9032.33942420378, Medianaporm2aprecioscorrientes: 1011.90644932672, Medianaporm2apreciosconstantes: 203.10850035244},
 {X: 1656, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 8028.7461548478, Medianaporm2aprecioscorrientes: 928.571428571429, Medianaporm2apreciosconstantes: 186.38160716611},
 {X: 1926, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 38000.0, Medianaapreciosconstantes: 7627.30884710541, Medianaporm2aprecioscorrientes: 945.945945945946, Medianaporm2apreciosconstantes: 189.868996905185},
 {X: 2123, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 69000.0, Medianaapreciosconstantes: 13849.5871171125, Medianaporm2aprecioscorrientes: 1250.0, Medianaporm2apreciosconstantes: 250.898317338994},
 {X: 2458, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 104828.947368421, Medianaapreciosconstantes: 21041.1252025238, Medianaporm2aprecioscorrientes: 1584.86490736646, Medianaporm2apreciosconstantes: 318.111950774292},
 {X: 3097, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 524144.736842105, Medianaapreciosconstantes: 105205.626012619, Medianaporm2aprecioscorrientes: 3932.45172893549, Medianaporm2apreciosconstantes: 789.316417445385},
 {X: 3440, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 104828.947368421, Medianaapreciosconstantes: 21041.1252025238, Medianaporm2aprecioscorrientes: 1314.28571428571, Medianaporm2apreciosconstantes: 263.80165937357},
 {X: 3955, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 80000.0, Medianaapreciosconstantes: 16057.4923096956, Medianaporm2aprecioscorrientes: 1145.47619047619, Medianaporm2apreciosconstantes: 229.918438993885},
 {X: 4206, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 38000.0, Medianaapreciosconstantes: 7627.30884710541, Medianaporm2aprecioscorrientes: 917.517006802721, Medianaporm2apreciosconstantes: 184.16277850937},
 {X: 4445, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 8028.7461548478, Medianaporm2aprecioscorrientes: 921.052631578947, Medianaporm2apreciosconstantes: 184.872444355048},
 {X: 4650, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 9032.33942420378, Medianaporm2aprecioscorrientes: 1040.0, Medianaporm2apreciosconstantes: 208.747400026043},
 {X: 5001, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 44000.0, Medianaapreciosconstantes: 8831.62077033258, Medianaporm2aprecioscorrientes: 1010.52631578947, Medianaporm2apreciosconstantes: 202.831481806681},
 {X: 5039, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2022-02", Medianaaprecioscorrientes: 49450.0, Medianaapreciosconstantes: 9925.5374339306, Medianaporm2aprecioscorrientes: 1090.35326086957, Medianaporm2apreciosconstantes: 218.854238765808},
 {X: 47, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 8465.17284472839, Medianaporm2aprecioscorrientes: 1070.86614173228, Medianaporm2apreciosconstantes: 201.445932962915},
 {X: 186, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 7524.59808420301, Medianaporm2aprecioscorrientes: 964.345991561181, Medianaporm2apreciosconstantes: 181.407900015253},
 {X: 668, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 100000.0, Medianaapreciosconstantes: 18811.4952105075, Medianaporm2aprecioscorrientes: 1500.0744047619, Medianaporm2apreciosconstantes: 282.186424805834},
 {X: 1135, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 46500.0, Medianaapreciosconstantes: 8747.345272886, Medianaporm2aprecioscorrientes: 1078.43137254902, Medianaporm2apreciosconstantes: 202.86906599567},
 {X: 1484, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 11286.8971263045, Medianaporm2aprecioscorrientes: 1258.06451612903, Medianaporm2apreciosconstantes: 236.660746196707},
 {X: 1584, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 9405.74760525377, Medianaporm2aprecioscorrientes: 1111.11111111111, Medianaporm2apreciosconstantes: 209.016613450084},
 {X: 1663, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 42000.0, Medianaapreciosconstantes: 7900.82798841317, Medianaporm2aprecioscorrientes: 962.954545454545, Medianaporm2apreciosconstantes: 181.146148197546},
 {X: 1924, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 38000.0, Medianaapreciosconstantes: 7148.36817999286, Medianaporm2aprecioscorrientes: 941.176470588235, Medianaporm2apreciosconstantes: 177.04936668713},
 {X: 2108, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 70000.0, Medianaapreciosconstantes: 13168.0466473553, Medianaporm2aprecioscorrientes: 1369.86301369863, Medianaporm2apreciosconstantes: 257.691715212432},
 {X: 2459, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 130000.0, Medianaapreciosconstantes: 24454.9437736598, Medianaporm2aprecioscorrientes: 1863.56589147287, Medianaporm2apreciosconstantes: 350.564608419071},
 {X: 3121, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 494166.666666667, Medianaapreciosconstantes: 92960.1388319248, Medianaporm2aprecioscorrientes: 3874.46941052052, Medianaporm2apreciosconstantes: 728.845627592647},
 {X: 3495, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 126500.0, Medianaapreciosconstantes: 23796.541441292, Medianaporm2aprecioscorrientes: 1406.56509489917, Medianaporm2apreciosconstantes: 264.595925459628},
 {X: 3961, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 88975.0, Medianaapreciosconstantes: 16737.5278635491, Medianaporm2aprecioscorrientes: 1312.79549805115, Medianaporm2apreciosconstantes: 246.956462239651},
 {X: 4201, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 40000.0, Medianaapreciosconstantes: 7524.59808420301, Medianaporm2aprecioscorrientes: 950.0, Medianaporm2apreciosconstantes: 178.709204499822},
 {X: 4458, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 44000.0, Medianaapreciosconstantes: 8277.05789262332, Medianaporm2aprecioscorrientes: 1070.81834532374, Medianaporm2apreciosconstantes: 201.436941743811},
 {X: 4615, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 47000.0, Medianaapreciosconstantes: 8841.40274893854, Medianaporm2aprecioscorrientes: 1111.11111111111, Medianaporm2apreciosconstantes: 209.016613450084},
 {X: 4966, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 8465.17284472839, Medianaporm2aprecioscorrientes: 1057.69230769231, Medianaporm2apreciosconstantes: 198.967737803446},
 {X: 5047, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2022-03", Medianaaprecioscorrientes: 52000.0, Medianaapreciosconstantes: 9781.97750946392, Medianaporm2aprecioscorrientes: 1163.69047619048, Medianaporm2apreciosconstantes: 218.907578193704},
 {X: 26, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 8873.34679684232, Medianaporm2aprecioscorrientes: 1125.0, Medianaporm2apreciosconstantes: 199.650302928952},
 {X: 248, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 42750.0, Medianaapreciosconstantes: 7586.71151130018, Medianaporm2aprecioscorrientes: 1000.0, Medianaporm2apreciosconstantes: 177.466935936846},
 {X: 628, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 130000.0, Medianaapreciosconstantes: 23070.70167179, Medianaporm2aprecioscorrientes: 1611.03318502039, Medianaporm2apreciosconstantes: 285.905123038147},
 {X: 1100, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 55000.0, Medianaapreciosconstantes: 9760.68147652655, Medianaporm2aprecioscorrientes: 1157.02479338843, Medianaporm2apreciosconstantes: 205.333644885607},
 {X: 1500, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 70000.0, Medianaapreciosconstantes: 12422.6855155792, Medianaporm2aprecioscorrientes: 1328.43137254902, Medianaporm2apreciosconstantes: 235.752645288654},
 {X: 1549, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 55250.0, Medianaapreciosconstantes: 9805.04821051076, Medianaporm2aprecioscorrientes: 1200.0, Medianaporm2apreciosconstantes: 212.960323124216},
 {X: 1667, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 7986.01211715808, Medianaporm2aprecioscorrientes: 1044.12719013628, Medianaporm2apreciosconstantes: 185.298053161835},
 {X: 1930, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 47000.0, Medianaapreciosconstantes: 8340.94598903178, Medianaporm2aprecioscorrientes: 949.49494949495, Medianaporm2apreciosconstantes: 168.503959374379},
 {X: 2139, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 77000.0, Medianaapreciosconstantes: 13664.9540671372, Medianaporm2aprecioscorrientes: 1546.875, Medianaporm2apreciosconstantes: 274.519166527309},
 {X: 2451, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 135000.0, Medianaapreciosconstantes: 23958.0363514743, Medianaporm2aprecioscorrientes: 1941.74757281553, Medianaporm2apreciosconstantes: 344.59599211038},
 {X: 3124, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 489342.105263158, Medianaapreciosconstantes: 86842.0440459384, Medianaporm2aprecioscorrientes: 3895.35695674831, Medianaporm2apreciosconstantes: 691.297063494401},
 {X: 3472, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 138507.894736842, Medianaapreciosconstantes: 24580.5716820106, Medianaporm2aprecioscorrientes: 1456.43939393939, Medianaporm2apreciosconstantes: 258.469836620141},
 {X: 3954, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 110000.0, Medianaapreciosconstantes: 19521.3629530531, Medianaporm2aprecioscorrientes: 1395.3488372093, Medianaporm2apreciosconstantes: 247.628282702576},
 {X: 4229, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 7986.01211715808, Medianaporm2aprecioscorrientes: 989.226403195291, Medianaporm2apreciosconstantes: 175.554978722896},
 {X: 4479, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 45000.0, Medianaapreciosconstantes: 7986.01211715808, Medianaporm2aprecioscorrientes: 1128.79684418146, Medianaporm2apreciosconstantes: 200.324117232065},
 {X: 4631, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 8873.34679684232, Medianaporm2aprecioscorrientes: 1195.65217391304, Medianaporm2apreciosconstantes: 212.188727750577},
 {X: 4983, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 48000.0, Medianaapreciosconstantes: 8518.41292496862, Medianaporm2aprecioscorrientes: 1105.55555555556, Medianaporm2apreciosconstantes: 196.199556952403},
 {X: 5090, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2022-04", Medianaaprecioscorrientes: 55500.0, Medianaapreciosconstantes: 9849.41494449497, Medianaporm2aprecioscorrientes: 1255.4347826087, Medianaporm2apreciosconstantes: 222.798164138107},
 {X: 46, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 8442.76574434091, Medianaporm2aprecioscorrientes: 1223.18702290076, Medianaporm2apreciosconstantes: 206.541629917378},
 {X: 214, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 48000.0, Medianaapreciosconstantes: 8105.05511456727, Medianaporm2aprecioscorrientes: 1050.0, Medianaporm2apreciosconstantes: 177.298080631159},
 {X: 647, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 130000.0, Medianaapreciosconstantes: 21951.1909352864, Medianaporm2aprecioscorrientes: 1684.78260869565, Medianaporm2apreciosconstantes: 284.484497907139},
 {X: 1078, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 58000.0, Medianaapreciosconstantes: 9793.60826343546, Medianaporm2aprecioscorrientes: 1212.12121212121, Medianaporm2apreciosconstantes: 204.673108953719},
 {X: 1475, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 12664.1486165114, Medianaporm2aprecioscorrientes: 1375.0, Medianaporm2apreciosconstantes: 232.176057969375},
 {X: 1561, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 10131.3188932091, Medianaporm2aprecioscorrientes: 1250.0, Medianaporm2apreciosconstantes: 211.069143608523},
 {X: 1642, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 8442.76574434091, Medianaporm2aprecioscorrientes: 1081.08108108108, Medianaporm2apreciosconstantes: 182.546286364128},
 {X: 1891, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 44900.0, Medianaapreciosconstantes: 7581.60363841814, Medianaporm2aprecioscorrientes: 1096.77419354839, Medianaporm2apreciosconstantes: 185.19615181135},
 {X: 2131, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 12664.1486165114, Medianaporm2aprecioscorrientes: 1557.94902912621, Medianaporm2apreciosconstantes: 263.067973890719},
 {X: 2457, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 139000.0, Medianaapreciosconstantes: 23470.8887692677, Medianaporm2aprecioscorrientes: 1936.84210526316, Medianaporm2apreciosconstantes: 327.046083570259},
 {X: 3113, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 500875.0, Medianaapreciosconstantes: 84575.4058439351, Medianaporm2aprecioscorrientes: 4073.98235239337, Medianaporm2apreciosconstantes: 687.913572956723},
 {X: 3492, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 140245.0, Medianaapreciosconstantes: 23681.1136363018, Medianaporm2aprecioscorrientes: 1546.55127196433, Medianaporm2apreciosconstantes: 261.143402016146},
 {X: 3935, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 120000.0, Medianaapreciosconstantes: 20262.6377864182, Medianaporm2aprecioscorrientes: 1426.64086687307, Medianaporm2apreciosconstantes: 240.895892806256},
 {X: 4225, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 8442.76574434091, Medianaporm2aprecioscorrientes: 1066.66666666667, Medianaporm2apreciosconstantes: 180.112335879273},
 {X: 4462, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 54800.0, Medianaapreciosconstantes: 9253.27125579764, Medianaporm2aprecioscorrientes: 1142.85714285714, Medianaporm2apreciosconstantes: 192.977502727792},
 {X: 4613, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 58000.0, Medianaapreciosconstantes: 9793.60826343546, Medianaporm2aprecioscorrientes: 1287.67123287671, Medianaporm2apreciosconstantes: 217.430131498094},
 {X: 5000, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 51000.0, Medianaapreciosconstantes: 8611.62105922773, Medianaporm2aprecioscorrientes: 1165.07599858607, Medianaporm2apreciosconstantes: 196.729274608325},
 {X: 5093, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2022-05", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 10131.3188932091, Medianaporm2aprecioscorrientes: 1359.28445452255, Medianaporm2apreciosconstantes: 229.522404589162},
 {X: 25, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 55000.0, Medianaapreciosconstantes: 8819.60334081244, Medianaporm2aprecioscorrientes: 1285.71428571429, Medianaporm2apreciosconstantes: 206.172545629382},
 {X: 220, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 50000.0, Medianaapreciosconstantes: 8017.8212189204, Medianaporm2aprecioscorrientes: 1142.85714285714, Medianaporm2apreciosconstantes: 183.264485003894},
 {X: 625, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 130000.0, Medianaapreciosconstantes: 20846.335169193, Medianaporm2aprecioscorrientes: 1756.09756097561, Medianaporm2apreciosconstantes: 281.601525737692},
 {X: 1115, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 9621.38546270448, Medianaporm2aprecioscorrientes: 1285.71428571429, Medianaporm2apreciosconstantes: 206.172545629382},
 {X: 1470, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 80000.0, Medianaapreciosconstantes: 12828.5139502726, Medianaporm2aprecioscorrientes: 1495.32710280374, Medianaporm2apreciosconstantes: 239.785307481732},
 {X: 1569, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 63250.0, Medianaapreciosconstantes: 10142.5438419343, Medianaporm2aprecioscorrientes: 1359.62301587302, Medianaporm2apreciosconstantes: 218.024285327985},
 {X: 1674, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 55000.0, Medianaapreciosconstantes: 8819.60334081244, Medianaporm2aprecioscorrientes: 1131.68030813674, Medianaporm2apreciosconstantes: 181.472207752263},
 {X: 1933, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 53000.0, Medianaapreciosconstantes: 8498.89049205563, Medianaporm2aprecioscorrientes: 1148.35164835165, Medianaporm2apreciosconstantes: 184.145564258722},
 {X: 2154, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 80000.0, Medianaapreciosconstantes: 12828.5139502726, Medianaporm2aprecioscorrientes: 1630.4347826087, Medianaporm2apreciosconstantes: 261.450691921318},
 {X: 2487, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 150000.0, Medianaapreciosconstantes: 24053.4636567612, Medianaporm2aprecioscorrientes: 2095.2380952381, Medianaporm2apreciosconstantes: 335.984889173808},
 {X: 3120, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 536125.0, Medianaapreciosconstantes: 85971.088019874, Medianaporm2aprecioscorrientes: 4305.62403100775, Medianaporm2apreciosconstantes: 690.434474330151},
 {X: 3449, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 145000.0, Medianaapreciosconstantes: 23251.6815348692, Medianaporm2aprecioscorrientes: 1622.77662277662, Medianaporm2apreciosconstantes: 260.222656793327},
 {X: 3965, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 200000.0, Medianaapreciosconstantes: 32071.2848756816, Medianaporm2aprecioscorrientes: 1583.33333333333, Medianaporm2apreciosconstantes: 253.897671932479},
 {X: 4232, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 55000.0, Medianaapreciosconstantes: 8819.60334081244, Medianaporm2aprecioscorrientes: 1110.77158135982, Medianaporm2apreciosconstantes: 178.119359088011},
 {X: 4470, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 70000.0, Medianaapreciosconstantes: 11224.9497064886, Medianaporm2aprecioscorrientes: 1212.12121212121, Medianaporm2apreciosconstantes: 194.371423488979},
 {X: 4648, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 9621.38546270448, Medianaporm2aprecioscorrientes: 1361.70212765957, Medianaporm2apreciosconstantes: 218.357684259959},
 {X: 4996, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 55000.0, Medianaapreciosconstantes: 8819.60334081244, Medianaporm2aprecioscorrientes: 1190.47619047619, Medianaporm2apreciosconstantes: 190.90050521239},
 {X: 5081, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2022-06", Medianaaprecioscorrientes: 65000.0, Medianaapreciosconstantes: 10423.1675845965, Medianaporm2aprecioscorrientes: 1443.29896907216, Medianaporm2apreciosconstantes: 231.442261989454},
 {X: 5, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 8958.45946222141, Medianaporm2aprecioscorrientes: 1375.0, Medianaporm2apreciosconstantes: 205.298029342574},
 {X: 199, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 8958.45946222141, Medianaporm2aprecioscorrientes: 1233.76623376623, Medianaporm2apreciosconstantes: 184.210746517539},
 {X: 660, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 130000.0, Medianaapreciosconstantes: 19409.9955014797, Medianaporm2aprecioscorrientes: 1821.499668215, Medianaporm2apreciosconstantes: 271.96384896923},
 {X: 1118, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 65000.0, Medianaapreciosconstantes: 9704.99775073986, Medianaporm2aprecioscorrientes: 1375.0, Medianaporm2apreciosconstantes: 205.298029342574},
 {X: 1476, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 11198.0743277768, Medianaporm2aprecioscorrientes: 1550.27027027027, Medianaporm2apreciosconstantes: 231.467222861721},
 {X: 1560, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 69000.0, Medianaapreciosconstantes: 10302.2283815546, Medianaporm2aprecioscorrientes: 1469.41223510596, Medianaporm2apreciosconstantes: 219.394499024815},
 {X: 1645, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 65000.0, Medianaapreciosconstantes: 9704.99775073986, Medianaporm2aprecioscorrientes: 1248.31838565022, Medianaporm2apreciosconstantes: 186.383494229886},
 {X: 1929, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 57000.0, Medianaapreciosconstantes: 8510.53648911034, Medianaporm2aprecioscorrientes: 1200.0, Medianaporm2apreciosconstantes: 179.169189244428},
 {X: 2182, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 84000.0, Medianaapreciosconstantes: 12541.84324711, Medianaporm2aprecioscorrientes: 1746.83544303797, Medianaporm2apreciosconstantes: 260.815908393787},
 {X: 2503, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 170000.0, Medianaapreciosconstantes: 25382.3018096273, Medianaporm2aprecioscorrientes: 2380.95238095238, Medianaporm2apreciosconstantes: 355.494423104024},
 {X: 3077, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 702619.047619048, Medianaapreciosconstantes: 104906.404257998, Medianaporm2aprecioscorrientes: 5439.63133640553, Medianaporm2apreciosconstantes: 812.178613610304},
 {X: 3500, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 150000.0, Medianaapreciosconstantes: 22396.1486555535, Medianaporm2aprecioscorrientes: 1751.28865979381, Medianaporm2apreciosconstantes: 261.480807756848},
 {X: 3950, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 230000.0, Medianaapreciosconstantes: 34340.7612718487, Medianaporm2aprecioscorrientes: 1902.54326720135, Medianaporm2apreciosconstantes: 284.064278905759},
 {X: 4184, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 8958.45946222141, Medianaporm2aprecioscorrientes: 1221.57622739018, Medianaporm2apreciosconstantes: 182.390685218138},
 {X: 4487, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 70000.0, Medianaapreciosconstantes: 10451.5360392583, Medianaporm2aprecioscorrientes: 1317.16191631292, Medianaporm2apreciosconstantes: 196.662360541186},
 {X: 4654, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 70000.0, Medianaapreciosconstantes: 10451.5360392583, Medianaporm2aprecioscorrientes: 1489.36170212766, Medianaporm2apreciosconstantes: 222.373107218262},
 {X: 5005, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 8958.45946222141, Medianaporm2aprecioscorrientes: 1333.33333333333, Medianaporm2apreciosconstantes: 199.076876938253},
 {X: 5082, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2022-07", Medianaaprecioscorrientes: 70000.0, Medianaapreciosconstantes: 10451.5360392583, Medianaporm2aprecioscorrientes: 1504.42477876106, Medianaporm2apreciosconstantes: 224.622139908206},
 {X: 22, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 65000.0, Medianaapreciosconstantes: 9070.0913563379, Medianaporm2aprecioscorrientes: 1440.6779661017, Medianaporm2apreciosconstantes: 201.032011809315},
 {X: 179, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 59000.0, Medianaapreciosconstantes: 8232.8521542144, Medianaporm2aprecioscorrientes: 1333.33333333333, Medianaporm2apreciosconstantes: 186.053156027444},
 {X: 653, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 130000.0, Medianaapreciosconstantes: 18140.1827126758, Medianaporm2aprecioscorrientes: 1967.2131147541, Medianaporm2apreciosconstantes: 274.504656433934},
 {X: 1079, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 10465.4900265437, Medianaporm2aprecioscorrientes: 1494.25287356322, Medianaporm2apreciosconstantes: 208.507847272136},
 {X: 1423, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 10465.4900265437, Medianaporm2aprecioscorrientes: 1710.52631578947, Medianaporm2apreciosconstantes: 238.686614640471},
 {X: 1505, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 70000.0, Medianaapreciosconstantes: 9767.79069144082, Medianaporm2aprecioscorrientes: 1532.38866396761, Medianaporm2apreciosconstantes: 213.829310393889},
 {X: 1631, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 8372.39202123499, Medianaporm2aprecioscorrientes: 1304.34782608696, Medianaporm2apreciosconstantes: 182.008522200761},
 {X: 1917, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 58000.0, Medianaapreciosconstantes: 8093.31228719382, Medianaporm2aprecioscorrientes: 1577.38095238095, Medianaporm2apreciosconstantes: 220.107528336038},
 {X: 2175, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 90000.0, Medianaapreciosconstantes: 12558.5880318525, Medianaporm2aprecioscorrientes: 1940.97222222222, Medianaporm2apreciosconstantes: 270.843005779534},
 {X: 2533, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 199925.0, Medianaapreciosconstantes: 27897.5079140901, Medianaporm2aprecioscorrientes: 2708.72871046229, Medianaporm2apreciosconstantes: 377.975644052743},
 {X: 3131, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 713750.0, Medianaapreciosconstantes: 99596.5800859412, Medianaporm2aprecioscorrientes: 5584.72445050537, Medianaporm2apreciosconstantes: 779.291707170118},
 {X: 3461, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 160000.0, Medianaapreciosconstantes: 22326.3787232933, Medianaporm2aprecioscorrientes: 1840.0, Medianaporm2apreciosconstantes: 256.753355317873},
 {X: 3920, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 185575.0, Medianaapreciosconstantes: 25895.1108223447, Medianaporm2aprecioscorrientes: 2021.27659574468, Medianaporm2apreciosconstantes: 282.04866738203},
 {X: 4172, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 65000.0, Medianaapreciosconstantes: 9070.0913563379, Medianaporm2aprecioscorrientes: 1228.77906976744, Medianaporm2apreciosconstantes: 171.463667993024},
 {X: 4437, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 70000.0, Medianaapreciosconstantes: 9767.79069144082, Medianaporm2aprecioscorrientes: 1372.54901960784, Medianaporm2apreciosconstantes: 191.52530767531},
 {X: 4646, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 71000.0, Medianaapreciosconstantes: 9907.3305584614, Medianaporm2aprecioscorrientes: 1559.52380952381, Medianaporm2apreciosconstantes: 217.615744996386},
 {X: 4978, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 60000.0, Medianaapreciosconstantes: 8372.39202123499, Medianaporm2aprecioscorrientes: 1420.16806722689, Medianaporm2apreciosconstantes: 198.170063247719},
 {X: 5059, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2022-08", Medianaaprecioscorrientes: 79000.0, Medianaapreciosconstantes: 11023.6494946261, Medianaporm2aprecioscorrientes: 1645.56962025316, Medianaporm2apreciosconstantes: 229.622565983237},
 {X: 56, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 70000.0, Medianaapreciosconstantes: 9197.54302411993, Medianaporm2aprecioscorrientes: 1557.37704918033, Medianaporm2apreciosconstantes: 204.629177351615},
 {X: 182, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 62000.0, Medianaapreciosconstantes: 8146.39524993479, Medianaporm2aprecioscorrientes: 1377.55102040816, Medianaporm2apreciosconstantes: 181.001211116062},
 {X: 612, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 148250.0, Medianaapreciosconstantes: 19479.0821903683, Medianaporm2aprecioscorrientes: 2162.16216216216, Medianaporm2apreciosconstantes: 284.093993023009},
 {X: 1074, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 9854.51038298563, Medianaporm2aprecioscorrientes: 1526.31578947368, Medianaporm2apreciosconstantes: 200.547930601111},
 {X: 1430, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 90000.0, Medianaapreciosconstantes: 11825.4124595828, Medianaporm2aprecioscorrientes: 1730.76923076923, Medianaporm2apreciosconstantes: 227.411778068899},
 {X: 1511, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 70000.0, Medianaapreciosconstantes: 9197.54302411993, Medianaporm2aprecioscorrientes: 1638.55421686747, Medianaporm2apreciosconstantes: 215.295327242738},
 {X: 1637, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 65000.0, Medianaapreciosconstantes: 8540.57566525422, Medianaporm2aprecioscorrientes: 1351.00035100035, Medianaporm2apreciosconstantes: 177.512626484669},
 {X: 1900, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 65000.0, Medianaapreciosconstantes: 8540.57566525422, Medianaporm2aprecioscorrientes: 1460.68376068376, Medianaporm2apreciosconstantes: 191.924310478888},
 {X: 2169, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 95000.0, Medianaapreciosconstantes: 12482.3798184485, Medianaporm2aprecioscorrientes: 2048.19277108434, Medianaporm2apreciosconstantes: 269.119159053423},
 {X: 2490, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 205000.0, Medianaapreciosconstantes: 26935.6617134941, Medianaporm2aprecioscorrientes: 2650.60240963855, Medianaporm2apreciosconstantes: 348.271852892664},
 {X: 3147, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 693804.347826087, Medianaapreciosconstantes: 91161.36199217, Medianaporm2aprecioscorrientes: 5582.33383308346, Medianaporm2apreciosconstantes: 733.482222925506},
 {X: 3485, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 180000.0, Medianaapreciosconstantes: 23650.8249191655, Medianaporm2aprecioscorrientes: 2000.0, Medianaporm2apreciosconstantes: 262.786943546284},
 {X: 3943, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 214070.652173913, Medianaapreciosconstantes: 28127.4861938711, Medianaporm2aprecioscorrientes: 2360.15009890238, Medianaporm2apreciosconstantes: 310.108315400508},
 {X: 4192, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 65000.0, Medianaapreciosconstantes: 8540.57566525422, Medianaporm2aprecioscorrientes: 1390.625, Medianaporm2apreciosconstantes: 182.719046684525},
 {X: 4473, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 9854.51038298563, Medianaporm2aprecioscorrientes: 1481.56277436348, Medianaporm2apreciosconstantes: 194.667676573466},
 {X: 4643, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 9854.51038298563, Medianaporm2aprecioscorrientes: 1620.25316455696, Medianaporm2apreciosconstantes: 212.890688442559},
 {X: 4969, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 65000.0, Medianaapreciosconstantes: 8540.57566525422, Medianaporm2aprecioscorrientes: 1475.46897546898, Medianaporm2apreciosconstantes: 193.86699118043},
 {X: 5080, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2022-09", Medianaaprecioscorrientes: 83256.5217391304, Medianaapreciosconstantes: 10939.3634390604, Medianaporm2aprecioscorrientes: 1727.83389450056, Medianaporm2apreciosconstantes: 227.026094045737},
 {X: 21, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 80000.0, Medianaapreciosconstantes: 9888.50210917961, Medianaporm2aprecioscorrientes: 1600.0, Medianaporm2apreciosconstantes: 197.770042183592},
 {X: 193, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 65000.0, Medianaapreciosconstantes: 8034.40796370844, Medianaporm2aprecioscorrientes: 1397.67441860465, Medianaporm2apreciosconstantes: 172.761330453981},
 {X: 634, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 170494.736842105, Medianaapreciosconstantes: 21074.2195608397, Medianaporm2aprecioscorrientes: 2285.71428571429, Medianaporm2apreciosconstantes: 282.528631690847},
 {X: 1080, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 85000.0, Medianaapreciosconstantes: 10506.5334910033, Medianaporm2aprecioscorrientes: 1636.63903061224, Medianaporm2apreciosconstantes: 202.298856327185},
 {X: 1435, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 95000.0, Medianaapreciosconstantes: 11742.5962546508, Medianaporm2aprecioscorrientes: 1844.11764705882, Medianaporm2apreciosconstantes: 227.944515531456},
 {X: 1544, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 9270.47072735589, Medianaporm2aprecioscorrientes: 1688.31168831169, Medianaporm2apreciosconstantes: 208.685921135284},
 {X: 1594, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 69500.0, Medianaapreciosconstantes: 8590.63620734979, Medianaporm2aprecioscorrientes: 1475.0971388202, Medianaporm2apreciosconstantes: 182.331264605854},
 {X: 1859, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 65000.0, Medianaapreciosconstantes: 8034.40796370844, Medianaporm2aprecioscorrientes: 1329.65009208103, Medianaporm2apreciosconstantes: 164.353096750177},
 {X: 2184, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 120000.0, Medianaapreciosconstantes: 14832.7531637694, Medianaporm2aprecioscorrientes: 2260.86956521739, Medianaporm2apreciosconstantes: 279.457668302902},
 {X: 2529, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 240000.0, Medianaapreciosconstantes: 29665.5063275388, Medianaporm2aprecioscorrientes: 2939.56442831216, Medianaporm2apreciosconstantes: 363.348613117927},
 {X: 3139, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 710394.736842105, Medianaapreciosconstantes: 87809.2481701657, Medianaporm2aprecioscorrientes: 5858.92566467716, Medianaporm2apreciosconstantes: 724.199984908583},
 {X: 3491, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 198910.526315789, Medianaapreciosconstantes: 24586.5894876463, Medianaporm2aprecioscorrientes: 2125.30193236715, Medianaporm2apreciosconstantes: 262.700658010701},
 {X: 3904, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 200000.0, Medianaapreciosconstantes: 24721.255272949, Medianaporm2aprecioscorrientes: 2388.93805309735, Medianaporm2apreciosconstantes: 295.287737209407},
 {X: 4166, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 9270.47072735589, Medianaporm2aprecioscorrientes: 1406.25, Medianaporm2apreciosconstantes: 173.821326137923},
 {X: 4422, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 9270.47072735589, Medianaporm2aprecioscorrientes: 1505.37634408602, Medianaporm2apreciosconstantes: 186.073964420046},
 {X: 4581, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 80000.0, Medianaapreciosconstantes: 9888.50210917961, Medianaporm2aprecioscorrientes: 1753.37837837838, Medianaporm2apreciosconstantes: 216.728572409807},
 {X: 4932, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 70000.0, Medianaapreciosconstantes: 8652.43934553216, Medianaporm2aprecioscorrientes: 1554.44444444444, Medianaporm2apreciosconstantes: 192.139089593642},
 {X: 5027, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2022-10", Medianaaprecioscorrientes: 85000.0, Medianaapreciosconstantes: 10506.5334910033, Medianaporm2aprecioscorrientes: 1777.77777777778, Medianaporm2apreciosconstantes: 219.744491315103},
 {X: 41, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 79500.0, Medianaapreciosconstantes: 9367.68252648284, Medianaporm2aprecioscorrientes: 1714.28571428571, Medianaporm2apreciosconstantes: 201.998545045452},
 {X: 236, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 70000.0, Medianaapreciosconstantes: 8248.27392268929, Medianaporm2aprecioscorrientes: 1547.61904761905, Medianaporm2apreciosconstantes: 182.359797610478},
 {X: 617, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 180000.0, Medianaapreciosconstantes: 21209.8472297725, Medianaporm2aprecioscorrientes: 2390.62072737216, Medianaporm2apreciosconstantes: 281.692780066061},
 {X: 1089, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 90000.0, Medianaapreciosconstantes: 10604.9236148862, Medianaporm2aprecioscorrientes: 1754.66703817219, Medianaporm2apreciosconstantes: 206.75677677083},
 {X: 1444, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 90000.0, Medianaapreciosconstantes: 10604.9236148862, Medianaporm2aprecioscorrientes: 1847.82608695652, Medianaporm2apreciosconstantes: 217.733938952978},
 {X: 1519, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 80000.0, Medianaapreciosconstantes: 9426.59876878776, Medianaporm2aprecioscorrientes: 1719.2118226601, Medianaporm2apreciosconstantes: 202.579000634663},
 {X: 1622, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 72000.0, Medianaapreciosconstantes: 8483.93889190899, Medianaporm2aprecioscorrientes: 1555.55555555556, Medianaporm2apreciosconstantes: 183.294976059763},
 {X: 1914, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 65000.0, Medianaapreciosconstantes: 7659.11149964006, Medianaporm2aprecioscorrientes: 1600.0, Medianaporm2apreciosconstantes: 188.531975375755},
 {X: 2185, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 120000.0, Medianaapreciosconstantes: 14139.8981531816, Medianaporm2aprecioscorrientes: 2301.51515151515, Medianaporm2apreciosconstantes: 271.193248670239},
 {X: 2528, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 238608.695652174, Medianaapreciosconstantes: 28115.8554582105, Medianaporm2aprecioscorrientes: 3253.75494071146, Medianaporm2apreciosconstantes: 383.398028975597},
 {X: 3134, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 864956.52173913, Medianaapreciosconstantes: 101919.976036013, Medianaporm2aprecioscorrientes: 6169.24876243091, Medianaporm2apreciosconstantes: 726.937909853458},
 {X: 3463, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 230000.0, Medianaapreciosconstantes: 27101.4714602648, Medianaporm2aprecioscorrientes: 2386.08695652174, Medianaporm2apreciosconstantes: 281.158554582105},
 {X: 3890, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 290000.0, Medianaapreciosconstantes: 34171.4205368556, Medianaporm2aprecioscorrientes: 2662.11208893006, Medianaporm2apreciosconstantes: 313.683281748539},
 {X: 4190, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 8837.43634573853, Medianaporm2aprecioscorrientes: 1489.36170212766, Medianaporm2apreciosconstantes: 175.495189844453},
 {X: 4483, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 8837.43634573853, Medianaporm2aprecioscorrientes: 1567.2514619883, Medianaporm2apreciosconstantes: 184.673133774497},
 {X: 4636, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 95000.0, Medianaapreciosconstantes: 11194.0860379355, Medianaporm2aprecioscorrientes: 2032.7868852459, Medianaporm2apreciosconstantes: 239.528329370836},
 {X: 4970, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 70000.0, Medianaapreciosconstantes: 8248.27392268929, Medianaporm2aprecioscorrientes: 1578.09157038939, Medianaporm2apreciosconstantes: 185.950450680837},
 {X: 5063, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2022-11", Medianaaprecioscorrientes: 91000.0, Medianaapreciosconstantes: 10722.7560994961, Medianaporm2aprecioscorrientes: 1952.62125035043, Medianaporm2apreciosconstantes: 230.082213430777},
 {X: 20, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 80000.0, Medianaapreciosconstantes: 8969.17104599074, Medianaporm2aprecioscorrientes: 1835.034, Medianaporm2apreciosconstantes: 205.734172765107},
 {X: 215, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 8408.59785561632, Medianaporm2aprecioscorrientes: 1640.079, Medianaporm2apreciosconstantes: 183.876863499218},
 {X: 622, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 200000.0, Medianaapreciosconstantes: 22422.9276149769, Medianaporm2aprecioscorrientes: 2661.29, Medianaporm2apreciosconstantes: 298.369565162309},
 {X: 1105, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 90000.0, Medianaapreciosconstantes: 10090.3174267396, Medianaporm2aprecioscorrientes: 1862.069, Medianaporm2apreciosconstantes: 208.765192005462},
 {X: 1461, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 115000.0, Medianaapreciosconstantes: 12893.1833786117, Medianaporm2aprecioscorrientes: 2190.117, Medianaporm2apreciosconstantes: 245.544174796651},
 {X: 1532, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 88500.0, Medianaapreciosconstantes: 9922.14546962726, Medianaporm2aprecioscorrientes: 1875.0, Medianaporm2apreciosconstantes: 210.214946390408},
 {X: 1639, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 75000.0, Medianaapreciosconstantes: 8408.59785561632, Medianaporm2aprecioscorrientes: 1603.571, Medianaporm2apreciosconstantes: 179.78378229238},
 {X: 1920, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 80000.0, Medianaapreciosconstantes: 8969.17104599074, Medianaporm2aprecioscorrientes: 2000.0, Medianaporm2apreciosconstantes: 224.229276149769},
 {X: 2187, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 120000.0, Medianaapreciosconstantes: 13453.7565689861, Medianaporm2aprecioscorrientes: 2388.119, Medianaporm2apreciosconstantes: 267.743097364755},
 {X: 2534, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 250000.0, Medianaapreciosconstantes: 28028.6595187211, Medianaporm2aprecioscorrientes: 3436.892, Medianaporm2apreciosconstantes: 385.325902682465},
 {X: 3159, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 947261.3, Medianaapreciosconstantes: 106201.857811844, Medianaporm2aprecioscorrientes: 6785.617, Medianaporm2apreciosconstantes: 760.766994069782},
 {X: 3514, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 226695.0, Medianaapreciosconstantes: 25415.8278783859, Medianaporm2aprecioscorrientes: 2562.911, Medianaporm2apreciosconstantes: 287.33983918314},
 {X: 3969, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 250000.0, Medianaapreciosconstantes: 28028.6595187211, Medianaporm2aprecioscorrientes: 2724.953, Medianaporm2apreciosconstantes: 305.50711936607},
 {X: 4169, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 80000.0, Medianaapreciosconstantes: 8969.17104599074, Medianaporm2aprecioscorrientes: 1500.0, Medianaporm2apreciosconstantes: 168.171957112326},
 {X: 4436, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 80000.0, Medianaapreciosconstantes: 8969.17104599074, Medianaporm2aprecioscorrientes: 1707.317, Medianaporm2apreciosconstantes: 191.415227534097},
 {X: 4585, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 85000.0, Medianaapreciosconstantes: 9529.74423636517, Medianaporm2aprecioscorrientes: 2000.0, Medianaporm2apreciosconstantes: 224.229276149769},
 {X: 4933, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 80000.0, Medianaapreciosconstantes: 8969.17104599074, Medianaporm2aprecioscorrientes: 1766.3, Medianaporm2apreciosconstantes: 198.028085231668},
 {X: 5042, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2022-12", Medianaaprecioscorrientes: 95000.0, Medianaapreciosconstantes: 10650.890617114, Medianaporm2aprecioscorrientes: 2085.145, Medianaporm2apreciosconstantes: 233.775277008655},
 {X: 67, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 90000.0, Medianaapreciosconstantes: 9519.16738347496, Medianaporm2aprecioscorrientes: 1914.89361702128, Medianaporm2apreciosconstantes: 202.535476244148},
 {X: 238, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 78000.0, Medianaapreciosconstantes: 8249.9450656783, Medianaporm2aprecioscorrientes: 1777.77777777778, Medianaporm2apreciosconstantes: 188.032935969876},
 {X: 618, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 215800.0, Medianaapreciosconstantes: 22824.8480150433, Medianaporm2aprecioscorrientes: 2781.29395218003, Medianaporm2apreciosconstantes: 294.173363038314},
 {X: 1101, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 98000.0, Medianaapreciosconstantes: 10365.3155953394, Medianaporm2aprecioscorrientes: 2000.0, Medianaporm2apreciosconstantes: 211.53705296611},
 {X: 1446, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 135000.0, Medianaapreciosconstantes: 14278.7510752124, Medianaporm2aprecioscorrientes: 2318.84057971014, Medianaporm2apreciosconstantes: 245.260351265055},
 {X: 1539, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 90000.0, Medianaapreciosconstantes: 9519.16738347496, Medianaporm2aprecioscorrientes: 1943.65079365079, Medianaporm2apreciosconstantes: 205.577080442065},
 {X: 1632, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 80000.0, Medianaapreciosconstantes: 8461.48211864441, Medianaporm2aprecioscorrientes: 1750.0, Medianaporm2apreciosconstantes: 185.094921345346},
 {X: 1931, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 85000.0, Medianaapreciosconstantes: 8990.32475105968, Medianaporm2aprecioscorrientes: 1917.80821917808, Medianaporm2apreciosconstantes: 202.843749419557},
 {X: 2153, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 150000.0, Medianaapreciosconstantes: 15865.2789724583, Medianaporm2aprecioscorrientes: 2808.98876404494, Medianaporm2apreciosconstantes: 297.102602480491},
 {X: 2530, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 270000.0, Medianaapreciosconstantes: 28557.5021504249, Medianaporm2aprecioscorrientes: 3978.61356932153, Medianaporm2apreciosconstantes: 420.812094672626},
 {X: 3125, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 935133.333333333, Medianaapreciosconstantes: 98907.6747318542, Medianaporm2aprecioscorrientes: 7792.77777777778, Medianaporm2apreciosconstantes: 824.230622765452},
 {X: 3443, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 287733.333333333, Medianaapreciosconstantes: 30433.1306867243, Medianaporm2aprecioscorrientes: 3117.11111111111, Medianaporm2apreciosconstantes: 329.692249106181},
 {X: 3893, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 251766.666666667, Medianaapreciosconstantes: 26628.9893508839, Medianaporm2aprecioscorrientes: 2708.33333333333, Medianaporm2apreciosconstantes: 286.456425891607},
 {X: 4191, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 82500.0, Medianaapreciosconstantes: 8725.90343485204, Medianaporm2aprecioscorrientes: 1772.48677248677, Medianaporm2apreciosconstantes: 187.473314136632},
 {X: 4457, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 93513.3333333333, Medianaapreciosconstantes: 9890.76747318542, Medianaporm2aprecioscorrientes: 2044.44444444444, Medianaporm2apreciosconstantes: 216.237876365357},
 {X: 4647, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 90000.0, Medianaapreciosconstantes: 9519.16738347496, Medianaporm2aprecioscorrientes: 2142.85714285714, Medianaporm2apreciosconstantes: 226.646842463689},
 {X: 4944, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 85000.0, Medianaapreciosconstantes: 8990.32475105968, Medianaporm2aprecioscorrientes: 1893.55742296919, Medianaporm2apreciosconstantes: 200.278778438502},
 {X: 5051, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2023-01", Medianaaprecioscorrientes: 110000.0, Medianaapreciosconstantes: 11634.5379131361, Medianaporm2aprecioscorrientes: 2307.69230769231, Medianaporm2apreciosconstantes: 244.081214960897},
 {X: 18, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 100000.0, Medianaapreciosconstantes: 9922.00061314789, Medianaporm2aprecioscorrientes: 2223.07221542228, Medianaporm2apreciosconstantes: 220.573238844919},
 {X: 213, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 85000.0, Medianaapreciosconstantes: 8433.7005211757, Medianaporm2aprecioscorrientes: 1931.81818181818, Medianaporm2apreciosconstantes: 191.675011844902},
 {X: 672, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 299200.0, Medianaapreciosconstantes: 29686.6258345385, Medianaporm2aprecioscorrientes: 3631.06796116505, Medianaporm2apreciosconstantes: 360.274585370613},
 {X: 1127, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 119000.0, Medianaapreciosconstantes: 11807.180729646, Medianaporm2aprecioscorrientes: 2222.22222222222, Medianaporm2apreciosconstantes: 220.488902514397},
 {X: 1488, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 150000.0, Medianaapreciosconstantes: 14883.0009197218, Medianaporm2aprecioscorrientes: 2519.68503937008, Medianaporm2apreciosconstantes: 250.003165055695},
 {X: 1543, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 102500.0, Medianaapreciosconstantes: 10170.0506284766, Medianaporm2aprecioscorrientes: 2193.45138656866, Medianaporm2apreciosconstantes: 217.634260024443},
 {X: 1652, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 98000.0, Medianaapreciosconstantes: 9723.56060088493, Medianaporm2aprecioscorrientes: 1949.15254237288, Medianaporm2apreciosconstantes: 193.394927205425},
 {X: 1876, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 98000.0, Medianaapreciosconstantes: 9723.56060088493, Medianaporm2aprecioscorrientes: 2452.99145299145, Medianaporm2apreciosconstantes: 243.385827006277},
 {X: 2158, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 166650.0, Medianaapreciosconstantes: 16535.014021811, Medianaporm2aprecioscorrientes: 2908.90688259109, Medianaporm2apreciosconstantes: 288.621758726589},
 {X: 2536, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 336600.0, Medianaapreciosconstantes: 33397.4540638558, Medianaporm2aprecioscorrientes: 4675.0, Medianaporm2apreciosconstantes: 463.853528664664},
 {X: 3153, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 1009800.0, Medianaapreciosconstantes: 100192.362191567, Medianaporm2aprecioscorrientes: 8014.28571428571, Medianaporm2apreciosconstantes: 795.177477710852},
 {X: 3524, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 338300.0, Medianaapreciosconstantes: 33566.1280742793, Medianaporm2aprecioscorrientes: 3484.47204968944, Medianaporm2apreciosconstantes: 345.729338135153},
 {X: 3966, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 280500.0, Medianaapreciosconstantes: 27831.2117198798, Medianaporm2aprecioscorrientes: 3291.2, Medianaporm2apreciosconstantes: 326.552884179923},
 {X: 4151, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 90000.0, Medianaapreciosconstantes: 8929.8005518331, Medianaporm2aprecioscorrientes: 1935.48387096774, Medianaporm2apreciosconstantes: 192.038721544798},
 {X: 4423, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 135000.0, Medianaapreciosconstantes: 13394.7008277496, Medianaporm2aprecioscorrientes: 2608.69565217391, Medianaporm2apreciosconstantes: 258.834798603858},
 {X: 4601, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 102000.0, Medianaapreciosconstantes: 10120.4406254108, Medianaporm2aprecioscorrientes: 2356.08552631579, Medianaporm2apreciosconstantes: 233.770820367341},
 {X: 4925, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 100000.0, Medianaapreciosconstantes: 9922.00061314789, Medianaporm2aprecioscorrientes: 2117.64705882353, Medianaporm2apreciosconstantes: 210.112954160779},
 {X: 5069, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2023-02", Medianaaprecioscorrientes: 120000.0, Medianaapreciosconstantes: 11906.4007357775, Medianaporm2aprecioscorrientes: 2539.68253968254, Medianaporm2apreciosconstantes: 251.987317159311},
 {X: 52, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 120000.0, Medianaapreciosconstantes: 11055.1538835826, Medianaporm2aprecioscorrientes: 2428.57142857143, Medianaporm2apreciosconstantes: 223.735257167742},
 {X: 205, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 90000.0, Medianaapreciosconstantes: 8291.36541268692, Medianaporm2aprecioscorrientes: 2116.51583710407, Medianaporm2apreciosconstantes: 194.986735635209},
 {X: 681, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 378173.913043478, Medianaapreciosconstantes: 34839.7566954352, Medianaporm2aprecioscorrientes: 4142.88742845029, Medianaporm2apreciosconstantes: 381.668817032313},
 {X: 1071, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 130000.0, Medianaapreciosconstantes: 11976.4167072144, Medianaporm2aprecioscorrientes: 2474.22680412371, Medianaporm2apreciosconstantes: 227.941317187269},
 {X: 1420, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 190000.0, Medianaapreciosconstantes: 17503.9936490057, Medianaporm2aprecioscorrientes: 2958.3048530417, Medianaporm2apreciosconstantes: 272.537628207709},
 {X: 1522, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 120000.0, Medianaapreciosconstantes: 11055.1538835826, Medianaporm2aprecioscorrientes: 2390.47619047619, Medianaporm2apreciosconstantes: 220.225684506287},
 {X: 1660, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 97000.0, Medianaapreciosconstantes: 8936.24938922924, Medianaporm2aprecioscorrientes: 1944.44444444444, Medianaporm2apreciosconstantes: 179.134437928421},
 {X: 1880, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 104000.0, Medianaapreciosconstantes: 9581.13336577155, Medianaporm2aprecioscorrientes: 2793.33003952568, Medianaporm2apreciosconstantes: 257.339111954918},
 {X: 2186, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 189086.956521739, Medianaapreciosconstantes: 17419.8783477176, Medianaporm2aprecioscorrientes: 3501.61030595813, Medianaporm2apreciosconstantes: 322.590339772548},
 {X: 2513, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 340356.52173913, Medianaapreciosconstantes: 31355.7810258916, Medianaporm2aprecioscorrientes: 5042.3188405797, Medianaporm2apreciosconstantes: 464.530089272468},
 {X: 3127, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 983252.173913043, Medianaapreciosconstantes: 90583.3674081314, Medianaporm2aprecioscorrientes: 8403.86473429952, Medianaporm2apreciosconstantes: 774.216815454115},
 {X: 3519, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 378173.913043478, Medianaapreciosconstantes: 34839.7566954352, Medianaporm2aprecioscorrientes: 3770.68141367913, Medianaporm2apreciosconstantes: 347.378860618228},
 {X: 3971, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 283630.434782609, Medianaapreciosconstantes: 26129.8175215764, Medianaporm2aprecioscorrientes: 3448.27586206897, Medianaporm2apreciosconstantes: 317.676835735131},
 {X: 4158, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 95000.0, Medianaapreciosconstantes: 8751.99682450286, Medianaporm2aprecioscorrientes: 2160.04234895539, Medianaporm2apreciosconstantes: 198.996671356308},
 {X: 4485, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 137500.0, Medianaapreciosconstantes: 12667.3638249383, Medianaporm2aprecioscorrientes: 2912.12592976994, Medianaporm2apreciosconstantes: 268.283335683147},
 {X: 4617, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 120000.0, Medianaapreciosconstantes: 11055.1538835826, Medianaporm2aprecioscorrientes: 2444.44444444444, Medianaporm2apreciosconstantes: 225.197579110015},
 {X: 5006, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 110000.0, Medianaapreciosconstantes: 10133.8910599507, Medianaporm2aprecioscorrientes: 2351.35135135135, Medianaporm2apreciosconstantes: 216.621258529658},
 {X: 5064, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2023-03", Medianaaprecioscorrientes: 135000.0, Medianaapreciosconstantes: 12437.0481190304, Medianaporm2aprecioscorrientes: 2755.10204081633, Medianaporm2apreciosconstantes: 253.817308551641},
 {X: 17, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 150000.0, Medianaapreciosconstantes: 12748.1018044543, Medianaporm2aprecioscorrientes: 2735.96673596674, Medianaporm2apreciosconstantes: 232.522549891363},
 {X: 239, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 117500.0, Medianaapreciosconstantes: 9986.01308015586, Medianaporm2aprecioscorrientes: 2115.38461538462, Medianaporm2apreciosconstantes: 179.78092288333},
 {X: 674, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 402675.0, Medianaapreciosconstantes: 34222.2792940575, Medianaporm2aprecioscorrientes: 4636.11256956841, Medianaporm2apreciosconstantes: 394.010900091789},
 {X: 1093, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 150000.0, Medianaapreciosconstantes: 12748.1018044543, Medianaporm2aprecioscorrientes: 2805.20732883317, Medianaporm2apreciosconstantes: 238.407124070444},
 {X: 1463, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 180000.0, Medianaapreciosconstantes: 15297.7221653452, Medianaporm2aprecioscorrientes: 3030.30303030303, Medianaporm2apreciosconstantes: 257.537410190996},
 {X: 1558, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 139000.0, Medianaapreciosconstantes: 11813.241005461, Medianaporm2aprecioscorrientes: 2710.39426523298, Medianaporm2apreciosconstantes: 230.349213489327},
 {X: 1606, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 115000.0, Medianaapreciosconstantes: 9773.54471674829, Medianaporm2aprecioscorrientes: 2000.0, Medianaporm2apreciosconstantes: 169.974690726057},
 {X: 1905, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 120000.0, Medianaapreciosconstantes: 10198.4814435634, Medianaporm2aprecioscorrientes: 2609.06217070601, Medianaporm2apreciosconstantes: 221.737267775405},
 {X: 2159, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 247800.0, Medianaapreciosconstantes: 21059.8641809585, Medianaporm2aprecioscorrientes: 4588.88888888889, Medianaporm2apreciosconstantes: 389.997484832565},
 {X: 2527, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 371700.0, Medianaapreciosconstantes: 31589.7962714377, Medianaporm2aprecioscorrientes: 5563.43642611684, Medianaporm2apreciosconstantes: 472.821692951646},
 {X: 3156, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 991200.0, Medianaapreciosconstantes: 84239.456723834, Medianaporm2aprecioscorrientes: 9027.33859397417, Medianaporm2apreciosconstantes: 767.20954279508},
 {X: 3518, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 413000.0, Medianaapreciosconstantes: 35099.7736349308, Medianaporm2aprecioscorrientes: 4226.6081871345, Medianaporm2apreciosconstantes: 359.208209714204},
 {X: 3959, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 330400.0, Medianaapreciosconstantes: 28079.8189079447, Medianaporm2aprecioscorrientes: 3849.68325791855, Medianaporm2apreciosconstantes: 327.174360578993},
 {X: 4149, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 185850.0, Medianaapreciosconstantes: 15794.8981357189, Medianaporm2aprecioscorrientes: 2115.38461538462, Medianaporm2apreciosconstantes: 179.78092288333},
 {X: 4449, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 137500.0, Medianaapreciosconstantes: 11685.7599874164, Medianaporm2aprecioscorrientes: 3000.0, Medianaporm2apreciosconstantes: 254.962036089086},
 {X: 4603, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 140000.0, Medianaapreciosconstantes: 11898.228350824, Medianaporm2aprecioscorrientes: 2582.01058201058, Medianaporm2apreciosconstantes: 219.438225064328},
 {X: 5003, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 140000.0, Medianaapreciosconstantes: 11898.228350824, Medianaporm2aprecioscorrientes: 2622.95081967213, Medianaporm2apreciosconstantes: 222.917627181714},
 {X: 5056, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2023-04", Medianaaprecioscorrientes: 155000.0, Medianaapreciosconstantes: 13173.0385312694, Medianaporm2aprecioscorrientes: 2988.19966583124, Medianaporm2apreciosconstantes: 253.959157013686},
 {X: 37, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 135000.0, Medianaapreciosconstantes: 10643.1276632875, Medianaporm2aprecioscorrientes: 3120.94155844156, Medianaporm2apreciosconstantes: 246.048736564096},
 {X: 219, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 130000.0, Medianaapreciosconstantes: 10248.9377498324, Medianaporm2aprecioscorrientes: 2504.11899313502, Medianaporm2apreciosconstantes: 197.419689837029},
 {X: 662, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 570939.130434783, Medianaapreciosconstantes: 45011.6892828425, Medianaporm2aprecioscorrientes: 5657.95534665097, Medianaporm2apreciosconstantes: 446.061785685824},
 {X: 1085, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 168261.956521739, Medianaapreciosconstantes: 13265.4332158177, Medianaporm2aprecioscorrientes: 3243.24324324324, Medianaporm2apreciosconstantes: 255.690754673573},
 {X: 1432, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 237891.304347826, Medianaapreciosconstantes: 18754.8705345177, Medianaporm2aprecioscorrientes: 3675.43859649123, Medianaporm2apreciosconstantes: 289.764164452077},
 {X: 1576, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 142500.0, Medianaapreciosconstantes: 11234.4125334701, Medianaporm2aprecioscorrientes: 3086.6905996543, Medianaporm2apreciosconstantes: 243.348460068075},
 {X: 1633, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 125000.0, Medianaapreciosconstantes: 9854.7478363773, Medianaporm2aprecioscorrientes: 2500.0, Medianaporm2apreciosconstantes: 197.094956727546},
 {X: 1885, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 122500.0, Medianaapreciosconstantes: 9657.65287964976, Medianaporm2aprecioscorrientes: 3000.0, Medianaporm2apreciosconstantes: 236.513948073055},
 {X: 2179, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 300000.0, Medianaapreciosconstantes: 23651.3948073055, Medianaporm2aprecioscorrientes: 5008.23798627003, Medianaporm2apreciosconstantes: 394.839379674058},
 {X: 2514, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 447235.652173913, Medianaapreciosconstantes: 35259.1566048933, Medianaporm2aprecioscorrientes: 6841.31855640806, Medianaporm2apreciosconstantes: 539.355753933842},
 {X: 3152, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 1189456.52173913, Medianaapreciosconstantes: 93774.3526725885, Medianaporm2aprecioscorrientes: 10308.6231884058, Medianaporm2apreciosconstantes: 812.711056495767},
 {X: 3486, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 426602.173913043, Medianaapreciosconstantes: 33632.4548029073, Medianaporm2aprecioscorrientes: 5075.01449275363, Medianaporm2apreciosconstantes: 400.103904736378},
 {X: 3891, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 363418.47826087, Medianaapreciosconstantes: 28651.1796987267, Medianaporm2aprecioscorrientes: 4852.12083476228, Medianaporm2apreciosconstantes: 382.531418385718},
 {X: 4168, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 155000.0, Medianaapreciosconstantes: 12219.8873171079, Medianaporm2aprecioscorrientes: 3203.81231671554, Medianaporm2apreciosconstantes: 252.582099970491},
 {X: 4429, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 142734.782608696, Medianaapreciosconstantes: 11252.9223207106, Medianaporm2aprecioscorrientes: 3833.33333333333, Medianaporm2apreciosconstantes: 302.212266982237},
 {X: 4645, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 155000.0, Medianaapreciosconstantes: 12219.8873171079, Medianaporm2aprecioscorrientes: 3194.44444444444, Medianaporm2apreciosconstantes: 251.843555818531},
 {X: 4984, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 130000.0, Medianaapreciosconstantes: 10248.9377498324, Medianaporm2aprecioscorrientes: 2881.35593220339, Medianaporm2apreciosconstantes: 227.160289109714},
 {X: 5065, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2023-05", Medianaaprecioscorrientes: 177519.782608696, Medianaapreciosconstantes: 13995.3015486177, Medianaporm2aprecioscorrientes: 3620.10484119642, Medianaporm2apreciosconstantes: 285.401762809915},
 {X: 16, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 140000.0, Medianaapreciosconstantes: 10412.5637524184, Medianaporm2aprecioscorrientes: 3384.61538461538, Medianaporm2apreciosconstantes: 251.732310498026},
 {X: 224, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 150000.0, Medianaapreciosconstantes: 11156.3183061626, Medianaporm2aprecioscorrientes: 2980.73989321129, Medianaporm2apreciosconstantes: 221.693886910281},
 {X: 632, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 485434.782608696, Medianaapreciosconstantes: 36104.4330111029, Medianaporm2aprecioscorrientes: 5805.86450960566, Medianaporm2apreciosconstantes: 431.813816744087},
 {X: 1130, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 180000.0, Medianaapreciosconstantes: 13387.5819673951, Medianaporm2aprecioscorrientes: 3562.03007518797, Medianaporm2apreciosconstantes: 264.927608899474},
 {X: 1437, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 270000.0, Medianaapreciosconstantes: 20081.3729510926, Medianaporm2aprecioscorrientes: 4675.32467532467, Medianaporm2apreciosconstantes: 347.729401750521},
 {X: 1540, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 161000.0, Medianaapreciosconstantes: 11974.4483152811, Medianaporm2aprecioscorrientes: 3426.59846547315, Medianaporm2apreciosconstantes: 254.854821254844},
 {X: 1600, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 150000.0, Medianaapreciosconstantes: 11156.3183061626, Medianaporm2aprecioscorrientes: 2777.89119118097, Medianaporm2apreciosconstantes: 206.606922324666},
 {X: 1852, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 150000.0, Medianaapreciosconstantes: 11156.3183061626, Medianaporm2aprecioscorrientes: 3171.42857142857, Medianaporm2apreciosconstantes: 235.876444187437},
 {X: 2183, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 315532.608695652, Medianaapreciosconstantes: 23467.8814572168, Medianaporm2aprecioscorrientes: 5352.11267605634, Medianaporm2apreciosconstantes: 398.06581749688},
 {X: 2517, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 436891.304347826, Medianaapreciosconstantes: 32493.9897099926, Medianaporm2aprecioscorrientes: 7003.1376064545, Medianaporm2apreciosconstantes: 520.861548529758},
 {X: 3144, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 1213344.23913043, Medianaapreciosconstantes: 90243.0303112512, Medianaporm2aprecioscorrientes: 10261.2230470131, Medianaporm2apreciosconstantes: 763.183136820062},
 {X: 3516, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 29750.1821497668, Medianaporm2aprecioscorrientes: 5201.08695652174, Medianaporm2apreciosconstantes: 386.833210833245},
 {X: 3949, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 400483.695652174, Medianaapreciosconstantes: 29786.1572341599, Medianaporm2aprecioscorrientes: 5547.82608695652, Medianaporm2apreciosconstantes: 412.622091555461},
 {X: 4223, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 169951.086956522, Medianaapreciosconstantes: 12640.1894837685, Medianaporm2aprecioscorrientes: 3258.47284605962, Medianaporm2apreciosconstantes: 242.350401750857},
 {X: 4431, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 180000.0, Medianaapreciosconstantes: 13387.5819673951, Medianaporm2aprecioscorrientes: 3398.04347826087, Medianaporm2apreciosconstantes: 252.73103107772},
 {X: 4611, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 175000.0, Medianaapreciosconstantes: 13015.704690523, Medianaporm2aprecioscorrientes: 3857.14285714286, Medianaporm2apreciosconstantes: 286.87675644418},
 {X: 4934, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 154500.0, Medianaapreciosconstantes: 11491.0078553474, Medianaporm2aprecioscorrientes: 3165.6314699793, Medianaporm2apreciosconstantes: 235.445282127296},
 {X: 5070, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2023-06", Medianaaprecioscorrientes: 190000.0, Medianaapreciosconstantes: 14131.3365211392, Medianaporm2aprecioscorrientes: 3883.47826086956, Medianaporm2apreciosconstantes: 288.835464088823},
 {X: 70, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 150000.0, Medianaapreciosconstantes: 10495.1254036192, Medianaporm2aprecioscorrientes: 3702.38095238095, Medianaporm2apreciosconstantes: 259.04634924806},
 {X: 210, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 154228.571428571, Medianaapreciosconstantes: 10790.9879864259, Medianaporm2aprecioscorrientes: 3357.7694235589, Medianaporm2apreciosconstantes: 234.934741177925},
 {X: 630, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 462685.714285714, Medianaapreciosconstantes: 32372.9639592779, Medianaporm2aprecioscorrientes: 6295.04373177842, Medianaporm2apreciosconstantes: 440.448489241876},
 {X: 1056, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 190000.0, Medianaapreciosconstantes: 13293.825511251, Medianaporm2aprecioscorrientes: 4046.40921409214, Medianaporm2apreciosconstantes: 283.117147575047},
 {X: 1457, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 257047.619047619, Medianaapreciosconstantes: 17984.9799773766, Medianaporm2aprecioscorrientes: 4901.96078431373, Medianaporm2apreciosconstantes: 342.97795436664},
 {X: 1568, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 180000.0, Medianaapreciosconstantes: 12594.150484343, Medianaporm2aprecioscorrientes: 3906.25, Medianaporm2apreciosconstantes: 273.310557385916},
 {X: 1623, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 155000.0, Medianaapreciosconstantes: 10844.9629170731, Medianaporm2aprecioscorrientes: 3000.0, Medianaporm2apreciosconstantes: 209.902508072383},
 {X: 1903, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 140000.0, Medianaapreciosconstantes: 9795.45037671123, Medianaporm2aprecioscorrientes: 3582.08955223881, Medianaporm2apreciosconstantes: 250.629860384936},
 {X: 2180, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 359866.666666667, Medianaapreciosconstantes: 25178.9719683273, Medianaporm2aprecioscorrientes: 6075.670995671, Medianaporm2apreciosconstantes: 425.099526737993},
 {X: 2518, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 462685.714285714, Medianaapreciosconstantes: 32372.9639592779, Medianaporm2aprecioscorrientes: 7406.45682001614, Medianaporm2apreciosconstantes: 518.211287483732},
 {X: 3115, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 1182419.04761905, Medianaapreciosconstantes: 82730.9078959325, Medianaporm2aprecioscorrientes: 10886.7226890756, Medianaporm2apreciosconstantes: 761.71679904183},
 {X: 3460, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 436980.952380952, Medianaapreciosconstantes: 30574.4659615402, Medianaporm2aprecioscorrientes: 5666.66666666667, Medianaporm2apreciosconstantes: 396.482515247836},
 {X: 3914, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 411276.19047619, Medianaapreciosconstantes: 28775.9679638025, Medianaporm2aprecioscorrientes: 5675.07730364873, Medianaporm2apreciosconstantes: 397.070986513509},
 {X: 4194, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 175000.0, Medianaapreciosconstantes: 12244.312970889, Medianaporm2aprecioscorrientes: 3291.13924050633, Medianaporm2apreciosconstantes: 230.272793665906},
 {X: 4474, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 205638.095238095, Medianaapreciosconstantes: 14387.9839819013, Medianaporm2aprecioscorrientes: 4285.71428571429, Medianaporm2apreciosconstantes: 299.860725817691},
 {X: 4622, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 210000.0, Medianaapreciosconstantes: 14693.1755650668, Medianaporm2aprecioscorrientes: 4819.27710843373, Medianaporm2apreciosconstantes: 337.192784052021},
 {X: 4972, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 150000.0, Medianaapreciosconstantes: 10495.1254036192, Medianaporm2aprecioscorrientes: 3253.52822580645, Medianaporm2apreciosconstantes: 227.641244893689},
 {X: 5054, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2023-07", Medianaaprecioscorrientes: 215000.0, Medianaapreciosconstantes: 15043.0130785208, Medianaporm2aprecioscorrientes: 4697.98657718121, Medianaporm2apreciosconstantes: 328.706388480243},
 {X: 24, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 200000.0, Medianaapreciosconstantes: 12449.7335781914, Medianaporm2aprecioscorrientes: 4350.38363171356, Medianaporm2apreciosconstantes: 270.805585888792},
 {X: 192, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 192956.25, Medianaapreciosconstantes: 12011.2695237344, Medianaporm2aprecioscorrientes: 4000.0, Medianaporm2apreciosconstantes: 248.994671563827},
 {X: 656, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 514550.0, Medianaapreciosconstantes: 32030.0520632919, Medianaporm2aprecioscorrientes: 7308.94886363636, Medianaporm2apreciosconstantes: 454.972330444486},
 {X: 1059, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 250000.0, Medianaapreciosconstantes: 15562.1669727392, Medianaporm2aprecioscorrientes: 5000.0, Medianaporm2apreciosconstantes: 311.243339454784},
 {X: 1483, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 353753.125, Medianaapreciosconstantes: 22020.6607935132, Medianaporm2aprecioscorrientes: 7080.96330275229, Medianaporm2apreciosconstantes: 440.78053298108},
 {X: 1538, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 220000.0, Medianaapreciosconstantes: 13694.7069360105, Medianaporm2aprecioscorrientes: 4615.38461538462, Medianaporm2apreciosconstantes: 287.301544112109},
 {X: 1636, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 199500.0, Medianaapreciosconstantes: 12418.6092442459, Medianaporm2aprecioscorrientes: 3970.29320987654, Medianaporm2apreciosconstantes: 247.145463451326},
 {X: 1923, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 205000.0, Medianaapreciosconstantes: 12760.9769176462, Medianaporm2aprecioscorrientes: 4422.63783343672, Medianaporm2apreciosconstantes: 275.303313695583},
 {X: 2167, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 482390.625, Medianaapreciosconstantes: 30028.1738093361, Medianaporm2aprecioscorrientes: 7546.73333333333, Medianaporm2apreciosconstantes: 469.77409692828},
 {X: 2524, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 546709.375, Medianaapreciosconstantes: 34031.9303172476, Medianaporm2aprecioscorrientes: 9095.58080808081, Medianaporm2apreciosconstantes: 566.187788997583},
 {X: 3104, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 1286375.0, Medianaapreciosconstantes: 80075.1301582296, Medianaporm2aprecioscorrientes: 13782.5892857143, Medianaporm2apreciosconstantes: 857.94782312389},
 {X: 3456, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 546709.375, Medianaapreciosconstantes: 34031.9303172476, Medianaporm2aprecioscorrientes: 7082.6592027559, Medianaporm2apreciosconstantes: 440.886100497181},
 {X: 3968, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 482390.625, Medianaapreciosconstantes: 30028.1738093361, Medianaporm2aprecioscorrientes: 7392.95977011494, Medianaporm2apreciosconstantes: 460.20189746109},
 {X: 4217, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 185046.25, Medianaapreciosconstantes: 11518.882560717, Medianaporm2aprecioscorrientes: 4056.09118852459, Medianaporm2apreciosconstantes: 252.486273329904},
 {X: 4481, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 250000.0, Medianaapreciosconstantes: 15562.1669727392, Medianaporm2aprecioscorrientes: 4685.47185430464, Medianaporm2apreciosconstantes: 291.664381371035},
 {X: 4659, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 240000.0, Medianaapreciosconstantes: 14939.6802938296, Medianaporm2aprecioscorrientes: 5032.24111414901, Medianaporm2apreciosconstantes: 313.25030586188},
 {X: 4994, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 165000.0, Medianaapreciosconstantes: 10271.0302020079, Medianaporm2aprecioscorrientes: 4000.0, Medianaporm2apreciosconstantes: 248.994671563827},
 {X: 5040, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2023-08", Medianaaprecioscorrientes: 257275.0, Medianaapreciosconstantes: 16015.0260316459, Medianaporm2aprecioscorrientes: 5350.87719298246, Medianaporm2apreciosconstantes: 333.084977311261},
 {X: 49, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 225000.0, Medianaapreciosconstantes: 12427.6399902103, Medianaporm2aprecioscorrientes: 5655.06465517241, Medianaporm2apreciosconstantes: 312.351589581536},
 {X: 217, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 200000.0, Medianaapreciosconstantes: 11046.7911024092, Medianaporm2aprecioscorrientes: 4818.6677631579, Medianaporm2apreciosconstantes: 266.154080857593},
 {X: 663, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 581277.8125, Medianaapreciosconstantes: 32106.2728357643, Medianaporm2aprecioscorrientes: 8475.29069767442, Medianaporm2apreciosconstantes: 468.123829347005},
 {X: 1120, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 305000.0, Medianaapreciosconstantes: 16846.356431174, Medianaporm2aprecioscorrientes: 5777.77777777778, Medianaporm2apreciosconstantes: 319.129520736265},
 {X: 1493, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 400881.25, Medianaapreciosconstantes: 22142.2571281133, Medianaporm2aprecioscorrientes: 7571.9594041578, Medianaporm2apreciosconstantes: 418.229268868269},
 {X: 1564, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 280000.0, Medianaapreciosconstantes: 15465.5075433728, Medianaporm2aprecioscorrientes: 5466.5625, Medianaporm2apreciosconstantes: 301.939869928818},
 {X: 1676, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 220000.0, Medianaapreciosconstantes: 12151.4702126501, Medianaporm2aprecioscorrientes: 4500.0, Medianaporm2apreciosconstantes: 248.552799804206},
 {X: 1932, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 290775.0, Medianaapreciosconstantes: 16060.6534140151, Medianaporm2aprecioscorrientes: 6147.13855421687, Medianaporm2apreciosconstantes: 339.530777429996},
 {X: 2110, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 546656.25, Medianaapreciosconstantes: 30193.9869928818, Medianaporm2aprecioscorrientes: 8724.74253731343, Medianaporm2apreciosconstantes: 481.902041160024},
 {X: 2509, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 583100.0, Medianaapreciosconstantes: 32206.9194590739, Medianaporm2aprecioscorrientes: 10123.2638888889, Medianaporm2apreciosconstantes: 559.14790727559},
 {X: 3142, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 1457750.0, Medianaapreciosconstantes: 80517.2986476848, Medianaporm2aprecioscorrientes: 15845.1086956522, Medianaporm2apreciosconstantes: 875.188028779184},
 {X: 3465, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 600000.0, Medianaapreciosconstantes: 33140.3733072275, Medianaporm2aprecioscorrientes: 8098.61111111111, Medianaporm2apreciosconstantes: 447.318325820471},
 {X: 3922, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 510212.5, Medianaapreciosconstantes: 28181.0545266897, Medianaporm2aprecioscorrientes: 8351.69270833333, Medianaporm2apreciosconstantes: 461.297023502361},
 {X: 4220, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 218662.5, Medianaapreciosconstantes: 12077.5947971527, Medianaporm2aprecioscorrientes: 4285.71428571429, Medianaporm2apreciosconstantes: 236.716952194482},
 {X: 4486, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 364437.5, Medianaapreciosconstantes: 20129.3246619212, Medianaporm2aprecioscorrientes: 8410.09615384615, Medianaporm2apreciosconstantes: 464.522876813566},
 {X: 4658, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 290821.125, Medianaapreciosconstantes: 16063.2010802131, Medianaporm2aprecioscorrientes: 6195.4375, Medianaporm2apreciosconstantes: 342.19851925266},
 {X: 5004, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 218662.5, Medianaapreciosconstantes: 12077.5947971527, Medianaporm2aprecioscorrientes: 4595.82086079388, Medianaporm2apreciosconstantes: 253.845364966421},
 {X: 5084, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2023-09", Medianaaprecioscorrientes: 300000.0, Medianaapreciosconstantes: 16570.1866536137, Medianaporm2aprecioscorrientes: 6666.66666666667, Medianaporm2apreciosconstantes: 368.226370080306},
 {X: 33, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 275290.0, Medianaapreciosconstantes: 14040.0328865317, Medianaporm2aprecioscorrientes: 6571.42857142857, Medianaporm2apreciosconstantes: 335.148655070476},
 {X: 256, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 250000.0, Medianaapreciosconstantes: 12750.2205733333, Medianaporm2aprecioscorrientes: 5555.55555555556, Medianaporm2apreciosconstantes: 283.338234962963},
 {X: 675, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 650000.0, Medianaapreciosconstantes: 33150.5734906666, Medianaporm2aprecioscorrientes: 10406.9230769231, Medianaporm2apreciosconstantes: 530.762258881929},
 {X: 1121, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 287500.0, Medianaapreciosconstantes: 14662.7536593333, Medianaporm2aprecioscorrientes: 6440.09216589862, Medianaporm2apreciosconstantes: 328.450382511213},
 {X: 1481, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 496063.333333333, Medianaapreciosconstantes: 25299.6676733719, Medianaporm2aprecioscorrientes: 9183.67346938776, Medianaporm2apreciosconstantes: 468.375449632653},
 {X: 1548, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 320000.0, Medianaapreciosconstantes: 16320.2823338667, Medianaporm2aprecioscorrientes: 6213.59223300971, Medianaporm2apreciosconstantes: 316.898686094498},
 {X: 1595, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 250000.0, Medianaapreciosconstantes: 12750.2205733333, Medianaporm2aprecioscorrientes: 4952.38095238095, Medianaporm2apreciosconstantes: 252.575798024127},
 {X: 1853, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 270000.0, Medianaapreciosconstantes: 13770.2382192, Medianaporm2aprecioscorrientes: 5411.6, Medianaporm2apreciosconstantes: 275.996374618602},
 {X: 2112, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 631353.333333333, Medianaapreciosconstantes: 32199.5770388369, Medianaporm2aprecioscorrientes: 10823.2, Medianaporm2apreciosconstantes: 551.992749237205},
 {X: 2478, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 721546.666666667, Medianaapreciosconstantes: 36799.5166158137, Medianaporm2aprecioscorrientes: 12025.7777777778, Medianaporm2apreciosconstantes: 613.325276930229},
 {X: 3103, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 2074446.66666667, Medianaapreciosconstantes: 105798.610270464, Medianaporm2aprecioscorrientes: 19603.6231884058, Medianaporm2apreciosconstantes: 999.802078754743},
 {X: 3439, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 750000.0, Medianaapreciosconstantes: 38250.66172, Medianaporm2aprecioscorrientes: 9778.03511530395, Medianaporm2apreciosconstantes: 498.688417975696},
 {X: 3932, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 550000.0, Medianaapreciosconstantes: 28050.4852613333, Medianaporm2aprecioscorrientes: 10367.0498084291, Medianaporm2apreciosconstantes: 528.728687008816},
 {X: 4210, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 227741.666666667, Medianaapreciosconstantes: 11615.0259349542, Medianaporm2aprecioscorrientes: 6086.41358024691, Medianaporm2apreciosconstantes: 310.412462594718},
 {X: 4480, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 360000.0, Medianaapreciosconstantes: 18360.3176256, Medianaporm2aprecioscorrientes: 7842.89855072464, Medianaporm2apreciosconstantes: 399.994745824062},
 {X: 4610, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 325000.0, Medianaapreciosconstantes: 16575.2867453333, Medianaporm2aprecioscorrientes: 7342.28305694481, Medianaporm2apreciosconstantes: 374.462913951578},
 {X: 4929, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 250000.0, Medianaapreciosconstantes: 12750.2205733333, Medianaporm2aprecioscorrientes: 5789.47368421053, Medianaporm2apreciosconstantes: 295.268265908772},
 {X: 5049, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2023-10", Medianaaprecioscorrientes: 330000.0, Medianaapreciosconstantes: 16830.2911568, Medianaporm2aprecioscorrientes: 7258.06451612903, Medianaporm2apreciosconstantes: 370.167694064516},
 {X: 72, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 295000.0, Medianaapreciosconstantes: 13337.9967008877, Medianaporm2aprecioscorrientes: 6691.54228855721, Medianaporm2apreciosconstantes: 302.548369385174},
 {X: 252, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 250000.0, Medianaapreciosconstantes: 11303.3870346506, Medianaporm2aprecioscorrientes: 6000.0, Medianaporm2apreciosconstantes: 271.281288831613},
 {X: 680, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 613761.25, Medianaapreciosconstantes: 27750.3238224837, Medianaporm2aprecioscorrientes: 10526.3157894737, Medianaporm2apreciosconstantes: 475.932085669498},
 {X: 1094, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 300000.0, Medianaapreciosconstantes: 13564.0644415807, Medianaporm2aprecioscorrientes: 6811.86868686869, Medianaporm2apreciosconstantes: 307.988752787575},
 {X: 1450, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 565000.0, Medianaapreciosconstantes: 25545.6546983103, Medianaporm2aprecioscorrientes: 9090.90909090909, Medianaporm2apreciosconstantes: 411.032255805475},
 {X: 1529, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 325000.0, Medianaapreciosconstantes: 14694.4031450457, Medianaporm2aprecioscorrientes: 6656.50080256822, Medianaporm2apreciosconstantes: 300.964019471563},
 {X: 1604, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 260000.0, Medianaapreciosconstantes: 11755.5225160366, Medianaporm2aprecioscorrientes: 5555.55555555556, Medianaporm2apreciosconstantes: 251.18637854779},
 {X: 1878, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 245000.0, Medianaapreciosconstantes: 11077.3192939575, Medianaporm2aprecioscorrientes: 5976.19047619048, Medianaporm2apreciosconstantes: 270.204775780694},
 {X: 2141, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 453375.0, Medianaapreciosconstantes: 20498.6923873388, Medianaporm2aprecioscorrientes: 9411.76470588235, Medianaporm2apreciosconstantes: 425.539276598609},
 {X: 2493, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 725400.0, Medianaapreciosconstantes: 32797.9078197421, Medianaporm2aprecioscorrientes: 11353.711790393, Medianaporm2apreciosconstantes: 513.34159458675},
 {X: 3154, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 2000000.0, Medianaapreciosconstantes: 90427.0962772045, Medianaporm2aprecioscorrientes: 20336.5954751131, Medianaporm2apreciosconstantes: 919.489638489307},
 {X: 3522, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 630000.0, Medianaapreciosconstantes: 28484.5353273194, Medianaporm2aprecioscorrientes: 9460.59929494712, Medianaporm2apreciosconstantes: 427.747261642118},
 {X: 3960, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 634725.0, Medianaapreciosconstantes: 28698.1693422743, Medianaporm2aprecioscorrientes: 10401.4242878561, Medianaporm2apreciosconstantes: 470.285297749008},
 {X: 4180, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 300000.0, Medianaapreciosconstantes: 13564.0644415807, Medianaporm2aprecioscorrientes: 6206.89655172414, Medianaporm2apreciosconstantes: 280.635816032704},
 {X: 4475, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 370000.0, Medianaapreciosconstantes: 16729.0128112828, Medianaporm2aprecioscorrientes: 7631.57894736842, Medianaporm2apreciosconstantes: 345.050762110385},
 {X: 4595, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 300000.0, Medianaapreciosconstantes: 13564.0644415807, Medianaporm2aprecioscorrientes: 7143.86959603118, Medianaporm2apreciosconstantes: 322.999691876053},
 {X: 4965, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 300000.0, Medianaapreciosconstantes: 13564.0644415807, Medianaporm2aprecioscorrientes: 6250.0, Medianaporm2apreciosconstantes: 282.584675866264},
 {X: 5046, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2023-11", Medianaaprecioscorrientes: 330000.0, Medianaapreciosconstantes: 14920.4708857387, Medianaporm2aprecioscorrientes: 7574.28655486908, Medianaporm2apreciosconstantes: 342.460369764141},
 {X: 71, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 12609.3560544313, Medianaporm2aprecioscorrientes: 7305.05876341338, Medianaporm2apreciosconstantes: 263.177391275493},
 {X: 225, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 290000.0, Medianaapreciosconstantes: 10447.7521593859, Medianaporm2aprecioscorrientes: 6153.84615384615, Medianaporm2apreciosconstantes: 221.702963594396},
 {X: 619, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 650000.0, Medianaapreciosconstantes: 23417.3755296581, Medianaporm2aprecioscorrientes: 10339.1768292683, Medianaporm2apreciosconstantes: 372.486748428485},
 {X: 1076, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 12609.3560544313, Medianaporm2aprecioscorrientes: 7749.01960784314, Medianaporm2apreciosconstantes: 279.17184945161},
 {X: 1419, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 18013.3657920447, Medianaporm2aprecioscorrientes: 9296.2356792144, Medianaporm2apreciosconstantes: 334.912987557492},
 {X: 1554, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 360000.0, Medianaapreciosconstantes: 12969.6233702722, Medianaporm2aprecioscorrientes: 7682.58426966292, Medianaporm2apreciosconstantes: 276.778401355293},
 {X: 1597, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 300000.0, Medianaapreciosconstantes: 10808.0194752268, Medianaporm2aprecioscorrientes: 6410.25641025641, Medianaporm2apreciosconstantes: 230.940587077496},
 {X: 1899, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 300000.0, Medianaapreciosconstantes: 10808.0194752268, Medianaporm2aprecioscorrientes: 7468.35443037975, Medianaporm2apreciosconstantes: 269.060400438136},
 {X: 2168, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 16212.0292128402, Medianaporm2aprecioscorrientes: 9569.59706959707, Medianaporm2apreciosconstantes: 344.761304994262},
 {X: 2502, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 654184.210526316, Medianaapreciosconstantes: 23568.118959181, Medianaporm2aprecioscorrientes: 11466.8715846995, Medianaporm2apreciosconstantes: 413.11390469119},
 {X: 3101, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 2163210.52631579, Medianaapreciosconstantes: 77933.4049914556, Medianaporm2aprecioscorrientes: 20384.0, Medianaporm2apreciosconstantes: 734.368896610077},
 {X: 3475, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 599999.0, Medianaapreciosconstantes: 21616.002923722, Medianaporm2aprecioscorrientes: 9509.56937799043, Medianaporm2apreciosconstantes: 342.598703461137},
 {X: 3938, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 508644.736842105, Medianaapreciosconstantes: 18324.8074058703, Medianaporm2aprecioscorrientes: 9182.20136110256, Medianaporm2apreciosconstantes: 330.804703787502},
 {X: 4186, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 315000.0, Medianaapreciosconstantes: 11348.4204489881, Medianaporm2aprecioscorrientes: 6333.33333333333, Medianaporm2apreciosconstantes: 228.169300032566},
 {X: 4418, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 355000.0, Medianaapreciosconstantes: 12789.4897123517, Medianaporm2aprecioscorrientes: 8718.75, Medianaporm2apreciosconstantes: 314.108065998779},
 {X: 4619, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 12609.3560544313, Medianaporm2aprecioscorrientes: 8325.9587020649, Medianaporm2apreciosconstantes: 299.957079339505},
 {X: 4922, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 300000.0, Medianaapreciosconstantes: 10808.0194752268, Medianaporm2aprecioscorrientes: 6666.66666666667, Medianaporm2apreciosconstantes: 240.178210560596},
 {X: 5050, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2023-12", Medianaaprecioscorrientes: 360000.0, Medianaapreciosconstantes: 12969.6233702722, Medianaporm2aprecioscorrientes: 8235.29411764706, Medianaporm2apreciosconstantes: 296.6907306925},
 {X: 78, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 10455.5191143075, Medianaporm2aprecioscorrientes: 7826.08695652174, Medianaporm2apreciosconstantes: 233.788005040415},
 {X: 177, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 300000.0, Medianaapreciosconstantes: 8961.87352654925, Medianaporm2aprecioscorrientes: 6896.55172413793, Medianaporm2apreciosconstantes: 206.020081070098},
 {X: 620, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 614240.0, Medianaapreciosconstantes: 18349.137316492, Medianaporm2aprecioscorrientes: 10636.1904761905, Medianaporm2apreciosconstantes: 317.733979506356},
 {X: 1114, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 390000.0, Medianaapreciosconstantes: 11650.435584514, Medianaporm2aprecioscorrientes: 8297.87234042553, Medianaporm2apreciosconstantes: 247.881608181149},
 {X: 1497, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 14936.4558775821, Medianaporm2aprecioscorrientes: 9411.76470588235, Medianaporm2apreciosconstantes: 281.156816519192},
 {X: 1566, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 380000.0, Medianaapreciosconstantes: 11351.7064669624, Medianaporm2aprecioscorrientes: 7536.23188405797, Medianaporm2apreciosconstantes: 225.129190038918},
 {X: 1651, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 320000.0, Medianaapreciosconstantes: 9559.33176165253, Medianaporm2aprecioscorrientes: 7000.0, Medianaporm2apreciosconstantes: 209.110382286149},
 {X: 1918, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 345000.0, Medianaapreciosconstantes: 10306.1545555316, Medianaporm2aprecioscorrientes: 8302.96296296296, Medianaporm2apreciosconstantes: 248.033679898989},
 {X: 2188, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 550000.0, Medianaapreciosconstantes: 16430.1014653403, Medianaporm2aprecioscorrientes: 10714.2857142857, Medianaporm2apreciosconstantes: 320.066911662473},
 {X: 2526, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 700000.0, Medianaapreciosconstantes: 20911.0382286149, Medianaporm2aprecioscorrientes: 12272.5274725275, Medianaporm2apreciosconstantes: 366.616130199642},
 {X: 3138, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 2456960.0, Medianaapreciosconstantes: 73396.5492659681, Medianaporm2aprecioscorrientes: 23511.5789473684, Medianaporm2apreciosconstantes: 702.359323119312},
 {X: 3489, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 614240.0, Medianaapreciosconstantes: 18349.137316492, Medianaporm2aprecioscorrientes: 10152.7272727273, Medianaporm2apreciosconstantes: 303.291525892431},
 {X: 3958, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 614240.0, Medianaapreciosconstantes: 18349.137316492, Medianaporm2aprecioscorrientes: 10136.8636363636, Medianaporm2apreciosconstantes: 302.817632883222},
 {X: 4209, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 10455.5191143075, Medianaporm2aprecioscorrientes: 8000.0, Medianaporm2apreciosconstantes: 238.983294041313},
 {X: 4484, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 10455.5191143075, Medianaporm2aprecioscorrientes: 9000.0, Medianaporm2apreciosconstantes: 268.856205796477},
 {X: 4628, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 406024.0, Medianaapreciosconstantes: 12129.1191224788, Medianaporm2aprecioscorrientes: 8896.49923896499, Medianaporm2apreciosconstantes: 265.764336695486},
 {X: 4962, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 300000.0, Medianaapreciosconstantes: 8961.87352654925, Medianaporm2aprecioscorrientes: 7259.2, Medianaporm2apreciosconstantes: 216.853441013088},
 {X: 5033, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2024-01", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 11949.1647020657, Medianaporm2aprecioscorrientes: 9090.90909090909, Medianaporm2apreciosconstantes: 271.571925046947},
 {X: 9, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 9236.32430779725, Medianaporm2aprecioscorrientes: 8421.05263157895, Medianaporm2apreciosconstantes: 222.22735176655},
 {X: 216, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 9236.32430779725, Medianaporm2aprecioscorrientes: 7343.13725490196, Medianaporm2apreciosconstantes: 193.78170606555},
 {X: 607, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 600000.0, Medianaapreciosconstantes: 15833.6988133667, Medianaporm2aprecioscorrientes: 10351.9417475728, Medianaporm2apreciosconstantes: 273.182546274308},
 {X: 1109, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 10555.7992089111, Medianaporm2aprecioscorrientes: 8510.63829787234, Medianaporm2apreciosconstantes: 224.591472530024},
 {X: 1454, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 11875.274110025, Medianaporm2aprecioscorrientes: 10000.0, Medianaporm2apreciosconstantes: 263.894980222779},
 {X: 1527, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 380000.0, Medianaapreciosconstantes: 10028.0092484656, Medianaporm2aprecioscorrientes: 8000.0, Medianaporm2apreciosconstantes: 211.115984178223},
 {X: 1659, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 320000.0, Medianaapreciosconstantes: 8444.63936712892, Medianaporm2aprecioscorrientes: 7113.40206185567, Medianaporm2apreciosconstantes: 187.719109643007},
 {X: 1910, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 320000.0, Medianaapreciosconstantes: 8444.63936712892, Medianaporm2aprecioscorrientes: 8000.0, Medianaporm2apreciosconstantes: 211.115984178223},
 {X: 2173, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 511800.0, Medianaapreciosconstantes: 13506.1450878018, Medianaporm2aprecioscorrientes: 10514.4097222222, Medianaporm2apreciosconstantes: 277.469994570002},
 {X: 2481, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 639750.0, Medianaapreciosconstantes: 16882.6813597523, Medianaporm2aprecioscorrientes: 12101.0101010101, Medianaporm2apreciosconstantes: 319.33958212817},
 {X: 3083, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 2132500.0, Medianaapreciosconstantes: 56275.6045325075, Medianaporm2aprecioscorrientes: 23457.5, Medianaporm2apreciosconstantes: 619.031649857583},
 {X: 3468, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 639750.0, Medianaapreciosconstantes: 16882.6813597523, Medianaporm2aprecioscorrientes: 10236.0, Medianaporm2apreciosconstantes: 270.122901756036},
 {X: 3896, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 533125.0, Medianaapreciosconstantes: 14068.9011331269, Medianaporm2aprecioscorrientes: 10526.3157894737, Medianaporm2apreciosconstantes: 277.784189708188},
 {X: 4185, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 9236.32430779725, Medianaporm2aprecioscorrientes: 8274.24568965517, Medianaporm2apreciosconstantes: 218.353190262996},
 {X: 4419, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 370000.0, Medianaapreciosconstantes: 9764.11426824281, Medianaporm2aprecioscorrientes: 8842.1052631579, Medianaporm2apreciosconstantes: 233.338719354878},
 {X: 4590, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 10555.7992089111, Medianaporm2aprecioscorrientes: 8750.0, Medianaporm2apreciosconstantes: 230.908107694931},
 {X: 4942, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 330000.0, Medianaapreciosconstantes: 8708.5343473517, Medianaporm2aprecioscorrientes: 7575.75757575758, Medianaporm2apreciosconstantes: 199.920439562711},
 {X: 5014, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2024-02", Medianaaprecioscorrientes: 420000.0, Medianaapreciosconstantes: 11083.5891693567, Medianaporm2aprecioscorrientes: 9156.6265060241, Medianaporm2apreciosconstantes: 241.63877707146},
 {X: 61, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 370000.0, Medianaapreciosconstantes: 8796.49933979729, Medianaporm2aprecioscorrientes: 8636.36363636364, Medianaporm2apreciosconstantes: 205.323694663573},
 {X: 243, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 8321.01288899743, Medianaporm2aprecioscorrientes: 7586.20689655172, Medianaporm2apreciosconstantes: 180.356929613737},
 {X: 671, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 591066.037735849, Medianaapreciosconstantes: 14052.1946235676, Medianaporm2aprecioscorrientes: 10117.6470588235, Medianaporm2apreciosconstantes: 240.540204522278},
 {X: 1131, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 9509.72901599707, Medianaporm2aprecioscorrientes: 8421.05263157895, Medianaporm2apreciosconstantes: 200.204821389412},
 {X: 1467, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 10698.4451429967, Medianaporm2aprecioscorrientes: 9876.54320987654, Medianaporm2apreciosconstantes: 234.808123851779},
 {X: 1570, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 390000.0, Medianaapreciosconstantes: 9271.98579059714, Medianaporm2aprecioscorrientes: 8053.69127516779, Medianaporm2apreciosconstantes: 191.471054013364},
 {X: 1615, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 320000.0, Medianaapreciosconstantes: 7607.78321279765, Medianaporm2aprecioscorrientes: 7500.0, Medianaporm2apreciosconstantes: 178.307419049945},
 {X: 1875, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 340000.0, Medianaapreciosconstantes: 8083.26966359751, Medianaporm2aprecioscorrientes: 7886.76236044657, Medianaporm2apreciosconstantes: 187.502432153531},
 {X: 2150, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 471852.830188679, Medianaapreciosconstantes: 11217.981376314, Medianaporm2aprecioscorrientes: 9934.93198554679, Medianaporm2apreciosconstantes: 236.196277437279},
 {X: 2473, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 600000.0, Medianaapreciosconstantes: 14264.5935239956, Medianaporm2aprecioscorrientes: 11470.5882352941, Medianaporm2apreciosconstantes: 272.705464429327},
 {X: 3084, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 1973773.58490566, Medianaapreciosconstantes: 46925.1298284648, Medianaporm2aprecioscorrientes: 21809.6528718857, Medianaporm2apreciosconstantes: 518.509721861488},
 {X: 3464, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 592132.075471698, Medianaapreciosconstantes: 14077.5389485394, Medianaporm2aprecioscorrientes: 9868.86792452831, Medianaporm2apreciosconstantes: 234.625649142324},
 {X: 3931, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 592132.075471698, Medianaapreciosconstantes: 14077.5389485394, Medianaporm2aprecioscorrientes: 10494.0066179851, Medianaporm2apreciosconstantes: 249.487898072795},
 {X: 4150, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 8321.01288899743, Medianaporm2aprecioscorrientes: 8285.71428571429, Medianaporm2apreciosconstantes: 196.987243902796},
 {X: 4425, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 394754.716981132, Medianaapreciosconstantes: 9385.02596569295, Medianaporm2aprecioscorrientes: 8450.70422535211, Medianaporm2apreciosconstantes: 200.9097679436},
 {X: 4578, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 410000.0, Medianaapreciosconstantes: 9747.47224139699, Medianaporm2aprecioscorrientes: 9047.61904761905, Medianaporm2apreciosconstantes: 215.101013457077},
 {X: 4935, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 360000.0, Medianaapreciosconstantes: 8558.75611439736, Medianaporm2aprecioscorrientes: 8000.0, Medianaporm2apreciosconstantes: 190.194580319941},
 {X: 5044, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2024-03", Medianaaprecioscorrientes: 420000.0, Medianaapreciosconstantes: 9985.21546679692, Medianaporm2aprecioscorrientes: 9189.18918918919, Medianaporm2apreciosconstantes: 218.466747664797},
 {X: 45, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 360000.0, Medianaapreciosconstantes: 7866.50378234405, Medianaporm2aprecioscorrientes: 8571.42857142857, Medianaporm2apreciosconstantes: 187.29770910343},
 {X: 253, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 7647.98978839005, Medianaporm2aprecioscorrientes: 7736.45320197044, Medianaporm2apreciosconstantes: 169.052328820078},
 {X: 649, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 550000.0, Medianaapreciosconstantes: 12018.2696674701, Medianaporm2aprecioscorrientes: 10243.9024390244, Medianaporm2apreciosconstantes: 223.843603562636},
 {X: 1077, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 8740.55975816006, Medianaporm2aprecioscorrientes: 8794.49328449328, Medianaporm2apreciosconstantes: 192.171985239627},
 {X: 1431, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 470000.0, Medianaapreciosconstantes: 10270.1577158381, Medianaporm2aprecioscorrientes: 9897.5, Medianaporm2apreciosconstantes: 216.274225515973},
 {X: 1520, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 390000.0, Medianaapreciosconstantes: 8522.04576420606, Medianaporm2aprecioscorrientes: 8372.09302325581, Medianaporm2apreciosconstantes: 182.941948426606},
 {X: 1611, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 330000.0, Medianaapreciosconstantes: 7210.96180048205, Medianaporm2aprecioscorrientes: 7500.0, Medianaporm2apreciosconstantes: 163.885495465501},
 {X: 1850, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 7647.98978839005, Medianaporm2aprecioscorrientes: 8000.0, Medianaporm2apreciosconstantes: 174.811195163201},
 {X: 2113, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 494875.0, Medianaapreciosconstantes: 10813.7112757986, Medianaporm2aprecioscorrientes: 10236.2204724409, Medianaporm2apreciosconstantes: 223.675741842678},
 {X: 2454, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 593850.0, Medianaapreciosconstantes: 12976.4535309584, Medianaporm2aprecioscorrientes: 11311.4285714286, Medianaporm2apreciosconstantes: 247.170543446827},
 {X: 3081, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 1979500.0, Medianaapreciosconstantes: 43254.8451031946, Medianaporm2aprecioscorrientes: 21444.5833333333, Medianaporm2apreciosconstantes: 468.594155284607},
 {X: 3450, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 550000.0, Medianaapreciosconstantes: 12018.2696674701, Medianaporm2aprecioscorrientes: 10000.0, Medianaporm2apreciosconstantes: 218.513993954001},
 {X: 3926, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 593850.0, Medianaapreciosconstantes: 12976.4535309584, Medianaporm2aprecioscorrientes: 10529.2553191489, Medianaporm2apreciosconstantes: 230.078963314864},
 {X: 4182, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 330000.0, Medianaapreciosconstantes: 7210.96180048205, Medianaporm2aprecioscorrientes: 7918.0, Medianaporm2apreciosconstantes: 173.019380412778},
 {X: 4450, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 422500.0, Medianaapreciosconstantes: 9232.21624455656, Medianaporm2aprecioscorrientes: 9514.16207710464, Medianaporm2apreciosconstantes: 207.897755459383},
 {X: 4588, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 8740.55975816006, Medianaporm2aprecioscorrientes: 9375.0, Medianaporm2apreciosconstantes: 204.856869331876},
 {X: 5007, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 370000.0, Medianaapreciosconstantes: 8085.01777629805, Medianaporm2aprecioscorrientes: 8035.71428571429, Medianaporm2apreciosconstantes: 175.591602284466},
 {X: 5078, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2024-04", Medianaaprecioscorrientes: 420000.0, Medianaapreciosconstantes: 9177.58774606806, Medianaporm2aprecioscorrientes: 9411.76470588235, Medianaporm2apreciosconstantes: 205.660229603766},
 {X: 2, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 380000.0, Medianaapreciosconstantes: 7968.840469833, Medianaporm2aprecioscorrientes: 8765.58411574573, Medianaporm2apreciosconstantes: 183.819845903368},
 {X: 185, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 7339.7214853725, Medianaporm2aprecioscorrientes: 7969.92481203008, Medianaporm2apreciosconstantes: 167.13436679903},
 {X: 633, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 552604.166666667, Medianaapreciosconstantes: 11588.4590713991, Medianaporm2aprecioscorrientes: 10714.2857142857, Medianaporm2apreciosconstantes: 224.685351593035},
 {X: 1083, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 8388.25312614, Medianaporm2aprecioscorrientes: 8860.75949367089, Medianaporm2apreciosconstantes: 185.815733806899},
 {X: 1441, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 472619.791666667, Medianaapreciosconstantes: 9911.13611230888, Medianaporm2aprecioscorrientes: 10256.4102564103, Medianaporm2apreciosconstantes: 215.08341349077},
 {X: 1518, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 8388.25312614, Medianaporm2aprecioscorrientes: 8433.73493975904, Medianaporm2apreciosconstantes: 176.860758683675},
 {X: 1624, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 7339.7214853725, Medianaporm2aprecioscorrientes: 7777.77777777778, Medianaporm2apreciosconstantes: 163.104921897167},
 {X: 1886, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 7339.7214853725, Medianaporm2aprecioscorrientes: 8400.0, Medianaporm2apreciosconstantes: 176.15331564894},
 {X: 2135, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 10485.316407675, Medianaporm2aprecioscorrientes: 10863.3540372671, Medianaporm2apreciosconstantes: 227.811408658678},
 {X: 2470, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 607864.583333333, Medianaapreciosconstantes: 12747.304978539, Medianaporm2aprecioscorrientes: 11940.2985074627, Medianaporm2apreciosconstantes: 250.395615705672},
 {X: 3089, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 2210416.66666667, Medianaapreciosconstantes: 46353.8362855966, Medianaporm2aprecioscorrientes: 23862.9586330935, Medianaporm2apreciosconstantes: 500.42134338249},
 {X: 3442, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 552604.166666667, Medianaapreciosconstantes: 11588.4590713991, Medianaporm2aprecioscorrientes: 10449.1058222402, Medianaporm2apreciosconstantes: 219.124361446935},
 {X: 3927, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 650000.0, Medianaapreciosconstantes: 13630.9113299775, Medianaporm2aprecioscorrientes: 10695.564516129, Medianaporm2apreciosconstantes: 224.292756220628},
 {X: 4203, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 325000.0, Medianaapreciosconstantes: 6815.45566498875, Medianaporm2aprecioscorrientes: 8000.0, Medianaporm2apreciosconstantes: 167.7650625228},
 {X: 4416, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 442083.333333333, Medianaapreciosconstantes: 9270.7672571193, Medianaporm2aprecioscorrientes: 10361.328125, Medianaporm2apreciosconstantes: 217.283607588734},
 {X: 4635, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 419989.583333333, Medianaapreciosconstantes: 8807.44733835516, Medianaporm2aprecioscorrientes: 9843.71184371184, Medianaporm2apreciosconstantes: 206.428866614593},
 {X: 4957, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 370000.0, Medianaapreciosconstantes: 7759.1341416795, Medianaporm2aprecioscorrientes: 8219.17808219178, Medianaporm2apreciosconstantes: 172.361365605616},
 {X: 5015, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2024-05", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 8388.25312614, Medianaporm2aprecioscorrientes: 9589.04109589041, Medianaporm2apreciosconstantes: 201.088259873219},
 {X: 54, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 390000.0, Medianaapreciosconstantes: 7818.87839258039, Medianaporm2aprecioscorrientes: 9090.90909090909, Medianaporm2apreciosconstantes: 182.258237589286},
 {X: 221, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 360000.0, Medianaapreciosconstantes: 7217.42620853574, Medianaporm2aprecioscorrientes: 8425.92592592593, Medianaporm2apreciosconstantes: 168.926385024885},
 {X: 627, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 590000.0, Medianaapreciosconstantes: 11828.5596195447, Medianaporm2aprecioscorrientes: 11538.4615384615, Medianaporm2apreciosconstantes: 231.327763094093},
 {X: 1067, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 420000.0, Medianaapreciosconstantes: 8420.33057662503, Medianaporm2aprecioscorrientes: 9263.77597109304, Medianaporm2apreciosconstantes: 185.723943010474},
 {X: 1434, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 490000.0, Medianaapreciosconstantes: 9823.71900606254, Medianaporm2aprecioscorrientes: 11034.4827586207, Medianaporm2apreciosconstantes: 221.223791832513},
 {X: 1525, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 8019.3624539286, Medianaporm2aprecioscorrientes: 9230.76923076923, Medianaporm2apreciosconstantes: 185.062210475275},
 {X: 1638, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 360000.0, Medianaapreciosconstantes: 7217.42620853574, Medianaporm2aprecioscorrientes: 7951.80722891566, Medianaporm2apreciosconstantes: 159.421060831111},
 {X: 1887, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 380000.0, Medianaapreciosconstantes: 7618.39433123217, Medianaporm2aprecioscorrientes: 8732.39436619718, Medianaporm2apreciosconstantes: 175.070588782948},
 {X: 2132, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 550000.0, Medianaapreciosconstantes: 11026.6233741518, Medianaporm2aprecioscorrientes: 11351.3513513514, Medianaporm2apreciosconstantes: 227.576502070948},
 {X: 2494, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 631944.444444444, Medianaapreciosconstantes: 12669.4788768664, Medianaporm2aprecioscorrientes: 12638.8888888889, Medianaporm2apreciosconstantes: 253.389577537328},
 {X: 3151, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 2527777.77777778, Medianaapreciosconstantes: 50677.9155074655, Medianaporm2aprecioscorrientes: 27377.0114343308, Medianaporm2apreciosconstantes: 548.865443993116},
 {X: 3504, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 590000.0, Medianaapreciosconstantes: 11828.5596195447, Medianaporm2aprecioscorrientes: 10750.0, Medianaporm2apreciosconstantes: 215.520365949331},
 {X: 3929, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 672500.0, Medianaapreciosconstantes: 13482.5531256675, Medianaporm2aprecioscorrientes: 11719.3930041152, Medianaporm2apreciosconstantes: 234.955150600087},
 {X: 4164, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 350000.0, Medianaapreciosconstantes: 7016.94214718753, Medianaporm2aprecioscorrientes: 8536.58536585366, Medianaporm2apreciosconstantes: 171.144930419208},
 {X: 4412, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 9021.78276066968, Medianaporm2aprecioscorrientes: 10394.6360153257, Medianaporm2apreciosconstantes: 208.395884458892},
 {X: 4580, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 425000.0, Medianaapreciosconstantes: 8520.57260729914, Medianaporm2aprecioscorrientes: 10000.0, Medianaporm2apreciosconstantes: 200.484061348215},
 {X: 4975, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 353888.888888889, Medianaapreciosconstantes: 7094.90817104517, Medianaporm2aprecioscorrientes: 8333.33333333333, Medianaporm2apreciosconstantes: 167.070051123512},
 {X: 5029, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2024-06", Medianaaprecioscorrientes: 420000.0, Medianaapreciosconstantes: 8420.33057662503, Medianaporm2aprecioscorrientes: 10101.0101010101, Medianaporm2apreciosconstantes: 202.509152876985},
 {X: 10, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 7710.92543557793, Medianaporm2aprecioscorrientes: 9666.66666666667, Medianaporm2apreciosconstantes: 186.347364693133},
 {X: 181, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 380000.0, Medianaapreciosconstantes: 7325.37916379904, Medianaporm2aprecioscorrientes: 8750.0, Medianaporm2apreciosconstantes: 168.676493903267},
 {X: 623, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 600000.0, Medianaapreciosconstantes: 11566.3881533669, Medianaporm2aprecioscorrientes: 12187.5, Medianaporm2apreciosconstantes: 234.942259365265},
 {X: 1063, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 430000.0, Medianaapreciosconstantes: 8289.24484324628, Medianaporm2aprecioscorrientes: 9545.45454545455, Medianaporm2apreciosconstantes: 184.010720621746},
 {X: 1447, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 560454.545454546, Medianaapreciosconstantes: 10804.0580250768, Medianaporm2aprecioscorrientes: 11250.0, Medianaporm2apreciosconstantes: 216.869777875629},
 {X: 1559, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 434500.0, Medianaapreciosconstantes: 8375.99275439653, Medianaporm2aprecioscorrientes: 9556.42530984997, Medianaporm2apreciosconstantes: 184.222207487307},
 {X: 1617, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 370000.0, Medianaapreciosconstantes: 7132.60602790959, Medianaporm2aprecioscorrientes: 8333.33333333333, Medianaporm2apreciosconstantes: 160.644279907874},
 {X: 1896, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 420000.0, Medianaapreciosconstantes: 8096.47170735683, Medianaporm2aprecioscorrientes: 9398.79518072289, Medianaporm2apreciosconstantes: 181.183522057059},
 {X: 2119, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 600000.0, Medianaapreciosconstantes: 11566.3881533669, Medianaporm2aprecioscorrientes: 12195.1219512195, Medianaporm2apreciosconstantes: 235.089190109083},
 {X: 2467, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 700568.181818182, Medianaapreciosconstantes: 13505.072531346, Medianaporm2aprecioscorrientes: 13888.8888888889, Medianaporm2apreciosconstantes: 267.740466513123},
 {X: 3086, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 2662159.09090909, Medianaapreciosconstantes: 51319.2756191148, Medianaporm2aprecioscorrientes: 29035.7439816282, Medianaporm2apreciosconstantes: 559.731142022164},
 {X: 3444, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 630511.363636364, Medianaapreciosconstantes: 12154.5652782114, Medianaporm2aprecioscorrientes: 11428.5714285714, Medianaporm2apreciosconstantes: 220.312155302226},
 {X: 3934, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 700000.0, Medianaapreciosconstantes: 13494.1195122614, Medianaporm2aprecioscorrientes: 12328.7671232877, Medianaporm2apreciosconstantes: 237.66551000069},
 {X: 4213, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 7710.92543557793, Medianaporm2aprecioscorrientes: 8888.88888888889, Medianaporm2apreciosconstantes: 171.353898568399},
 {X: 4464, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 475000.0, Medianaapreciosconstantes: 9156.7239547488, Medianaporm2aprecioscorrientes: 10666.6666666667, Medianaporm2apreciosconstantes: 205.624678282079},
 {X: 4612, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 8674.79111502517, Medianaporm2aprecioscorrientes: 10526.3157894737, Medianaporm2apreciosconstantes: 202.919090409946},
 {X: 4961, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 380000.0, Medianaapreciosconstantes: 7325.37916379904, Medianaporm2aprecioscorrientes: 8695.65217391304, Medianaporm2apreciosconstantes: 167.628813816912},
 {X: 5072, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2024-07", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 8674.79111502517, Medianaporm2aprecioscorrientes: 10562.5890172496, Medianaporm2apreciosconstantes: 203.618340796665},
 {X: 75, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 7400.12038005226, Medianaporm2aprecioscorrientes: 10000.0, Medianaporm2apreciosconstantes: 185.003009501306},
 {X: 202, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 7400.12038005226, Medianaporm2aprecioscorrientes: 9032.25806451613, Medianaporm2apreciosconstantes: 167.099492452793},
 {X: 687, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 660757.613636364, Medianaapreciosconstantes: 12224.2147073629, Medianaporm2aprecioscorrientes: 12742.1913892502, Medianaporm2apreciosconstantes: 235.734375465292},
 {X: 1125, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 8325.13542755879, Medianaporm2aprecioscorrientes: 10000.0, Medianaporm2apreciosconstantes: 185.003009501306},
 {X: 1426, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 530272.727272727, Medianaapreciosconstantes: 9810.205040192, Medianaporm2aprecioscorrientes: 11333.3333333333, Medianaporm2apreciosconstantes: 209.670077434813},
 {X: 1517, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 8325.13542755879, Medianaporm2aprecioscorrientes: 10000.0, Medianaporm2apreciosconstantes: 185.003009501306},
 {X: 1591, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 390000.0, Medianaapreciosconstantes: 7215.11737055095, Medianaporm2aprecioscorrientes: 8915.66265060241, Medianaporm2apreciosconstantes: 164.942442205984},
 {X: 1907, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 420000.0, Medianaapreciosconstantes: 7770.12639905487, Medianaporm2aprecioscorrientes: 9895.54924242424, Medianaporm2apreciosconstantes: 183.070639051686},
 {X: 2107, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 600000.0, Medianaapreciosconstantes: 11100.1805700784, Medianaporm2aprecioscorrientes: 12893.7073437073, Medianaporm2apreciosconstantes: 238.537466221495},
 {X: 2469, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 700000.0, Medianaapreciosconstantes: 12950.2106650915, Medianaporm2aprecioscorrientes: 14276.5734265734, Medianaporm2apreciosconstantes: 264.120904928246},
 {X: 3136, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 2650037.95454545, Medianaapreciosconstantes: 49026.4996883595, Medianaporm2aprecioscorrientes: 28281.2121212121, Medianaporm2apreciosconstantes: 523.210935476907},
 {X: 3517, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 650000.0, Medianaapreciosconstantes: 12025.1956175849, Medianaporm2aprecioscorrientes: 11931.1363636364, Medianaporm2apreciosconstantes: 220.729613404321},
 {X: 3975, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 662840.909090909, Medianaapreciosconstantes: 12262.75630024, Medianaporm2aprecioscorrientes: 12549.6621621622, Medianaporm2apreciosconstantes: 232.172526822468},
 {X: 4212, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 397704.545454545, Medianaapreciosconstantes: 7357.653780144, Medianaporm2aprecioscorrientes: 9272.48677248677, Medianaporm2apreciosconstantes: 171.543795847111},
 {X: 4435, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 9250.15047506532, Medianaporm2aprecioscorrientes: 10605.4545454545, Medianaporm2apreciosconstantes: 196.204100803839},
 {X: 4589, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 8325.13542755879, Medianaporm2aprecioscorrientes: 10975.6097560976, Medianaporm2apreciosconstantes: 203.052083598996},
 {X: 4963, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 7400.12038005226, Medianaporm2aprecioscorrientes: 9142.85714285714, Medianaporm2apreciosconstantes: 169.145608686909},
 {X: 5076, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2024-08", Medianaaprecioscorrientes: 460000.0, Medianaapreciosconstantes: 8510.1384370601, Medianaporm2aprecioscorrientes: 10989.010989011, Medianaporm2apreciosconstantes: 203.300010440996},
 {X: 19, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 7149.87476281153, Medianaporm2aprecioscorrientes: 10144.9275362319, Medianaporm2apreciosconstantes: 181.337403404641},
 {X: 227, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 7149.87476281153, Medianaporm2aprecioscorrientes: 9250.0, Medianaporm2apreciosconstantes: 165.340853890017},
 {X: 636, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 650000.0, Medianaapreciosconstantes: 11618.5464895687, Medianaporm2aprecioscorrientes: 12855.9928443649, Medianaporm2apreciosconstantes: 229.796846972026},
 {X: 1091, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 8043.60910816297, Medianaporm2aprecioscorrientes: 10344.8275862069, Medianaporm2apreciosconstantes: 184.910554210643},
 {X: 1462, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 515953.846153846, Medianaapreciosconstantes: 9222.51345847731, Medianaporm2aprecioscorrientes: 11510.7913669065, Medianaporm2apreciosconstantes: 205.751791735584},
 {X: 1542, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 480000.0, Medianaapreciosconstantes: 8579.84971537384, Medianaporm2aprecioscorrientes: 10186.7865790384, Medianaporm2apreciosconstantes: 182.085620689035},
 {X: 1627, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 7149.87476281153, Medianaporm2aprecioscorrientes: 9280.7924203273, Medianaporm2apreciosconstantes: 165.891258762477},
 {X: 1879, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 420000.0, Medianaapreciosconstantes: 7507.36850095211, Medianaporm2aprecioscorrientes: 10476.1904761905, Medianaporm2apreciosconstantes: 187.258624740302},
 {X: 2142, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 614230.769230769, Medianaapreciosconstantes: 10979.1826886635, Medianaporm2aprecioscorrientes: 13125.0, Medianaporm2apreciosconstantes: 234.605265654753},
 {X: 2521, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 680000.0, Medianaapreciosconstantes: 12154.7870967796, Medianaporm2aprecioscorrientes: 13953.488372093, Medianaporm2apreciosconstantes: 249.41423591203},
 {X: 3145, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 2334076.92307692, Medianaapreciosconstantes: 41720.8942169212, Medianaporm2aprecioscorrientes: 26137.4795417349, Medianaporm2apreciosconstantes: 467.199263347383},
 {X: 3511, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 630000.0, Medianaapreciosconstantes: 11261.0527514282, Medianaporm2aprecioscorrientes: 12000.0, Medianaporm2apreciosconstantes: 214.496242884346},
 {X: 3972, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 614230.769230769, Medianaapreciosconstantes: 10979.1826886635, Medianaporm2aprecioscorrientes: 12284.6153846154, Medianaporm2apreciosconstantes: 219.58365377327},
 {X: 4167, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 410000.0, Medianaapreciosconstantes: 7328.62163188182, Medianaporm2aprecioscorrientes: 9573.44782876698, Medianaporm2apreciosconstantes: 171.122382559985},
 {X: 4409, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 491384.615384615, Medianaapreciosconstantes: 8783.34615093077, Medianaporm2aprecioscorrientes: 10968.4065934066, Medianaporm2apreciosconstantes: 196.056833726134},
 {X: 4597, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 8043.60910816297, Medianaporm2aprecioscorrientes: 11007.8204296245, Medianaporm2apreciosconstantes: 196.761343708333},
 {X: 4947, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 7149.87476281153, Medianaporm2aprecioscorrientes: 9346.28474191719, Medianaporm2apreciosconstantes: 167.06191350571},
 {X: 5041, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2024-09", Medianaaprecioscorrientes: 480000.0, Medianaapreciosconstantes: 8579.84971537384, Medianaporm2aprecioscorrientes: 11398.0967486122, Medianaporm2apreciosconstantes: 203.737410717466},
 {X: 44, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 420000.0, Medianaapreciosconstantes: 7309.99854140814, Medianaporm2aprecioscorrientes: 10625.6109481916, Medianaporm2apreciosconstantes: 184.936191744883},
 {X: 189, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 6961.90337276966, Medianaporm2aprecioscorrientes: 9821.42857142857, Medianaporm2apreciosconstantes: 170.939591742112},
 {X: 635, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 640000.0, Medianaapreciosconstantes: 11139.0453964315, Medianaporm2aprecioscorrientes: 12659.5744680851, Medianaporm2apreciosconstantes: 220.336835467976},
 {X: 1075, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7832.14129436587, Medianaporm2aprecioscorrientes: 10714.2857142857, Medianaporm2apreciosconstantes: 186.479554627758},
 {X: 1477, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 8702.37921596208, Medianaporm2aprecioscorrientes: 11900.0, Medianaporm2apreciosconstantes: 207.116625339897},
 {X: 1557, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 8702.37921596208, Medianaporm2aprecioscorrientes: 11111.1111111111, Medianaporm2apreciosconstantes: 193.386204799157},
 {X: 1644, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 400000.0, Medianaapreciosconstantes: 6961.90337276966, Medianaporm2aprecioscorrientes: 9333.33333333333, Medianaporm2apreciosconstantes: 162.444412031292},
 {X: 1928, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7832.14129436587, Medianaporm2aprecioscorrientes: 10710.0, Medianaporm2apreciosconstantes: 186.404962805908},
 {X: 2155, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 650000.0, Medianaapreciosconstantes: 11313.0929807507, Medianaporm2aprecioscorrientes: 13704.1537781706, Medianaporm2apreciosconstantes: 238.517486023},
 {X: 2506, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 680000.0, Medianaapreciosconstantes: 11835.2357337084, Medianaporm2aprecioscorrientes: 13802.8169014085, Medianaporm2apreciosconstantes: 240.234693849095},
 {X: 3098, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 2261000.0, Medianaapreciosconstantes: 39352.1588145805, Medianaporm2aprecioscorrientes: 26038.266509434, Medianaporm2apreciosconstantes: 453.18973858276},
 {X: 3501, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 600000.0, Medianaapreciosconstantes: 10442.8550591545, Medianaporm2aprecioscorrientes: 12101.9108280255, Medianaporm2apreciosconstantes: 210.630834526471},
 {X: 3948, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 680000.0, Medianaapreciosconstantes: 11835.2357337084, Medianaporm2aprecioscorrientes: 11605.3743961353, Medianaporm2apreciosconstantes: 201.988737876772},
 {X: 4211, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 416500.0, Medianaapreciosconstantes: 7249.08188689641, Medianaporm2aprecioscorrientes: 10000.0, Medianaporm2apreciosconstantes: 174.047584319242},
 {X: 4446, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7832.14129436587, Medianaporm2aprecioscorrientes: 10500.0, Medianaporm2apreciosconstantes: 182.749963535204},
 {X: 4607, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 470000.0, Medianaapreciosconstantes: 8180.23646300435, Medianaporm2aprecioscorrientes: 11607.1428571429, Medianaporm2apreciosconstantes: 202.019517513406},
 {X: 4974, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 413250.0, Medianaapreciosconstantes: 7192.51642199265, Medianaporm2aprecioscorrientes: 10151.828847481, Medianaporm2apreciosconstantes: 176.690128732646},
 {X: 5053, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2024-10", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 8702.37921596208, Medianaporm2aprecioscorrientes: 11764.7058823529, Medianaporm2apreciosconstantes: 204.761863904989},
 {X: 28, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7648.57548195756, Medianaporm2aprecioscorrientes: 11014.4927536232, Medianaporm2apreciosconstantes: 187.211509381248},
 {X: 226, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 420000.0, Medianaapreciosconstantes: 7138.67044982705, Medianaporm2aprecioscorrientes: 9842.27521112767, Medianaporm2apreciosconstantes: 167.287521925577},
 {X: 673, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 650000.0, Medianaapreciosconstantes: 11047.9423628276, Medianaporm2aprecioscorrientes: 12686.5671641791, Medianaporm2apreciosconstantes: 215.631481249218},
 {X: 1137, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 490000.0, Medianaapreciosconstantes: 8328.44885813156, Medianaporm2aprecioscorrientes: 11111.1111111111, Medianaporm2apreciosconstantes: 188.85371560389},
 {X: 1503, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 550000.0, Medianaapreciosconstantes: 9348.25892239257, Medianaporm2aprecioscorrientes: 11794.7368421053, Medianaporm2apreciosconstantes: 200.473188948151},
 {X: 1588, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 480000.0, Medianaapreciosconstantes: 8158.48051408806, Medianaporm2aprecioscorrientes: 10958.904109589, Medianaporm2apreciosconstantes: 186.266678403836},
 {X: 1654, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 420000.0, Medianaapreciosconstantes: 7138.67044982705, Medianaporm2aprecioscorrientes: 9722.22222222222, Medianaporm2apreciosconstantes: 165.247001153404},
 {X: 1927, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 443619.047619048, Medianaapreciosconstantes: 7540.11949099647, Medianaporm2aprecioscorrientes: 10526.3157894737, Medianaporm2apreciosconstantes: 178.914046361581},
 {X: 2165, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 620000.0, Medianaapreciosconstantes: 10538.0373306971, Medianaporm2aprecioscorrientes: 13308.5714285714, Medianaporm2apreciosconstantes: 226.203584729894},
 {X: 2462, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 665428.571428571, Medianaapreciosconstantes: 11310.1792364947, Medianaporm2aprecioscorrientes: 13414.6341463415, Medianaporm2apreciosconstantes: 228.006315180307},
 {X: 3093, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 2107190.47619048, Medianaapreciosconstantes: 35815.5675822333, Medianaporm2aprecioscorrientes: 23948.5924487923, Medianaporm2apreciosconstantes: 407.050260069393},
 {X: 3451, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 600000.0, Medianaapreciosconstantes: 10198.1006426101, Medianaporm2aprecioscorrientes: 12025.3164556962, Medianaporm2apreciosconstantes: 204.392312457375},
 {X: 3941, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 652169.047619048, Medianaapreciosconstantes: 11084.8093060237, Medianaporm2aprecioscorrientes: 11090.4761904762, Medianaporm2apreciosconstantes: 188.502987274912},
 {X: 4204, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 420000.0, Medianaapreciosconstantes: 7138.67044982705, Medianaporm2aprecioscorrientes: 10370.3703703704, Medianaporm2apreciosconstantes: 176.263467896965},
 {X: 4469, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7648.57548195756, Medianaporm2aprecioscorrientes: 10810.8108108108, Medianaporm2apreciosconstantes: 183.749561128109},
 {X: 4649, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 8498.41720217506, Medianaporm2aprecioscorrientes: 11935.4838709677, Medianaporm2apreciosconstantes: 202.86544289063},
 {X: 4987, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7648.57548195756, Medianaporm2aprecioscorrientes: 10309.2783505155, Medianaporm2apreciosconstantes: 175.225096952064},
 {X: 5067, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2024-11", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 8498.41720217506, Medianaporm2aprecioscorrientes: 11902.7941755214, Medianaporm2apreciosconstantes: 202.3098215504},
 {X: 65, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7447.49316607973, Medianaporm2aprecioscorrientes: 11351.3513513514, Medianaporm2apreciosconstantes: 187.864692477688},
 {X: 223, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 420000.0, Medianaapreciosconstantes: 6950.99362167441, Medianaporm2aprecioscorrientes: 10000.0, Medianaporm2apreciosconstantes: 165.499848135105},
 {X: 643, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 650000.0, Medianaapreciosconstantes: 10757.4901287818, Medianaporm2aprecioscorrientes: 12727.2727272727, Medianaporm2apreciosconstantes: 210.63617035377},
 {X: 1124, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 490000.0, Medianaapreciosconstantes: 8109.49255862015, Medianaporm2aprecioscorrientes: 11290.9090909091, Medianaporm2apreciosconstantes: 186.864373985273},
 {X: 1486, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 546900.0, Medianaapreciosconstantes: 9051.1866945089, Medianaporm2aprecioscorrientes: 12000.0, Medianaporm2apreciosconstantes: 198.599817762126},
 {X: 1575, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 8274.99240675525, Medianaporm2aprecioscorrientes: 11333.3333333333, Medianaporm2apreciosconstantes: 187.566494553119},
 {X: 1621, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7447.49316607973, Medianaporm2aprecioscorrientes: 10098.5031385804, Medianaporm2apreciosconstantes: 167.130073582694},
 {X: 1856, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 437520.0, Medianaapreciosconstantes: 7240.94935560712, Medianaporm2aprecioscorrientes: 10666.6666666667, Medianaporm2apreciosconstantes: 176.533171344113},
 {X: 2138, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 650000.0, Medianaapreciosconstantes: 10757.4901287818, Medianaporm2aprecioscorrientes: 13138.592750533, Medianaporm2apreciosconstantes: 217.44351049222},
 {X: 2465, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 656280.0, Medianaapreciosconstantes: 10861.4240334107, Medianaporm2aprecioscorrientes: 13592.2330097087, Medianaporm2apreciosconstantes: 224.951249892375},
 {X: 3123, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 1968840.0, Medianaapreciosconstantes: 32584.272100232, Medianaporm2aprecioscorrientes: 23504.541856323, Medianaporm2apreciosconstantes: 388.999810770668},
 {X: 3470, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 600000.0, Medianaapreciosconstantes: 9929.99088810631, Medianaporm2aprecioscorrientes: 12369.7775366251, Medianaporm2apreciosconstantes: 204.719630377649},
 {X: 3944, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 650000.0, Medianaapreciosconstantes: 10757.4901287818, Medianaporm2aprecioscorrientes: 11421.0683229814, Medianaporm2apreciosconstantes: 189.018507299408},
 {X: 4219, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7447.49316607973, Medianaporm2aprecioscorrientes: 10555.5555555556, Medianaporm2apreciosconstantes: 174.694284142612},
 {X: 4456, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 492210.0, Medianaapreciosconstantes: 8146.06802505801, Medianaporm2aprecioscorrientes: 11276.6599597586, Medianaporm2apreciosconstantes: 186.628551081127},
 {X: 4656, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 8274.99240675525, Medianaporm2aprecioscorrientes: 12000.0, Medianaporm2apreciosconstantes: 198.599817762126},
 {X: 4993, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 480000.0, Medianaapreciosconstantes: 7943.99271048504, Medianaporm2aprecioscorrientes: 10614.6304675716, Medianaporm2apreciosconstantes: 175.671973039336},
 {X: 5075, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2024-12", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 8274.99240675525, Medianaporm2aprecioscorrientes: 12000.0, Medianaporm2apreciosconstantes: 198.599817762126},
 {X: 36, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 480000.0, Medianaapreciosconstantes: 7772.98699728322, Medianaporm2aprecioscorrientes: 11688.3116883117, Medianaporm2apreciosconstantes: 189.277280778001},
 {X: 203, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7287.17530995302, Medianaporm2aprecioscorrientes: 10344.8275862069, Medianaporm2apreciosconstantes: 167.521271493173},
 {X: 629, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 663625.0, Medianaapreciosconstantes: 10746.5593668168, Medianaporm2aprecioscorrientes: 13157.8947368421, Medianaporm2apreciosconstantes: 213.075301460615},
 {X: 1111, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 8096.86145550336, Medianaporm2aprecioscorrientes: 11764.7058823529, Medianaporm2apreciosconstantes: 190.514387188314},
 {X: 1455, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 600000.0, Medianaapreciosconstantes: 9716.23374660403, Medianaporm2aprecioscorrientes: 12784.6153846154, Medianaporm2apreciosconstantes: 207.030519062255},
 {X: 1508, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 8096.86145550336, Medianaporm2aprecioscorrientes: 11526.8065268065, Medianaporm2apreciosconstantes: 186.661910943888},
 {X: 1598, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7287.17530995302, Medianaporm2aprecioscorrientes: 10666.6666666667, Medianaporm2apreciosconstantes: 172.733044384072},
 {X: 1882, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7287.17530995302, Medianaporm2aprecioscorrientes: 10810.8108108108, Medianaporm2apreciosconstantes: 175.067274713586},
 {X: 2133, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 663625.0, Medianaapreciosconstantes: 10746.5593668168, Medianaporm2aprecioscorrientes: 13432.8358208955, Medianaporm2apreciosconstantes: 217.527621192627},
 {X: 2510, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 700000.0, Medianaapreciosconstantes: 11335.6060377047, Medianaporm2aprecioscorrientes: 14285.7142857143, Medianaporm2apreciosconstantes: 231.338898728668},
 {X: 3135, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 2413181.81818182, Medianaapreciosconstantes: 39078.3976975158, Medianaporm2aprecioscorrientes: 26813.1313131313, Medianaporm2apreciosconstantes: 434.204418861286},
 {X: 3497, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 600000.0, Medianaapreciosconstantes: 9716.23374660403, Medianaporm2aprecioscorrientes: 12795.3488372093, Medianaporm2apreciosconstantes: 207.204333619439},
 {X: 3970, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 650000.0, Medianaapreciosconstantes: 10525.9198921544, Medianaporm2aprecioscorrientes: 12470.4956575364, Medianaporm2apreciosconstantes: 201.943751241057},
 {X: 4227, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7287.17530995302, Medianaporm2aprecioscorrientes: 11132.4786324786, Medianaporm2apreciosconstantes: 180.276274287061},
 {X: 4415, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 530450.0, Medianaapreciosconstantes: 8589.96031814351, Medianaporm2aprecioscorrientes: 11846.5909090909, Medianaporm2apreciosconstantes: 191.840410621869},
 {X: 4608, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 8096.86145550336, Medianaporm2aprecioscorrientes: 12191.3109756098, Medianaporm2apreciosconstantes: 197.42271186094},
 {X: 4955, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 8096.86145550336, Medianaporm2aprecioscorrientes: 11562.5, Medianaporm2apreciosconstantes: 187.239921158515},
 {X: 5028, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2025-01", Medianaaprecioscorrientes: 550000.0, Medianaapreciosconstantes: 8906.54760105369, Medianaporm2aprecioscorrientes: 12500.0, Medianaporm2apreciosconstantes: 202.421536387584},
 {X: 80, Barrio: "ALMAGRO", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 7930.32463847047, Medianaporm2aprecioscorrientes: 11971.25, Medianaporm2apreciosconstantes: 189.871797656579},
 {X: 229, Barrio: "BALVANERA", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7137.29217462342, Medianaporm2aprecioscorrientes: 10652.1739130435, Medianaporm2apreciosconstantes: 168.950394471763},
 {X: 652, Barrio: "BELGRANO", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 700000.0, Medianaapreciosconstantes: 11102.4544938587, Medianaporm2aprecioscorrientes: 13737.7450980392, Medianaporm2apreciosconstantes: 217.889556856014},
 {X: 1081, Barrio: "CABALLITO", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 550000.0, Medianaapreciosconstantes: 8723.35710231752, Medianaporm2aprecioscorrientes: 12247.4489795918, Medianaporm2apreciosconstantes: 194.252492802534},
 {X: 1439, Barrio: "COLEGIALES", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 600000.0, Medianaapreciosconstantes: 9516.38956616456, Medianaporm2aprecioscorrientes: 13076.9230769231, Medianaporm2apreciosconstantes: 207.408490544613},
 {X: 1535, Barrio: "DEVOTO", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 598562.5, Medianaapreciosconstantes: 9493.58988282896, Medianaporm2aprecioscorrientes: 11690.3096903097, Medianaporm2apreciosconstantes: 185.415901936826},
 {X: 1607, Barrio: "FLORES", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 455000.0, Medianaapreciosconstantes: 7216.59542100813, Medianaporm2aprecioscorrientes: 11111.1111111111, Medianaporm2apreciosconstantes: 176.229436410455},
 {X: 1869, Barrio: "MONTSERRAT", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7137.29217462342, Medianaporm2aprecioscorrientes: 10810.8108108108, Medianaporm2apreciosconstantes: 171.466478669632},
 {X: 2160, Barrio: "NUNEZ", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 680000.0, Medianaapreciosconstantes: 10785.2415083198, Medianaporm2aprecioscorrientes: 14117.6470588235, Medianaporm2apreciosconstantes: 223.915048615636},
 {X: 2520, Barrio: "PALERMO", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 717077.875, Medianaapreciosconstantes: 11373.3206796291, Medianaporm2aprecioscorrientes: 14854.8357664234, Medianaporm2apreciosconstantes: 235.6073401578},
 {X: 3141, Barrio: "PUERTO MADERO", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 2394250.0, Medianaapreciosconstantes: 37974.3595313158, Medianaporm2aprecioscorrientes: 27362.8571428571, Medianaporm2apreciosconstantes: 433.992680357895},
 {X: 3478, Barrio: "RECOLETA", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 622505.0, Medianaapreciosconstantes: 9873.33347814212, Medianaporm2aprecioscorrientes: 13301.3888888889, Medianaporm2apreciosconstantes: 210.968664062866},
 {X: 3916, Barrio: "RETIRO", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 658418.75, Medianaapreciosconstantes: 10442.9488711119, Medianaporm2aprecioscorrientes: 13076.9230769231, Medianaporm2apreciosconstantes: 207.408490544613},
 {X: 4162, Barrio: "SAN NICOLAS", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 450000.0, Medianaapreciosconstantes: 7137.29217462342, Medianaporm2aprecioscorrientes: 11351.3513513514, Medianaporm2apreciosconstantes: 180.039802603114},
 {X: 4452, Barrio: "SAN TELMO", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 522381.875, Medianaapreciosconstantes: 8285.3157080058, Medianaporm2aprecioscorrientes: 11971.25, Medianaporm2apreciosconstantes: 189.871797656579},
 {X: 4642, Barrio: "VILLA CRESPO", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 550000.0, Medianaapreciosconstantes: 8723.35710231752, Medianaporm2aprecioscorrientes: 12857.1428571429, Medianaporm2apreciosconstantes: 203.92263356067},
 {X: 4964, Barrio: "VILLA DEL PARQUE", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 500000.0, Medianaapreciosconstantes: 7930.32463847047, Medianaporm2aprecioscorrientes: 11791.4438502674, Medianaporm2apreciosconstantes: 187.019955377833},
 {X: 5045, Barrio: "VILLA URQUIZA", Inmueble: "Departamento", Mes: "2025-02", Medianaaprecioscorrientes: 550000.0, Medianaapreciosconstantes: 8723.35710231752, Medianaporm2aprecioscorrientes: 12941.1764705882, Medianaporm2apreciosconstantes: 205.255461231}]